import React from "react";
import { StyledStepper } from "./styles";

const BaasStepper = ({ children, ...props }) => {
    return (
        <StyledStepper {...props}>
            {children}
        </StyledStepper>
    );
};

export default BaasStepper;
