import React, { useState } from "react";
import BaasTable from "components/atoms/Table/Table";
import BaasTableHeader from "components/atoms/Table/TableHeader";
import BaasTableHeaderCol from "components/atoms/Table/TableHeaderCol";
import BaasTableRow from "components/atoms/Table/TableRow";
import BaasTableCol from "components/atoms/Table/TableCol";
import BaasGrid from "components/atoms/Grid/Grid";
import MovementReportModal from "./MovementReportModal";
import moment from "moment";

export default function MovementReportList({ data }) {
  const [modal, setModal] = useState(false);
  const [item, setItem] = useState();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      <BaasGrid p="16px 0px">
        <BaasTable>
          <BaasTableHeader>
            <BaasTableHeaderCol flex={2}>PactualId</BaasTableHeaderCol>
            <BaasTableHeaderCol>Last Update Date</BaasTableHeaderCol>
            <BaasTableHeaderCol>Status</BaasTableHeaderCol>
            <BaasTableHeaderCol>TaxId</BaasTableHeaderCol>
            <BaasTableHeaderCol>Name</BaasTableHeaderCol>
            <BaasTableHeaderCol>Total Amount</BaasTableHeaderCol>
            <BaasTableHeaderCol>Total Paid</BaasTableHeaderCol>
            <BaasTableHeaderCol>Discount</BaasTableHeaderCol>
            <BaasTableHeaderCol>Interest</BaasTableHeaderCol>
          </BaasTableHeader>
          {data.map((item, _index) => (
            <>
              <BaasTableRow
                onClick={() => {
                  setItem(item);
                  setModal(true);
                }}
              >
                <BaasTableCol flex={2}>{item.pactualId}</BaasTableCol>
                <BaasTableCol>
                  {moment(item.lastUpdateTimestamp).format("YYYY-MM-DD")}
                </BaasTableCol>
                <BaasTableCol>{item.status}</BaasTableCol>
                {"taxId" in item.body ? (
                  <>
                    <BaasTableCol>{item.body.taxId}</BaasTableCol>
                    <BaasTableCol>{item.body.name}</BaasTableCol>
                    <BaasTableCol>
                      {`${formatter.format(item.body.totalAmountCreated)}`}
                    </BaasTableCol>
                    <BaasTableCol>
                      {formatter.format(item.body.totalAmountPaid)}
                    </BaasTableCol>
                    <BaasTableCol>
                      {formatter.format(item.body.totalDiscount)}
                    </BaasTableCol>
                    <BaasTableCol>
                      {formatter.format(item.body.totalInterest)}
                    </BaasTableCol>
                  </>
                ) : (
                  <>
                    <BaasTableCol>N/A</BaasTableCol>
                    <BaasTableCol>N/A</BaasTableCol>
                    <BaasTableCol>N/A</BaasTableCol>
                    <BaasTableCol>N/A</BaasTableCol>
                    <BaasTableCol>N/A</BaasTableCol>
                    <BaasTableCol>N/A</BaasTableCol>
                  </>
                )}
              </BaasTableRow>
            </>
          ))}
        </BaasTable>
      </BaasGrid>
      {modal ? (
        <MovementReportModal modal={modal} setModal={setModal} data={item} />
      ) : null}
    </>
  );
}
