import React, { useState } from "react";
import BaasGrid from "components/atoms/Grid/Grid";
import EfxInterfaceDownloadListModal from "./EfxInterfaceDownloadListModal";
import DinamicTable from "components/organism/DinamicTable/DinamicTable";
import InfoIcon from "@mui/icons-material/Info";

export default function EfxInterfaceDownloadList({ data, loading }) {
  const [modal, setModal] = useState({ state: false, pactualId: "" });
  const [dataModal, setDataModal] = useState({});

  const conditionalAction = (item) => {
    let conditions = [
      {
        onClick: () => {
          setModal({
            state: true,
            pactualId: item.pactualId,
          });
          setDataModal(item);
        },
        icon: InfoIcon,
        tooltip: "More info",
      },
    ];
    return conditions;
  };

  return (
    <>
      <BaasGrid p="32px 0px">
        <DinamicTable
          loading={loading}
          data={data}
          defaultSort={{
            key: "status",
            orientation: "desc",
          }}
          tableSchema={[
            {
              header: "PactualId",
              key: "pactualId",
              tooltip: true,
              style: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "noWrap",
              },
            },
            {
              header: "Create date",
              key: "createTimestamp",
              sort: true,
            },
            {
              header: "Status",
              key: "status",
              sort: true,
            },
          ]}
          getExtraActions={(data) => conditionalAction(data)}
        />
      </BaasGrid>
      <EfxInterfaceDownloadListModal
        modal={modal}
        setModal={setModal}
        dataModal={dataModal}
      />
    </>
  );
}
