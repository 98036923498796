import { Box, Button, TextField, Typography } from "@mui/material";
import { queryGetSearchBank } from "api/obk-api";
import {
  mutationEnrollment,
  mutationFidoRegistration,
  queryGetEnrollments,
} from "api/openFinance/openFinance-api";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import BaasText from "components/atoms/Text/BaasText";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import OpenFinanceParticipantInfoDialog from "components/organism/OpenFinanceParticipantInfoDialog/OpenFinanceParticipantInfoDialog";
import { encodeBase64 } from "helpers/base64Converter.js";
// import { convertArrayBufferToBase64 } from "helpers/convertArrayBufferToBase64";
import { getNavigatorInfo } from "helpers/getNavigatorInfo";
import { useCustomMutation } from "helpers/useCustomMutation";
import { useCustomQuery } from "helpers/useCustomQuery";
import moment from "moment";
import { openFinanceValues } from "pages/private/OpenFinance/constants/openFinanceValues";
import { useAccount } from "providers/account";
import { useCallback, useEffect, useState } from "react";
import { useWebAuthn } from "react-hook-webauthn";
import guidGenerator from "services/guidGenerator";

export const OpenFinanceParticipantPanel = ({
  enrollmentPactualId,
  enrollmentStatus,
  // getFidoOptions,
  handleBack,
  handleNext,
  loadingFidoOptions,
  setValues,
  values,
  fidoOptionsData,
}) => {
  const [isEnrolling, setIsEnrolling] = useState(false);
  const [enrollmentResponse, setEnrollmentResponse] = useState();
  const { accountSelected } = useAccount();

  const { getCredential } = useWebAuthn({
    rpId: window.location.hostname,
    rpName: fidoOptionsData?.body?.fidoRegistrationOptions?.rp?.name,
  });

  const [banksData, setBanksData] = useState([]);

  const isRegistratingFido = enrollmentStatus === "AWAITING_ENROLLMENT";

  const {
    fetch: loadEnrollments,
    // loading: loadingEnrollments,
    data: enrollmentsData,
  } = useCustomQuery(queryGetEnrollments);

  const searchBank = async () => {
    const response = await queryGetSearchBank();
    setBanksData(response.body);
  };

  const {
    mutate: mutateEnrollment,
    isSubmitting: isSubmittingEnrollment,
  } = useCustomMutation(mutationEnrollment);

  const {
    mutate: mutateFidoRegistration,
    isSubmitting: isSubmittingFidoRegistration,
  } = useCustomMutation(mutationFidoRegistration);

  useEffect(() => {
    searchBank();
  }, []);

  useEffect(() => {
    loadEnrollments({
      values: {
        authorizationServerId: openFinanceValues.authorizationServerId,
        taxId: openFinanceValues.taxId,
      },
    });
  }, [loadEnrollments]);

  const handleSubmitEnrollment = useCallback(() => {
    mutateEnrollment(
      {
        authorizationServer: openFinanceValues.authorizationServerId,
        redirectCallbackUrl: `${window.location.href}?pactualId={pactualId}&status={status}`,
        platform: "BROWSER",
        loggedUser: openFinanceValues.loggedUser,
        businessEntity: openFinanceValues.businessEntity,
        debtorAccount: openFinanceValues.debtorAccount,
        rp: window.location.hostname,
        riskSignals: getNavigatorInfo(
          moment(accountSelected.createTimestamp).format("YYYY-MM-DD")
        ),
        clientRequestId: guidGenerator(),
      },
      (response) => {
        setEnrollmentResponse(response);
        sessionStorage.setItem(
          "opfParams",
          JSON.stringify({
            ...values,
            pactualId: response?.body?.pactualId,
            enrollmentId: response?.body?.body?.enrollment?.enrollmentId,
          })
        );
      }
    );
  }, [accountSelected.createTimestamp, mutateEnrollment, values]);

  const onRegister = useCallback(
    async (e) => {
      const fidoOptions = fidoOptionsData.body.fidoRegistrationOptions;
      const credential = await getCredential({
        ...fidoOptions,
        challenge: fidoOptions.challenge,
        userDisplayName: fidoOptions.user.displayName,
        userId: fidoOptions.user.id,
        userName: fidoOptions.user.name,
      });
      const decoder = new TextDecoder("utf-8");
      const clientDataObject = JSON.parse(
        decoder.decode(credential.response.clientDataJSON)
      );
      const splitOrigin = clientDataObject.origin.split(":");
      const origin = `${splitOrigin[0]}:${splitOrigin[1]}`;

      await mutateFidoRegistration(
        {
          pactualId: fidoOptionsData?.pactualId,
          enrollmentId: fidoOptionsData?.body.enrollment?.enrollmentId,
          enrollmentPactualId: enrollmentPactualId,
          id: credential.id,
          rawId: credential.id,
          clientExtensionResults: {
            credProps: {
              rk: true,
            },
          },
          response: {
            attestationObject: encodeBase64(
              credential.response.attestationObject
            ),
            clientDataJSON: window.btoa(
              JSON.stringify({
                ...clientDataObject,
                origin: origin,
              })
            ),
          },
          authenticatorAttachment: credential.authenticatorAttachment,
          type: credential.type,
        },
        () => handleNext(false)
      );
    },
    [
      handleNext,
      enrollmentPactualId,
      fidoOptionsData,
      getCredential,
      mutateFidoRegistration,
    ]
  );

  return (
    <>
      <BaasGrid item xs={6}>
        <BaasText variant="body2">
          WebAuthn specifies an API for creating and using public key
          credentials. Credentials belong to a user and are managed by an
          authenticator, with which the RP interacts through the client.
        </BaasText>
      </BaasGrid>
      {!isEnrolling && !isRegistratingFido && (
        <>
          {values?.selectedBank?.logoUrl && (
            <BaasGrid item xs={12}>
              <Box display="flex" alignItems={"center"} mt="16px">
                <BaasText variant="body1" sx={{ fontWeight: "bold" }}>
                  Participant Information
                </BaasText>
              </Box>
              <Box display="flex" alignItems={"center"} mt="16px">
                <Box display="flex" alignItems={"center"}>
                  <img
                    alt="logo"
                    src={values?.selectedBank?.logoUrl}
                    width="40px"
                    height="40px"
                  />
                  <Typography variant="body2" ml={1}>
                    {values?.selectedBank?.displayName}
                  </Typography>
                </Box>
                <OpenFinanceParticipantInfoDialog />{" "}
              </Box>
            </BaasGrid>
          )}
          <BaasGrid item xs={12}>
            <Box display="flex" alignItems={"center"} mt="16px">
              <BaasText variant="body1" sx={{ fontWeight: "bold" }}>
                Enrollment Process
              </BaasText>
            </Box>
          </BaasGrid>
        </>
      )}

      {isRegistratingFido && (
        <BaasGrid item xs={6}>
          <Box display="flex" flexDirection="column" mt={2}>
            <TextField
              value={accountSelected.name}
              disabled
              variant="standard"
            />
            <Box mt={3} display="flex" justifyContent={"space-between"}>
              <Button
                variant="outlined"
                onClick={onRegister}
                style={{ minWidth: "120px" }}
                disabled={isSubmittingFidoRegistration}
              >
                {isSubmittingFidoRegistration
                  ? "Fido registration in progress..."
                  : "Register Fido"}
              </Button>
            </Box>
          </Box>
        </BaasGrid>
      )}

      <BaasGrid item xs={12}>
        {!isRegistratingFido && (
          <>
            <BaasRow container column={12} spacing={3}>
              <BaasGrid item xs={3}>
                <BaasInfoDisplay
                  label={"Tax Id:"}
                  value={openFinanceValues.debtorAccount.number}
                />
              </BaasGrid>
              <BaasGrid item xs={3}>
                <BaasInfoDisplay
                  label={"Business Entity:"}
                  value={openFinanceValues.taxId}
                />
              </BaasGrid>
            </BaasRow>
            <BaasGrid item xs={6}>
              <Box
                mb={2}
                mt={2}
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
              >
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isSubmittingEnrollment}
                  onClick={handleSubmitEnrollment}
                  style={{ marginTop: "16px", width: "180px" }}
                >
                  {isSubmittingEnrollment ? "Enroll in progress" : "Enroll Now"}
                </Button>
              </Box>
            </BaasGrid>
          </>
        )}

        {enrollmentResponse?.body?.body?.enrollmentRedirectUrl &&
          !isSubmittingEnrollment && (
            <BaasGrid item xs={6}>
              <Box display="flex" alignItems={"center"} mt="32px">
                <BaasText variant="body1">Redirect Callback URL</BaasText>
              </Box>
              <Box display="flex" alignItems={"center"} mt="16px">
                <BaasText variant="body2">
                  {enrollmentResponse?.body?.body?.enrollmentRedirectUrl}
                </BaasText>
              </Box>
            </BaasGrid>
          )}

        {isSubmittingEnrollment && (
          <BaasGrid item xs={6}>
            <Box mt="32px" width="100%">
              <BaasSkeleton width="100%" mt={1} height={"54.5px"} />
            </Box>
          </BaasGrid>
        )}
      </BaasGrid>
    </>
  );
};
