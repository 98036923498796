import BaasText from "components/atoms/Text/BaasText";
import { labelFormatter } from "helpers/labelFormatter";
import React from "react";

const BaasInfoDisplay = ({ value, label, key, ...props }) => {
  //   const [hiden, setHiden] = useState(false);

  return (
    <>
      <BaasText variant="subtitle2">
        {label ? label : labelFormatter(key)}
      </BaasText>
      <BaasText
        variant="body1"
        style={{ wordBreak: "break-all", opacity: "0.8" }}
        color="grey.600"
      >
        {value}
      </BaasText>
    </>
  );
};

export default BaasInfoDisplay;
