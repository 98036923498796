import styled from "@emotion/styled";
import QRCode from "qrcode.react";

export const StyledQRCode = styled(QRCode)`
  text-align: center;
  padding: 16px;
  box-shadow: 0 1px 4px 0 rgba(50, 50, 50, 0.2);
  background: white;
  border-radius: 4px;
`;
