import { useTheme } from "@mui/material";
import { ToastContainer } from "react-toastify";
import styled from "styled-components";

const ToastContainerStyled = styled(ToastContainer)`
  --toastify-color-success: ${(props) => props.palette.success.main};
  --toastify-color-warning: ${(props) => props.palette.warning.main};
  --toastify-color-error: ${(props) => props.palette.error.main};
  --toastify-color-info: ${(props) => props.palette.primary.light};

  --toastify-font-family: "Moderat-Regular";

  .Toastify__toast {
    border-radius: 4px;
  }
  .Toastify__close-button {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const BaasToastContainer = () => {
  const theme = useTheme();
  return <ToastContainerStyled theme="colored" palette={theme.palette} />;
};

export default BaasToastContainer;
