import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasSelect from "components/atoms/Select/Select";
import React from "react";

const BaasTextFieldAccountType = ({ ...props }) => {
  const transactionType = [
    { name: "CACC", value: "CACC" },
    { name: "SLRY", value: "SLRY" },
    { name: "SVGS", value: "SVGS" },
    { name: "TRAN", value: "TRAN" },
  ];

  return (
    <BaasSelect {...props} renderValue={(value) => value.name}>
      {transactionType.map((item) => (
        <BaasMenuItem value={item}>{item.name}</BaasMenuItem>
      ))}
    </BaasSelect>
  );
};

export default BaasTextFieldAccountType;
