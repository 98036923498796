import { useMediaQuery, useTheme } from "@mui/material";
import BaasHeaderPublic from "components/organism/HeaderPublic/HeaderPublic";
import BaasHeaderPublicMobile from "components/organism/HeaderPublic/HeaderPublicMobile";
import React from "react";

const BaasHeader = ({ ...props }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  return isMobile ? (
    <BaasHeaderPublicMobile />
  ) : (
    <BaasHeaderPublic {...props} />
  );
};

export default BaasHeader;
