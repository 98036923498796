import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/material";
import { useTheme } from "@mui/system";
import {
  mutationDeleteWebhookConfig,
  mutationUpdateWebhookConfig,
  queryListWebhookConfig,
} from "api/pix-apiv2";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import DialogConfirmation from "components/organism/DialogConfirmation/DialogConfirmation";
import DialogInput from "components/organism/DialogInput/DialogInput";
import DinamicTable from "components/organism/DinamicTable/DinamicTable";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import { useCustomQuery } from "helpers/useCustomQuery";
import { useOtp } from "providers/oneTimePassword";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Endpoints from "constants/endpoints";
import StackCard from "components/atoms/StackCard/StackCard";

const PixWebhookConfig = () => {
  const theme = useTheme();
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [submitingUpdate, setSubmitingUpdate] = useState(false);
  const [urlModalOpen, setUrlModalOpen] = useState();
  const { setOpen } = useOtp();

  const isSandbox = Endpoints.Environment === "Sandbox";

  const {
    fetch: loadWebhookConfig,
    loading: loadingWebhookConfig,
    data,
  } = useCustomQuery(queryListWebhookConfig);

  const handleRefetch = useCallback(() => {
    setTimeout(() => {
      setDeleteConfirmation(false);
      setUrlModalOpen(null);
      loadWebhookConfig({
        objectPath: "body.items",
      });
    }, 700);
  }, [loadWebhookConfig]);

  const mutateUpdateWebhookConfig = useCallback(async (values) => {
    const response = await mutationUpdateWebhookConfig(values);
    if (response.chave === values.key) {
      handleRefetch();
      toast.success(toastMessages.requestSuccess);
    } else {
      if ("errors" in response) {
        response.errors.map((error) => {
          return toast.error(error.message);
        });
      }
    }
    setSubmitingUpdate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mutateDeleteWebhookConfig = useCallback(
    async (values) => {
      const response = await mutationDeleteWebhookConfig(values);
      if (response.chave === values.key) {
        handleRefetch();
        toast.success(toastMessages.requestSuccess);
      } else {
        if ("errors" in response) {
          response.errors.map((error) => {
            return toast.error(error.message);
          });
        }
      }
    },
    [handleRefetch]
  );

  useEffect(() => {
    loadWebhookConfig({
      objectPath: "body.items",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const conditionalAction = (item) => {
    let actions = [
      {
        onClick: () => setUrlModalOpen(item),
        icon: EditIcon,
        tooltip: "Update",
      },
      {
        onClick: () => setDeleteConfirmation(item),
        icon: DeleteIcon,
        tooltip: "Delete",
        disabled: !item.webhookUrl,
      },
    ];
    return actions;
  };

  const onSubmitUpdate = useCallback(
    (value) => {
      setSubmitingUpdate(true);
      if (isSandbox) {
        mutateUpdateWebhookConfig({
          webhookUrl: value,
          key: urlModalOpen?.chave,
        });
      } else {
        mutateUpdateWebhookConfig({
          otp: value,
          webhookUrl: urlModalOpen?.webhookUrl,
          key: urlModalOpen?.chave,
        });
      }
    },
    [
      isSandbox,
      mutateUpdateWebhookConfig,
      urlModalOpen?.chave,
      urlModalOpen?.webhookUrl,
    ]
  );

  const onSubmitDelete = useCallback(
    (otp) => {
      mutateDeleteWebhookConfig({
        key: deleteConfirmation?.chave,
        otp: otp,
      });
    },
    [deleteConfirmation?.chave, mutateDeleteWebhookConfig]
  );

  return (
    <BaasFlex flex={1} flexDirection="column">
      <BaasPageHeader>Pix Key Webhook</BaasPageHeader>
      <BaasContentGrid spacing={4}>
        <BaasGrid item spacing={1}>
          <BaasRow>
            <BaasText variant="body2">
              This feature is used to configure a webhook url to a PIX key.
            </BaasText>
          </BaasRow>
        </BaasGrid>
        <StackCard
          sx={{
            "& .MuiGrid-root": {
              padding: "0px 0px",
            },
          }}
        >
          <Formik initialValues={{}} onSubmit={() => {}}>
            {({ values, isSubmitting, ...props }) => (
              <>
                <Form>
                  <BaasOtpModal
                    onConfirm={
                      deleteConfirmation
                        ? () => onSubmitDelete(values.otp)
                        : () => onSubmitUpdate(values.otp)
                    }
                    {...props}
                  />
                  <DinamicTable
                    actionsColWidth="100px"
                    data={data || []}
                    loading={loadingWebhookConfig}
                    defaultSort={{
                      key: "createTimestamp",
                      orientation: "desc",
                    }}
                    getExtraActions={(data) => conditionalAction(data)}
                    tableSchema={[
                      {
                        header: "Key",
                        key: "chave",
                        sort: true,
                      },
                      {
                        header: "Bank/Branch/Account",
                        key: "bankBranchAccount",
                        formatValue: (value) =>
                          value ? value.replaceAll("_", "/") : "N/A",
                        sort: true,
                      },
                      {
                        header: "Webhook Url",
                        key: "webhookUrl",
                        sort: true,
                        style: {
                          maxWidth: "320px",
                          overflowWrap: "anywhere",
                        },
                      },
                    ]}
                  />
                </Form>
                <DialogInput
                  description="Please, insert an URL to the target key."
                  inputLabel="Pix key webhook URL"
                  loading={submitingUpdate}
                  onCancel={() => setUrlModalOpen(undefined)}
                  onSave={
                    isSandbox
                      ? (e) => {
                          onSubmitUpdate(e);
                        }
                      : (e) => {
                          setUrlModalOpen({
                            ...urlModalOpen,
                            webhookUrl: e,
                          });
                          setOpen(true);
                        }
                  }
                  defaultValue={urlModalOpen?.webhookUrl}
                  onSaveLabel="Save"
                  open={Boolean(urlModalOpen)}
                  setOpen={setUrlModalOpen}
                  customStyle={{ minWidth: "500px" }}
                  title="Webhook url"
                />
                <DialogConfirmation
                  open={Boolean(deleteConfirmation)}
                  id={deleteConfirmation?.chave}
                  idLabel={"Key"}
                  setOpen={setDeleteConfirmation}
                  onConfirm={(e) => {
                    isSandbox ? onSubmitDelete() : setOpen(true);
                  }}
                  title="Delete Pix key webhook URL?"
                  content="You have selected to delete this webhook URL, if this was the action that you wanted to do, please confirm your choice or cancel and return to the page."
                />
              </>
            )}
          </Formik>
        </StackCard>
      </BaasContentGrid>
    </BaasFlex>
  );
};

export default PixWebhookConfig;
