import React, { useCallback, useState } from "react";
import BaasTable from "components/atoms/Table/Table";
import BaasTableHeader from "components/atoms/Table/TableHeader";
import BaasTableHeaderCol from "components/atoms/Table/TableHeaderCol";
import BaasTableRow from "components/atoms/Table/TableRow";
import BaasTableCol from "components/atoms/Table/TableCol";
import BaasGrid from "components/atoms/Grid/Grid";
import { Form, Formik } from "formik";
import { useOtp } from "providers/oneTimePassword";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import { toastMessages } from "constants/toastMessages";
import { toast } from "react-toastify";
import { mutationPostPaymentPaymentSlipCnabFileByPactualId } from "api/cnab-api";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import BaasIconButton from "components/atoms/IconButton/IconButton";
import moment from "moment";
import converterFromBase64 from "helpers/converterFromBase64";
import { isNewCnabProfile } from "helpers/isNewCnabProfile";

const CnabSettlementDownloadList = ({ data }) => {
  const { setOpen } = useOtp();

  const [fileName, setFileName] = useState("");

  const download = useCallback(async (values) => {
    const response = await mutationPostPaymentPaymentSlipCnabFileByPactualId(
      values
    );
    if (response.body) {
      toast.success("Your download will start shortly.");
      converterFromBase64(
        `baas_settlement_file_${moment().format()}.txt`,
        response.body,
        "txt"
      );
    } else {
      toast.error(toastMessages.cnab.download.error);
    }
    return response;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownload = (item) => {
    setOpen(true);
    setFileName(item);
  };

  const onSubmit = useCallback(
    (values) => {
      download({
        fileName: fileName,
        otp: values.otp,
        newCnab: isNewCnabProfile(),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fileName]
  );

  return (
    <>
      <BaasGrid p="32px 0px">
        <BaasTable>
          <BaasTableHeader>
            <BaasTableHeaderCol>File</BaasTableHeaderCol>
            <BaasTableHeaderCol width="64px">Download</BaasTableHeaderCol>
          </BaasTableHeader>
          <Formik initialValues={{}}>
            {({ values, isSubmitting, ...props }) => (
              <Form>
                <BaasOtpModal
                  onConfirm={() => onSubmit(values, props)}
                  {...props}
                />
                {data.map((item) => (
                  <>
                    <BaasTableRow>
                      <BaasTableCol>
                        {" "}
                        Settlement_file_{item.dateReference}
                      </BaasTableCol>
                      <BaasTableCol width="64px">
                        <BaasIconButton
                          onClick={() => handleDownload(item.fileName)}
                          color="grey"
                          variant="outlined"
                          tooltip="Download CNAB file"
                        >
                          <FileDownloadIcon fontSize="medium" />
                        </BaasIconButton>
                      </BaasTableCol>
                    </BaasTableRow>
                  </>
                ))}
              </Form>
            )}
          </Formik>
        </BaasTable>
      </BaasGrid>
    </>
  );
};

export default CnabSettlementDownloadList;
