import QrCodeIcon from "@mui/icons-material/QrCode";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import BaasTransactionSuccess from "components/templates/TransactionSuccess/TransactionSuccess";
import React, { useState } from "react";
import DueDateEmvPanel from "./components/DueDateEmvPanel";
import EmvSelector from "./components/EmvSelector";
import ImmediateEmvPanel from "./components/ImmediateEmvPanel";
import StackCard from "components/atoms/StackCard/StackCard";

const QrCodeTester = () => {
  const theme = useTheme();
  const [emvType, setEmvType] = useState("immediate");
  const [success, setSuccess] = useState(false);

  return (
    <BaasFlex flex={1} flexDirection="column">
      {!success ? (
        <>
          <BaasPageHeader>BaaS Tester</BaasPageHeader>
          <BaasGrid
            item
            spacing={1}
            style={{ padding: "16px", marginBottom: "16px" }}
          >
            <BaasRow>
              <BaasText variant="h6">QR Code Tester</BaasText>
            </BaasRow>
            <BaasText variant="body2">
              Please, select the type of QR Code you want to generate. This flow
              is used to test the Cash-out process.
              <Box>
                <Typography fontWeight="bold" color="error" variant="body2">
                  Emvs created from QR Code Tester are not accepted in the QR
                  Code Payment feature.
                </Typography>
              </Box>
            </BaasText>
          </BaasGrid>
          <BaasContentGrid spacing={4}>
            <StackCard>
              <BaasGrid item style={{ padding: "16px", paddingBottom: "0px" }}>
                <Box display="flex">
                  <EmvSelector
                    handleClick={() => setEmvType("immediate")}
                    active={emvType === "immediate"}
                    label="Immediate collection"
                    icon={QrCodeIcon}
                  />
                  <EmvSelector
                    handleClick={() => setEmvType("dueDate")}
                    active={emvType === "dueDate"}
                    label="Due date collection"
                    icon={QrCode2Icon}
                  />
                </Box>
              </BaasGrid>
              <BaasGrid item style={{ padding: "16px" }}>
                {emvType === "immediate" ? (
                  <ImmediateEmvPanel setSuccess={setSuccess} />
                ) : (
                  <DueDateEmvPanel setSuccess={setSuccess} />
                )}
              </BaasGrid>
            </StackCard>
          </BaasContentGrid>
        </>
      ) : (
        <BaasTransactionSuccess
          pactualId={success}
          idLabel={"Emv"}
          onClick={() => {
            setSuccess(undefined);
          }}
        />
      )}
    </BaasFlex>
  );
};

export default QrCodeTester;
