import { useCallback, useState } from "react";
import { toastMessages } from "constants/toastMessages";
import { toast } from "react-toastify";

export const useCustomMutation = (mutationFunction) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const mutate = useCallback(async (values, onCompleted, onError) => {
    setIsSubmitting(true);
    const response = await mutationFunction(values);
    if (response.status < 300) {
      toast.success(toastMessages.requestSuccess);
      onCompleted?.(response);
    } else {
      if ("errors" in response) {
        response.errors.map((error) => {
          setIsSubmitting(false);
          if (onError) {
            onError(error);
          } else {
            return toast.error(error.message);
          }
        });
      }
      if (response.status === 422) {
        setIsSubmitting(false);
        return toast.error("Sorry, there was a problem with your request");
      }
    }
    setIsSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    mutate,
    isSubmitting,
  };
};
