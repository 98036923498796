import { useTheme } from "@mui/system";
import BaasButton from "components/atoms/Button/Button";
import BaasContainer from "components/atoms/Container/Container";
import {
  default as BaasGrid,
  default as BaasRow,
} from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import { Form, Formik } from "formik";
import React, { useCallback, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import { mutationPostUserMfaNew } from "api/security-api";

const LoginRegisterNewOtp = () => {
  const theme = useTheme();
  const history = useHistory();
  const [reCaptcha, setReCaptcha] = useState("");
  const [validated, setValidated] = useState(false);
  const [send, setSend] = useState(false);

  const { mutate } = useMutation((a) => mutationPostUserMfaNew(a), {
    onSettled: () => {
      setSend(true);
    },
  });

  const verifyReCaptcha = (response) => {
    if (response !== null) {
      setValidated(true);
      setReCaptcha(response);
    } else {
      setValidated(false);
    }
  };

  const handleRedirect = () => {
    history.push("/login");
  };

  const onSubmit = useCallback(
    (values, formik) => {
      mutate(
        {
          userId: values.email,
          password: values.password,
          reCaptcha: reCaptcha,
        },
        {
          onSettled: () => {
            formik.setSubmitting(false);
          },
        }
      );
    },
    [mutate, reCaptcha]
  );

  return (
    <>
      <BaasContainer width="100%">
        <Formik initialValues={{}} onSubmit={onSubmit}>
          {({ isSubmitting, values, ...props }) => (
            <Form>
              <BaasGrid container spacing={2} sx={{ marginTop: "32px" }}>
                <BaasGrid xs={0} md={3} />
                <BaasGrid
                  xs={12}
                  md={6}
                  sx={{
                    backgroundColor: theme.palette.grey[300],
                    borderRadius: "4px",
                  }}
                  p="32px"
                >
                  {send ? (
                    <>
                      <BaasGrid p="0px 0px 32px 0px ">
                        <BaasText variant="h4" color="grey.700">
                          Requisition sent!
                        </BaasText>
                      </BaasGrid>
                      <BaasRow>
                        <BaasText variant="body1" color="grey.700">
                          An email was sent to your registered account. Follow
                          the instructions in it to complete this proccess.
                        </BaasText>
                      </BaasRow>

                      <BaasGrid
                        display="flex"
                        justifyContent="center"
                        p="32px 0px 0px 0px"
                      >
                        <BaasButton
                          size="medium"
                          variant="contained"
                          onClick={handleRedirect}
                          loading={isSubmitting}
                        >
                          Back to login
                        </BaasButton>
                      </BaasGrid>
                    </>
                  ) : (
                    <>
                      <BaasGrid p="0px 0px 32px 0px ">
                        <BaasText variant="h4" color="grey.700">
                          Register new One Time Password
                        </BaasText>
                      </BaasGrid>
                      <BaasRow>
                        <BaasText variant="body2" color="grey.700">
                          Fill the fields bellow with your registered email
                          account.
                        </BaasText>
                      </BaasRow>
                      <BaasGrid p="16px 0px">
                        <BaasTextField
                          name="email"
                          fullWidth
                          label="Email"
                          required
                        />
                      </BaasGrid>
                      <BaasGrid p="16px 0px">
                        <BaasTextField
                          name="password"
                          label="Password"
                          type="password"
                          fullWidth
                          required
                        />
                      </BaasGrid>
                      <BaasGrid
                        display="flex"
                        justifyContent="center"
                        p="0px 0px 32px 0px "
                      >
                        <ReCAPTCHA
                          render="explicit"
                          sitekey="6LcyC-8UAAAAACvi8DnSyiq7e_f2qRlR9ODYqV_B"
                          onChange={verifyReCaptcha}
                        />
                      </BaasGrid>
                      <BaasGrid display="flex" justifyContent="center">
                        <BaasButton
                          type="submit"
                          size="medium"
                          variant="contained"
                          loading={isSubmitting}
                          disabled={!validated}
                        >
                          Register password change
                        </BaasButton>
                      </BaasGrid>
                    </>
                  )}
                </BaasGrid>
              </BaasGrid>
            </Form>
          )}
        </Formik>
      </BaasContainer>
    </>
  );
};

export default LoginRegisterNewOtp;
