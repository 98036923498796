import { useTheme } from "@mui/system";
import SupermarketIcon from "assets/icons/Supermarket.svg";
import BaasButton from "components/atoms/Button/Button";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import { currencyFormatter } from "helpers/currencyFormatter";

const BaasObkShoppingCart = ({ items, checkOut, total }) => {
  const theme = useTheme();

  return (
    <BaasGrid
      spacing={2}
      p={3}
      sx={{
        borderRadius: "4px",
        backgroundColor: theme.palette.grey[100],
        position: "sticky",
        top: "16px",
      }}
    >
      <BaasGrid container>
        <BaasGrid p="8px 0px">
          <BaasText variant="h6">
            <span>
              <img
                src={SupermarketIcon}
                alt=""
                style={{
                  background: theme.palette.grey[400],
                  width: "36px",
                  height: "36px",
                  borderRadius: "100%",
                  textAlign: "center",
                  verticalAlign: "middle",
                  padding: "6px",
                }}
              />
            </span>{" "}
            Shopping Cart
          </BaasText>
        </BaasGrid>
        {items.map((item) => (
          <BaasGrid xs={12} p="8px 0">
            <BaasText variant="body2" color="grey.700">
              <span style={{ color: theme.palette.grey[500] }}>
                {item.quantity}x
              </span>{" "}
              {item.description}
            </BaasText>
          </BaasGrid>
        ))}

        {items.length === 0 ? (
          <BaasGrid xs={12} p="12px 0">
            <BaasText variant="body2" color="grey.500">
              Your cart is empty
            </BaasText>
          </BaasGrid>
        ) : null}

        <hr style={{ width: "100%", margin: "16px 0", strokeWidth: 0.5 }} />

        <BaasGrid xs={12} p="8px 0">
          <BaasText variant="body2" color="grey.600">
            <strong>Total:</strong>
          </BaasText>
          <BaasText variant="h6" color="grey.700">
            <strong>R$ {currencyFormatter(total(items))}</strong>
          </BaasText>
        </BaasGrid>

        <BaasGrid xs={12} p="8px 0">
          <BaasButton
            variant="contained"
            backgroundColor="black"
            disabled={items.length === 0}
            onClick={() => checkOut()}
          >
            Check Out
          </BaasButton>
        </BaasGrid>
      </BaasGrid>
    </BaasGrid>
  );
};

export default BaasObkShoppingCart;
