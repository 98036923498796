import BaasRow from "components/atoms/Grid/Row";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasButton from "components/atoms/Button/Button";

const FormActions = ({
  onCancel,
  cancelLabel = "Cancel",
  isSubmitting,
  submitLabel,
  submitDisabled,
}) => {
  return (
    <BaasRow
      container
      sx={{ justifyContent: onCancel ? "space-between" : "center" }}
      flexDirection="row"
      p="16px 0px"
    >
      {onCancel && (
        <BaasGrid item>
          <BaasButton
            size="medium"
            variant="outlined"
            backgroundColor="black"
            onClick={onCancel}
          >
            {cancelLabel}
          </BaasButton>
        </BaasGrid>
      )}
      <BaasGrid item>
        <BaasButton
          variant="contained"
          type="submit"
          loading={isSubmitting}
          disabled={submitDisabled}
        >
          {submitLabel}
        </BaasButton>
      </BaasGrid>
    </BaasRow>
  );
};

export default FormActions;
