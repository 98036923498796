import React from "react";
import styled from "styled-components";

const BaasTableStyle = styled.div`
  border-radius: 4px;
  overflow: scroll;
`;

const BaasTable = ({ children }) => {
  return <BaasTableStyle>{children}</BaasTableStyle>;
};

export default BaasTable;
