import moment from "moment";

export default function intervalDate(startDate, endDate, days) {
  const intervalDates = moment(endDate).diff(moment(startDate));
  if (intervalDates / (1000 * 60 * 60 * 24) > days) {
    return false;
  } else return true;
}

// startDate, endDate, days (interval of days)
