import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { Alert, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/system";
import {
  mutationPostAuthorizePixKey,
  mutationPostRejectPixKey,
  mutationPostValidatePixKey,
} from "api/obk-api";
import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasCardWithIcon from "components/molecules/CardWithIcon/CardWithIcon";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import { useInterval } from "helpers/useInterval";
import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import BankBranch from "assets/icons/Bank-Branch.svg";

export default function MockBankApproval() {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [warningOpen, setWarningOpen] = useState(true);
  const [delay, setDelay] = useState(1000);
  const [expireTime, setExpireTime] = useState(null);
  const [state, setState] = useState({
    loggedIn: false,
    login: null,
    pactualIdTpp: null,
    trData: null,
    useBaas: JSON.parse(sessionStorage.getItem("useBaas")),
    error: false,
    errorMessage: "Failed to load transaction...",
    debtorAccount: {
      number: "94088392",
      accountType: "CACC",
      ispb: "12345678",
      issuer: "6272",
    },
  });

  useEffect(() => {
    setState({
      ...state,
      pactualIdTpp: getIdFromUrl(),
    });
  }, []);

  useEffect(() => {
    if (state.pactualIdTpp) validateKey();
  }, [state.loggedIn]);

  const validateKey = async () => {
    setLoading(true);
    const trData = await mutationPostValidatePixKey(
      state.pactualIdTpp,
      {
        userTaxId: state.login,
      },
      {
        useBaas: state.useBaas ? state.useBaas : false,
      }
    );

    if (trData.status < 300) {
      setState({ ...state, trData: trData.body });
      checkExpireTime(trData.createTimestamp);
    } else {
      setState({ ...state, error: true, trData: null });
      if ("errors" in trData) {
        trData.errors.map((error) => {
          return toast.error(error.message);
        });
      }
    }
    setLoading(false);
  };

  // ==========================

  const checkExpireTime = (date) => {
    const expireDate = moment(date).add(4, "minutes");
    const difference = expireDate.diff(date, "seconds");
    setExpireTime(difference);
  };

  const countdown = () => {
    if (expireTime !== null) {
      if (expireTime > 0) {
        setExpireTime(expireTime - 1);
      } else {
        setDelay(null);
        setState({
          ...state,
          error: true,
          trData: null,
          errorMessage: "Approval time expired...",
        });
      }
    }
  };

  const countdownFormatter = (secs) => {
    const divisorForMinutes = secs % (60 * 60);
    const minutes = Math.floor(divisorForMinutes / 60);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);

    return `${minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
  };

  useInterval(() => countdown(), delay);

  // ==========================

  const getIdFromUrl = () => {
    const url = window.location.href;
    const index = url.indexOf("?id=");
    const id = url.slice(index + 4);
    return id;
  };

  const approveTr = async (status) => {
    let tr = null;
    if (status) {
      tr = await mutationPostAuthorizePixKey(
        state.pactualIdTpp,
        {
          debtorAccount: state.debtorAccount,
        },
        {
          useBaas: state.useBaas ? state.useBaas : false,
        }
      );
    } else {
      tr = await mutationPostRejectPixKey(state.pactualIdTpp, {
        useBaas: state.useBaas ? state.useBaas : false,
      });
    }
    if (tr.status < 300) {
      window.location.replace(tr.body.body.url);
      return toast.success(toastMessages.success);
    } else {
      if ("errors" in tr) {
        tr.errors.map((error) => {
          return toast.error(error.message);
        });
      }
    }
  };

  const onSubmit = (values) => {
    if (values.password === "123456") {
      setState({ ...state, loggedIn: true, login: values.login });
    } else {
      toast.error(toastMessages.loginFail);
    }
  };

  return (
    <>
      <BaasFlex flexDirection="column" width="100%">
        <BaasPageHeader>Open Finance Test Suite</BaasPageHeader>
        <BaasContentGrid spacing={4}>
          <BaasGrid p="16px 0px" item>
            <BaasText variant="h6">TPP Side of the flow</BaasText>
          </BaasGrid>

          <BaasCardWithIcon title={"Mock Bank"} img={BankBranch} />

          <BaasGrid container spacing={3}>
            <BaasGrid item xs={12} md={8}>
              <BaasText variant="body1" p="18px 0px">
                This page is part of BTG's BaaS Open Banking Mock interface. It
                aims to exemplify to clients/developers the implementation flow
                for these features.
              </BaasText>
            </BaasGrid>
          </BaasGrid>
          {/* === Conteúdo principal === */}

          <BaasGrid container spacing={3}>
            <BaasGrid item xs={12} md={10}>
              {expireTime > 0 ? (
                <>
                  <BaasText variant="body1">
                    This transaction expires in:
                  </BaasText>
                  <BaasText variant="h6">
                    {countdownFormatter(expireTime)}
                  </BaasText>
                </>
              ) : null}

              {!state.loggedIn ? (
                <>
                  <BaasGrid
                    p="16px"
                    sx={{ background: theme.palette.grey[100] }}
                  >
                    {warningOpen ? (
                      <Alert
                        onClose={() => setWarningOpen(false)}
                        severity="warning"
                        style={{ margin: "8px 0" }}
                      >
                        This is the same Tax Id that was used to create the
                        payment in the PISP - TPP Bank Selection screen.
                      </Alert>
                    ) : null}
                    <BaasText variant="subtitle1">
                      Please confirm the requestors Tax Id (Password: 123456).
                    </BaasText>
                    <BaasGrid item xs={12}>
                      <Formik initialValues={{}} onSubmit={onSubmit}>
                        {({ values, ...props }) => (
                          <Form>
                            <BaasTextField
                              fullWidth
                              name="login"
                              label="CPF/CNPJ"
                              type="text"
                              required
                            />
                            <BaasTextField
                              fullWidth
                              name="password"
                              label="Password"
                              type="password"
                              required
                            />
                            <BaasButton
                              type="submit"
                              variant="contained"
                              backgroundColor="black"
                            >
                              Confirm
                            </BaasButton>
                          </Form>
                        )}
                      </Formik>
                    </BaasGrid>
                  </BaasGrid>
                </>
              ) : (
                <>
                  {/* Error Message */}
                  {state.error ? (
                    <>
                      <BaasGrid
                        p="16px"
                        sx={{ background: theme.palette.grey[100] }}
                      >
                        <div style={{ textAlign: "center", padding: "16px 0" }}>
                          <DoDisturbIcon
                            sx={{ color: "#D22D4B" }}
                            fontSize="large"
                          />
                          <BaasText
                            textAlign="center"
                            variant="h5"
                            color="error.main"
                          >
                            {state.errorMessage}
                          </BaasText>
                        </div>
                      </BaasGrid>
                    </>
                  ) : null}

                  {/* Client Logo Display */}
                  {state.trData !== null ? (
                    <>
                      <BaasRow item spacing={1}>
                        <BaasText variant="h6">PISP Organization Logo</BaasText>
                      </BaasRow>
                      <BaasGrid
                        p="16px"
                        sx={{ background: theme.palette.grey[100] }}
                      >
                        <img
                          src={state.trData.body.data.organizationLogo}
                          alt="Logo"
                          width={"100px"}
                          height={"100px"}
                        />
                      </BaasGrid>
                    </>
                  ) : null}

                  {/* Loading info */}
                  {loading ? (
                    <BaasGrid
                      p="16px"
                      sx={{ background: theme.palette.grey[100] }}
                    >
                      <div style={{ textAlign: "center", padding: "16px" }}>
                        <CircularProgress color="inherit" />
                        <BaasText textAlign="center" variant="h6">
                          Loading transaction data...
                        </BaasText>
                      </div>
                    </BaasGrid>
                  ) : null}

                  {/* Transaction Info display */}
                  {state.trData !== null ? (
                    <>
                      <BaasRow item spacing={1}>
                        <BaasText variant="h6">
                          Transaction Information
                        </BaasText>
                      </BaasRow>
                      <BaasGrid
                        p="16px"
                        sx={{ background: theme.palette.grey[100] }}
                      >
                        <BaasRow container spacing={1}>
                          <BaasGrid item xs={12}>
                            <BaasInfoDisplay
                              label={"PISP Organization Name:"}
                              value={state.trData.body.organizationName}
                            />
                          </BaasGrid>
                          <BaasGrid item xs={12} md={6}>
                            {console.log(state.trData)}
                            <BaasInfoDisplay
                              label={"Amount:"}
                              value={`R$ ${state.trData.body.data.payment.amount}`}
                            />
                          </BaasGrid>
                          <BaasGrid item xs={12} md={6}>
                            <BaasInfoDisplay
                              label={"Currency:"}
                              value={state.trData.body.data.payment.currency}
                            />
                          </BaasGrid>
                          <BaasGrid item xs={12} md={6}>
                            <BaasInfoDisplay
                              label={"Date:"}
                              value={state.trData.body.data.payment.date}
                            />
                          </BaasGrid>
                          <BaasGrid item xs={12} md={6}>
                            <BaasInfoDisplay
                              label={"PISP User Tax Id:"}
                              value={
                                state.trData.body.data.loggedUser.document
                                  .identification
                              }
                            />
                          </BaasGrid>
                          <BaasGrid item xs={12} md={6}>
                            <BaasInfoDisplay
                              label={"PISP User Document Type:"}
                              value={
                                state.trData.body.data.loggedUser.document.rel
                              }
                            />
                          </BaasGrid>
                        </BaasRow>
                      </BaasGrid>
                    </>
                  ) : null}

                  {/* Creditor Info Display */}
                  {state.trData !== null ? (
                    <>
                      <BaasRow item spacing={1}>
                        <BaasText variant="h6">Creditor Information</BaasText>
                      </BaasRow>
                      <BaasGrid
                        p="16px"
                        sx={{ background: theme.palette.grey[100] }}
                      >
                        <BaasRow container spacing={1}>
                          <BaasGrid item xs={12} md={6}>
                            <BaasInfoDisplay
                              label={"Creditor Name:"}
                              value={state.trData.body.data.creditor.name}
                            />
                          </BaasGrid>
                          <BaasGrid item xs={12} md={6}>
                            <BaasInfoDisplay
                              label={"Creditor CPF/CNPJ:"}
                              value={state.trData.body.data.creditor.cpfCnpj}
                            />
                          </BaasGrid>
                          <BaasGrid item xs={12} md={6}>
                            <BaasInfoDisplay
                              label={"Account Number:"}
                              value={
                                state.trData.body.data.payment.details
                                  .creditorAccount.number
                              }
                            />
                          </BaasGrid>
                          <BaasGrid item xs={12} md={6}>
                            <BaasInfoDisplay
                              label={"Account Type:"}
                              value={
                                state.trData.body.data.payment.details
                                  .creditorAccount.accountType
                              }
                            />
                          </BaasGrid>
                          <BaasGrid item xs={12} md={6}>
                            <BaasInfoDisplay
                              label={"Ispb:"}
                              value={
                                state.trData.body.data.payment.details
                                  .creditorAccount.ispb
                              }
                            />
                          </BaasGrid>
                          <BaasGrid item xs={12} md={6}>
                            <BaasInfoDisplay
                              label={"Issuer:"}
                              value={
                                state.trData.body.data.payment.details
                                  .creditorAccount.issuer
                              }
                            />
                          </BaasGrid>
                        </BaasRow>
                        {/* Action Buttons */}
                        <BaasRow
                          container
                          sx={{ justifyContent: "space-between" }}
                          flexDirection="row"
                        >
                          <BaasGrid item p="16px 0px">
                            <BaasButton
                              variant="outlined"
                              backgroundColor="black"
                              disabled={!state.trData ? true : false}
                              onClick={() => approveTr(false)}
                            >
                              <CancelIcon style={{ paddingRight: "8px" }} />
                              Deny
                            </BaasButton>
                          </BaasGrid>
                          <BaasGrid item p="16px 0px">
                            <BaasButton
                              variant="contained"
                              backgroundColor="black"
                              disabled={!state.trData ? true : false}
                              onClick={() => approveTr(true)}
                            >
                              <CheckIcon
                                color="success"
                                style={{ paddingRight: "8px" }}
                              />
                              Approve
                            </BaasButton>
                          </BaasGrid>
                        </BaasRow>
                      </BaasGrid>
                    </>
                  ) : null}
                </>
              )}
            </BaasGrid>
          </BaasGrid>

          {/* === Fim do conteúdo principal === */}
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
}
