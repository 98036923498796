import { Autocomplete } from "@mui/material";
import React, { useMemo } from "react";
import styled from "styled-components";
import { useTheme } from "@mui/system";
import { useField, useFormikContext } from "formik";

import BaasText from "../Text/BaasText";
import BaasTextField from "../TextField/TextField";
const SelectStyle = styled.div``;

const BaasAutocomplete = ({
  name,
  options,
  variant = "standard",
  label,
  optionName,
  isLoading,
  ...props
}) => {
  const theme = useTheme();
  const [{ value }, { error }, { setValue }] = useField(name);
  const [{ value: textValue }, , { setValue: setValueText }] = useField(
    `${name}.text`
  );

  const { submitCount } = useFormikContext();

  const showError = useMemo(() => !!error && submitCount > 0, [
    submitCount,
    error,
  ]);

  const helperText = useMemo(() => {
    if (showError) {
      return <BaasText variant="body2">{error}</BaasText>;
    }

    return null;
  }, [showError, error]);

  const handleChange = (value) => {
    setValue(value);
    setValueText(value?.name || "");
  };

  return (
    <SelectStyle theme={theme}>
      {isLoading ? (
        <>loading</>
      ) : (
        <>
          <Autocomplete
            variant={variant}
            name={name}
            options={options}
            getOptionLabel={(option) => option.name}
            error={showError && error}
            helperText={helperText}
            onChange={(e, value) => handleChange(value)}
            renderInput={(params) => (
              <BaasTextField {...params} name={`${name}.text`} label={label} />
            )}
            value={value}
            inputValue={textValue || ""}
            {...props}
          />
        </>
      )}
    </SelectStyle>
  );
};

export default BaasAutocomplete;
