import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { toastMessages } from "constants/toastMessages";
import getSafe from "./getSafe";

export const useCustomQuery = (queryFunction) => {
  const [loading, setLoading] = useState();
  const [data, setData] = useState([]);

  const fetch = useCallback(
    async (props) => {
      const {
        values,
        isDictHubPath = false,
        emptyWarning,
        objectPath = "body",
        objectFallback = [],
        onCompleted,
        onNotFoundError,
        skipGenericNotFoundError = false,
        onError,
      } = props;

      setLoading(true);
      const response = await queryFunction(values, isDictHubPath);
      if (response.status < 300 || response.status === "OK") {
        if (emptyWarning && response.body?.items?.length === 0) {
          toast.warning(toastMessages.search.searchEmpty);
        }
        setData(getSafe(response, objectPath, objectFallback));
        if (onCompleted) {
          onCompleted(getSafe(response, objectPath, objectFallback));
        }
      } else {
        onError?.();
        if (response.status === 404 && !skipGenericNotFoundError) {
          if (onNotFoundError) {
            setLoading(false);
            onNotFoundError();
          } else {
            toast.error(toastMessages.requestError);
          }
          setData(undefined);
        }
        if ("errors" in response) {
          response.errors.forEach((error) => {
            toast.error(error.message);
          });
        }
      }
      setLoading(false);
    },
    [queryFunction]
  );

  return {
    fetch,
    data,
    loading,
    setLoading,
  };
};
