import BaasText from "components/atoms/Text/BaasText";
import React, { useCallback, useState } from "react";
import { useTheme } from "@mui/system";
import BaasGrid from "components/atoms/Grid/Grid";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

import { Form, Formik } from "formik";
import BaasButton from "components/atoms/Button/Button";
import { useOtp } from "providers/oneTimePassword";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";

import { toastMessages } from "constants/toastMessages";
import BaasTextField from "components/atoms/TextField/TextField";
import { mutationPostImportFile } from "api/cnab-api";
import guidGenerator from "services/guidGenerator";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import { isNewCnabProfile } from "helpers/isNewCnabProfile";
const CnabInterfaceUpload = () => {
  const theme = useTheme();
  const [upload, setUpload] = useState(false);
  const [clientRequestId, setClientRequestId] = useState();
  const [file, setFile] = useState(null);
  const { setOpen } = useOtp();
  const clientNewCnab = isNewCnabProfile();
  const getFiles = (e) => {
    setFile(e.target.files[0]);
  };
  const { mutate } = useMutation((e) => mutationPostImportFile(e));

  const onSubmit = useCallback(
    (values, formik) => {
      mutate(
        {
          otp: values.otp,
          File: file,
          Description: values.description,
          ImportType: clientNewCnab ? values.importType : "CNAB",
          ImportSubType: clientNewCnab ? values.importSubType : "PaymentSlipSe",
          ClientRequestId: clientRequestId,
        },
        {
          onSuccess: (data) => {
            if (data.errors) {
              toast.error(data.errors[0].message);
            } else {
              setUpload(false);
              setFile(null);
              toast.success(toastMessages.cnab.upload.success);
              formik.resetForm();
            }
          },

          onSettled: () => {
            formik.setSubmitting(false);
          },
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [file]
  );

  return (
    <>
      <Formik
        initialValues={{}}
        onSubmit={() => setOpen(true)}
        enableReinitialize
      >
        {({ values, isSubmitting, resetForm, ...props }) => (
          <Form>
            <BaasOtpModal
              onConfirm={() => onSubmit(values, props)}
              {...props}
            />
            <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
              <BaasText>
                Upload your CNAB files here. Please note that a description must
                be submitted with each file.
              </BaasText>
              {upload ? (
                <>
                  <BaasGrid container p="8px 0px" spacing={3}>
                    <BaasGrid item xs={12}>
                      <BaasTextField
                        name="description"
                        label="Description"
                        fullWidth
                      />
                    </BaasGrid>
                    {clientNewCnab && (
                      <>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="importType"
                            label="Layout"
                            required
                            select
                          >
                            {/* <BaasMenuItem value="CNABFebraban240">
                              CNAB Febraban 240
                            </BaasMenuItem> */}
                            <BaasMenuItem value="CNABSantander400">
                              CNAB Santander 400
                            </BaasMenuItem>
                          </BaasTextField>
                        </BaasGrid>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="importSubType"
                            label="Product"
                            required
                            select
                          >
                            <BaasMenuItem value="PaymentSlipSe">
                              Issuance of payment slip (PaymentSlipSe)
                            </BaasMenuItem>
                            {/* <BaasMenuItem value="PaymentSlipPay">
                              Payment slip pay (PaymentSlipPay)
                            </BaasMenuItem>
                            <BaasMenuItem value="ExternalTransfer">
                              TED (External Transfer)
                            </BaasMenuItem> */}
                          </BaasTextField>
                        </BaasGrid>
                      </>
                    )}
                  </BaasGrid>
                  <BaasGrid container spacing={3}>
                    <BaasGrid item xs={12} sm={6}>
                      <BaasTextField
                        name="file"
                        label="File"
                        type="file"
                        onChange={(e) => getFiles(e)}
                        fullWidth
                      />
                    </BaasGrid>
                  </BaasGrid>
                  <BaasGrid item display="flex">
                    <BaasButton
                      style={{ margin: "16px 0px", marginRight: "24px" }}
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        resetForm();
                        setFile(null);
                        setUpload(false);
                      }}
                    >
                      Cancel
                    </BaasButton>
                    <BaasButton
                      style={{ margin: "16px 0px" }}
                      variant="contained"
                      backgroundColor="black"
                      disabled={
                        file !== null && values.description ? false : true
                      }
                      onClick={() => setOpen(true)}
                    >
                      Upload
                    </BaasButton>
                  </BaasGrid>
                </>
              ) : (
                <>
                  <BaasGrid item>
                    <BaasButton
                      style={{ margin: "16px 0px" }}
                      variant="contained"
                      onClick={() => {
                        setClientRequestId(guidGenerator());
                        setUpload(true);
                      }}
                    >
                      Upload CNAB file
                    </BaasButton>
                  </BaasGrid>
                </>
              )}
            </BaasGrid>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CnabInterfaceUpload;
