import { useTheme } from "@mui/system";
import { mutationPostUser } from "api/security-api";
import BaasButton from "components/atoms/Button/Button";
import BaasContainer from "components/atoms/Container/Container";
import {
  default as BaasGrid,
  default as BaasRow,
} from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import React, { useCallback, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useMutation } from "react-query";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

const LoginRegister = () => {
  const theme = useTheme();
  const history = useHistory();
  const [reCaptcha, setReCaptcha] = useState("");
  const [validated, setValidated] = useState(false);

  const { mutate } = useMutation((a) => mutationPostUser(a), {
    onSuccess: () => {
      toast.success(toastMessages.registerSuccess);
      history.push("/login");
    },
    onError: (error) => {
      if (error.status === 400) {
        toast.error(toastMessages.userExist);
      } else {
        toast.error(toastMessages.unknownError);
      }
    },
  });

  const verifyReCaptcha = (response) => {
    if (response !== null) {
      setValidated(true);
      setReCaptcha(response);
    } else {
      setValidated(false);
    }
  };

  const onSubmit = useCallback(
    (values, formik) => {
      mutate(
        {
          company: values.company,
          email: values.email,
          fullName: values.fullName,
          telephone: values.telephone,
          reCaptcha: reCaptcha,
        },
        {
          onSettled: () => {
            formik.setSubmitting(false);
          },
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reCaptcha]
  );

  return (
    <>
      <BaasContainer width="100%">
        <Formik initialValues={{}} onSubmit={onSubmit}>
          {({ isSubmitting, values, ...props }) => (
            <Form>
              <BaasGrid container spacing={2} sx={{ marginTop: "32px" }}>
                <BaasGrid xs={0} md={3} />
                <BaasGrid
                  xs={12}
                  md={6}
                  sx={{
                    backgroundColor: theme.palette.grey[300],
                    borderRadius: "4px",
                  }}
                  p="32px"
                >
                  <>
                    <BaasGrid p="0px 0px 32px 0px ">
                      <BaasText variant="h4" color="grey.700">
                        Sign Up!
                      </BaasText>
                    </BaasGrid>
                    <BaasRow>
                      <BaasText variant="body2" color="grey.700">
                        Please fill the fields bellow with all the required
                        information. They'll be sent to our team for analysis.
                        We will contact you as soon as possible for the next
                        registration steps.
                      </BaasText>
                    </BaasRow>
                    <BaasGrid p="8px 0px">
                      <BaasTextField
                        name="fullName"
                        label="Full name"
                        fullWidth
                        required
                      />
                    </BaasGrid>
                    <BaasGrid p="8px 0px">
                      <BaasTextField
                        name="company"
                        label="Company"
                        fullWidth
                        required
                      />
                    </BaasGrid>
                    <BaasGrid p="8px 0px">
                      <BaasTextField
                        name="email"
                        label="Email"
                        fullWidth
                        required
                      />
                    </BaasGrid>
                    <BaasGrid p="8px 0px">
                      <BaasTextField
                        name="telephone"
                        label="Telephone"
                        fullWidth
                        required
                      />
                    </BaasGrid>

                    <BaasGrid
                      display="flex"
                      justifyContent="center"
                      p="0px 0px 16px 0px "
                    >
                      <ReCAPTCHA
                        render="explicit"
                        sitekey="6LcyC-8UAAAAACvi8DnSyiq7e_f2qRlR9ODYqV_B"
                        onChange={verifyReCaptcha}
                      />
                    </BaasGrid>
                    <BaasGrid display="flex" justifyContent="center">
                      <BaasButton
                        type="submit"
                        size="medium"
                        variant="contained"
                        loading={isSubmitting}
                        disabled={!validated}
                      >
                        sign up
                      </BaasButton>
                    </BaasGrid>
                  </>
                </BaasGrid>
              </BaasGrid>
            </Form>
          )}
        </Formik>
      </BaasContainer>
    </>
  );
};

export default LoginRegister;
