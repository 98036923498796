import { OpenFinanceAmountPanel } from "components/organism/OpenFinanceAmountPanel/OpenFinanceAmountPanel";
import { OpenFinanceCheckoutPanel } from "components/organism/OpenFinanceCheckoutPanel/OpenFinanceCheckoutPanel";
import { OpenFinanceParticipantPanel } from "components/organism/OpenFinanceParticipantPanel/OpenFinanceParticipantPanel";
import { OpenFinancePoolingPanel } from "components/organism/OpenFinancePoolingPanel/OpenFinancePoolingPanel";

export const openFinanceSteps = [
  {
    title: "Amount",
    component: OpenFinanceAmountPanel,
  },
  {
    title: "Participant",
    component: OpenFinanceParticipantPanel,
  },
  {
    title: "Checkout",
    component: OpenFinanceCheckoutPanel,
  },
  {
    title: "Finish",
    component: OpenFinancePoolingPanel,
  },
];
