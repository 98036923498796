import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import { useTheme } from "@mui/system";

const PixInfractionPanelSkeleton = () => {
  const theme = useTheme();

  return (
    <BaasRow sx={{ background: theme.palette.grey[100] }} width="100%">
      <BaasGrid p="16px">
        <BaasGrid item xs={12} sm={12}>
          <BaasSkeleton width="100%" height="40px" />
        </BaasGrid>
        <BaasGrid item xs={12} sm={12}>
          <BaasSkeleton width="100%" height="40px" />
        </BaasGrid>
        <BaasGrid item xs={12} sm={12}>
          <BaasSkeleton width="100%" height="40px" />
        </BaasGrid>
        <BaasGrid item xs={12} sm={12}>
          <BaasSkeleton width="100%" height="40px" />
        </BaasGrid>
      </BaasGrid>
    </BaasRow>
  );
};

export default PixInfractionPanelSkeleton;
