import styled from "styled-components";

export const BaasFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "inherit"};
  flex-direction: ${(props) => props.flexDirection || "row"};
  flex: ${(props) => props.flex || ""};
  flex-wrap: ${(props) => props.flexWrap || ""};
  width: ${(props) => props.width || "auto"};
  padding: ${(props) => props.padding || ""};
`;

export default BaasFlex;
