import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { Form, Formik } from "formik";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { toastMessages } from "constants/toastMessages";
import PixLimitReportsConfigCreate from "./PixLimitValidatorReportsConfigCreate";
import PixLimitValidatorReportsConfigList from "./PixLimitValidatorReportsConfigList";
import DialogConfirmation from "components/organism/DialogConfirmation/DialogConfirmation";
import { useCustomQuery } from "helpers/useCustomQuery";
import { queryGetReportConfigs } from "api/pixLimitValidator/pixLimitValidator";
import {
  mutationDeleteReportConfigs,
  mutationUpdateReportConfigs,
} from "api/pixLimitValidator/pixLimitValidator";
import { mutationCreateReportConfig } from "api/pixLimitValidator/pixLimitValidator";

const REFETCH_DELAY = 3000;

const PixLimitReportsConfig = () => {
  // const theme = useTheme();
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState();
  const [refetch, setRefetch] = useState(undefined);
  const [refetching, setRefetching] = useState(false);

  const [submittingSave, setSubmittingSave] = useState(false);
  const handleRefetch = useCallback(() => {
    setRefetch(!refetch);
  }, [refetch]);

  const {
    fetch: loadReportConfigs,
    loading: loadingReportConfigs,
    data: reportConfigsData,
  } = useCustomQuery(queryGetReportConfigs);

  useEffect(() => {
    loadReportConfigs({
      onCompleted: () => setRefetching(false),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch]);

  const getResponseStatus = (responses) => {
    const responseStatus = responses.map((response) => {
      if (response.status < 300) {
        return "OK";
      }
      if (response.status >= 300) {
        return "ERROR";
      }
    });
    return responseStatus;
  };

  const onDelete = useCallback(async () => {
    setSubmittingSave(true);
    const ids = itemToDelete?.ids;
    const responses = await handleDeleteReportConfig(ids);
    let responsesStatus = getResponseStatus(responses);
    if (responsesStatus.includes("ERROR")) {
      toast.error(toastMessages.requestError);
      setSubmittingSave(false);
      setItemToDelete(null);
    } else {
      toast.success("Report Config removed with success");
      setRefetching(true);
      setTimeout(() => {
        handleRefetch();
      }, [REFETCH_DELAY]);
      setItemToDelete(null);
      setSubmittingSave(false);
    }
  }, [handleRefetch, itemToDelete?.ids]);

  const initialValues = useMemo(() => {
    return {};
  }, []);

  const handleCreateReportConfig = async (itemsToCreate) => {
    let responses = [];

    itemsToCreate.forEach(async (item) => {
      const createResponse = await mutationCreateReportConfig(item);
      responses.push(createResponse);
    });
    return responses;
  };

  const handleDeleteReportConfig = async (itemsToDelete) => {
    let responses = [];
    itemsToDelete.forEach(async (item) => {
      if (item) {
        const deleteResponse = await mutationDeleteReportConfigs(item);
        responses.push(deleteResponse);
      }
    });
    return responses;
  };

  const handleUpdateReportConfig = async (itemsToUpdate) => {
    let responses = [];
    itemsToUpdate.forEach(async (item) => {
      const updateResponse = await mutationUpdateReportConfigs(item);
      responses.push(updateResponse);
    });
    return responses;
  };

  const onSubmitSaveReportConfig = async (
    itemsToCreate,
    itemsToDelete,
    itemsToUpdate
  ) => {
    let responses = [];
    setSubmittingSave(true);

    if (itemsToCreate.length > 0) {
      const responsesCreate = await handleCreateReportConfig(itemsToCreate);
      responses = [...responses, responsesCreate];
    }
    if (itemsToUpdate.length > 0) {
      const responsesUpdate = await handleUpdateReportConfig(itemsToUpdate);
      responses = [...responses, responsesUpdate];
    }
    if (itemsToDelete.length > 0) {
      responses.push(await handleDeleteReportConfig(itemsToDelete));
    }

    let responsesStatus = getResponseStatus(responses);
    if (responsesStatus.includes("ERROR")) {
      toast.error(toastMessages.requestError);
      setSubmittingSave(false);
    } else {
      toast.success("Report Config saved with success");
      setRefetching(true);
      setTimeout(() => {
        handleRefetch();
      }, [REFETCH_DELAY]);
      setSubmittingSave(false);
    }
  };

  if (isCreateOpen) {
    return (
      <PixLimitReportsConfigCreate
        onBack={() => setIsCreateOpen(false)}
        handleRefetch={handleRefetch}
      />
    );
  }

  return (
    <BaasFlex flexDirection="column" width="100%">
      <BaasPageHeader>Pix Limit Validator</BaasPageHeader>
      <BaasContentGrid spacing={4}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={() => {}}
        >
          {({ values, isSubmitting, ...props }) => (
            <Form>
              <BaasGrid item spacing={1}>
                <BaasRow>
                  <BaasText variant="h6">
                    Pix Limit Validator Reports Config
                  </BaasText>
                </BaasRow>
              </BaasGrid>

              {/* <BaasRow>
                <BaasGrid
                  p="16px"
                  sx={{
                    background: theme.palette.grey[100],
                    paddingTop: "0px",
                  }}
                >
                  <BaasRow container column={12} spacing={1}>
                    <BaasGrid item xs={12}>
                      <BaasText variant="body2">
                        Use this session to config reports.
                      </BaasText>
                    </BaasGrid>
                  </BaasRow>
                  <BaasGrid item>
                    <BaasButton
                      onClick={() => setIsCreateOpen(true)}
                      variant="contained"
                      backgroundColor="black"
                      type="submit"
                    >
                      Create Report Config
                    </BaasButton>
                  </BaasGrid>
                </BaasGrid>
              </BaasRow> */}
              <PixLimitValidatorReportsConfigList
                data={reportConfigsData}
                handleRefetch={handleRefetch}
                onClickDelete={setItemToDelete}
                onSubmitSaveReportConfig={onSubmitSaveReportConfig}
                loading={loadingReportConfigs || refetching}
                isSubmitting={submittingSave}
              />
            </Form>
          )}
        </Formik>
      </BaasContentGrid>
      <DialogConfirmation
        open={Boolean(itemToDelete)}
        id={itemToDelete?.reportType}
        idLabel="Report Type"
        setOpen={setItemToDelete}
        onConfirm={onDelete}
        title="Delete this Report Config?"
        content="You have selected to delete this Report Config, if this was the action that you wanted to do, please confirm your choice or cancel and return to the page."
      />
    </BaasFlex>
  );
};
export default PixLimitReportsConfig;
