import queryToString from "helpers/queryToString";
import { baasApiCall } from "services/apiCall";

export const mutationPostPixDictRefundByRefundIdCancel = (payload) => {
  return baasApiCall(
    "POST",
    payload.accountType === "Indirect"
      ? `/pc/v2/dict-hub/refund/${payload.refundId}/cancel`
      : `/pc/v2/pix/dict/refund/${payload.refundId}/cancel`,
    false,
    payload.otp,
    true
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetPixDictRefund = (queryParams) => {
  return baasApiCall(
    "GET",
    queryParams.accountType === "Indirect"
      ? `/pc/v2/dict-hub/refund${queryToString(queryParams)}`
      : `/pc/v2/pix/dict/refund${queryToString(queryParams)}`
  )
    .then((data) => data.json())
    .catch((error) => error);
};

export const mutationPostPixDictRefund = (payload) => {
  return baasApiCall(
    "POST",
    payload.accountType === "Indirect"
      ? "/pc/v2/dict-hub/refund"
      : "/pc/v2/pix/dict/refund",
    payload
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostInfractionReportClose = (payload) => {
  return baasApiCall(
    "POST",
    payload.accountType === "Indirect"
      ? `/pc/v2/dict-hub/infraction-report/${payload.infractionReportId}/close`
      : `/pc/v2/pix/infraction-report/${payload.infractionReportId}/close`,
    payload,
    false,
    true
  ).then((data) => data.json());
};

export const mutationPostPixInfractionReportByInfractionReportIdCancel = (
  payload
) => {
  return baasApiCall(
    "POST",
    payload.accountType === "Indirect"
      ? `/pc/v2/dict-hub/infraction-report/${payload.infractionReportId}/cancel`
      : `/pc/v2/pix/infraction-report/${payload.infractionReportId}/cancel`,
    false,
    payload.otp,
    true
  ).then((data) => data.json());
};

export const mutationPostPixInfractionReportByInfractionReportIdAcnowledge = (
  payload
) => {
  return baasApiCall(
    "POST",
    `/pc/v2/pix/infraction-report/${payload.infractionReportId}/acknowledge`,
    false,
    payload.otp,
    true
  ).then((data) => data.json());
};

export const queryGetPixInfractionReportSearch = (queryParams) => {
  return baasApiCall(
    "GET",
    queryParams.accountType === "Indirect"
      ? `/pc/v2/dict-hub/infraction-report/search${queryToString(queryParams)}`
      : `/pc/v2/pix/infraction-report/search${queryToString(queryParams)}`
  )
    .then((data) => data.json())
    .catch((error) => error);
};

export const mutationPostPicInfractionReport = (payload) => {
  return baasApiCall(
    "POST",
    payload.accountType === "Indirect"
      ? "/pc/v2/dict-hub/infraction-report"
      : "/pc/v2/merchant/dict/infraction-report",
    payload
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPutPixDictEntryUpdate = (params, payload) => {
  return baasApiCall("PUT", `/pc/v2/dict-hub/entry/${params}`, payload, true)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const mutationPostPixDictEntryDelete = (params, payload) => {
  return baasApiCall(
    "POST",
    `/pc/v2/dict-hub/entry/${params}/delete`,
    payload,
    true
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetPixDictEntrySearchList = (params) => {
  return baasApiCall(
    "GET",
    `/pc/v2/dict-hub/entry/list${queryToString(params)}`
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostPixDictEntryCreate = (payload) => {
  return baasApiCall("POST", "/pc/v2/dict-hub/entry", payload, true)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetLatestSync = () => {
  return baasApiCall("GET", "/pc/dict-hub/cid/sync")
    .then((data) => data.json())
    .catch((error) => error.json);
};

export const mutationPostPixClaimCreate = (payload) => {
  return baasApiCall("POST", "/pc/v2/dict-hub/claim", payload, true)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetPixClaimSearchList = (queryParams, dictHub = true) => {
  return baasApiCall(
    "GET",
    dictHub
      ? `/pc/v2/dict-hub/claim${queryToString(queryParams)}`
      : `/pc/v2/pix/dict/claim${queryToString(queryParams)}`
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostPixClaimCancel = (payload) => {
  return baasApiCall(
    "POST",
    `/pc/v2/dict-hub/claim/${payload.claimId}/cancel`,
    payload,
    true
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostPixClaimConfirm = (payload) => {
  return baasApiCall(
    "POST",
    `/pc/v2/dict-hub/claim/${payload.claimId}/confirm`,
    payload,
    true
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostPixDictByRefundIdClose = (payload) => {
  return baasApiCall(
    "POST",
    payload.accountType === "Indirect"
      ? `/pc/v2/dict-hub/refund/${payload.refundId}/close`
      : `/pc/v2/pix/dict/refund/${payload.refundId}/close`,
    payload,
    true
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

//DICT CUSTOMERS
export const queryGetMerchantCustomers = () => {
  return baasApiCall("GET", `/pc/v2/dict-hub/customer`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetMerchantCustomerById = (queryParams) => {
  return baasApiCall("GET", `/pc/v2/dict-hub/customer/${queryParams}`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationDeleteMerchantCustomerById = (queryParams) => {
  return baasApiCall("DELETE", `/pc/v2/dict-hub/customer/${queryParams}`)
    .then((data) => data)
    .catch((error) => error);
};

export const mutationCreateMerchantCustomer = (payload) => {
  return baasApiCall("POST", `/pc/v2/dict-hub/customer`, payload, true)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationUpdateMerchantCustomer = (payload) => {
  return baasApiCall(
    "PATCH",
    `/pc/v2/dict-hub/customer/${payload.merchantCustomerId}`,
    payload,
    true
  )
    .then((data) => data)
    .catch((error) => error);
};

//ANTI FRAUD

export const mutationCreateFraudMarker = (payload) => {
  return baasApiCall("POST", `/pc/v2/dict-hub/anti-fraud`, payload, true)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetFraudMarkerById = (queryParams) => {
  return baasApiCall("GET", `/pc/v2/dict-hub/anti-fraud/${queryParams}`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetKeyStatistics = (key) => {
  return baasApiCall("GET", `/pc/v2/dict-hub/statistics/entries/${key}`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationCancelFraudMarkerById = (payload) => {
  return baasApiCall(
    "POST",
    `/pc/v2/dict-hub/anti-fraud/${payload.markerId}/cancel`,
    payload
  )
    .then((data) => data)
    .catch((error) => error);
};

export const queryGetPersonStatisticsByTaxId = (taxId) => {
  return baasApiCall("GET", `/pc/v2/dict-hub/statistics/persons/${taxId}`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryListFraudMarker = (queryParams) => {
  return baasApiCall(
    "GET",
    `/pc/v2/dict-hub/anti-fraud${queryToString(queryParams)}`
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};
