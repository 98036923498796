import { useTheme } from "@mui/private-theming";
import {
  mutationPostWebhookSubscription,
  useQueryGetWebhookResource,
  useQueryGetWebhookSubscription,
} from "api/webhooks-api";
import BaasAutocomplete from "components/atoms/Autocomplete/Autocomplete";
import BaasBox from "components/atoms/Box/Box";
import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import BaasLoading from "components/molecules/Loading/Loading";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import getSafe from "helpers/getSafe";
import { useOtp } from "providers/oneTimePassword";
import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import WebhookConfigResourceContent from "./WebhookConfigResourceContent";
import WebhookConfigSideBar from "./WebhookConfigSideBar";
import StackCard from "components/atoms/StackCard/StackCard";

import { ReactComponent as WarningIcon } from "assets/icons/Attention.svg";
import { ReactComponent as CancelIcon } from "assets/icons/Cancel.svg";
import { ReactComponent as DoneIcon } from "assets/icons/Check.svg";
import { sortByName } from "helpers/sortByName";
import { validateWhPayload } from "./utils";

const WebhookConfig = () => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const { setOpen, isOtpRequired } = useOtp();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    data: resources,
    isLoading: isLoadingResources,
  } = useQueryGetWebhookResource();

  const { data: subscriptions, isLoading } = useQueryGetWebhookSubscription();

  const saveWebhook = useCallback(async (values) => {
    setIsSubmitting(true);
    const response = await mutationPostWebhookSubscription(values);

    if (typeof response === "string") {
      queryClient.invalidateQueries(useQueryGetWebhookSubscription.queyKey);
      queryClient.invalidateQueries(useQueryGetWebhookResource.queyKey);
      toast.success(toastMessages.webhook.subscriptionsSaved);
    } else {
      if (response.status === 403) {
        response.errors.map((a) => {
          return toast.error(a.message);
        });
      } else {
        toast.error(response.message);
      }
    }
    setOpen(false);
    setIsSubmitting(false);
  }, []);

  const initialValues = useMemo(() => {
    const initialValues = {
      edited: [],
      resources:
        !isLoading &&
        !isLoadingResources &&
        resources.sort(sortByName).map((resource, index) => {
          return {
            ...resource,
            index,
            subscription: subscriptions?.resources.find(
              (subscription) => subscription.entity === resource.entity
            ) || { secret: "", webhookUrl: "", isMtls: false },
            events: resource.events.map((event) => {
              return {
                ...event,
                checked: subscriptions?.resources.find(
                  (subscription) => subscription.entity === resource.entity
                )
                  ? subscriptions.resources
                      .find(
                        (subscription) =>
                          subscription.entity === resource.entity
                      )
                      .eventNames?.find((name) => name === event.name)
                  : null,
              };
            }),
          };
        }),
    };

    return initialValues;
  }, [resources, subscriptions, isLoadingResources, isLoading]);

  const onSubmit = useCallback((values) => {
    let newSubscription = [];
    values.resources.map((resource) => {
      if (resource.subscription.webhookUrl || resource.subscription.secret)
        newSubscription.push(resource);
    });

    const payloadValidation = validateWhPayload(newSubscription);
    if (payloadValidation.valid) {
      saveWebhook({
        otp: values.otp || undefined,
        resources: newSubscription.map((resource) => {
          return {
            entity: resource.entity,
            eventNames: resource.events
              .filter((a) => a.checked)
              .map((filtred) => filtred.name),
            isMtls: getSafe(resource, "subscription.isMtls"),
            secret: getSafe(resource, "subscription.secret"),
            webhookUrl: getSafe(resource, "subscription.webhookUrl"),
          };
        }),
      });
    } else {
      toast.warning(payloadValidation.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading || isLoadingResources) {
    return <BaasLoading />;
  }

  const subscribed = (initialValues.resources || []).filter((wh) => {
    return Boolean(wh.subscription?.webhookUrl) === true;
  });

  const notSubscribed = (initialValues.resources || []).filter((wh) => {
    return Boolean(wh.subscription?.webhookUrl) !== true;
  });

  const webhookList = subscribed.concat(notSubscribed);

  return (
    <>
      <BaasFlex flexDirection="column" width="100%">
        <BaasPageHeader>Webhook</BaasPageHeader>
        <Formik
          initialValues={initialValues}
          onSubmit={isOtpRequired ? () => setOpen(true) : onSubmit}
          enableReinitialize
        >
          {({ values, initialValues, ...props }) => (
            <Form>
              <BaasContentGrid
                spacing={4}
                gridChildrenItemSize={9}
                sidebar={
                  subscriptions ? (
                    <WebhookConfigSideBar
                      subscriptions={subscriptions.resources}
                      initialValues={initialValues}
                      values={values}
                    />
                  ) : null
                }
              >
                <BaasOtpModal
                  onConfirm={() => onSubmit(values, props)}
                  {...props}
                />
                <BaasGrid p="16px 0px">
                  <BaasText sx={{ paddingLeft: "8px" }} variant="subtitle1">
                    This session refers to the subscription of webhooks by third
                    party applications.
                  </BaasText>
                </BaasGrid>
                <StackCard>
                  <BaasGrid item xs={12} md={6}>
                    <BaasAutocomplete
                      name="webhookOptions"
                      label="Webhook Options"
                      isLoading={isLoadingResources}
                      renderOption={(props, option) => (
                        <BaasBox
                          component="li"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                          {...props}
                        >
                          {option.name}
                          <BaasGrid>
                            {option.subscription.blockedManyErrors && (
                              <CancelIcon color={theme.palette.error.main} />
                            )}

                            {option.subscription.qtyConsecutiveErrors > 0 && (
                              <WarningIcon color={theme.palette.warning.main} />
                            )}

                            {!!option.subscription.eventNames && (
                              <DoneIcon color={theme.palette.success.main} />
                            )}
                          </BaasGrid>
                        </BaasBox>
                      )}
                      // options={initialValues.resources.sort(sortByName)}
                      options={webhookList}
                    />
                  </BaasGrid>
                  <BaasGrid
                    item
                    xs={12}
                    md={6}
                    sx={{ margin: "12px 0px", textAlign: "end" }}
                  >
                    <BaasButton
                      type="submit"
                      variant="contained"
                      color="error"
                      backgroundColor="black"
                      loading={isSubmitting}
                    >
                      Save all subscriptions
                    </BaasButton>
                  </BaasGrid>
                </StackCard>
                {!!values.webhookOptions?.entity && (
                  <WebhookConfigResourceContent initialValues={initialValues} />
                )}
              </BaasContentGrid>
            </Form>
          )}
        </Formik>
      </BaasFlex>
    </>
  );
};

export default WebhookConfig;
