import BaasText from "components/atoms/Text/BaasText";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import React from "react";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import ReceiptDownloadInterface from "./ReceiptDownloadInterface";

export default function Receipt() {
  return (
    <>
      <BaasFlex flexDirection="column" width="100%">
        <BaasPageHeader>Receipt</BaasPageHeader>
        <BaasContentGrid spacing={4}>
          <BaasGrid p="16px 0px" item>
            <BaasText variant="h6">Receipt Download Service</BaasText>
          </BaasGrid>
          <ReceiptDownloadInterface />
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
}
