import { baasApiCall } from "services/apiCall";

export const mutationCreateQrCodeDueDate = (payload) => {
  return baasApiCall("POST", `/pc/baas-tester/qrcode/due-date`, payload)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationCreateQrCodeImmediate = (payload) => {
  return baasApiCall("POST", `/pc/baas-tester/qrcode/immediate`, payload)
    .then((data) => data.json())
    .catch((error) => error.json());
};
