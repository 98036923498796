import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box } from "@mui/material";
import { useTheme } from "@mui/system";
import { mutationCreateMerchantCustomer } from "api/pix-api";
import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import Endpoint from "constants/endpoints";
import { Form, Formik } from "formik";
import { useCustomMutation } from "helpers/useCustomMutation";
import { useOtp } from "providers/oneTimePassword";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import guidGenerator from "services/guidGenerator";
import * as yup from "yup";

const CreateCustomer = () => {
  const theme = useTheme();
  const history = useHistory();
  const [merchantCustomerId] = useState(guidGenerator());
  const { setOpen } = useOtp();
  const initialValues = useMemo(() => {
    return {
      merchantCustomerId: merchantCustomerId,
    };
  }, [merchantCustomerId]);

  const validationSchema = yup.object().shape({});

  const { mutate, isSubmitting } = useCustomMutation(
    mutationCreateMerchantCustomer
  );

  const onSubmit = useCallback(
    (values) => {
      mutate(
        {
          merchantCustomerId: values.merchantCustomerId,
          name: values.name,
          taxId: values.taxId,
          additionalData: [values.additionalData],
        },
        (response) =>
          history.push({
            pathname: `/pix/customers-management`,
          })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <BaasFlex flex={1} flexDirection="column">
      <BaasContentGrid spacing={4}>
        <BaasGrid item spacing={1}>
          <BaasRow>
            <BaasText variant="h6">Pix Customers Management</BaasText>
          </BaasRow>
        </BaasGrid>
        <BaasGrid sx={{ background: theme.palette.grey[100] }} p="16px">
          <Box mb={3}>
            <BaasButton
              startIcon={<ArrowBackIcon />}
              size="small"
              onClick={() =>
                history.push({
                  pathname: `/pix/customers-management`,
                })
              }
            >
              Back
            </BaasButton>
          </Box>
          <BaasText variant="body2" fontWeight="bold">
            Merchant Customer Create
          </BaasText>
          <BaasRow>
            <BaasGrid sx={{ background: theme.palette.grey[100] }}>
              <Formik
                initialValues={initialValues}
                onSubmit={
                  Endpoint.Environment === "Sandbox"
                    ? onSubmit
                    : () => setOpen(true)
                }
                validationSchema={validationSchema}
              >
                {({ values, setFieldValue, ...props }) => (
                  <Form>
                    <BaasOtpModal
                      onConfirm={() => onSubmit(values)}
                      {...props}
                    />
                    <BaasText variant="body2">
                      Create new merchant customer for a client.
                    </BaasText>
                    <BaasRow>
                      <BaasGrid sx={{ background: theme.palette.grey[100] }}>
                        <BaasRow container column={12} spacing={3}>
                          <BaasGrid item xs={12} md={6}>
                            <BaasTextField
                              fullWidth
                              name="merchantCustomerId"
                              label="Merchant Customer Id"
                              required
                            />
                          </BaasGrid>
                          <BaasGrid item xs={12} md={6}>
                            <BaasTextField
                              fullWidth
                              name="name"
                              label="Name"
                              required
                            />
                          </BaasGrid>
                          <BaasGrid item xs={12} md={6}>
                            <BaasTextField
                              fullWidth
                              name="taxId"
                              label="Tax Id"
                              type="number"
                              required
                            />
                          </BaasGrid>
                          <BaasGrid item xs={12} md={6}>
                            <BaasTextField
                              fullWidth
                              name="additionalData"
                              label="Additional Data"
                            />
                          </BaasGrid>
                        </BaasRow>
                        <BaasButton
                          startIcon={<AddIcon />}
                          type="submit"
                          size="medium"
                          variant="contained"
                          isSubmiting={isSubmitting}
                        >
                          Create Customer
                        </BaasButton>
                      </BaasGrid>
                    </BaasRow>
                  </Form>
                )}
              </Formik>
            </BaasGrid>
          </BaasRow>
        </BaasGrid>
      </BaasContentGrid>
    </BaasFlex>
  );
};

export default CreateCustomer;
