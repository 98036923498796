import BaasButtonRound from "components/atoms/ButtonRound/ButtonRound";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import React from "react";

import contactUsImage from "assets/img/landing/contactUs.jpg";
import contactUsImage2 from "assets/img/landing/contactUs2.jpg";

const LandingContactUs = () => {
  function Mailto({ email, subject, body, ...props }) {
    return (
      <a
        href={`mailto:${email}?subject=${
          encodeURIComponent(subject) || ""
        }&body=${encodeURIComponent(body) || ""}`}
      >
        {props.children}
      </a>
    );
  }
  return (
    <>
      <BaasGrid>
        <BaasGrid container backgroundColor="grey.700">
          <BaasGrid md={2} />
          <BaasGrid
            md={4}
            p={3}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <BaasGrid p="4px 0px">
              <BaasText variant="h6" color="grey.100">
                Contact us
              </BaasText>
            </BaasGrid>
            <BaasGrid p="4px 0px">
              <BaasText
                variant="subtitle1"
                color="grey.100"
                style={{ opacity: "0.7" }}
              >
                We are here to help. Contact our Sales Team.
              </BaasText>
            </BaasGrid>
            <BaasGrid p="18px 0px">
              <Mailto
                email="baas@btgpactual.com"
                subject="BTG BaaS Contact Us"
                body=""
              >
                <BaasButtonRound variant="outlined" backgroundColor="white">
                  email us for api request
                </BaasButtonRound>
              </Mailto>
            </BaasGrid>
          </BaasGrid>
          <BaasGrid
            md={6}
            display="flex"
            justifyContent="flex-end"
            height="400px"
            sx={{
              backgroundImage: `url(${contactUsImage})`,
              backgroundSize: "cover",
            }}
          />
        </BaasGrid>

        <BaasGrid container backgroundColor="black">
          <BaasGrid
            XS={12}
            md={6}
            display="flex"
            justifyContent="flex-end"
            height="400px"
            style={{
              background:
                "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 0.01%, #000000 50.32%)",
            }}
            sx={{
              backgroundImage: `url(${contactUsImage2})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <BaasGrid
              sx={{
                width: "100%",
                backgroundImage: `url(${contactUsImage2})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          </BaasGrid>

          <BaasGrid
            xs={12}
            md={4}
            p={3}
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <BaasGrid p="4px 0px">
              <BaasText variant="h6" color="grey.100">
                If you are already a Client...
              </BaasText>
            </BaasGrid>
            <BaasGrid p="4px 0px">
              <BaasText
                variant="subtitle1"
                color="grey.100"
                style={{ opacity: "0.7" }}
              >
                Open a ticket with our support team to solve any technical
                problem regarding integration.
              </BaasText>
            </BaasGrid>
            <BaasGrid p="18px 0px">
              <BaasButtonRound
                variant="outlined"
                backgroundColor="white"
                href="https://btgpactual.atlassian.net/servicedesk/customer/portal/35"
              >
                support channel
              </BaasButtonRound>
            </BaasGrid>
          </BaasGrid>
          <BaasGrid md={2} />
        </BaasGrid>
      </BaasGrid>
    </>
  );
};

export default LandingContactUs;
