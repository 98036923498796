import { useTheme } from "@mui/system";
import { queryGetUserSecret } from "api/security-api";
import { mutationPostUserPublicKey } from "api/webhooks-api";
import ExampleImg01 from "assets/img/example_imgs/certificate_example_01.png";
import ExampleImg02 from "assets/img/example_imgs/certificate_example_02.png";
import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import getSafe from "helpers/getSafe";
import { useOtp } from "providers/oneTimePassword";
import React, { useCallback, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";
import MtlsCertificateDisplay from "./MtlsCerificateDisplay";

const MtlsCertificate = () => {
  const theme = useTheme();
  const [certificate, setCertificate] = useState({
    state: false,
    savedCertificates: [],
  });
  const { setOpen } = useOtp();

  const { mutate } = useMutation((e) => mutationPostUserPublicKey(e));

  const initialValues = useMemo(() => {
    return { certificate: getSafe(certificate, "body.mtlsCertificate") };
  }, [certificate]);

  const validationSchema = yup.object().shape({
    certificate: yup.string().required("The Certificate field can't be empty."),
  });

  const handleViewCertificate = useCallback(
    async (values, formik) => {
      const data = await queryGetUserSecret(values.otp);
      setOpen(false);
      setCertificate({
        state: true,
        savedCertificates:
          "mtlsCertificates" in data ? data.mtlsCertificates : [],
      });
      formik.resetForm();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setOpen]
  );

  const onSubmit = useCallback(
    (values, formik) => {
      mutate(
        { certificate: values.certificate, otp: values.otp },
        {
          onSuccess: (data) => {
            data.message
              ? toast.error(data.message)
              : toast.success(toastMessages.certificateSuccess);
          },

          onSettled: () => {
            formik.setSubmitting(false);
          },
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <BaasFlex flexDirection="column" width="100%">
        <BaasPageHeader>mTLS Certificate</BaasPageHeader>
        <BaasContentGrid spacing={4}>
          <BaasGrid p="16px 0px" item>
            <BaasText variant="h6">Save mTLS Certificate</BaasText>
          </BaasGrid>

          <Formik
            initialValues={initialValues}
            onSubmit={() => setOpen(true)}
            enableReinitialize
            validationSchema={validationSchema}
          >
            {({ values, isSubmitting, ...props }) => (
              <Form>
                <BaasOtpModal
                  onConfirm={() =>
                    certificate.state
                      ? onSubmit(values, props)
                      : handleViewCertificate(values, props)
                  }
                  {...props}
                />
                <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
                  <BaasText>
                    The certificate can be from public or private certificate
                    authorities. Certificate can have a maximum chain length of
                    four. You can also provide self-signed certificate. The
                    following are supported:
                  </BaasText>

                  <BaasGrid p={1}>
                    <BaasText>● SHA-256 or stronger</BaasText>
                    <BaasText>● RSA-2048 or stronger</BaasText>
                    <BaasText>● ECDSA-256 or stronger</BaasText>
                  </BaasGrid>

                  <BaasText>
                    The certificate must meet X.509 syntax requirements. The
                    certificate's validity period must be current. The names and
                    subjects of certificates must form an unbroken chain.
                    Certificates can have a maximum chain length of four and all
                    certificates of chain are required. Example:
                  </BaasText>

                  <BaasGrid container spacing={3} p="16px 0px">
                    <BaasGrid item>
                      <img src={ExampleImg01} alt="" />
                    </BaasGrid>
                    <BaasGrid item>
                      <img src={ExampleImg02} alt="" />
                    </BaasGrid>
                  </BaasGrid>

                  {!certificate.state ? (
                    <BaasGrid p="16px 0px">
                      <BaasButton
                        variant="contained"
                        onClick={() => setOpen(true)}
                      >
                        Edit certificate
                      </BaasButton>
                    </BaasGrid>
                  ) : null}
                </BaasGrid>

                {certificate.savedCertificates.length > 0 ? (
                  <BaasGrid
                    p="16px"
                    m="16px 0"
                    sx={{ background: theme.palette.grey[100] }}
                  >
                    <BaasGrid p="16px 0px">
                      <MtlsCertificateDisplay
                        certificates={certificate.savedCertificates}
                      />
                    </BaasGrid>
                  </BaasGrid>
                ) : null}

                <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
                  <BaasGrid>
                    {!certificate.state ? null : (
                      <>
                        <BaasGrid p="16px 0px">
                          <BaasButton
                            variant="contained"
                            backgroundColor="black"
                            type="submit"
                            loading={isSubmitting}
                          >
                            Save New Certificate
                          </BaasButton>
                        </BaasGrid>
                        <BaasTextField
                          name="certificate"
                          label="Certificate"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={15}
                        />
                      </>
                    )}
                  </BaasGrid>
                </BaasGrid>
              </Form>
            )}
          </Formik>
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
};

export default MtlsCertificate;
