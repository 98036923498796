import React from "react";
import styled from "styled-components";
import BaasText from "../Text/BaasText";

const BaasTableHeaderColStyle = styled.div`
  padding: 16px;
  flex: ${(props) => props.flex || 1};
  width: ${(props) => props.width};
`;

const BaasTableHeaderCol = ({ children, ...props }) => {
  return (
    <BaasTableHeaderColStyle {...props}>
      <BaasText variant="subtitle2">{children}</BaasText>
    </BaasTableHeaderColStyle>
  );
};

export default BaasTableHeaderCol;
