import BaasRow from "components/atoms/Grid/Row";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import BaasCardBalance from "components/molecules/CardBalance/CardBalance";
import BaasTransactionCard from "components/molecules/TransactionCard/TransactionCard";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import React from "react";
import DashboardSearch from "./DashboardSearch";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasFlex from "components/atoms/Flex/Flex";
import { useQueryGetAccountInfo } from "api/security-api";
import getSafe from "helpers/getSafe";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import { useQueryGetUserInfo } from "api/security-api";
import { checkPermission } from "services/interfaceController";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  boxCard: {
    flex: 1,
    display: "flex",
    maxWidth: "300px",
    [theme.breakpoints.up("2200")]: {
      width: "300px",
      flex: "none",
      marginRight: "10px",
    },
  },
}));

const Dashboard = () => {
  const { data, isLoading } = useQueryGetAccountInfo();
  const { data: userData } = useQueryGetUserInfo();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  const transactionPanelCardsInfo = [
    {
      label: "External transfer",
      path: "/external-transfer/cash-out",
      permissions: ["ted-w", "all-w"],
      subRoute: [
        { name: "Cash in", path: "/external-transfer/cash-in" },
        { name: "Cash out", path: "/external-transfer/cash-out" },
      ],
    },
    {
      label: "Internal transfer",
      path: "/internal-transfer",
      permissions: ["tef-w", "all-w"],
      subRoute: null,
    },
    {
      label: "Payment slip",
      path: "/payment-slip",
      permissions: ["payment-slip-w", "all-w"],
      subRoute: null,
    },
    {
      label: "Pix payment",
      path: "/pix-payment/cash-out",
      permissions: ["pix-pay-w", "all-w"],
      subRoute: [
        { name: "Cash in", path: "/pix-payment/cash-in" },
        { name: "Cash out", path: "/pix-payment/cash-out" },
      ],
    },
  ];

  const containerWidth = isMobile ? "100%" : "calc(100% - 232px)";

  return (
    <>
      <BaasFlex flexDirection="column" width={containerWidth}>
        <BaasPageHeader>Dashboard</BaasPageHeader>
        <BaasContentGrid spacing={4}>
          <BaasRow container spacing={1}>
            <BaasText sx={{ paddingLeft: "8px" }} variant="h6">
              Hello, {getSafe(userData, "fullName")}
            </BaasText>
          </BaasRow>
          {checkPermission(["balance-adm", "account-r", "all-w", "all-r"]) &&
            checkPermission(["no-balance-v"], true) && (
              <BaasFlex style={{ overflowX: "scroll" }}>
                {isLoading ? (
                  <BaasSkeleton
                    variant="rectangular"
                    width="242px"
                    height="152px"
                    sx={{ borderRadius: "8px" }}
                  ></BaasSkeleton>
                ) : (
                  getSafe(data, "body.items", []).map((account, index) => (
                    <BaasCardBalance account={account} index={index} />
                  ))
                )}
              </BaasFlex>
            )}

          {checkPermission([
            "Env-Development",
            "Env-Sandbox",
            "user-key-w",
            "all-w",
          ]) || checkPermission(["Env-Production", "transactional-v"]) ? (
            <>
              <BaasRow item spacing={1}>
                <BaasText variant="h6">Transaction panel</BaasText>
              </BaasRow>

              <BaasFlex style={{ overflowX: "scroll" }}>
                {transactionPanelCardsInfo.map((transaction) =>
                  checkPermission(transaction.permissions) ? (
                    <Box className={classes.boxCard}>
                      <BaasTransactionCard
                        label={transaction.label}
                        permissions={transaction.permissions}
                        path={transaction.path}
                        subRoute={transaction.subRoute}
                      />
                    </Box>
                  ) : null
                )}
              </BaasFlex>
            </>
          ) : null}
          {checkPermission(["balance-only-v"], true) && (
            <>
              <BaasGrid item spacing={1}>
                <BaasRow>
                  <BaasText variant="h6">Search</BaasText>
                  <BaasText variant="body2">
                    All your latest transactions are listed bellow
                  </BaasText>
                </BaasRow>
              </BaasGrid>{" "}
              <BaasRow p={0}>
                <DashboardSearch />
              </BaasRow>
            </>
          )}
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
};

export default Dashboard;
