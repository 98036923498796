export const formatPixKeys = (values) => {
  let key = values.key;
  const keyType = values.keyType;
  if (keyType === "keyCpf" || keyType === "keyCnpj" || keyType === "keyPhone") {
    key = key.replace(/[^\d]+/g, "");
    if (keyType === "keyPhone") {
      key = "+55" + key;
    }
    return key;
  } else if (keyType === "keyEmail") {
    return key.toLowerCase();
  } else if (keyType === "keyEvp") {
    return "";
  } else {
    return key;
  }
};
