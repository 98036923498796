export const abatementModalities = [
  {
    value: "FIXED_VALUE",
    label: "FIXED VALUE",
  },
  {
    value: "PERCENT",
    label: "PERCENT",
  },
];

export const interestModalities = [
  {
    value: "VALUE_CALENDAR_DAYS",
    label: "VALUE_CALENDAR_DAYS",
  },
  {
    value: "PERCENTAGE_PER_DAY_CALENDAR_DAYS",
    label: "PERCENTAGE_PER_DAY_CALENDAR_DAYS",
  },
  {
    value: "PERCENTAGE_PER_MONTH_CALENDAR_DAYS",
    label: "PERCENTAGE_PER_MONTH_CALENDAR_DAYS",
  },
  {
    value: "PERCENTAGE_PER_YEAR_CALENDAR_DAYS",
    label: "PERCENTAGE_PER_YEAR_CALENDAR_DAYS",
  },
  {
    value: "VALUE_WORKING_DAYS",
    label: "VALUE_WORKING_DAYS",
  },
  {
    value: "PERCENTAGE_PER_DAYWORKING_DAYS",
    label: "PERCENTAGE_PER_DAYWORKING_DAYS",
  },
  {
    value: "PERCENTAGE_PER_MONTH_WORKING_DAYS",
    label: "PERCENTAGE_PER_MONTH_WORKING_DAYS",
  },
  {
    value: "PERCENTAGE_PER_YEAR_WORKING_DAYS",
    label: "PERCENTAGE_PER_YEAR_WORKING_DAYS",
  },
];

export const fineModalities = [
  {
    value: "FIXED_VALUE",
    label: "FIXED VALUE",
  },
  {
    value: "PERCENT",
    label: "PERCENT",
  },
];

export const discountModalities = [
  {
    value: "FIXED_VALUE_UNTIL_THE_DATES_INFORMED",
    label: "FIXED_VALUE_UNTIL_THE_DATES_INFORMED VALUE",
  },
  {
    value: "PERCENTAGE_DATE_REPORTED",
    label: "PERCENTAGE_DATE_REPORTED",
  },
  {
    value: "AMOUNT_PER_CALENDAR_DAY_ADVANCE",
    label: "AMOUNT_PER_CALENDAR_DAY_ADVANCE",
  },
  {
    value: "AMOUNT_ADVANCE_BUSINESS_DAY",
    label: "AMOUNT_ADVANCE_BUSINESS_DAY",
  },
  {
    value: "PERCENTAGE_ADVANCE_CURRENT_DAY",
    label: "PERCENTAGE_ADVANCE_CURRENT_DAY",
  },
  {
    value: "PERCENTAGE_ADVANCE_BUSINESS_DAY",
    label: "PERCENTAGE_ADVANCE_BUSINESS_DAY",
  },
];
