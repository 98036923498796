import ListIcon from "@mui/icons-material/List";
import { useTheme } from "@mui/system";
import {
  queryGetPixInfractionByReportId,
  queryInfractionReportRoutesSandbox,
} from "api/pix-api";
import BaasBox from "components/atoms/Box/Box";
import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import BaasText from "components/atoms/Text/BaasText";
import { AddButton } from "components/molecules/AddButton/AddButton";
import { BackButton } from "components/molecules/BackButton/BackButton";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import DinamicForm from "components/organism/DinamicForm/DinamicForm";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { useCustomQuery } from "helpers/useCustomQuery";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const PixTestSuitTransactionDetails = () => {
  const theme = useTheme();
  const history = useHistory();
  const { infractionId } = useParams();
  const [refetchInfraction, setRefetchInfraction] = useState();
  const [refetchRoutes, setRefetchRoutes] = useState(false);
  const [loadingGeneric, setLoadingGeneric] = useState();

  const {
    fetch: fetchInfraction,
    data: infractionData,
    loading: loadingInfraction,
  } = useCustomQuery(queryGetPixInfractionByReportId);

  const {
    fetch: fetchRoutes,
    data: routesData,
    loading: loadingRoutes,
  } = useCustomQuery(queryInfractionReportRoutesSandbox);

  useEffect(() => {
    fetchInfraction({
      values: infractionId,
      objectFallback: {},
    });
  }, [fetchInfraction, infractionId, refetchInfraction]);

  useEffect(() => {
    fetchRoutes({
      values: infractionId,
      objectFallback: [],
      onNotFoundError: () =>
        toast.warning("There is no actions available to this infraction"),
    });
  }, [fetchRoutes, infractionId, refetchRoutes]);

  const handleRefetchRoutes = () => {
    setRefetchInfraction(!refetchInfraction);
    setRefetchRoutes(!refetchRoutes);
  };

  const infractionValues = [
    { label: "Infraction Id", value: infractionData.id },
    { label: "E2e Id", value: infractionData.endToEndId },
    { label: "Last update", value: infractionData.lastModified },
    { label: "Infraction type", value: infractionData.infractionType },
    { label: "Report Details", value: infractionData.reportDetails },
    { label: "Status", value: infractionData.status },
    {
      label: "Credited participant",
      value: infractionData.infractionData?.creditedParticipant,
    },
    {
      label: "Debited participant",
      value: infractionData.infractionData?.debitedParticipant,
    },
    {
      label: "Analysis Details",
      value: infractionData.analysisDetails,
    },
  ];

  const canVisitRefund =
    infractionData.status === "CLOSED" &&
    infractionData.analysisResult === "AGREED";

  const onRefundClick = () => {
    history.push({
      pathname: `/pix-test-suit-refund-create/${infractionData.id}/${infractionData.endToEndId}`,
    });
  };

  return (
    <BaasFlex flex={1} flexDirection="column">
      <BaasContentGrid spacing={4}>
        <BaasGrid item spacing={1}>
          <BaasRow>
            <BaasText variant="h6">Pix Test Suit Infraction</BaasText>
          </BaasRow>
        </BaasGrid>
        <BaasGrid sx={{ background: theme.palette.grey[100] }} p="16px">
          <BackButton path="/pix-test-suit-infractions-list" />
          <BaasText variant="body2" fontWeight="bold">
            Decide what action to take about the infraction.
          </BaasText>
          <BaasRow>
            <BaasGrid sx={{ background: theme.palette.grey[100] }}>
              <BaasRow container column={12} spacing={3}>
                {loadingInfraction ? (
                  <>
                    <BaasGrid item xs={12} sm={12}>
                      <BaasSkeleton width="100%" height="65px" />
                    </BaasGrid>
                    <BaasGrid item xs={12} sm={12}>
                      <BaasSkeleton width="100%" height="65px" />
                    </BaasGrid>
                    <BaasGrid item xs={12} sm={12}>
                      <BaasSkeleton width="100%" height="65px" />
                    </BaasGrid>
                    <BaasGrid item xs={12} sm={12}>
                      <BaasSkeleton width="100%" height="65px" />
                    </BaasGrid>
                  </>
                ) : (
                  infractionData &&
                  infractionValues.map((row) => (
                    <BaasGrid item xs={12} sm={6} key={row.label}>
                      <BaasInfoDisplay
                        fullWidth
                        label={row.label}
                        value={row.value || "Not available"}
                      />
                    </BaasGrid>
                  ))
                )}
              </BaasRow>
            </BaasGrid>
          </BaasRow>
          <BaasBox mb={3}>
            <BaasButton
              variant="outlined"
              onClick={() => history.push("/pix-test-suit-infractions-list")}
              startIcon={<ListIcon />}
            >
              Go to Infractions List
            </BaasButton>
          </BaasBox>
          <BaasText variant="body2" fontWeight="bold">
            Possible Actions
          </BaasText>
          {loadingRoutes ? (
            <BaasSkeleton width="100%" height="65px" />
          ) : routesData && routesData.length > 0 ? (
            routesData.map((route) => (
              <BaasBox
                sx={{ borderTop: "1px solid #CACACA" }}
                mt={2}
                key={route.message}
              >
                <DinamicForm
                  onComplete={handleRefetchRoutes}
                  route={route}
                  loading={loadingGeneric}
                  setLoading={setLoadingGeneric}
                />
              </BaasBox>
            ))
          ) : (
            <>
              <BaasText color="error" mt={1}>
                There is no actions available to this infraction.
              </BaasText>
              {canVisitRefund && (
                <BaasBox mt={2}>
                  <AddButton onClick={onRefundClick} label="Create Refund" />
                </BaasBox>
              )}
            </>
          )}
        </BaasGrid>
      </BaasContentGrid>
    </BaasFlex>
  );
};

export default PixTestSuitTransactionDetails;
