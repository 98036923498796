import { baasApiCall, baasApiCallUpload } from "services/apiCall";
import { baasApiCallDev } from "services/apiCallDev";

export const mutationPostImportFile = (payload) => {
  return baasApiCallUpload("/cm/import-file", payload).then((data) =>
    data.json()
  );
};

export const queryGetEfxListByDateFromAndDateTo = (params) => {
  return baasApiCall(
    "GET",
    `/tr/efx-services/headers/range/${params.dateFrom}/${params.dateTo}`
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetEfxDownloadByDateAndFileName = (date, fileName) => {
  return baasApiCallDev(
    "GET",
    `/tr/efx-services/file/download/${date}/${fileName}`
  )
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
