export default function converterFromBase64(name, validBase64Data, type) {
  const data = Buffer.from(validBase64Data, "base64");

  function save(name, data, type) {
    const blob = new Blob([data], { type: type });
    let objectURL = window.URL.createObjectURL(blob);
    let anchor = document.createElement("a");

    anchor.href = objectURL;
    anchor.download = name;
    anchor.click();

    URL.revokeObjectURL(objectURL);
  }

  save(name, data, type);
}
