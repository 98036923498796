import { useMediaQuery, useTheme } from "@mui/material";
import BaasGrid from "components/atoms/Grid/Grid";
import React from "react";

const BaasContentGrid = ({
  children,
  sidebar: Sidebar,
  gridChildrenItemSize = 12,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <BaasGrid
      container
      spacing="16px"
      style={{ marginLeft: "0px", width: "100%" }}
    >
      <BaasGrid item xs={12} md={gridChildrenItemSize} xl={9} p={2}>
        {children}
      </BaasGrid>
      {isMobile ? null : (
        <BaasGrid item xs={3} lg={3} md={3} xl={3} p="8px 16px">
          {Sidebar}
        </BaasGrid>
      )}
    </BaasGrid>
  );
};

export default BaasContentGrid;
