import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useTheme } from "@mui/system";
import {
  queryGetFraudMarkerById,
  queryGetKeyStatistics,
  queryGetPersonStatisticsByTaxId,
} from "api/dictv2-api";
import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import BaasText from "components/atoms/Text/BaasText";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import DialogConfirmation from "components/organism/DialogConfirmation/DialogConfirmation";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { useCustomQuery } from "helpers/useCustomQuery";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

const AntiFraudDetails = () => {
  const theme = useTheme();
  const { fraudMarkerId } = useParams();
  const history = useHistory();
  const [open, setOpen] = useState();
  const [expandedStatistics, setExpandedStatistics] = useState(undefined);
  const [expandedKeyStatistics, setExpandedKeyStatistics] = useState(undefined);

  const { fetch, data, loading } = useCustomQuery(queryGetFraudMarkerById);
  const {
    fetch: fetchPersonStatistics,
    data: personStatisticsData,
    loading: loadingPersonStatistics,
  } = useCustomQuery(queryGetPersonStatisticsByTaxId);

  const {
    fetch: fetchKeyStatistics,
    data: keyStatisticsData,
    loading: loadingKeyStatistics,
  } = useCustomQuery(queryGetKeyStatistics);

  useEffect(() => {
    if (fraudMarkerId) {
      fetch({
        values: fraudMarkerId,
        objectPath: "body",
        objectFallback: {},
      });
    }
  }, [fraudMarkerId, fetch]);

  const fraudMarkerValues = [
    { label: "Id", value: data?.fraudMarker?.id },
    { label: "Tax Id", value: data?.fraudMarker?.taxIdNumber },
    { label: "Fraud Type", value: data?.fraudMarker?.fraudType },
    { label: "Key", value: data?.fraudMarker?.key },
    { label: "Status", value: data?.fraudMarker?.status },
    {
      label: "Created at",
      value: data?.fraudMarker?.creationTime
        ? moment(data?.fraudMarker?.creationTime).format("YYYY-MM-DD")
        : "N/A",
    },
    {
      label: "Updated at",
      value: data?.fraudMarker?.lastModified
        ? moment(data?.fraudMarker?.lastModified).format("YYYY-MM-DD")
        : "N/A",
    },
  ];

  const handleExpandPersonStatistics = (e) => {
    e.stopPropagation();
    if (!expandedStatistics) {
      fetchPersonStatistics({
        values: data?.fraudMarker?.taxIdNumber,
        objectPath: "body",
        objectFallback: {},
        onCompleted: () => setExpandedStatistics(true),
        onError: () => setExpandedStatistics(true),
      });
    } else {
      setExpandedStatistics(false);
    }
  };

  const handleExpandKeyStatistics = (e) => {
    e.stopPropagation();
    if (!expandedKeyStatistics) {
      fetchKeyStatistics({
        values: data?.fraudMarker?.key,
        objectPath: "body",
        objectFallback: {},
        skipGenericNotFoundError: true,
        onCompleted: () => setExpandedKeyStatistics(true),
        onError: () => setExpandedKeyStatistics(true),
      });
    } else {
      setExpandedKeyStatistics(false);
    }
  };

  return (
    <BaasFlex flex={1} flexDirection="column">
      <BaasContentGrid spacing={4}>
        <BaasGrid item spacing={1}>
          <BaasRow>
            <BaasText variant="h6">Pix Anti-Fraud</BaasText>
          </BaasRow>
        </BaasGrid>
        <BaasGrid sx={{ background: theme.palette.grey[100] }} p="16px">
          <Box mb={3}>
            <BaasButton
              startIcon={<ArrowBackIcon />}
              size="small"
              onClick={() =>
                history.push({
                  pathname: `/pix/v2/anti-fraud`,
                })
              }
            >
              Back
            </BaasButton>
          </Box>
          <BaasText variant="body2" fontWeight="bold">
            Fraud Marker details
          </BaasText>
          <BaasRow>
            <BaasGrid sx={{ background: theme.palette.grey[100] }}>
              <BaasRow container column={12} spacing={3}>
                {loading ? (
                  <>
                    <BaasGrid item xs={12} sm={12}>
                      <BaasSkeleton width="100%" height="65px" />
                    </BaasGrid>
                    <BaasGrid item xs={12} sm={12}>
                      <BaasSkeleton width="100%" height="65px" />
                    </BaasGrid>
                    <BaasGrid item xs={12} sm={12}>
                      <BaasSkeleton width="100%" height="65px" />
                    </BaasGrid>
                    <BaasGrid item xs={12} sm={12}>
                      <BaasSkeleton width="100%" height="65px" />
                    </BaasGrid>
                  </>
                ) : (
                  data &&
                  fraudMarkerValues.map((row) => (
                    <BaasGrid item xs={12} sm={6} key={row.label}>
                      <BaasInfoDisplay
                        fullWidth
                        label={row.label}
                        value={row.value || "Not available"}
                      />
                    </BaasGrid>
                  ))
                )}
              </BaasRow>
              {data?.fraudMarker?.taxIdNumber && (
                <Accordion
                  expanded={expandedStatistics}
                  sx={{ backgroundColor: "transparent" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    onClick={(e) => handleExpandPersonStatistics(e)}
                  >
                    <BaasText display="flex" alignItems="center">
                      Person Statistics
                    </BaasText>
                  </AccordionSummary>
                  <AccordionDetails>
                    {loadingPersonStatistics ? (
                      <BaasSkeleton width="100%" height="65px" />
                    ) : (
                      <ReactJson
                        name={null}
                        src={personStatisticsData}
                        style={{ maxHeight: "300px", overflowY: "scroll" }}
                        displayObjectSize={false}
                        displayDataTypes={false}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
              {data?.fraudMarker?.key && (
                <Accordion
                  expanded={expandedKeyStatistics}
                  sx={{ backgroundColor: "transparent" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    onClick={(e) => handleExpandKeyStatistics(e)}
                  >
                    <BaasText display="flex" alignItems="center">
                      Key Statistics
                    </BaasText>
                  </AccordionSummary>
                  <AccordionDetails>
                    {loadingKeyStatistics ? (
                      <BaasSkeleton width="100%" height="65px" />
                    ) : (
                      <ReactJson
                        name={null}
                        src={keyStatisticsData}
                        style={{ maxHeight: "300px", overflowY: "scroll" }}
                        displayObjectSize={false}
                        displayDataTypes={false}
                      />
                    )}
                  </AccordionDetails>
                </Accordion>
              )}
            </BaasGrid>
          </BaasRow>
        </BaasGrid>
      </BaasContentGrid>
      <DialogConfirmation
        open={Boolean(open)}
        id={open}
        setOpen={setOpen}
        onConfirm={() => {
          setOpen(false);
        }}
        title="Cancel this Fraud Marker?"
        content="You have selected to delete this fraud marker, if this was the action that you wanted to do, please confirm your choice or cancel and return to the page."
      />
    </BaasFlex>
  );
};

export default AntiFraudDetails;
