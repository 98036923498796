import BaasCardFaq from "components/molecules/CardFaq/CardFaq";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import React from "react";
import FintechImage from "assets/fintech-imgs/faq.svg";
import styled from "styled-components";
import BaasButton from "components/atoms/Button/Button";

const ContactUs = () => {
  function Mailto({ email, subject, body, ...props }) {
    return (
      <a
        href={`mailto:${email}?subject=${
          encodeURIComponent(subject) || ""
        }&body=${encodeURIComponent(body) || ""}`}
      >
        {props.children}
      </a>
    );
  }
  const faqArray = [
    {
      title: "Anyone can use our APIs?",
      description:
        " Any company can sign up to our Sandbox and test away. If you are interested in integrating our APIs contact us for further instructions.",
    },
    {
      title: "How to start using our APIs?",
      description:
        " Check our documentation section. There you can find every BaaS available solution and how it works.",
    },
    {
      title: "How does the integration process works?",
      description:
        "Our Sandbox environment is available for testing. If you want to take your integration one step further, mail us on the link below so we can understand better your needs and guide you through the steps.",
    },
  ];
  const keepInToutch = [
    {
      title: "Any questions?",
      description:
        "Contact us directly in case of doubts. We are here to help you find the best products for your business.",
    },
    {
      title: "If you are already a Client...",
      description:
        "Open a ticket with our support team to solve any technical problem regarding integration.",
    },
  ];

  return (
    <>
      <BaasGrid display="flex" flexDirection="column">
        <BaasGrid display="flex" justifyContent="center" width="100%">
          <BaasGrid item sm={9} sx={{ maxWidth: "1440px" }} p={2}>
            <BaasGrid>
              <BaasText variant="h4" p="36px 0px">
                BTG Bank as a Service contact us
              </BaasText>
              <BaasText variant="body1">
                Here you can find our documentation divided by sessions. Each
                session refers to a different set of functionalities and
                services to best suit our client's needs. Feel free to explore
                our services and if in need of assistance, please contact our
                team for support.
              </BaasText>
            </BaasGrid>
            <BaasGrid container spacing={6} sx={{ marginTop: "8px" }}>
              <BaasGrid md={6} item>
                <BaasGrid container spacing={3} p="32px 0px">
                  {faqArray.map((faq) => (
                    <>
                      <BaasGrid item xs={12} md={12} xl={12}>
                        <BaasCardFaq item={faq} />
                      </BaasGrid>
                    </>
                  ))}
                </BaasGrid>
              </BaasGrid>{" "}
              <BaasGrid md={6} item>
                <Image />
              </BaasGrid>
            </BaasGrid>
            <BaasGrid>
              <BaasText variant="h4" p="32px 0px" align="center">
                Keep in touch with us!
              </BaasText>
              <BaasText variant="body1" p="0px 0px 48px 0px" align="center">
                We are always here to help. Share your ideas and bring feedback
                to our support team.
              </BaasText>
            </BaasGrid>
            <BaasGrid container spacing={4}>
              <BaasGrid item md={6}>
                <BaasCardFaq item={keepInToutch[0]}>
                  <Mailto
                    email="baas@btgpactual.com"
                    subject="BTG BaaS Contact Us"
                    body=""
                  >
                    <BaasButton
                      onClick={() => {}}
                      style={{ marginTop: "16px" }}
                    >
                      mail us!
                    </BaasButton>
                  </Mailto>
                </BaasCardFaq>
              </BaasGrid>

              <BaasGrid item md={6}>
                <BaasCardFaq item={keepInToutch[1]}>
                  <BaasButton
                    style={{ marginTop: "16px" }}
                    href="https://btgpactual.atlassian.net/servicedesk/customer/portal/35"
                  >
                    Support channel
                  </BaasButton>
                </BaasCardFaq>
              </BaasGrid>
            </BaasGrid>
          </BaasGrid>
        </BaasGrid>
      </BaasGrid>
    </>
  );
};

export default ContactUs;

const Image = styled.div`
  width: 100%;
  height: 100%;

  background-position: center;
  background-size: 85%;
  background-repeat: no-repeat;
  background-image: url(${FintechImage});
`;
