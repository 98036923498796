import React from "react";
import { StyledAvatarWrapper, StyledCardWrapper, Styledlabel } from "./styles";
import { ReactComponent as UserIcon } from "assets/icons/User.svg";
import BaasBox from "components/atoms/Box/Box";
import Tooltip from '@mui/material/Tooltip';

const CardContent = React.forwardRef(function CardContent(props, ref) {
    const { active, label } = props;
    return (
        <div ref={ref} {...props}>
            <StyledCardWrapper active={active} >
                <BaasBox mr={1} >
                    <StyledAvatarWrapper active={active}>
                        <UserIcon />
                    </StyledAvatarWrapper>
                </BaasBox>
                <Styledlabel active={active}>{label}</Styledlabel>
            </StyledCardWrapper>
        </div>
    )
});

const EntityCard = (props) => {
    const { tooltipTitle } = props;

    return (
        <Tooltip title={tooltipTitle} arrow>
            <CardContent {...props} />
        </Tooltip>
    );
};

export default EntityCard;
