import ReCAPTCHA from "react-google-recaptcha";

const BaasRecaptcha = ({ siteKey, verifyReCaptcha }) => {
  return (
    <>
      <ReCAPTCHA
        render="explicit"
        sitekey={siteKey}
        onChange={verifyReCaptcha}
      />
    </>
  );
};

export default BaasRecaptcha;
