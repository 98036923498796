import React, { useCallback, useState } from "react";
import ReactJson from "react-json-view";
import BaasModal from "components/atoms/Modal/Modal";
import Backdrop from "@mui/material/Backdrop";
import styled from "styled-components";
import { useTheme } from "@mui/private-theming";
import { Box } from "@mui/system";
import BaasText from "components/atoms/Text/BaasText";
import BaasButton from "components/atoms/Button/Button";
import BaasGrid from "components/atoms/Grid/Grid";
import { Form, Formik } from "formik";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import { useOtp } from "providers/oneTimePassword";
import { mutationPostDownloadFile } from "api/cnab-api";
import { toast } from "react-toastify";
import { toastMessages } from "constants/toastMessages";
import converterFromBase64 from "helpers/converterFromBase64";

const BoxStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  max-width: 600px;

  height: 65vh;
  background-color: ${(props) => props.theme.palette.grey[200]};

  box-shadow: 24;
  padding: 4px;
  border-radius: 4px;
  overflow: hidden;
  overflow-y: scroll;

  :focus-visible {
    outline: none;
  }
`;

const JsonStyled = styled(Box)`
  border-radius: 4px;
  background: ${(props) => props.theme.palette.grey[100]};
  max-height: 600px;
  overflow-y: scroll;
  border-radius: 4px;
  padding: 8px;
`;

const MovementReportModal = ({ data, setModal, modal }) => {
  const theme = useTheme();
  const { setOpen } = useOtp();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setModal(false);
  };

  const mutateDownloadFile = useCallback(
    async (payload, otp) => {
      const response = await mutationPostDownloadFile(payload, otp);
      if (response.status < 300) {
        if ("body" in response) {
          converterFromBase64(data.body.fileName, response.body, "xlsx");
          toast.success("Your download will start shortly.");
        } else {
          toast.error(toastMessages.cnab.download.error);
        }
      } else {
        if ("errors" in response) {
          response.errors.map((error) => toast.error(error.message));
        } else toast.error(toastMessages.cnab.download.error);
      }
      setIsSubmitting(false);
    },
    [data.body.fileName]
  );

  const onSubmit = useCallback(
    (values) => {
      setIsSubmitting(true);
      mutateDownloadFile(
        {
          fileName: data.body.fileName,
        },
        values.otp
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data.body.fileName, mutateDownloadFile]
  );

  return (
    <>
      <BaasModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Formik initialValues={{}} onSubmit={() => setOpen(true)}>
          {({ values, isSubmitting, ...props }) => (
            <Form>
              <BaasOtpModal
                onConfirm={() => onSubmit(values, props)}
                {...props}
              />
              <BoxStyled p={3} theme={theme}>
                <BaasText>Transaction Details (Object TreeView)</BaasText>
                <BaasGrid p="16px 0px">
                  <BaasButton
                    backgroundColor="black"
                    variant="contained"
                    type="submit"
                    loading={isSubmitting}
                    disabled={!data.body.fileName ? true : false}
                  >
                    Donwload
                  </BaasButton>
                </BaasGrid>
                <JsonStyled theme={theme}>
                  <ReactJson
                    name={null}
                    src={data}
                    style={{ height: "100%", overflowY: "scroll" }}
                    displayObjectSize={false}
                    displayDataTypes={false}
                  />
                </JsonStyled>
              </BoxStyled>
            </Form>
          )}
        </Formik>
      </BaasModal>
    </>
  );
};

export default MovementReportModal;
