import React, { useState } from "react";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import BaasRow from "components/atoms/Grid/Row";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import { useParams } from "react-router-dom";
import PixTransactionInputModal from "components/organism/PixTransactionInputModal/PixTransactionInputModal";
import PixInfractionReportPanel from "components/organism/PixInfractionReportPanel/PixInfractionReportPanel";

const PixTestSuitInfraction = () => {
  const { txId: txIdParams } = useParams();
  const [txId, setTxId] = useState(txIdParams);
  const [open, setOpen] = useState(!txId);

  const componentProps = {
    faqMessages: [
      {
        title: "How to create an infraction report",
        description:
          "Take a look on documentation about how to use the methods.",
        externalLink:
          "https://developer.btgpactual.com/docpc#operation/post-pix-infraction-report",
        linkLabel: "Read Documentation",
      },
    ],
    setOpen,
    txId,
  };

  return (
    <BaasFlex flex={1} flexDirection="column">
      <BaasPageHeader>Pix</BaasPageHeader>
      <BaasContentGrid spacing={4}>
        <BaasGrid item spacing={1}>
          <BaasRow>
            <BaasText variant="h6">Pix Test Suit Infraction</BaasText>
          </BaasRow>
        </BaasGrid>
        <PixInfractionReportPanel props={componentProps} />
      </BaasContentGrid>
      <PixTransactionInputModal
        open={open}
        setOpen={setOpen}
        setTxId={setTxId}
      />
    </BaasFlex>
  );
};

export default PixTestSuitInfraction;
