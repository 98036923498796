import { useTheme } from "@mui/system";
import BaasGrid from "components/atoms/Grid/Grid";
import moment from "moment";
import BaasInfoDisplay from "../InfoDisplay/InfoDisplay";

const SecretDisplayer = ({ data, ...props }) => {
  const theme = useTheme();

  return (
    <>
      <BaasGrid
        p="16px"
        sx={{ background: theme.palette.grey[100], marginTop: 3 }}
      >
        <BaasGrid container p={1} spacing={3}>
          <BaasGrid item xs={12} md={6} lg={3}>
            <BaasInfoDisplay label={"Prefix:"} value={data.prefix} />
          </BaasGrid>

          <BaasGrid item xs={12} md={6} lg={3}>
            <BaasInfoDisplay
              label={"Expiration Date:"}
              value={moment(data.expirationDate).format("DD/MM/YYYY")}
            />
          </BaasGrid>
          {"profileSubsetId" in data ? (
            <BaasGrid item xs={12} lg={3}>
              <BaasInfoDisplay
                label={"Secret Key Profile:"}
                value={data.profileSubsetId}
              />
            </BaasGrid>
          ) : null}
          <BaasGrid item xs={12}>
            <BaasInfoDisplay label={"Secret Key:"} value={data.secret} />
          </BaasGrid>
        </BaasGrid>
      </BaasGrid>
    </>
  );
};

export default SecretDisplayer;
