import { useTheme } from "@mui/system";
import React from "react";
import styled from "styled-components";

const BaasTableHeaderStyle = styled.div`
  display: flex;
  background-color: ${(props) => props.theme.palette.grey[300]};
  min-width: ${(props) => props.minWidth};
`;

const BaasTableHeader = ({ children, ...props }) => {
  const theme = useTheme();
  return (
    <BaasTableHeaderStyle theme={theme} {...props}>
      {children}
    </BaasTableHeaderStyle>
  );
};

export default BaasTableHeader;
