import { Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Checkbox from "@mui/material/Checkbox";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasTextFieldCurrency from "components/molecules/TextFieldCurrency/TextFieldCurrency";
import React from "react";
import { interestModalities } from "../constants/modalities";

const InterestFormInputs = ({
  values,
  activeModalities,
  toggleModality,
  errors,
}) => {
  const modalityIsActive = activeModalities.includes("INTEREST");

  return (
    <BaasGrid item xs={12}>
      <Accordion
        expanded={modalityIsActive}
        style={{ width: "100%", marginTop: "16px" }}
      >
        <AccordionSummary>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <Checkbox
                defaultChecked={modalityIsActive}
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleModality("INTEREST");
                }}
              />
              <BaasText variant="body1" color="textSecondary">
                <b>INTEREST</b>
              </BaasText>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <BaasRow
            container
            column={12}
            spacing={2}
            style={{ marginTop: "-32px" }}
          >
            <BaasGrid item xs={12} md={6}>
              <BaasTextField
                fullWidth
                name="interestModality"
                label="Modality"
                disabled={!modalityIsActive}
                required
                variant="standard"
                select
              >
                {interestModalities.map((modality) => (
                  <BaasMenuItem value={modality.value}>
                    {modality.label}
                  </BaasMenuItem>
                ))}
              </BaasTextField>
            </BaasGrid>
            <BaasGrid item xs={12} md={6}>
              {(values.interestModality === "VALUE_CALENDAR_DAYS" ||
                values.interestModality === "VALUE_WORKING_DAYS") && (
                <BaasTextFieldCurrency
                  fullWidth
                  name="interestAmountPerc"
                  label="Amount"
                  disabled={!modalityIsActive}
                  errors={errors}
                  required={Boolean(values.interestModality)}
                />
              )}
              {values.interestModality !== "VALUE_CALENDAR_DAYS" &&
                values.interestModality !== "VALUE_WORKING_DAYS" && (
                  <BaasTextField
                    fullWidth
                    name="interestAmountPerc"
                    label="Amount (%)"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    errors={errors}
                    required={Boolean(values.interestModality)}
                    type="number"
                    disabled={!modalityIsActive}
                  />
                )}
            </BaasGrid>
          </BaasRow>
        </AccordionDetails>
      </Accordion>
    </BaasGrid>
  );
};

export default InterestFormInputs;
