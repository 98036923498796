import { useTheme } from "@mui/system";
import BaasButton from "components/atoms/Button/Button";
import BaasText from "components/atoms/Text/BaasText";
import React, { useCallback } from "react";
import styled from "styled-components";
import { useHistory } from "react-router";
import BaasMenu from "components/atoms/Menu/Menu";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useOtp } from "providers/oneTimePassword";

const CardStyle = styled.div`
  background: ${(props) => props.theme.palette.grey[800]};
  border-radius: 4px;
  height: 118px;
  padding: 22px;
  margin: 4px;

  width: 100%;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const BaasTransactionCard = ({ label, path, subRoute, permissions }) => {
  const theme = useTheme();
  const history = useHistory();
  const { isOtpRequired } = useOtp();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRedirect = useCallback(
    (value) => {
      history.push(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  );

  return (
    <CardStyle theme={theme}>
      <BaasText color="grey.100" variant="h6">
        {label}
      </BaasText>
      {subRoute && !isOtpRequired ? (
        <>
          <BaasMenu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {subRoute.map((flux) => (
              <BaasMenuItem onClick={() => handleRedirect(flux.path)}>
                {flux.name}
              </BaasMenuItem>
            ))}
          </BaasMenu>
          <BaasButton
            variant="contained"
            size="small"
            backgroundColor="white"
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            endIcon={<KeyboardArrowDownIcon />}
            onClick={handleClick}
          >
            Transfer
          </BaasButton>
        </>
      ) : (
        <BaasButton
          onClick={() => handleRedirect(path)}
          variant="contained"
          size="small"
          backgroundColor="white"
        >
          Transfer
        </BaasButton>
      )}
    </CardStyle>
  );
};

export default BaasTransactionCard;
