import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/material";
import { useTheme } from "@mui/system";
import {
  mutationUpdateMerchantCustomer,
  queryGetMerchantCustomerById,
} from "api/pix-api";
import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import Endpoint from "constants/endpoints";
import { Form, Formik } from "formik";
import { useCustomMutation } from "helpers/useCustomMutation";
import { useCustomQuery } from "helpers/useCustomQuery";
import { useOtp } from "providers/oneTimePassword";
import { useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";

const CustomerEdit = () => {
  const theme = useTheme();
  const { customerId } = useParams();
  const { fetch, data, loading } = useCustomQuery(queryGetMerchantCustomerById);
  const history = useHistory();
  const { setOpen } = useOtp();
  const validationSchema = yup.object().shape({});

  const { mutate, isSubmitting } = useCustomMutation(
    mutationUpdateMerchantCustomer
  );

  useEffect(() => {
    if (customerId) {
      fetch({
        values: customerId,
        objectPath: "body",
        objectFallback: {},
      });
    }
  }, [customerId, fetch]);

  const onSubmit = useCallback(
    (values) => {
      mutate(
        {
          merchantCustomerId: values.merchantCustomerId,
          name: values.name,
          taxId: values.taxId,
          additionalData: [values.additionalData],
        },
        (response) =>
          history.push({
            pathname: `/pix/customers-management`,
          })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const inputs = [
    {
      name: "merchantCustomerId",
      label: "Merchant Customer Id",
      required: true,
      disabled: true,
    },
    {
      name: "name",
      label: "Name",
      required: true,
      disabled: false,
    },
    {
      name: "taxId",
      label: "Tax Id",
      required: true,
      disabled: false,
    },
    {
      name: "additionalData",
      label: "Aditional data",
      disabled: false,
    },
  ];

  return (
    <BaasFlex flex={1} flexDirection="column">
      <BaasContentGrid spacing={4}>
        <BaasGrid item spacing={1}>
          <BaasRow>
            <BaasText variant="h6">Pix Customers Management</BaasText>
          </BaasRow>
        </BaasGrid>
        <BaasGrid sx={{ background: theme.palette.grey[100] }} p="16px">
          <Box mb={3}>
            <BaasButton
              startIcon={<ArrowBackIcon />}
              size="small"
              onClick={() =>
                history.push({
                  pathname: `/pix/customers-management`,
                })
              }
            >
              Back
            </BaasButton>
          </Box>
          <BaasText variant="body2" fontWeight="bold">
            Merchant Customer Update
          </BaasText>
          <BaasRow>
            <BaasGrid sx={{ background: theme.palette.grey[100] }}>
              {loading ? (
                <>
                  <BaasGrid item xs={12} sm={12}>
                    <BaasSkeleton width="100%" height="65px" />
                  </BaasGrid>
                  <BaasGrid item xs={12} sm={12}>
                    <BaasSkeleton width="100%" height="65px" />
                  </BaasGrid>
                  <BaasGrid item xs={12} sm={12}>
                    <BaasSkeleton width="100%" height="65px" />
                  </BaasGrid>
                  <BaasGrid item xs={12} sm={12}>
                    <BaasSkeleton width="100%" height="65px" />
                  </BaasGrid>
                </>
              ) : (
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    merchantCustomerId: customerId,
                    name: data?.name || "",
                    taxId: data?.taxId || "",
                    additionalData:
                      data?.additionalData && data?.additionalData.length > 0
                        ? data?.additionalData[0]
                        : "",
                  }}
                  onSubmit={
                    Endpoint.Environment === "Sandbox"
                      ? onSubmit
                      : () => setOpen(true)
                  }
                  validationSchema={validationSchema}
                >
                  {({ values, setFieldValue, ...props }) => {
                    return (
                      <Form>
                        <BaasOtpModal
                          onConfirm={() => onSubmit(values)}
                          {...props}
                        />
                        <BaasText variant="body2">
                          Update the Merchant customer data.
                        </BaasText>

                        <BaasRow>
                          <BaasGrid
                            sx={{ background: theme.palette.grey[100] }}
                          >
                            <BaasRow container column={12} spacing={3}>
                              {inputs.map((input) => {
                                return (
                                  <BaasGrid
                                    item
                                    xs={12}
                                    md={6}
                                    key={input.name}
                                  >
                                    <BaasTextField
                                      fullWidth
                                      name={input.name}
                                      label={input.label}
                                      disabled={input.disabled}
                                      required={input.required}
                                    />
                                  </BaasGrid>
                                );
                              })}
                            </BaasRow>
                            <BaasButton
                              startIcon={<EditIcon />}
                              type="submit"
                              size="medium"
                              onClick={() => {}}
                              variant="contained"
                              isSubmiting={isSubmitting}
                            >
                              Save Changes
                            </BaasButton>
                          </BaasGrid>
                        </BaasRow>
                      </Form>
                    );
                  }}
                </Formik>
              )}
            </BaasGrid>
          </BaasRow>
        </BaasGrid>
      </BaasContentGrid>
    </BaasFlex>
  );
};

export default CustomerEdit;
