export const toastMessages = {
  loginFail: "Invalid credentials.",
  registerSuccess:
    "Your submission has been sent! Our team will contact you ASAP.",
  userExist: "User already exists",
  redirectSuccess: "You'll be redirected shortly.",
  unknownError: "Unknown error...",
  requestSuccess: "Request sent successfully!",
  requestError: "Request sent error.",
  certificateSuccess: "Certificate submitted successfully.",
  newPassword: {
    error: {
      400: "Session token is compromised.",
      403: "The user related to the token parameter has credentials locked. You already requested a password change, if the problem persist contact us",
      404: "Token parameter not found or expired.",
      409: "The user related to the token parameter is active. The token is no longer valid.",
      428: "The code is invalid",
    },
    success: {
      200: "Password change completed. Now you can set your One Time Password.",
      204: "Password change completed. Login with your new credentials!",
    },
  },
  newOtp: {
    success: "New OTP registration completed. Login with your new credentials!",
    error: {
      428: "The code is invalid",
    },
  },
  webhook: {
    subscriptionsSaved: "Your subscriptions were saved successfully",
    pingSuccess: "Ping message sent to the subscribed URL",
    pingError: "Error sending ping message.",
  },
  search: {
    searchEmpty: "No results found.",
  },
  cnab: {
    download: {
      error: "Error downloading the file.",
    },
    upload: {
      success: "Upload successful.",
    },
  },
  efx: {
    download: {
      success: "Download successful.",
      error: "Error downloading the file.",
    },
    upload: {
      success: "Upload successful.",
      error: "Error uploading the file.",
    },
    validateDate: {
      error: "Invalid date.",
    },
  },
};
