import styled from "@emotion/styled";
import DialogContent from "@mui/material/DialogContent";

export const StyledIdWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  padding-left: 24px;
`;

export const StyledDialogContent = styled(DialogContent)`
  display: flex;
  flex-direction: column;
`;
