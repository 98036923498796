import { mutationCreateQrCodeDueDate } from "api/baasTester/qrTester-api";
import BaasBox from "components/atoms/Box/Box";
import BaasButton from "components/atoms/Button/Button";
import BaasDatePicker from "components/atoms/DatePicker/DatePicker";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasTextFieldCurrency from "components/molecules/TextFieldCurrency/TextFieldCurrency";
import FaqSection from "components/organism/FaqSection/FaqSection";
import { Form, Formik } from "formik";
import { useCustomMutation } from "helpers/useCustomMutation";
import moment from "moment";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import { formatAmountValues } from "../helpers/formatAmountValues";
import { formatYupSchemaByModalities } from "../helpers/formatYupSchemaByModalities";
import AbatementFormInputs from "./AbatementFormInputs";
import DiscountFormInputs from "./DiscountFormInputs";
import FineFormInputs from "./FineFormInputs";
import InterestFormInputs from "./InterestFormInputs";

const DueDateEmvPanel = ({ setSuccess }) => {
  const [activeModalities, setActiveModalities] = useState([]);

  const { mutate, isSubmitting } = useCustomMutation(
    mutationCreateQrCodeDueDate
  );

  const [discountDateValues, setDiscountDateValues] = useState([
    {
      date: moment().add(1, "days"),
      amount: 0.0,
    },
  ]);

  const validationSchema = {
    amount: yup
      .number()
      .min(0.01, "Minimum value is R$0.01")
      .max(10, "Maximum value is R$10.00")
      .required("Amount is Required"),
    debtorTaxId: yup
      .string()
      .min(11, "Tax Id must have more than 11 characters")
      .max(14, "Tax Id must have less than 14 characters")
      .required("Tax Id is required"),
  };

  const initialValues = useMemo(() => {
    return {
      clientRequestId: "",
      dueDate: moment().add(1, "days"),
      expirationAfterPayment: "",
      debtorTaxId: "",
      debtorName: "",
      amount: "",
      abatementModality: "FIXED_VALUE",
      discountModality: "FIXED_VALUE_UNTIL_THE_DATES_INFORMED",
      fineModality: "FIXED_VALUE",
      interestModality: "VALUE_CALENDAR_DAYS",
    };
  }, []);

  const checkDuplicateDate = () => {
    for (let i = 0; i < discountDateValues.length; i++) {
      const date = moment(discountDateValues[i].date).format("YYYY-MM-DD");

      for (let j = i + 1; j < discountDateValues.length; j++) {
        if (date === moment(discountDateValues[j].date).format("YYYY-MM-DD")) {
          return true;
        }
      }
    }
    return false;
  };

  const onSubmit = useCallback(
    (values, resetForm) => {
      if (
        (activeModalities.includes("DISCOUNT") &&
          values.discountModality === "FIXED_VALUE_UNTIL_THE_DATES_INFORMED") ||
        values.discountModality === "PERCENTAGE_DATE_REPORTED"
      ) {
        const hasDuplicatedDate = checkDuplicateDate();
        if (hasDuplicatedDate) {
          toast.error("Duplicated date in Discount list");
        } else {
          mutate(
            {
              amount: formatAmountValues(
                values,
                activeModalities,
                discountDateValues
              ),
              debtor: {
                cpf: values.debtorTaxId.length < 14 ? values.debtorTaxId : null,
                cnpj:
                  values.debtorTaxId.length >= 14 ? values.debtorTaxId : null,
                name: values.debtorName,
              },
              calendar: {
                dueDate: values.dueDate,
                expirationAfterPayment: values.expirationAfterPayment,
              },
            },
            (data) => {
              resetForm();
              setSuccess(data.body?.body?.loc?.body?.emv);
            }
          );
        }
      } else {
        mutate(
          {
            amount: formatAmountValues(
              values,
              activeModalities,
              discountDateValues
            ),
            debtor: {
              cpf: values.debtorTaxId.length < 14 ? values.debtorTaxId : null,
              cnpj: values.debtorTaxId.length >= 14 ? values.debtorTaxId : null,
              name: values.debtorName,
            },
            calendar: {
              dueDate: values.dueDate,
              expirationAfterPayment: values.expirationAfterPayment,
            },
          },
          (data) => {
            resetForm();
            setSuccess(data.body?.body?.loc?.body?.emv);
          }
        );
      }
    },
    [
      activeModalities,
      checkDuplicateDate,
      discountDateValues,
      mutate,
      setSuccess,
    ]
  );

  const toggleModality = (value) => {
    if (activeModalities.includes(value)) {
      const result = activeModalities.filter((modality) => modality !== value);
      setActiveModalities(result);
    } else {
      let modalities = [...activeModalities, value];
      setActiveModalities(modalities);
    }
  };

  const yupSchema = formatYupSchemaByModalities(
    validationSchema,
    activeModalities
  );

  return (
    <BaasFlex flexDirection="column" width="100%">
      <BaasRow>
        <BaasText variant="h6">Due Date Collection</BaasText>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { resetForm }) => {
            onSubmit(values, resetForm);
          }}
          validationSchema={yupSchema}
        >
          {({ values, setFieldValue, errors, isValid, ...props }) => {
            return (
              <Form>
                <BaasText variant="body2">
                  Create a collection due date
                </BaasText>
                <BaasRow container column={12} spacing={2}>
                  <BaasGrid item xs={12} md={6}>
                    <BaasTextField
                      fullWidth
                      name="debtorName"
                      label="Debtor Name"
                      required
                    />
                  </BaasGrid>
                  <BaasGrid item xs={12} md={6}>
                    <BaasTextField
                      fullWidth
                      name="debtorTaxId"
                      displayError={true}
                      label="Debtor Tax Id"
                      required
                    />
                  </BaasGrid>
                  <BaasGrid item xs={12} md={6}>
                    <BaasTextFieldCurrency
                      fullWidth
                      name="amount"
                      label="Amount"
                      required
                      errors={errors}
                    />
                  </BaasGrid>
                  <BaasGrid item xs={12} sm={6}>
                    <BaasDatePicker
                      fullWidth
                      name="dueDate"
                      label="Due Date"
                      minDate={moment()}
                      required
                    />
                  </BaasGrid>
                  <BaasGrid item xs={12}>
                    <BaasTextField
                      fullWidth
                      name="expirationAfterPayment"
                      label="Expiration after payment (In Days)"
                      required
                      type="number"
                      inputProps={{ min: 0 }}
                    />
                  </BaasGrid>
                  <AbatementFormInputs
                    values={values}
                    errors={errors}
                    activeModalities={activeModalities}
                    toggleModality={toggleModality}
                  />
                  <FineFormInputs
                    values={values}
                    errors={errors}
                    activeModalities={activeModalities}
                    toggleModality={toggleModality}
                  />
                  <InterestFormInputs
                    values={values}
                    errors={errors}
                    activeModalities={activeModalities}
                    toggleModality={toggleModality}
                  />
                  <DiscountFormInputs
                    values={values}
                    errors={errors}
                    discountDateValues={discountDateValues}
                    setDiscountDateValues={setDiscountDateValues}
                    activeModalities={activeModalities}
                    toggleModality={toggleModality}
                  />
                </BaasRow>
                <BaasGrid p={0} item>
                  <BaasButton
                    type="submit"
                    size="medium"
                    variant="contained"
                    backgroundColor="primary"
                    loading={isSubmitting}
                    disabled={!isValid}
                  >
                    Create
                  </BaasButton>
                </BaasGrid>
              </Form>
            );
          }}
        </Formik>
      </BaasRow>
      <BaasBox mt={2}>
        <FaqSection
          messages={[
            {
              title: "How to create a due date collection",
              description:
                "Read the docs about the creation of a due date collection",
              externalLink:
                "https://developer.btgpactual.com/docpi#tag/PixCollectionDueDate",
              linkLabel: "Read Documentation",
            },
          ]}
        />
      </BaasBox>
    </BaasFlex>
  );
};

export default DueDateEmvPanel;
