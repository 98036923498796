import BaasGrid from "components/atoms/Grid/Grid";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import { StyledInfoWrapper, StyledPanelTitle, StyledPanelWrapper } from "./styles";

const PixQrCodeViewPanelSkeleton = () => {
    return (
        <StyledPanelWrapper container spacing={1} p={2}>
            <StyledPanelTitle item md={12} mb={2}>
                <BaasSkeleton width="100px" height="23px" />
            </StyledPanelTitle>
            <StyledInfoWrapper item xs={12} sm={12} md={6}>
                <BaasSkeleton width="100px" height="23px" />
                <BaasSkeleton width="100px" height="23px" />
                <BaasSkeleton width="100px" height="23px" />
                <BaasSkeleton width="100px" height="23px" />
            </StyledInfoWrapper>
            <BaasGrid item xs={5} sm={6} md={6} mb={2}>
                <BaasSkeleton width="200px" height="200px" />
            </BaasGrid>
        </StyledPanelWrapper>
    )
}

export default PixQrCodeViewPanelSkeleton;