import * as yup from "yup";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import React, { useState, useCallback, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import { useTheme } from "@mui/system";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasButton from "components/atoms/Button/Button";
import { useCustomMutation } from "helpers/useCustomMutation";
import { useCustomQuery } from "helpers/useCustomQuery";
import { mutationPostPixSandboxRefund } from "api/pix-api";
import { queryGetRefundExistence } from "api/pix-api";
import BaasBox from "components/atoms/Box/Box";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import { BackButton } from "components/molecules/BackButton/BackButton";

const PixTestSuitRefund = () => {
  const { endToEndId, infractionId } = useParams();
  const [existRefundData, setExistRefundData] = useState(undefined);

  const history = useHistory();
  const theme = useTheme();

  const { fetch, data, loading } = useCustomQuery(queryGetRefundExistence);

  useEffect(() => {
    setExistRefundData(data);
  }, [data, setExistRefundData]);

  const validationSchema = yup.object().shape({
    endToEndId: yup
      .string()
      .required()
      .min(32, "Must be exactly 32 digits")
      .max(32, "Must be exactly 32 digits"),
    infractionId: yup
      .string()
      .required()
      .min(36, "Must be exactly 36 digits")
      .max(36, "Must be exactly 36 digits"),
  });

  useEffect(() => {
    if (
      endToEndId &&
      endToEndId.length === 32 &&
      infractionId &&
      infractionId.length === 36
    ) {
      fetch({
        values: endToEndId,
        objectPath: "body",
        objectFallback: {},
      });
    }
  }, [endToEndId, fetch, infractionId]);

  const { mutate, isSubmitting } = useCustomMutation(
    mutationPostPixSandboxRefund
  );

  const redirectToDetails = useCallback(
    (id) => {
      history.push({
        pathname: `/pix-test-suit-refund-details/${id}`,
      });
    },
    [history]
  );

  const onSubmit = useCallback(
    (values) => {
      mutate(
        {
          endToEndId: values.endToEndId || endToEndId,
          infractionId: values.infractionId || infractionId,
          refundReason: values.refundReason,
          refundDetails: values.refundDetails,
        },
        (response) => redirectToDetails(response.body.id)
      );
    },
    [endToEndId, infractionId, mutate, redirectToDetails]
  );

  const inputs = [
    {
      name: "infractionId",
      label: "Infraction id",
      disabled: Boolean(infractionId),
      value: infractionId,
    },
    {
      name: "endToEndId",
      label: "End to end id",
      disabled: Boolean(endToEndId),
      value: endToEndId,
    },
    {
      name: "refundReason",
      label: "Reason",
      disabled: true,
    },
    {
      name: "refundDetails",
      label: "details",
      disabled: false,
    },
  ];

  const onRefundClick = (setValues) => {
    setExistRefundData(undefined);
    history.push({
      pathname: `/pix-test-suit-refund-create`,
    });
    setValues({
      refundDetails: "",
      refundReason: "FRAUD",
      endToEndId: "",
      infractionId: "",
    });
  };

  const onDetailsClick = (idRefund) => {
    history.push({
      pathname: `/pix-test-suit-refund-details/${idRefund}`,
    });
  };

  return (
    <BaasFlex flex={1} flexDirection="column">
      <BaasPageHeader>Pix</BaasPageHeader>
      <BaasContentGrid spacing={4}>
        <BaasGrid item spacing={1}>
          <BaasRow>
            <BaasText variant="h6">Pix Test Suit Refund</BaasText>
          </BaasRow>
        </BaasGrid>
        <BaasGrid sx={{ background: theme.palette.grey[100] }} p={2}>
          {loading ? (
            <>
              <BaasGrid item xs={12} sm={12}>
                <BaasSkeleton width="100%" height="65px" />
              </BaasGrid>
              <BaasGrid item xs={12} sm={12}>
                <BaasSkeleton width="100%" height="65px" />
              </BaasGrid>
              <BaasGrid item xs={12} sm={12}>
                <BaasSkeleton width="100%" height="65px" />
              </BaasGrid>
              <BaasGrid item xs={12} sm={12}>
                <BaasSkeleton width="100%" height="65px" />
              </BaasGrid>
            </>
          ) : (
            <Formik
              initialValues={{
                refundReason: "FRAUD",
                endToEndId: endToEndId,
                infractionId: infractionId,
              }}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {({ values, setFieldValue, setValues, ...props }) => (
                <Form>
                  <BackButton path="/pix-test-suit-refund" />
                  <BaasText variant="body2" fontWeight="bold">
                    Create a refund by a closed and agreed infraction.
                  </BaasText>
                  <BaasRow container column={12} spacing={3}>
                    {inputs.map((input) => {
                      return (
                        <BaasGrid item xs={12} md={6} key={input.name}>
                          <BaasTextField
                            fullWidth
                            disabled={input.disabled}
                            name={input.name}
                            label={input.label}
                            required
                          />
                        </BaasGrid>
                      );
                    })}
                  </BaasRow>
                  {existRefundData && existRefundData.existRefund ? (
                    <BaasBox mt={2}>
                      <BaasText variant="body2" fontWeight="bold" color="error">
                        A refund was created by this infraction
                      </BaasText>
                      <BaasBox
                        mt={2}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        <BaasButton
                          startIcon={<ClearIcon />}
                          onClick={() => onRefundClick(setValues)}
                          color="error"
                          variant="outlined"
                        >
                          Clear Values
                        </BaasButton>
                        <BaasButton
                          onClick={() =>
                            onDetailsClick(existRefundData.idRefund)
                          }
                          startIcon={<VisibilityIcon />}
                          variant="outlined"
                        >
                          View Refund Details
                        </BaasButton>
                      </BaasBox>
                    </BaasBox>
                  ) : (
                    <BaasBox
                      mt={2}
                      width="100%"
                      display="flex"
                      justifyContent="space-between"
                    >
                      <BaasButton
                        startIcon={<ClearIcon />}
                        onClick={() => onRefundClick(setValues)}
                        color="error"
                        variant="outlined"
                      >
                        Clear Values
                      </BaasButton>
                      <BaasButton
                        variant="contained"
                        type="submit"
                        disabled={
                          existRefundData && !!existRefundData.existRefund
                        }
                        loading={isSubmitting}
                      >
                        Create Refund
                      </BaasButton>
                    </BaasBox>
                  )}
                </Form>
              )}
            </Formik>
          )}
        </BaasGrid>
      </BaasContentGrid>
    </BaasFlex>
  );
};

export default PixTestSuitRefund;
