export const openFinanceValues = {
  authorizationServerId: "da20a893-86a6-4b44-a122-bab6e6e73f56",
  taxId: "18475198066",
  loggedUser: {
    identification: "18475198066",
    type: "CPF",
  },
  businessEntity: {
    identification: "64539964000195",
    type: "CNPJ",
  },
  debtorAccount: {
    ispb: "12345678",
    issuer: "1774",
    number: "123456789",
    accountType: "CACC",
  },
  fidoOptionsPactualId: "e8db2fe5-c824-441a-a122-3211efb991b1",
};
