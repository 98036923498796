import BaasNavBar from "components/organism/NavBar/NavBar";
import BaasNavBarMobile from "components/organism/NavBar/NavBarMobile";

import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";

const BaasAppBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div>
      {isMobile ? (
        <BaasNavBarMobile />
      ) : (
        <>
          <div style={{ width: "240px" }} />
          <BaasNavBar />
        </>
      )}
    </div>
  );
};

export default BaasAppBar;
