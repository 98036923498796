import { Box } from "@mui/material";
import StepLabel from "@mui/material/StepLabel";
import { useTheme } from "@mui/system";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasStep from "components/atoms/Step/Step";
import BaasStepper from "components/atoms/Stepper/Stepper";
import BaasText from "components/atoms/Text/BaasText";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import { OpenFinanceAmountPanel } from "components/organism/OpenFinanceAmountPanel/OpenFinanceAmountPanel";
import { OpenFinanceCheckoutPanel } from "components/organism/OpenFinanceCheckoutPanel/OpenFinanceCheckoutPanel";
import { OpenFinanceParticipantPanel } from "components/organism/OpenFinanceParticipantPanel/OpenFinanceParticipantPanel";
import { OpenFinancePoolingPanel } from "components/organism/OpenFinancePoolingPanel/OpenFinancePoolingPanel";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import React, { useEffect, useState } from "react";
import { openFinanceSteps } from "./stepsConfig";
import { useParams } from "react-router-dom";
import { useCustomQuery } from "helpers/useCustomQuery";
import { queryGetEnrollmentByPactualId } from "api/openFinance/openFinance-api";
import StackCard from "components/atoms/StackCard/StackCard";

const steps = [
  OpenFinanceAmountPanel,
  OpenFinanceParticipantPanel,
  OpenFinanceCheckoutPanel,
  OpenFinancePoolingPanel,
];

export default function OpenFinance() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const url = new URL(window.location.href);
  const enrollmentPactualId = url.searchParams.get("pactualId");
  const enrollmentStatus = url.searchParams.get("status");

  const [authPoolingPactualid, setAuthPoolingPactualId] = useState();
  // const params = JSON.parse(sessionStorage.getItem("opfParams"));
  const [values, setValues] = useState();

  const {
    fetch: getFidoOptions,
    loading: loadingFidoOptions,
    data: fidoOptionsData,
  } = useCustomQuery(queryGetEnrollmentByPactualId);

  useEffect(() => {
    if (enrollmentPactualId && enrollmentStatus === "AWAITING_ENROLLMENT") {
      getFidoOptions({
        values: enrollmentPactualId,
        onCompleted: () => handleFidoRegistrationStep(),
      });
    }
  }, []);

  const handleFidoRegistrationStep = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = (canPersistData = true) => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (canPersistData) {
      sessionStorage.setItem("opfParams", JSON.stringify(values));
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const StepComponent = steps[activeStep];

  const componentProps = {
    enrollmentPactualId,
    enrollmentStatus,
    getFidoOptions,
    handleNext,
    handleBack,
    authPoolingPactualid,
    setAuthPoolingPactualId,
    setValues,
    values,
    fidoOptionsData,
  };

  return (
    <BaasFlex flex={1} flexDirection="column">
      <BaasPageHeader>Open Finance</BaasPageHeader>
      <BaasContentGrid spacing={4}>
        <BaasGrid p="16px 0px" xs={12}>
          <Box display="flex" alignItems="center" mb={2}>
            <BaasText variant="h6">Open Finance Flow</BaasText>
          </Box>
        </BaasGrid>
        <StackCard>
          <BaasGrid item sm={6}>
            <Box mb={4}>
              <BaasStepper activeStep={activeStep}>
                {openFinanceSteps.map((step, index) => {
                  const stepProps = {};
                  if (isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <BaasStep key={step.title} {...stepProps}>
                      <StepLabel>{step.title}</StepLabel>
                    </BaasStep>
                  );
                })}
              </BaasStepper>
            </Box>
          </BaasGrid>
          <StepComponent {...componentProps} />
        </StackCard>
      </BaasContentGrid>
    </BaasFlex>
  );
}
