import {
  baasApiOptions,
  baasUploadFile,
  baasUrlFormatter,
} from "helpers/baasApiHelpers";

export const baasApiCall = async (
  method,
  endpoint,
  payload = false,
  // extraHeaders = undefined,
  otp,
  noError = false,
  stringfy
) => {
  const otpSelect = () => {
    if (payload.otp) {
      return payload.otp;
    } else if (otp) {
      return otp;
    } else {
      return null;
    }
  };

  const response = await fetch(
    baasUrlFormatter(endpoint),
    baasApiOptions(
      method,
      payload,
      otpSelect(),
      stringfy
      // extraHeaders
    )
  );

  if (!response.ok && !noError) throw response;

  return response;
};

// =====================================================

export const baasApiCallUpload = async (endpoint, payload = false, otp) => {
  const otpSelect = () => {
    if (payload.otp) {
      return payload.otp;
    } else if (otp) {
      return otp;
    } else {
      return null;
    }
  };
  try {
    const response = await fetch(
      baasUrlFormatter(endpoint),
      baasUploadFile(payload, otpSelect())
    );
    return response;
  } catch (err) {
    console.log(err);
  }
};

export const baasApiCallGetCertificate = async () => {
  const options = {
    method: "GET",
    headers: {
      accept: "text/plain",
    },
  };

  try {
    let apiReturn = await fetch(
      baasUrlFormatter("/cm/webhook/certificate"),
      options
    );
    return apiReturn.text();
  } catch (err) {
    console.log(err);
  }
};
