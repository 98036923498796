import { useQuery } from "react-query";

import { baasApiCall, baasApiCallUpload } from "services/apiCall";
import { baasApiCallDev } from "services/apiCallDev";

// ==== Search  ====

export const queryGetImportFileByPactualId = (values) => {
  return baasApiCall("GET", `/cm/import-file/${values.pactualId}`)
    .then((data) => data.json())
    .catch(() => []);
};

export const queryGetImportFileByRange = (values) => {
  return baasApiCall(
    "GET",
    values.newCnab
      ? `/tr/payment-slip-cnab/bank-van/range/${values.dateFrom}/${values.dateTo}`
      : `/tr/payment-slip-cnab/header/range/${values.dateFrom}/${values.dateTo}`
  )
    .then((data) => data.json())
    .catch((error) => error);
};

export const mutationPostPaymentPaymentSlipCnabFileByPactualId = (payload) => {
  return baasApiCall(
    "POST",
    payload.newCnab
      ? `/tr/payment-slip-cnab/bank-van/file/download`
      : `/tr/payment-slip-cnab/file/download`,
    payload,
    payload.otp
  )
    .then((data) => data.json())
    .catch(() => []);
};

export const mutationPostImportFile = (payload) => {
  return baasApiCallUpload("/cm/import-file", payload).then((data) =>
    data.json()
  );
};

//  ==== Settlement ====
export const queryGetPaymentPaymentSlipCnabFileByRange = (values) => {
  const payload = values.newCnab ? values : undefined;
  const verb = values.newCnab ? "POST" : "GET";
  return baasApiCallDev(
    verb,
    values.newCnab
      ? `/tr/payment-slip-cnab/bank-van/settlement-file/range`
      : `/tr/payment-slip-cnab/file/settlemented/${values.startDate}/${values.endDate}`,
    payload
  );
};

// export const queryGetPaymentPaymentSlipCnabSettlementFileByFileName = (
//   values
// ) => {
//   return baasApiCallDev(
//     "GET",
//     `/tr/payment/payment-slip/cnab/settlement/file/${values.fileName}`
//   );
// };

export const queryGetPaymentPaymentSlipCnabSettlementFile = ({
  settlementDate,
  otp,
}) => {
  return baasApiCall(
    "GET",
    `/tr/payment/payment-slip/cnab/settlement/file/${settlementDate}`,
    false,
    otp
  ).then((data) => data.json());
};

export const useQueryGetPaymentPaymentSlipCnabSettlementFile = (config) => {
  return useQuery(
    "getPixPaymentApprovalEndpoint",
    () => queryGetPaymentPaymentSlipCnabSettlementFile(),
    config
  );
};
useQueryGetPaymentPaymentSlipCnabSettlementFile.queryKey =
  "getPixPaymentApprovalEndpoint";

//  ==== Movement Report ====

export const queryGetMovementReport = (payload) => {
  return baasApiCall(
    "GET",
    `/tr/payment-slip-cnab/movement/report/${payload.startDate}/${payload.endDate}`
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostMovementReportByRange = (payload, otp) => {
  return baasApiCall(
    "POST",
    `/tr/payment-slip-cnab/movement/report/generate/${payload.startDate}/${payload.endDate}`,
    false,
    otp
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostDownloadFile = (payload, otp) => {
  return baasApiCall(
    "POST",
    `/tr/payment-slip-cnab/file/download`,
    payload,
    otp
  )
    .then((data) => data.json())
    .catch(() => []);
};
