import { useCustomQuery } from "./useCustomQuery";

export const useCustomPagination = (customQuery) => {
  const getQueryVariables = ({ values, limit, page }) => {
    const parsedPage = Number(page);
    return {
      ...values,
      limit: limit,
      start: (parsedPage === 0 ? parsedPage : parsedPage - 1) * limit,
    };
  };

  const { fetch: fetchQuery, loading, data } = useCustomQuery(customQuery);

  const fetch = (props) => {
    const {
      values,
      onCompleted,
      limit = 10,
      page = 0,
      onNotFoundError,
    } = props;
    fetchQuery({
      values: getQueryVariables({ values, limit, page }),
      onCompleted,
      onNotFoundError,
    });
  };

  return {
    fetch,
    loading,
    data,
  };
};
