import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import React from "react";

const MtlsCertificateDisplay = ({ certificates }) => {
  return (
    <BaasGrid container spacing={3}>
      {certificates.length > 0 ? (
        <>
          <BaasGrid item xs={12}>
            <BaasText variant="h6">Saved Certificates</BaasText>
          </BaasGrid>
          <BaasGrid item xs={12}>
            <BaasText>
              Bellow you can find information regarding previously saved mTLS
              certificates.
            </BaasText>
          </BaasGrid>
        </>
      ) : null}

      {certificates.map((certificate) => (
        <>
          <BaasGrid item xs={12}>
            <hr />
          </BaasGrid>
          <BaasGrid item xs={12} md={6}>
            <BaasInfoDisplay
              label={"Effective Date:"}
              value={certificate.effectiveDate}
            />
          </BaasGrid>
          <BaasGrid item xs={12} md={6}>
            <BaasInfoDisplay
              label={"Expiration Date:"}
              value={certificate.expirationDate}
            />
          </BaasGrid>
          <BaasGrid item xs={12} md={6}>
            <BaasInfoDisplay label={"Issuer:"} value={certificate.issuer} />
          </BaasGrid>
          <BaasGrid item xs={12} md={6}>
            <BaasInfoDisplay label={"Subject:"} value={certificate.subject} />
          </BaasGrid>
        </>
      ))}
    </BaasGrid>
  );
};

export default MtlsCertificateDisplay;
