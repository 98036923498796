import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useTheme } from "@mui/system";
import { useQueryGetUserInfo } from "api/security-api";
import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import BaasObkProductCard from "components/molecules/ObkProductCard/ObkProductCard";
import BaasObkShoppingCart from "components/molecules/ObkShoppingCart/ObkShoppingCart";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { currencyFormatter } from "helpers/currencyFormatter";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import StackCard from "components/atoms/StackCard/StackCard";

// == store imgs ==
import cap from "assets/obkMockStore/mock_store_cap.jpg";
import polo_fem_blk from "assets/obkMockStore/mock_store_polo_fem_blk.jpg";
import polo_fem_white from "assets/obkMockStore/mock_store_polo_fem_white.jpg";
import polo_masc_grey from "assets/obkMockStore/mock_store_polo_masc_grey.jpg";
import polo_masc_white from "assets/obkMockStore/mock_store_polo_masc_white.jpg";
import pullover from "assets/obkMockStore/mock_store_pullover.jpg";
import shorts from "assets/obkMockStore/mock_store_shorts.jpg";
import tshirt from "assets/obkMockStore/mock_store_tshirt.jpg";

import StoreIcon from "assets/icons/Store.svg";
import BaasCardWithIcon from "components/molecules/CardWithIcon/CardWithIcon";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function MockStore() {
  const theme = useTheme();
  const { data: userData } = useQueryGetUserInfo();
  const history = useHistory();
  const [showPayments, setShowPayments] = useState(false);
  const [state, setState] = useState({
    products: [
      {
        id: 1,
        img: cap,
        description: "BTG Cap Navy Blue",
        value: 68.0,
      },
      {
        id: 2,
        img: polo_fem_blk,
        description: "BTG Woman's Polo Shirt Black",
        value: 90.0,
      },
      {
        id: 3,
        img: polo_fem_white,
        description: "BTG Woman's Polo Shirt White",
        value: 90.0,
      },
      {
        id: 4,
        img: polo_masc_grey,
        description: "BTG Men's Polo Shirt Grey",
        value: 90.0,
      },
      {
        id: 5,
        img: polo_masc_white,
        description: "BTG Men's Polo Shirt White",
        value: 90.0,
      },
      {
        id: 6,
        img: pullover,
        description: "BTG Pullover Navy Blue",
        value: 230.0,
      },
      {
        id: 7,
        img: shorts,
        description: "BTG Shorts Navy Blue",
        value: 299.0,
      },
      {
        id: 2,
        img: tshirt,
        description: "BTG Sports Shirt",
        value: 83.0,
      },
    ],
    cart: [],
    paymentMethod: "pixKey",
    useBaas: true,
  });

  const addToCart = (id) => {
    let cart = state.cart;
    const index = cart.findIndex((obj) => obj.id === id);
    if (index >= 0) {
      cart[index].quantity++;
    } else {
      let newItem = state.products.find((i) => i.id === id);
      newItem.quantity = 1;
      cart.push(newItem);
    }
    setState({
      ...state,
      cart: cart,
    });
  };

  const total = (items) => {
    let sum = 0;
    items.map((i) => {
      sum = sum + i.value * i.quantity;
    });
    return sum;
  };

  const pay = () => {
    sessionStorage.setItem(
      "obkAmount",
      JSON.stringify((total(state.cart) + 7.99) * 100)
    );
    sessionStorage.setItem(
      "paymentMethod",
      JSON.stringify(state.paymentMethod)
    );
    sessionStorage.setItem("useBaas", JSON.stringify(state.useBaas));
    history.push("/mock-obk/mock-bank");
  };

  return (
    <>
      <BaasFlex flexDirection="column" width="100%">
        <BaasPageHeader>Open Finance Test Suite</BaasPageHeader>
        <BaasContentGrid spacing={4}>
          <BaasGrid p="16px 0px" item>
            <BaasText variant="h6">PISP Side of the flow</BaasText>
          </BaasGrid>

          <BaasCardWithIcon title={"Mock E-Commerce"} img={StoreIcon} />

          {showPayments ? (
            <BaasGrid container spacing={3}>
              <BaasGrid item xs={12} md={8} m="16px 0 0 0">
                <BaasButton
                  backgroundColor="black"
                  onClick={() => setShowPayments(false)}
                  style={{ padding: "0 12px 0 0" }}
                >
                  <span>
                    <ArrowBackIcon
                      style={{
                        width: "24px",
                        height: "24px",
                        borderRadius: "100%",
                        textAlign: "center",
                        verticalAlign: "middle",
                        padding: "6px",
                      }}
                    />
                  </span>
                  Go back to shopping
                </BaasButton>
              </BaasGrid>
            </BaasGrid>
          ) : null}

          <BaasGrid container spacing={3}>
            <BaasGrid item xs={12} md={8}>
              <BaasText variant="body1" p="18px 0px">
                This page is part of BTG's BaaS Open Banking Mock interface. It
                aims to exemplify to clients/developers the implementation flow
                for these features.
              </BaasText>
            </BaasGrid>
          </BaasGrid>

          {showPayments ? (
            <>
              <BaasGrid container spacing={3}>
                {/* Endereço e meio de pagamento */}

                <BaasGrid item xs={12} md={8}>
                  <StackCard>
                    <BaasText variant="h6" p="24px 0">
                      Select Payment Institution:
                    </BaasText>

                    <RadioGroup
                      onChange={(e) =>
                        setState({
                          ...state,
                          useBaas: e.target.value === "true" ? true : false,
                        })
                      }
                      defaultValue={true}
                      name="useBaas"
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="Imperssonate BTG for payment (BTG as Initiator)."
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={`Imperssonate the logged client for payment (${userData.company} as Initiator).`}
                      />
                    </RadioGroup>
                  </StackCard>
                </BaasGrid>

                {/* Total e mandar pagar */}
                <BaasGrid item xs={12} md={4}>
                  <StackCard>
                    <BaasText variant="h6" m={"0 0 16px 0"}>
                      Deliver to:
                    </BaasText>
                    <BaasText variant="body1">
                      Botafogo, Rio de Janeiro - RJ, 22250-911
                    </BaasText>
                    <BaasText variant="body1">
                      Centro Empresarial Mourisco - Praia de Botafogo, 501 - 5º
                      e 6º Andar
                    </BaasText>

                    <hr
                      style={{
                        width: "100%",
                        margin: "16px 0",
                        strokeWidth: 0.5,
                      }}
                    />

                    <BaasText variant="body1" m={"16px 0 0 0"}>
                      Shipping: <strong>R$ 7,99</strong>
                    </BaasText>
                    <BaasText variant="h6" m={"0 0 16px 0"}>
                      Total: R$ {currencyFormatter(total(state.cart) + 7.99)}
                    </BaasText>
                    <BaasGrid xs={12} p="8px 0">
                      <BaasButton
                        variant="contained"
                        backgroundColor="black"
                        onClick={() => pay()}
                      >
                        Confirm payment
                      </BaasButton>
                    </BaasGrid>
                  </StackCard>
                </BaasGrid>
              </BaasGrid>
            </>
          ) : (
            <BaasGrid container spacing={3}>
              <BaasGrid item xs={12} md={8}>
                <BaasGrid container spacing={3}>
                  {state.products.map((item) => (
                    <BaasGrid item xs={12} sm={6} lg={4}>
                      <StackCard
                        sx={{
                          padding: 0,
                        }}
                      >
                        <BaasObkProductCard item={item} addToCart={addToCart} />
                      </StackCard>
                    </BaasGrid>
                  ))}
                </BaasGrid>
              </BaasGrid>
              <BaasGrid item xs={12} md={4}>
                <StackCard>
                  <BaasObkShoppingCart
                    items={state.cart}
                    checkOut={() => setShowPayments(true)}
                    total={total}
                  />
                </StackCard>
              </BaasGrid>
            </BaasGrid>
          )}
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
}
