import React, { useEffect } from "react";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import { useHistory } from "react-router";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DinamicTable from "components/organism/DinamicTable/DinamicTable";
import { useCustomQuery } from "helpers/useCustomQuery";
import { AddButton } from "components/molecules/AddButton/AddButton";
import { queryGetPixSandboxRefund } from "api/pix-api";

const PixTestSuitRefundsList = () => {
  const history = useHistory();
  const { fetch, loading, data } = useCustomQuery(queryGetPixSandboxRefund);

  useEffect(() => {
    fetch({
      values: {
        participantRole: "CONTESTED",
        limit: 50,
      },
      emptyWarning: true,
      objectPath: "body.items",
    });
  }, [fetch]);

  const onViewClick = (item) => {
    history.push({
      pathname: `/pix-test-suit-refund-details/${item.id}`,
    });
  };

  return (
    <BaasFlex flex={1} flexDirection="column">
      <BaasPageHeader>Pix</BaasPageHeader>
      <BaasContentGrid spacing={4}>
        <BaasGrid item spacing={1}>
          <BaasRow>
            <BaasText variant="h6">Pix Test Suit Refund</BaasText>
          </BaasRow>
        </BaasGrid>
        <AddButton
          label="Create Refund"
          onClick={() =>
            history.push({
              pathname: `/pix-test-suit-refund-create`,
            })
          }
        />
        <DinamicTable
          loading={loading}
          data={data}
          tableSchema={[
            {
              header: "Identification",
              key: "transactionId",
              tooltip: true,
              style: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "noWrap",
              },
            },
            {
              header: "Amount",
              key: "refundAmount",
              sort: true,
              sortType: "number",
            },
            {
              header: "Reason",
              key: "refundReason",
            },
            {
              header: "Requesting Part.",
              key: "requestingParticipant",
            },
            {
              header: "Status",
              key: "status",
              sort: true,
            },
          ]}
          extraActions={[
            {
              onClick: onViewClick,
              icon: VisibilityIcon,
              tooltip: "View",
            },
          ]}
        />
      </BaasContentGrid>
    </BaasFlex>
  );
};

export default PixTestSuitRefundsList;
