import Backdrop from "@mui/material/Backdrop";
import { useTheme } from "@mui/private-theming";
import BaasButton from "components/atoms/Button/Button";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasModal from "components/atoms/Modal/Modal";
import BaasText from "components/atoms/Text/BaasText";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import { Form, Formik } from "formik";
import React from "react";
import ReactJson from "react-json-view";
import { BoxStyled, JsonStyled } from "./styles";

export default function ModalJsonInfo({ modal, setModal, title }) {
  const theme = useTheme();
  const handleClose = () => {
    setModal({ ...modal, state: false });
  };

  return (
    <>
      <BaasModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modal && modal.state}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Formik initialValues={{}}>
          <Form>
            <BaasOtpModal />
            <BoxStyled p={3} theme={theme}>
              <BaasGrid container spacing={2} p="16px 0px">
                <BaasText>{title}</BaasText>
              </BaasGrid>

              <JsonStyled theme={theme}>
                <ReactJson
                  name={null}
                  src={modal.data}
                  style={{ height: "100%", overflowY: "scroll" }}
                  displayObjectSize={false}
                  displayDataTypes={false}
                />
              </JsonStyled>
              <BaasGrid
                item
                container
                sx={{ justifyContent: "space-between" }}
                flexDirection="row"
                p="16px 0px"
              >
                <BaasButton
                  type="submit"
                  size="medium"
                  variant="outlined"
                  backgroundColor="black"
                  onClick={() => handleClose()}
                >
                  Close
                </BaasButton>
              </BaasGrid>
            </BoxStyled>
          </Form>
        </Formik>
      </BaasModal>
    </>
  );
}
