import styled from "@emotion/styled";
import BaasText from "components/atoms/Text/BaasText";

export const StyledKeyLabel = styled(BaasText)`
    font-weight: 500;
`;

export const StyledTypeLabel = styled(BaasText)`
    color: ${({ theme }) => theme.palette.text.secondary};
    margin-left: ${({ theme }) => theme.spacing(0.5)};
    text-transform: capitalize;
`;