import BaasRow from "components/atoms/Grid/Row";
import BaasGrid from "components/atoms/Grid/Grid";
import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/system";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import BaasText from "components/atoms/Text/BaasText";
import StepLabel from "@mui/material/StepLabel";
import BaasBox from "components/atoms/Box/Box";
import EntityCard from "components/molecules/EntityCard/EntityCard";
import BaasStepper from "components/atoms/Stepper/Stepper";
import BaasStep from "components/atoms/Step/Step";
import { paymentSteps } from "./stepsConfig";
import DialogInput from "components/organism/DialogInput/DialogInput";
import { useCustomQuery } from "helpers/useCustomQuery";
import { queryGetQrCodeInfo } from "api/pix-api";

const PixTestSuitPayment = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [selectedPixKey, setSelectedPixKey] = useState();
  const [dinamicQrData, setDinamicQrData] = useState();

  const [emvQrCode, setEmvQrCode] = useState();
  const [openEmv, setOpenEmv] = useState(false);

  const handlePrevious = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const { fetch, data, loading } = useCustomQuery(queryGetQrCodeInfo);

  useEffect(() => {
    if (emvQrCode) {
      fetch({
        values: encodeURIComponent(emvQrCode),
        objectPath: "body",
        objectFallback: {},
        onError: () => {
          setOpenEmv(false);
          setEmvQrCode(undefined);
          setDinamicQrData(undefined);
        },
      });
    }
  }, [emvQrCode, fetch]);

  useEffect(() => {
    if (data && data.loc && !loading && emvQrCode) {
      setDinamicQrData({
        body: data,
      });
      setActiveStep(2);
      setOpenEmv(false);
    }
  }, [data, emvQrCode, loading]);

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const currentStep = paymentSteps[activeStep];
  const CurrentComponent = currentStep?.component;

  const toggleEmvDialog = () => {
    setOpenEmv(!openEmv);
  };
  const componentProps = {
    handleNext,
    handlePrevious,
    dinamicQrData,
    emvQrCode,
    setEmvQrCode,
    faqMessages: currentStep?.faqMessages || [],
    setDinamicQrData,
    selectedPixKey,
    setSelectedPixKey,
    toggleEmvDialog,
  };

  return (
    <>
      <BaasFlex flex={1} flexDirection="column">
        <BaasPageHeader>Pix</BaasPageHeader>
        <BaasContentGrid spacing={4}>
          <BaasGrid item spacing={1}>
            <BaasRow>
              <BaasText variant="h6">Pix Test Suit</BaasText>
            </BaasRow>
          </BaasGrid>
          <BaasRow>
            <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
              <BaasRow container display="flex" alignItems="center">
                <BaasStepper activeStep={activeStep}>
                  {paymentSteps.map((step, index) => {
                    const stepProps = {};
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <BaasStep key={step.title} {...stepProps}>
                        <StepLabel>{step.title}</StepLabel>
                      </BaasStep>
                    );
                  })}
                </BaasStepper>
              </BaasRow>
              <BaasBox mt={3}>
                <BaasText variant="body2">{currentStep?.instruction}</BaasText>
              </BaasBox>
              <BaasRow
                display="flex"
                flexDirection="row"
                justifyContent="flex-end"
              >
                <BaasBox mr={2}>
                  <EntityCard
                    active={currentStep?.entity === "CREDITED"}
                    label={"Credited Client"}
                    tooltipTitle="You are now impersonating an action taken by the credited party of this flow."
                  />
                </BaasBox>
                <EntityCard
                  active={currentStep?.entity === "DEBITED"}
                  label={"Debited Client"}
                  tooltipTitle="You are now impersonating an action taken by the debited party of this flow."
                />
              </BaasRow>
              <BaasBox mt={2} display="flex" flexDirection="column">
                <CurrentComponent props={componentProps} />
              </BaasBox>
            </BaasGrid>
          </BaasRow>
        </BaasContentGrid>
        <DialogInput
          description="With an EMV, you can load a QR Code data and proceed to payment."
          inputLabel="QR Code EMV"
          loading={loading}
          onSave={setEmvQrCode}
          open={openEmv}
          setOpen={setOpenEmv}
          title="Please, insert a QR Code EMV"
        />
      </BaasFlex>
    </>
  );
};

export default PixTestSuitPayment;
