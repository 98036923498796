import { Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";

const BaasPagination = ({
  onSelectPage,
  page = 1,
  resultsCount = 1,
  rowsPerPage = 1,
}) => {
  const totalPages = Math.ceil(resultsCount / rowsPerPage);

  return (
    <Box width="100%" display="flex" justifyContent="center">
      <Pagination
        count={totalPages}
        disabled={totalPages <= 1}
        page={page}
        onChange={(e, value) => onSelectPage(value)}
      />
    </Box>
  );
};

export default BaasPagination;
