import { CircularProgress } from "@mui/material";
import React from "react";
import styled from "styled-components";

const ContainerStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
`;

const BaasLoading = () => {
  return (
    <>
      <ContainerStyle>
        <CircularProgress color="primary" size={100} thickness={4} />
      </ContainerStyle>
    </>
  );
};

export default BaasLoading;
