import { baasApiCall } from "services/apiCall";

export const mutationPixPaymentTesterDict = (payload) => {
  return baasApiCall(
    "POST",
    `/pc/baas-tester/payment/dict/${payload.values.creditedPixKey}`,
    payload.values
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPixPaymentTesterDictBulk = (payload) => {
  return baasApiCall(
    "POST",
    `/pc/baas-tester/payment/dict-bulk/${payload.payload.creditedPixKey}`,
    payload.payload
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};
