import queryToString from "helpers/queryToString";
import { useQuery } from "react-query";

import { baasApiCall } from "services/apiCall";
import { baasApiCallDev } from "services/apiCallDev";

export const genericBaasApiCallWithPayload = (params) => {
  return baasApiCallDev(
    params.route.method,
    `/${params.route.path}`,
    params.payload
  );
};

export const genericBaasApiCall = (params) => {
  return baasApiCallDev(params.route.method, `/${params.route.path}`);
};

export const mutationPutPixPaymentApprovalEndpoint = (payload) => {
  return baasApiCallDev("PUT", "/pi/pix/payment/approval-endpoint", payload);
};

export const queryGetPixPaymentApprovalEndpoint = () => {
  return baasApiCallDev("GET", "/pi/pix/payment/approval-endpoint");
};

export const useQueryGetPixPaymentApprovalEndpoint = (config) => {
  return useQuery(
    "getPixPaymentApprovalEndpoint",
    () => queryGetPixPaymentApprovalEndpoint(),
    config
  );
};
useQueryGetPixPaymentApprovalEndpoint.queryKey =
  "getPixPaymentApprovalEndpoint";

export const mutationSandboxPostPixPayment = (payload) => {
  return baasApiCall("POST", `/pc/mdw/qrcode/payment`, payload, true)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetPixTransaction = (transactionIdentification) => {
  return baasApiCall(
    "GET",
    `/pi/pix/payment/transaction-identification/${transactionIdentification}`
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

// ==== Infraction report ===============================

export const queryGetPixInfractionReportSearch = (queryParams) => {
  return baasApiCall(
    "GET",
    queryParams.accountType === "Indirect"
      ? `/pc/dict-hub/infraction-report/search${queryToString(queryParams)}`
      : `/pc/pix/infraction-report/search${queryToString(queryParams)}`
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetPixInfractionByReportId = (params) => {
  return baasApiCall("GET", `/pc/pix/infraction-report/${params}`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostPicInfractionReport = (payload) => {
  return baasApiCall(
    "POST",
    payload.accountType === "Indirect"
      ? "/pc/dict-hub/infraction-report"
      : "/pc/pix/infraction-report",
    payload
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostPixInfractionReportSandbox = (payload) => {
  return baasApiCall("POST", "/pc/pix/infraction-report", payload)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryInfractionReportRoutesSandbox = (infractionReportId) => {
  return baasApiCall(
    "GET",
    `/pc/mdw/infraction-report/routes/${infractionReportId}`
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostPixInfractionReportByInfractionReportIdCancel = (
  payload
) => {
  return baasApiCall(
    "POST",
    payload.accountType === "Indirect"
      ? `/pc/dict-hub/infraction-report/${payload.infractionReportId}/cancel`
      : `/pc/pix/infraction-report/${payload.infractionReportId}/cancel`,
    false,
    payload.otp,
    true
  ).then((data) => data.json());
};

export const mutationPostPixInfractionReportByInfractionReportIdAcnowledge = (
  payload
) => {
  return baasApiCall(
    "POST",
    `/pc/pix/infraction-report/${payload.infractionReportId}/acknowledge`,
    false,
    payload.otp,
    true
  ).then((data) => data.json());
};

export const mutationPostInfractionReportClose = (payload) => {
  return baasApiCall(
    "POST",
    payload.accountType === "Indirect"
      ? `/pc/dict-hub/infraction-report/${payload.infractionReportId}/close`
      : `/pc/pix/infraction-report/${payload.infractionReportId}/close`,
    payload,
    false,
    true
  ).then((data) => data.json());
};

// ==== Dict Refund  ===============================

export const queryGetPixDictRefund = (queryParams) => {
  return baasApiCall(
    "GET",
    queryParams.accountType === "Indirect"
      ? `/pc/dict-hub/refund${queryToString(queryParams)}`
      : `/pc/pix/dict/refund${queryToString(queryParams)}`
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetPixDictRefundById = (refundId) => {
  return baasApiCall("GET", `/pc/pix/dict/refund/${refundId}`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryRefundRoutesSandbox = (refundId) => {
  return baasApiCall("GET", `/pc/mdw/refund/routes/${refundId}`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetRefundExistence = (transactionId) => {
  return baasApiCall(
    "GET",
    `/pc/mdw/refund/validate-refund-created/${transactionId}`
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetPixSandboxRefund = (queryParams) => {
  return baasApiCall("GET", `/pc/pix/dict/refund${queryToString(queryParams)}`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostPixDictRefund = (payload) => {
  return baasApiCall(
    "POST",
    payload.accountType === "Indirect"
      ? "/pc/dict-hub/refund"
      : "/pc/pix/dict/refund",
    payload
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostPixSandboxRefund = (payload) => {
  return baasApiCall("POST", "/pc/mdw/refund/create", payload)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostPixDictRefundByRefundIdCancel = (payload) => {
  return baasApiCall(
    "POST",
    payload.accountType === "Indirect"
      ? `/pc/dict-hub/refund/${payload.refundId}/cancel`
      : `/pc/pix/dict/refund/${payload.refundId}/cancel`,
    false,
    payload.otp,
    true
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostPixDictByRefundIdClose = (payload) => {
  return baasApiCall(
    "POST",
    payload.accountType === "Indirect"
      ? `/pc/dict-hub/refund/${payload.refundId}/close`
      : `/pc/pix/dict/refund/${payload.refundId}/close`,
    payload,
    true
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

// ==== Dict Reversal  ===============================

export const mutationPostPixSandboxReversal = (payload) => {
  return baasApiCall(
    "POST",
    `/pc/mdw/pix/payment/end-to-end/return/in/${payload.endToEndId}`,
    payload,
    true
  )
    .then((data) => data.json())
    .catch((error) => error);
};

// ==== Dict Entry  ===============================

export const queryGetDictEntryInformationByKey = (params, extraHeaders) => {
  return baasApiCall("GET", `/pc/pix/dict/entry/${params}`, false, extraHeaders)
    .then((data) => data)
    .catch((error) => error.json());
};

export const queryGetDictEntryPixByAccount = (params) => {
  return baasApiCall("GET", `/pc/pix/dict/entry/keys${queryToString(params)}`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetPixDictEntrySearchList = (params) => {
  return baasApiCall("GET", `/pc/dict-hub/entry/list${queryToString(params)}`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationSandboxPostPixDictEntryCreate = (payload) => {
  return baasApiCall("POST", "/pc/pix/dict/entry", payload, true)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostPixDictEntryCreate = (payload) => {
  return baasApiCall("POST", "/pc/dict-hub/entry", payload, true)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetLatestSync = () => {
  return baasApiCall("GET", "/pc/dict-hub/cid/sync")
    .then((data) => data?.json())
    .catch((error) => error?.json());
};

export const mutationPostPixDictEntryDelete = (params, payload) => {
  return baasApiCall(
    "POST",
    `/pc/dict-hub/entry/${params}/delete`,
    payload,
    true
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPutPixDictEntryUpdate = (params, payload) => {
  return baasApiCall("PUT", `/pc/dict-hub/entry/${params}`, payload, true)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

// ==== Dict Claim  ===============================

export const queryGetPixClaimSearchList = (queryParams, dictHub = true) => {
  return baasApiCall(
    "GET",
    dictHub
      ? `/pc/dict-hub/claim${queryToString(queryParams)}`
      : `/pc/pix/dict/claim${queryToString(queryParams)}`
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetPixClaimById = (payload, dictHub = true) => {
  return baasApiCall(
    "GET",
    dictHub ? `/pc/dict-hub/claim/${payload}` : `/pc/pix/dict/claim/${payload}`
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryClaimRoutesSandbox = (claimId) => {
  return baasApiCall("GET", `/pc/mdw/claim/routes/${claimId}`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostPixClaimCreate = (payload) => {
  return baasApiCall("POST", "/pc/dict-hub/claim", payload, true)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostPixClaimCreateSandbox = (payload) => {
  return baasApiCall("POST", "/pc/mdw/claim/create", payload, true)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostPixClaimConfirm = (payload) => {
  return baasApiCall(
    "POST",
    `/pc/dict-hub/claim/${payload.claimId}/confirm`,
    payload,
    true
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostPixClaimCancel = (payload) => {
  return baasApiCall(
    "POST",
    `/pc/dict-hub/claim/${payload.claimId}/cancel`,
    payload,
    true
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

//Static BR Code
export const queryGetQrCodeInfo = (queryParams) => {
  return baasApiCall("GET", `/pc/mdw/qrcode/info/${queryParams}`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetPaymentByEndToEndId = (queryParams) => {
  return baasApiCall("GET", `/pi/pix/payment/end-to-end/in-out/${queryParams}`)
    .then((data) => data.json())
    .catch((error) => error);
};
export const queryGetLocationBrCodeImage = (queryParams) => {
  return baasApiCall("GET", `/pc/pix/location/${queryParams}/svg`)
    .then((data) => data)
    .catch((error) => error.json());
};

export const mutationCreateLocationPixQRCode = (payload) => {
  return baasApiCall("POST", `/pi/pix/collection/location`, payload, true)
    .then((data) => data.json())
    .catch((error) => error.json());
};

//DICT CUSTOMERS
export const queryGetMerchantCustomers = () => {
  return baasApiCall("GET", `/pc/dict-hub/customer`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetMerchantCustomerById = (queryParams) => {
  return baasApiCall("GET", `/pc/dict-hub/customer/${queryParams}`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationDeleteMerchantCustomerById = (queryParams) => {
  return baasApiCall("DELETE", `/pc/dict-hub/customer/${queryParams}`)
    .then((data) => data)
    .catch((error) => error);
};

export const mutationCreateMerchantCustomer = (payload) => {
  return baasApiCall("POST", `/pc/dict-hub/customer`, payload, true)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationUpdateMerchantCustomer = (payload) => {
  return baasApiCall(
    "PATCH",
    `/pc/dict-hub/customer/${payload.merchantCustomerId}`,
    payload,
    true
  )
    .then((data) => data)
    .catch((error) => error);
};
