import { useTheme } from "@mui/system";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasText from "components/atoms/Text/BaasText";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../../assets/HeaderLogo.svg";
import { useHistory } from "react-router";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { routes } from "constants/routes";
import { checkPermission } from "services/interfaceController";

const NavBarBackground = styled.div`
  width: 240px;
  height: 100vh;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.palette.grey[700]};
  position: fixed;
  color: ${(props) => props.theme.palette.grey[100]};
  z-index: 5;
`;

const NavBarButton = styled.div`
  padding: 14px;
  margin: 4px 8px;
  border-radius: 4px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  transition: background-color 50ms ease-out;
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.palette.primary.main};
  }
`;

const NavBarButtonSubRoute = styled.div`
  padding: 4px 0px;
  border-radius: 4px;
  margin: 8px;
  background-color: ${(props) => props.theme.palette.grey[600]};
`;

const NavBarNotification = styled.div`
  background: ${(props) => props.theme.palette.error.main};
  height: 10px;
  width: 10px;
  border-radius: 10px;
  margin: -20px -6px 0px 0px;
  z-index: 1;
`;

const BaasNavBar = ({ notification, onClose }) => {
  const theme = useTheme();
  const history = useHistory();
  const [colapse, setColapse] = useState({});

  const handleClick = useCallback(
    (path) => {
      history.push(path);
      setColapse({});
      if (onClose) {
        onClose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, onClose]
  );

  const handleColapse = useCallback(
    (item) => {
      setColapse({ [item.name]: !colapse[item.name] });
    },
    [colapse]
  );

  return (
    <NavBarBackground theme={theme}>
      <Logo style={{ margin: "20px" }} />
      {routes.map((item) =>
        item.showOnSidebar && checkPermission(item.permission) ? (
          item.subRoute ? (
            <>
              <NavBarButton onClick={() => handleColapse(item)} theme={theme}>
                <BaasFlex>
                  <BaasFlex>
                    {notification && <NavBarNotification theme={theme} />}
                    <img
                      src={item.icon}
                      alt=""
                      style={{ marginRight: "8px" }}
                    />
                  </BaasFlex>
                  <BaasText variant="body2">{item.name}</BaasText>
                </BaasFlex>
                {item.subRoute && colapse[item.name] ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </NavBarButton>
              {item.subRoute && colapse[item.name] && (
                <NavBarButtonSubRoute theme={theme}>
                  {item.subRoute.map((sub) =>
                    checkPermission(sub.permission) ? (
                      <NavBarButton
                        onClick={() => handleClick(sub.path)}
                        theme={theme}
                      >
                        <BaasFlex>
                          <BaasText variant="body2">{sub.name}</BaasText>
                        </BaasFlex>
                      </NavBarButton>
                    ) : null
                  )}
                </NavBarButtonSubRoute>
              )}
            </>
          ) : (
            <NavBarButton onClick={() => handleClick(item.path)} theme={theme}>
              <BaasFlex>
                <BaasFlex>
                  {notification && <NavBarNotification theme={theme} />}
                  <img src={item.icon} alt="" style={{ marginRight: "8px" }} />
                </BaasFlex>
                <BaasText variant="body2">{item.name}</BaasText>
              </BaasFlex>
            </NavBarButton>
          )
        ) : null
      )}
    </NavBarBackground>
  );
};

export default BaasNavBar;
