import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import { BaasModalChangeAccount } from "components/organism/ModalChangeAccount/ModalChangeAccount";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { useAccount } from "providers/account";
import React, { useState } from "react";
import ClaimSearch from "./ClaimSearch";
import AccountTypeSelectedLabel from "components/molecules/AccountTypeSelectedLabel/AccountTypeSelectedLabel";

const ClaimV2 = () => {
  const { accountSelected } = useAccount();
  const [disclaimer, setDisclaimer] = useState(true);
  const modalChangeDisplayed = sessionStorage.getItem(
    "MODAL_ACCOUNT_DISPLAYED"
  );
  const disabledCondition = (account) => {
    if (account.markups?.includes("DICT-HUB:true")) {
      return false;
    } else {
      return true;
    }
  };

  const showModalAccountChange =
    !modalChangeDisplayed &&
    (accountSelected.markups?.includes("DICT-HUB:true") ||
      accountSelected.markups?.includes("PIX-MERCHANT:true"));

  return (
    <>
      <BaasFlex flexDirection="column" width="100%">
        <BaasPageHeader>DICT Hub</BaasPageHeader>
        <BaasContentGrid spacing={4}>
          <BaasGrid p="16px 0px" item>
            <BaasGrid display="flex">
              <BaasText variant="h6">DICT Claim</BaasText>
              <AccountTypeSelectedLabel />
            </BaasGrid>
          </BaasGrid>

          <ClaimSearch />
          {!accountSelected.markups?.includes("DICT-HUB:true") ||
            showModalAccountChange ? (
            <BaasModalChangeAccount
              state={disclaimer}
              setState={setDisclaimer}
              disabledCondition={disabledCondition}
              title="Change account"
              redirect={!modalChangeDisplayed ? false : true}
              onSubmitCompleted={
                !modalChangeDisplayed
                  ? () =>
                    sessionStorage.setItem("MODAL_ACCOUNT_DISPLAYED", true)
                  : null
              }
              description={
                !modalChangeDisplayed
                  ? "Please, select the account you want to use."
                  : "Your account does not have permission to access Pix switch to an account that has access."
              }
            />
          ) : null}
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
};

export default ClaimV2;
