import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";

const AnswerInput = ({
  answer,
  answerKey,
  earliest,
  questionId,
  loading,
  onAnswer,
  question,
  submitingAnswer,
}) => {
  const [answerInput, setAnswerInput] = useState("");

  const helperText = `Your answer must have been generated within the last 
  ${(earliest && earliest.replace("-", "").replace("h", " hours")) || "N/A"}
  , after that, retry your process.`;

  const disabled = loading || submitingAnswer || answer;

  const formatAnswerText = () => {
    if (question?.splunkConfig.keyParameter && answer?.value && question.key) {
      return answer?.value
        .replaceAll('"', "")
        .replace(question?.splunkConfig.keyParameter, question.key)
        .replace("=", ": ");
    }
    if (!answer?.value) {
      return answerInput;
    }
  };

  return (
    <>
      <Box width="100%" display="flex" mt={2}>
        <TextField
          sx={{ minWidth: "250px" }}
          hiddenLabel
          fullWidth
          disabled={loading || submitingAnswer || answer?.value}
          placeholder={`Insert your ${answerKey || "answer"} here`}
          variant="standard"
          value={formatAnswerText()}
          onChange={(e) => setAnswerInput(e.target.value)}
          helperText={earliest && !disabled ? helperText : null}
        />
        {!answer?.value && (
          <Box ml={2}>
            <Button
              disabled={loading || submitingAnswer || answer || !answerInput}
              variant="contained"
              onClick={() => onAnswer(answerInput, questionId)}
            >
              {loading || submitingAnswer ? "Loading..." : "Validate"}
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default AnswerInput;
