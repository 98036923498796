import React from "react";
import { StyledStep } from "./styles";

const BaasStep = ({ children, ...props }) => {
    return (
        <StyledStep {...props} >
            {children}
        </StyledStep>
    );
};

export default BaasStep;
