import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import React from "react";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasFlex from "components/atoms/Flex/Flex";
import UserCredentialsAccountInfo from "./UserCredentialsAccountInfo";
import UserCredentialsClientInfo from "./UserCredentialsClientInfo";
import UserCredentialsSecrets from "./UserCredentialsSecrets";
import { useQueryGetUserInfo } from "api/security-api";
import { checkPermission } from "services/interfaceController";
import StackCard from "components/atoms/StackCard/StackCard";

const UserCredentials = () => {
  const { data } = useQueryGetUserInfo();

  return (
    <>
      <BaasFlex flexDirection="column" width="100%">
        <BaasPageHeader>User credentials</BaasPageHeader>
        <BaasContentGrid spacing={4}>
          <BaasRow container spacing={1}>
            <BaasText sx={{ paddingLeft: "8px" }} variant="subtitle1">
              With your API credentials you can perform any API request to BaaS
              systems without restriction.
            </BaasText>
          </BaasRow>

          <StackCard item spacing={1}>
            <BaasText variant="h6">Client information</BaasText>
            <UserCredentialsClientInfo data={data} />
          </StackCard>

          {checkPermission(["user-key-w", "all-w"]) ? (
            <>
              <BaasRow item spacing={1}>
                <StackCard>
                  <BaasText variant="h6">Client Secret Keys</BaasText>
                  <UserCredentialsSecrets data={data} />
                </StackCard>
              </BaasRow>
            </>
          ) : null}

          <BaasRow item spacing={1}>
            <StackCard>
              <BaasText variant="h6">Account information</BaasText>
              <UserCredentialsAccountInfo />
            </StackCard>
          </BaasRow>
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
};

export default UserCredentials;
