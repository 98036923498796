import CheckIcon from "@mui/icons-material/Check";
import { CircularProgress } from "@mui/material";
import { useTheme } from "@mui/system";
import { queryGetObkTransaction } from "api/obk-api";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { currencyFormatter } from "helpers/currencyFormatter";
import { useInterval } from "helpers/useInterval";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import BaasCardWithIcon from "components/molecules/CardWithIcon/CardWithIcon";
import StoreIcon from "assets/icons/Store.svg";

export default function MockBankConfirm() {
  const theme = useTheme();
  const [delay, setDelay] = useState(1500);
  const [state, setState] = useState({
    urlPactuaId: null,
    urlStatus: null,
    trStatus: null,
    trData: null,
    poolingCount: 0,
    error: false,
    useBaas: JSON.parse(sessionStorage.getItem("useBaas")),
  });

  useEffect(() => {
    getIdAndStatusFromUrl();
  }, []);

  useEffect(() => {
    toastControl(state.trStatus);
  }, [state.trStatus]);

  const updateTr = async () => {
    const trData = await queryGetObkTransaction(state.urlPactuaId, {
      useBaas: state.useBaas,
    });

    if (trData.status < 300 && state.poolingCount < 30) {
      setState({
        ...state,
        trData: trData.body,
        trStatus: trData.body.status,
        poolingCount: state.poolingCount + 1,
      });
      if (
        trData.body.status === "SETTLEMENT_COMPLETED" ||
        trData.body.status === "CONSENT_REJECTED"
      )
        setDelay(null);
    } else {
      setDelay(null);
      setState({
        ...state,
        error: true,
      });
      if ("errors" in trData) {
        trData.errors.map((error) => {
          return toast.error(error.message);
        });
      }
    }
  };

  useInterval(updateTr, delay);

  const toastControl = (status) => {
    if (status) {
      if (status === "SETTLEMENT_COMPLETED") {
        return toast.success(`Transaction status update: ${status}`);
      } else if (status === "REJECTED" || status === "CONSENT_REJECTED") {
        return toast.error(`Transaction status update: ${status}`);
      } else {
        return toast.info(`Transaction status update: ${status}`);
      }
    }
  };

  const getIdAndStatusFromUrl = () => {
    const url = window.location.href;
    const urlLength = url.split("/").length;
    const id = url.split("/")[urlLength - 2];
    const status = url.split("/")[urlLength - 1];

    setState({
      ...state,
      urlPactuaId: id,
      urlStatus: status,
    });
  };

  return (
    <>
      <BaasFlex flexDirection="column" width="100%">
        <BaasPageHeader>Open Finance Test Suite</BaasPageHeader>
        <BaasContentGrid spacing={4}>
          <BaasGrid p="16px 0px" item>
            <BaasText variant="h6">PISP Side of the flow</BaasText>
          </BaasGrid>

          <BaasCardWithIcon title={"Mock E-Commerce"} img={StoreIcon} />

          <BaasGrid container spacing={3}>
            <BaasGrid item xs={12} md={8}>
              <BaasText variant="body1" p="18px 0px">
                This page is part of BTG's BaaS Open Banking Mock interface. It
                aims to exemplify to clients/developers the implementation flow
                for these features.
              </BaasText>
            </BaasGrid>
          </BaasGrid>
          {/* === Conteúdo principal === */}
          {/* Check do Status */}
          <BaasRow item spacing={1}>
            <BaasText variant="h6">Transaction Status</BaasText>
          </BaasRow>
          <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
            <div style={{ textAlign: "center" }}>
              {state.trData?.status === "SETTLEMENT_COMPLETED" ? (
                <>
                  <CheckIcon color="success" fontSize="large" />
                  <BaasText
                    textAlign="center"
                    variant="h5"
                    color="success.main"
                  >
                    Request successful!
                  </BaasText>
                </>
              ) : null}
              {state.trData?.status !== "SETTLEMENT_COMPLETED" &&
              state.trData?.status !== "REJECTED" &&
              state.trData?.status !== "CONSENT_REJECTED" &&
              !state.error ? (
                <>
                  <CircularProgress color="inherit" />
                  <BaasText textAlign="center" variant="h6">
                    {state.trData
                      ? state.trData.status
                      : "Loading transaction status..."}
                  </BaasText>
                </>
              ) : null}
              {state.error ||
              state.trData?.status === "REJECTED" ||
              state.trData?.status === "CONSENT_REJECTED" ? (
                <>
                  <DoDisturbIcon sx={{ color: "#D22D4B" }} fontSize="large" />
                  <BaasText textAlign="center" variant="h5" color="error.main">
                    {state.trData?.status === "REJECTED" ||
                    state.trData?.status === "CONSENT_REJECTED"
                      ? "Transaction rejected..."
                      : "Failed to load transaction status..."}
                  </BaasText>
                </>
              ) : null}
            </div>
          </BaasGrid>
          {state.trData !== null ? (
            <>
              {/* Transaction Info */}
              <BaasRow item spacing={1}>
                <BaasText variant="h6">Transaction Information</BaasText>
              </BaasRow>
              <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
                <BaasRow container spacing={1}>
                  <BaasGrid item xs={12}>
                    <BaasInfoDisplay
                      label={"Amount:"}
                      value={`R$ ${currencyFormatter(
                        state.trData.body.amount / 100
                      )}`}
                    />
                  </BaasGrid>
                  <BaasGrid item xs={12} md={6}>
                    <BaasInfoDisplay
                      label={"CNPJ Initiator:"}
                      value={state.trData.body.payment?.cnpjInitiator}
                    />
                  </BaasGrid>
                  <BaasGrid item xs={12} md={6}>
                    <BaasInfoDisplay
                      label={"EndToEnd Id:"}
                      value={state.trData.body.payment?.endToEndId}
                    />
                  </BaasGrid>
                  <BaasGrid item xs={12} md={6}>
                    <BaasInfoDisplay
                      label={"Pactual Id:"}
                      value={state.trData.pactualId}
                    />
                  </BaasGrid>
                </BaasRow>
              </BaasGrid>

              {/* Creditor Info */}
              <BaasRow item spacing={1}>
                <BaasText variant="h6">Creditor Account Information</BaasText>
              </BaasRow>
              <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
                <BaasRow container spacing={1}>
                  <BaasGrid item xs={12} md={6}>
                    <BaasInfoDisplay
                      label={"Name:"}
                      value={state.trData.body.creditorAccount.name}
                    />
                  </BaasGrid>
                  <BaasGrid item xs={12} md={6}>
                    <BaasInfoDisplay
                      label={"CPF/CNPJ:"}
                      value={state.trData.body.creditorAccount.cpfCnpj}
                    />
                  </BaasGrid>
                  <BaasGrid item xs={12} md={6}>
                    <BaasInfoDisplay
                      label={"Account Number:"}
                      value={state.trData.body.creditorAccount.number}
                    />
                  </BaasGrid>
                  <BaasGrid item xs={12} md={6}>
                    <BaasInfoDisplay
                      label={"Account Type:"}
                      value={state.trData.body.creditorAccount.accountType}
                    />
                  </BaasGrid>
                  <BaasGrid item xs={12} md={6}>
                    <BaasInfoDisplay
                      label={"ISPB:"}
                      value={state.trData.body.creditorAccount.ispb}
                    />
                  </BaasGrid>
                  <BaasGrid item xs={12} md={6}>
                    <BaasInfoDisplay
                      label={"Issuer:"}
                      value={state.trData.body.creditorAccount.issuer}
                    />
                  </BaasGrid>
                  <BaasGrid item xs={12} md={6}>
                    <BaasInfoDisplay
                      label={"Person Type:"}
                      value={state.trData.body.creditorAccount.personType}
                    />
                  </BaasGrid>
                </BaasRow>
              </BaasGrid>
            </>
          ) : null}
          {/* === Fim do conteúdo principal === */}
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
}
