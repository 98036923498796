import React, { useCallback, useState } from "react";
import { useTheme } from "@mui/system";
import BaasGrid from "components/atoms/Grid/Grid";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import BaasButton from "components/atoms/Button/Button";
import BaasDatePicker from "components/atoms/DatePicker/DatePicker";
import moment from "moment";
import CnabSettlementDownloadList from "./CnabSettlementDownloadList";
import { queryGetPaymentPaymentSlipCnabFileByRange } from "api/cnab-api";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasTextField from "components/atoms/TextField/TextField";
import { isNewCnabProfile } from "helpers/isNewCnabProfile";
const CnabSettlementDownload = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dataByRange = useCallback(async (values) => {
    try {
      const response = await queryGetPaymentPaymentSlipCnabFileByRange(values);
      if (response.status < 300) {
        if (response.body?.length > 0) {
          setData(response.body);
        } else if (response.body) {
          setData(response.body);
          toast.warning("No results found");
        } else {
          toast.error("Error");
        }
        return response;
      } else {
        if ("errors" in response) {
          response.errors.map((error) => toast.error(error.message));
        } else toast.error("Your link is broken");
      }
    } catch (err) {
      console.log(err);
      toast.error("Register not found.");
    } finally {
      setIsSubmitting(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback((values) => {
    setIsSubmitting(true);
    dataByRange({
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      endDate: moment(values.endDate).format("YYYY-MM-DD"),
      layout: values.layout,
      product: values.product,
      newCnab: isNewCnabProfile(),
    });
  }, []);

  return (
    <>
      <Formik initialValues={{}} onSubmit={onSubmit} enableReinitialize>
        {({ values, ...props }) => {
          return (
            <Form>
              <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
                <BaasGrid container p="16px 0px" spacing={3}>
                  <BaasGrid item xs={12} sm={6}>
                    <BaasDatePicker
                      name="startDate"
                      label="Start date"
                      fullWidth
                      maxDate={moment()}
                    />
                  </BaasGrid>
                  <BaasGrid item xs={12} sm={6}>
                    <BaasDatePicker
                      name="endDate"
                      label="End date"
                      fullWidth
                      maxDate={moment()}
                    />
                  </BaasGrid>
                  {isNewCnabProfile() && (
                    <>
                      <BaasGrid item xs={12} md={6}>
                        <BaasTextField
                          fullWidth
                          name="layout"
                          label="Layout"
                          required
                          select
                        >
                          {/* <BaasMenuItem value="FEBRABAN240">
                            CNAB Febraban 240
                          </BaasMenuItem> */}
                          <BaasMenuItem value="SANTANDER400 ">
                            CNAB Santander 400
                          </BaasMenuItem>
                        </BaasTextField>
                      </BaasGrid>
                      <BaasGrid item xs={12} md={6}>
                        <BaasTextField
                          fullWidth
                          name="product"
                          label="Product"
                          required
                          select
                        >
                          <BaasMenuItem value="PAYMENT_SLIP_SE">
                            Issuance of payment slip (PaymentSlipSe)
                          </BaasMenuItem>
                          {/* <BaasMenuItem value="PAYMENT_SLIP_PAY ">
                            Payment slip pay (PaymentSlipPay)
                          </BaasMenuItem>
                          <BaasMenuItem value="EXTERNAL_TRANSFER  ">
                            TED (External Transfer)
                          </BaasMenuItem> */}
                        </BaasTextField>
                      </BaasGrid>
                    </>
                  )}
                </BaasGrid>

                <BaasGrid item p="16px 0px">
                  <BaasButton
                    variant="contained"
                    backgroundColor="black"
                    type="submit"
                    loading={isSubmitting}
                  >
                    Search
                  </BaasButton>
                </BaasGrid>
              </BaasGrid>
            </Form>
          );
        }}
      </Formik>
      {data.length > 0 && <CnabSettlementDownloadList data={data} />}
    </>
  );
};

export default CnabSettlementDownload;
