import Endpoints from "constants/endpoints";
import React, { createContext, useContext, useState } from "react";
const OtpContext = createContext();

const OtpProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const isOtpRequired =
    Endpoints.Environment !== "Development" &&
    Endpoints.Environment !== "Sandbox";

  return (
    <OtpContext.Provider value={{ open, setOpen, isOtpRequired }}>
      {children}
    </OtpContext.Provider>
  );
};

const useOtp = () => {
  const context = useContext(OtpContext);
  const { open, setOpen, isOtpRequired } = context;
  return { open, setOpen, isOtpRequired };
};

export { OtpProvider, useOtp };
