import { Box } from "@mui/material";
import React from "react";
import styled from "styled-components";

const BoxStyle = styled.div``;

const BaasBox = ({ children, ...props }) => {
  return (
    <BoxStyle>
      <Box {...props}>{children}</Box>
    </BoxStyle>
  );
};

export default BaasBox;
