import * as yup from "yup";
import BaasGrid from "components/atoms/Grid/Grid";
import React, { useMemo, useState, useCallback } from "react";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasTextFieldCurrency from "components/molecules/TextFieldCurrency/TextFieldCurrency";
import BaasRow from "components/atoms/Grid/Row";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasText from "components/atoms/Text/BaasText";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import BaasBox from "components/atoms/Box/Box";
import { useTheme } from "@mui/system";
import { Form, Formik } from "formik";
import FormActions from "components/molecules/FormActions";
import { useAccount } from "providers/account";
import guidGenerator from "services/guidGenerator";
import { StepsNavigator } from "components/molecules/StepsNavigator/StepsNavigator";
import { mutationCreateLocationPixQRCode } from "api/pix-api";
import { toast } from "react-toastify";
import { toastMessages } from "constants/toastMessages";
import { getDataByMarkup } from "helpers/getDataByMarkup";
import { formatPixKeys } from "helpers/formatPixKeys";
import FaqSection from "components/organism/FaqSection/FaqSection";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";

const PixQrCodeGenPanel = ({ props }) => {
  const theme = useTheme();
  const {
    onCancel,
    selectedPixKey,
    setSelectedPixKey,
    handleNext,
    handlePrevious,
    faqMessages,
    setDinamicQrData,
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { accountSelected } = useAccount();

  const initialValues = useMemo(() => {
    return {
      keyType: selectedPixKey?.keyType,
      key: selectedPixKey?.key,
      accountNumber: accountSelected?.account,
      name: accountSelected?.name.replace(/[{()}]/g, ""),
      merchantCity: getDataByMarkup(
        accountSelected.markups,
        "PIX-MERCHANT-CITY"
      ),
      merchantName: getDataByMarkup(
        accountSelected.markups,
        "PIX-MERCHANT-NAME"
      ),
      type: "COB",
    };
  }, []);

  const validationSchema = yup.object().shape({
    amount: yup
      .number()
      .min(0.01, "Minimum value is R$0.01")
      .max(100, "Maximum value is R$100.00")
      .required("Amount is Required"),
  });

  const getPriceWarning = (price) => {
    if (price) {
      const cents = price.toString().split(".")[1];
      if (Number(cents) < 50) {
        return "A value between 01 and 50 cents will simulate an error, use a value between 51 and 00 cents to simulate a successful payment, i.e. R$1.00, R$5.99";
      } else {
        return null;
      }
    }
    return null;
  };

  const mutateCreateQRCode = useCallback(async (values) => {
    const response = await mutationCreateLocationPixQRCode(values);
    if (response.status < 300) {
      if (response.body) {
        const resp = response.body;
        setDinamicQrData(resp);
        setIsSubmitting(false);
        handleNext();
      }
      toast.success(toastMessages.requestSuccess);
    } else {
      if ("errors" in response) {
        response.errors.map((error) => toast.error(error.message));
      } else toast.error("Your link is broken");
    }
    setIsSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(
    (values) => {
      if (!values.merchantCity && !values.merchantName) {
        toast.error("User does not have Merchant Markups");
      } else {
        setIsSubmitting(true);
        const submitValues = {
          key: formatPixKeys(values),
          amount: {
            original: values.amount.toFixed(2),
          },
          aditionalInformation: values.aditionalInformation,
          clientRequestId: guidGenerator().replaceAll("-", ""),
          locationInformation: {
            type: "COB",
            merchant: {
              city: values.merchantCity,
              name: values.merchantName,
            },
          },
        };
        mutateCreateQRCode(submitValues);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutateCreateQRCode, setIsSubmitting]
  );

  const onPrevious = () => {
    setDinamicQrData(null);
    setSelectedPixKey(undefined);
    handlePrevious();
  };

  const inputs = [
    {
      name: "keyType",
      label: "Key Type",
    },
    {
      name: "key",
      label: "Key",
    },
    {
      name: "accountNumber",
      label: "Account",
    },
    {
      name: "merchantName",
      label: "Merchant name",
    },
    {
      name: "merchantCity",
      label: "Merchant city",
    },
    {
      name: "type",
      label: "Type",
    },
  ];

  return (
    <BaasGrid>
      <BaasFlex flexDirection="column" width="100%">
        <BaasGrid sx={{ background: theme.palette.grey[100] }}>
          <BaasText variant="body2" fontWeight="bold">
            Create new dinamic QR Code Pix bellow.
          </BaasText>
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, errors, isValid, setFieldValue, ...props }) => (
              <Form>
                <BaasOtpModal
                  onConfirm={() => onSubmit(values, props)}
                  {...props}
                />
                <BaasRow>
                  <BaasGrid sx={{ background: theme.palette.grey[100] }}>
                    <BaasRow container column={12} spacing={3}>
                      {inputs.map(({ name, label, value }) => {
                        return (
                          <BaasGrid item xs={12} md={6} key={name}>
                            <BaasInfoDisplay
                              fullWidth
                              label={label}
                              value={values[name] || "Not available"}
                            />
                          </BaasGrid>
                        );
                      })}
                      <BaasGrid item xs={12} md={6}>
                        <BaasTextFieldCurrency
                          fullWidth
                          required
                          name="amount"
                          label="Amount"
                          errors={errors}
                          warning={getPriceWarning(values.amount)}
                        />
                      </BaasGrid>
                      <BaasGrid item xs={12} md={6}>
                        <BaasTextField
                          fullWidth
                          name="aditionalInformation"
                          label="Aditional Information"
                        />
                      </BaasGrid>
                    </BaasRow>
                    <FormActions
                      onCancel={onCancel}
                      submitLabel="Create"
                      submitDisabled={!isValid || !values.amount}
                      isSubmitting={isSubmitting}
                    />
                  </BaasGrid>
                </BaasRow>
              </Form>
            )}
          </Formik>
        </BaasGrid>
      </BaasFlex>
      <StepsNavigator
        handleNext={handleNext}
        handlePrevious={onPrevious}
        previousDisabled={false}
        nextDisabled={true}
        nextLabel="PAY QR CODE"
      />
      <BaasBox>
        <FaqSection messages={faqMessages} />
      </BaasBox>
    </BaasGrid>
  );
};

export default PixQrCodeGenPanel;
