import React, { useCallback } from "react";
import MoneyInput from "@rschpdr/react-money-input";
import { useField } from "formik";
import { TextField } from "@mui/material";
import BaasText from "components/atoms/Text/BaasText";

const BaasTextFieldCurrency = ({
  name,
  errors,
  warning,
  variant = "standard",
  ...props
}) => {
  const [{ value }, , { setValue }] = useField(name);

  const onChange = useCallback(
    (event) => {
      setValue(event.target.value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  return (
    <>
      <MoneyInput
        customInput={TextField}
        value={value}
        onChange={onChange}
        variant={variant}
        currencyConfig={{
          locale: "pt-BR",
          currencyCode: "BRL",
          currencyDisplay: "symbol",
        }}
        style={{ margin: "8px 0px" }}
        {...props}
      />
      {errors && errors[name] && (
        <BaasText color="error">{errors[name]}</BaasText>
      )}
      {warning && <BaasText color="#ffa10f">{warning}</BaasText>}
    </>
  );
};
export default BaasTextFieldCurrency;
