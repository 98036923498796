import Endpoints from "constants/endpoints";

let visualizationControl = {
  showTransactionCards: true,
  showBalanceCard: true,
  showRoutesOnSidebar: true,
  showErrorReportOption: true,
  showRoutesOnSidebarSpecificUser: true,
  checkOtp: false,
  allowRegistration: true,
};

function envVisualizationSorter() {
  switch (Endpoints.Environment) {
    case "local":
      return (visualizationControl = {
        ...visualizationControl,
        showTransactionCards: true,
        showBalanceCard: true,
        showRoutesOnSidebar: true,
        checkOtp: false,
        allowRegistration: true,
      });
    case "Development":
      return (visualizationControl = {
        ...visualizationControl,
        checkOtp: false,
      });
    case "UAT":
      return (visualizationControl = {
        ...visualizationControl,
        showTransactionCards: false,
        showRoutesOnSidebar: false,
        checkOtp: true,
        allowRegistration: false,
      });
    case "Sandbox":
      return (visualizationControl = {
        ...visualizationControl,
        showTransactionCards: true,
        showErrorReportOption: false,
      });
    case "Production":
      return (visualizationControl = {
        ...visualizationControl,
        showTransactionCards: false,
        showBalanceCard: true,
        showRoutesOnSidebar: false,
        checkOtp: true,
        allowRegistration: false,
      });
    default:
      return visualizationControl;
  }
}

let envVisualization = envVisualizationSorter();

function checkPermission(roles, inverse = false) {
  const userRoles = JSON.parse(sessionStorage.getItem("BAAS_USER_ROLES")) || [];
  let havePermission = false;
  let validPermission = false;
  let haveEnv = false;
  let validEnv = false;
  let valid = false;

  roles.map((role) => {
    if (role.includes("Env")) {
      haveEnv = true;
      if (userRoles.includes(role)) validEnv = true;
    }
    if (!role.includes("Env")) {
      havePermission = true;
      if (userRoles.includes(role)) validPermission = true;
    }

    return null;
  });

  if (
    !havePermission ||
    (!inverse &&
      userRoles.includes("all-w") &&
      !userRoles.includes("Env-Sandbox"))
  ) {
    validPermission = true;
  }

  if (!haveEnv) {
    validEnv = true;
  }

  if (validPermission && validEnv) {
    valid = true;
  }

  return inverse ? !valid : valid;
}

export { envVisualization, checkPermission };
