import { useTheme } from "@mui/system";
import React from "react";
import styled from "styled-components";
import { ReactComponent as Logo } from "../../../assets/HeaderLogo.svg";
import MenuIcon from "@mui/icons-material/Menu";
import BaasIconButton from "components/atoms/IconButton/IconButton";
import { routesHeader } from "constants/routesHeader";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import { Link, Popover } from "@mui/material";
import BaasButton from "components/atoms/Button/Button";
import BaasGrid from "components/atoms/Grid/Grid";

const HeaderBackground = styled.div`
  width: 100%;
  height: 75px;
  position: fixed;
  z-index: 10;

  background-color: ${(props) => props.theme.palette.grey[700]};
  color: ${(props) => props.theme.palette.grey[100]};

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BaasHeaderPublicMobile = () => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClickLinkMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseLinkMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <HeaderBackground theme={theme}>
        <BaasIconButton size="large" onClick={handleClickLinkMenu}>
          <MenuIcon color="grey.100" />
        </BaasIconButton>

        <Link href="/landing">
          <BaasButton backgroundColor="white">
            <Logo />
          </BaasButton>
        </Link>
        <BaasGrid p="0px 4px">
          <BaasButton href="/login" variant="text" backgroundColor="white">
            Login
          </BaasButton>
        </BaasGrid>

        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleCloseLinkMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          {routesHeader.map((route, _index) => (
            <BaasMenuItem>
              <Link underline="none" color="grey.700" href={route.path}>
                {route.name}
              </Link>
            </BaasMenuItem>
          ))}
        </Popover>
      </HeaderBackground>
      <div style={{ height: "75px" }} />
    </>
  );
};

export default BaasHeaderPublicMobile;
