import axios from "axios";
import EndpointsObk from "constants/endpointsObk";
import { baasUrlFormatterObk } from "helpers/baasApiHelpers";
import { baasApiOptionsObk } from "helpers/baasApiHelpers";

export const baasApiCallObk = async (
  method,
  endpoint,
  payload,
  stringfy = false,
  noError = false
) => {
  const response = await fetch(baasUrlFormatterObk(endpoint), {
    method: method,
    AccessControlAllowOrigin: "*",
    dataType: "json",
    headers: new Headers({
      "Content-Type": "application/json",
      "Request-From": "Portal",
      Authorization: "Bearer " + sessionStorage.getItem("BAAS_ACCESS_TOKEN"),
      [sessionStorage.getItem(
        "BAAS_HEADER_TO_SESSION_TOKEN"
      )]: sessionStorage.getItem("BAAS_SESSION_TOKEN"),
    }),
    body: payload ? JSON.stringify(payload) : null,
  });

  if (!response.ok && !noError) throw response;

  return response;
};
