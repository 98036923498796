import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";

const EmvSelector = (props) => {
  const theme = useTheme();
  return (
    <Box
      mr={2}
      width="200px"
      height="60px"
      alignItems="center"
      justifyContent="center"
      display="flex"
      borderRadius="4px"
      onClick={props.handleClick}
      sx={{
        background: theme.palette.grey[200],
        border: `4px solid ${
          props.active ? theme.palette.primary.main : theme.palette.grey[200]
        }`,
        cursor: "pointer",
      }}
    >
      <props.icon style={{ width: "45px", height: "45px" }} />
      <Typography>{props.label}</Typography>
    </Box>
  );
};

export default EmvSelector;
