import React, { useEffect } from "react";
import BaasModal from "components/atoms/Modal/Modal";
import Backdrop from "@mui/material/Backdrop";
import styled from "styled-components";
import { useTheme } from "@mui/private-theming";
import { Box } from "@mui/system";
import { useQueryGetWebhookSubscriptionerrorsById } from "api/webhooks-api";
import ReactJson from "react-json-view";
import BaasText from "components/atoms/Text/BaasText";
import BaasChip from "components/atoms/Chip/Chip";
import BaasGrid from "components/atoms/Grid/Grid";

const BoxStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  max-width: 600px;

  max-height: 70vh;
  background-color: ${(props) => props.theme.palette.grey[200]};

  box-shadow: 24;
  padding: 4px;
  border-radius: 4px;
  overflow: hidden;
  overflow-y: scroll;

  :focus-visible {
    outline: none;
  }
`;

const JsonStyled = styled(Box)`
  border-radius: 4px;
  background: ${(props) => props.theme.palette.grey[200]};
  max-height: 400px;
  overflow-y: scroll;
  padding: 8px;
`;

const LogStyled = styled(Box)`
  background: ${(props) => props.theme.palette.grey[100]};
  padding: 16px;
  border-radius: 4px;
  margin-top: 16px;
`;

const WebhookConfigResourceContentActionsLogModal = ({
  entity,
  setModal,
  modal,
}) => {
  const theme = useTheme();

  const {
    data: errors,
    isLoading,
    refetch,
  } = useQueryGetWebhookSubscriptionerrorsById({
    entity,
  });

  useEffect(() => {
    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  const handleClose = async () => {
    setModal({ state: false });
  };
  return (
    <>
      <BaasModal
        open={modal.state}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {!isLoading && (
          <BoxStyled p={3} theme={theme}>
            <BaasGrid p="4px 0px">
              <BaasText variant="h6">{entity} error list</BaasText>
            </BaasGrid>

            <BaasGrid p="4px 0px">
              <BaasText variant="body1">
                {errors.length} error(s) found. Check the error messages listed
                bellow.
              </BaasText>
            </BaasGrid>

            {errors.map((error) => (
              <LogStyled theme={theme}>
                <BaasGrid p="4px 0px">
                  <BaasText variant="body1">
                    <strong>Notification ID:</strong> {error.notifyId}
                  </BaasText>
                </BaasGrid>

                <BaasGrid p="4px 0px">
                  <BaasText variant="body1">
                    <strong>Subscripbed URL:</strong> {error.webhookUrl}
                  </BaasText>
                </BaasGrid>

                <BaasGrid display="flex" alignItems="center" p="4px 0px">
                  <BaasText variant="body1" sx={{ paddingRight: "4px" }}>
                    <strong>Status:</strong>
                  </BaasText>
                  <BaasChip color="error" label={error.httpStatusCode} />
                </BaasGrid>

                <JsonStyled theme={theme}>
                  <ReactJson name={null} src={error} />
                </JsonStyled>
              </LogStyled>
            ))}
          </BoxStyled>
        )}
      </BaasModal>
    </>
  );
};

export default WebhookConfigResourceContentActionsLogModal;
