import React from "react";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import { useTheme } from "@mui/system";

const TextFieldStyle = styled.div`
  width: 100%;
  margin: 8px 0px;
`;

const BaasTextFieldDefault = ({
  name,
  variant = "standard",
  type = "text",
  ...props
}) => {
  const theme = useTheme();

  return (
    <TextFieldStyle theme={theme}>
      <TextField
        sx={{ "& .MuiFilledInput-root:before": { borderStyle: "none" } }}
        variant={variant}
        name={name}
        type={type}
        {...props}
      />
    </TextFieldStyle>
  );
};

export default BaasTextFieldDefault;
