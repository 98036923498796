export const formatAmountValues = (
  values,
  activeModalities,
  discountDateValues
) => {
  let amountValues = {
    original: values.amount.toFixed(2),
  };
  if (activeModalities.includes("ABATEMENT")) {
    amountValues = {
      ...amountValues,
      abatement: {
        amountPerc: values.abatementAmountPerc.toFixed(2),
        modality: values.abatementModality,
      },
    };
  }
  if (activeModalities.includes("FINE")) {
    amountValues = {
      ...amountValues,
      fine: {
        amountPerc: values.fineAmountPerc.toFixed(2),
        modality: values.fineModality,
      },
    };
  }
  if (activeModalities.includes("INTEREST")) {
    amountValues = {
      ...amountValues,
      interest: {
        amountPerc: values.interestAmountPerc.toFixed(2),
        modality: values.interestModality,
      },
    };
  }

  if (activeModalities.includes("DISCOUNT")) {
    if (
      values.discountModality === "FIXED_VALUE_UNTIL_THE_DATES_INFORMED" ||
      values.discountModality === "PERCENTAGE_DATE_REPORTED"
    ) {
      amountValues = {
        ...amountValues,
        discount: {
          modality: values.discountModality,
          discountDateFixed: discountDateValues,
        },
      };
    } else {
      amountValues = {
        ...amountValues,
        discount: {
          amountPerc: values.discountAmountPerc.toFixed(2),
          modality: values.discountModality,
        },
      };
    }
  }
  return amountValues;
};
