import EndpointsObk from "constants/endpointsObk";
import Endpoints from "../constants/endpoints";

const baasApiOptions = (
  method,
  payload,
  otp = null,
  stringify = true
  // extraHeaders
) => {
  let requestOptions = {
    method: method,
    AccessControlAllowOrigin: "*",
    dataType: "json",
    headers: new Headers({
      // ...extraHeaders,
      "Content-Type": "application/json",
      "Request-From": "Portal",
      Authorization: "Bearer " + sessionStorage.getItem("BAAS_ACCESS_TOKEN"),
      [sessionStorage.getItem(
        "BAAS_HEADER_TO_SESSION_TOKEN"
      )]: sessionStorage.getItem("BAAS_SESSION_TOKEN"),
    }),
    body: payload ? JSON.stringify(payload) : null,
  };

  if (otp !== null) {
    requestOptions.headers = {
      ...requestOptions.headers,
      "Content-Type": "application/json",
      "Request-From": "Portal",
      Authorization: "Bearer " + sessionStorage.getItem("BAAS_ACCESS_TOKEN"),
      [sessionStorage.getItem(
        "BAAS_HEADER_TO_SESSION_TOKEN"
      )]: sessionStorage.getItem("BAAS_SESSION_TOKEN"),
      [sessionStorage.getItem("BAAS_HEADER_TO_TRANSACTION_CODE")]: otp,
    };
  }

  if (!stringify) requestOptions.body = payload;

  return requestOptions;
};

const baasApiRefreshOptions = () => {
  return {
    method: "POST",
    AccessControlAllowOrigin: "*",
    headers: new Headers({
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    }),
    body: new URLSearchParams({
      refresh_token: sessionStorage.getItem("BAAS_REFRESH_TOKEN"),
      grant_type: "refresh_token",
    }),
  };
};

const baasUrlFormatter = (endpoint) => {
  return Endpoints.BaaSApiGateway + endpoint;
};

const baasUploadFile = (payload, otp) => {
  const formData = new FormData();

  const contractKeys = [
    "Description",
    "ImportType",
    "ImportSubType",
    "ClientRequestId",
  ];

  contractKeys.map((key) => formData.append(key, payload[key]));

  // formData.append("ClientRequestId", guidGenerator());

  formData.append("File", payload.File);

  const requestOptions = {
    method: "POST",
    body: formData,
    headers: {
      Authorization: "Bearer " + sessionStorage.getItem("BAAS_ACCESS_TOKEN"),
      [sessionStorage.getItem(
        "BAAS_HEADER_TO_SESSION_TOKEN"
      )]: sessionStorage.getItem("BAAS_SESSION_TOKEN"),
      [sessionStorage.getItem("BAAS_HEADER_TO_TRANSACTION_CODE")]: otp,
    },
  };

  return requestOptions;
};

const baasUrlFormatterObk = (endpoint) => {
  return EndpointsObk.BaaSApiGateway + endpoint;
};

const baasApiOptionsObk = (method, payload, stringify) => {
  let requestOptions = {
    method: method,
    AccessControlAllowOrigin: "*",
    dataType: "json",
    headers: new Headers({
      "Content-Type": "application/json",
      "Request-From": "Portal",
      Authorization: "Bearer " + sessionStorage.getItem("BAAS_ACCESS_TOKEN"),
      [sessionStorage.getItem(
        "BAAS_HEADER_TO_SESSION_TOKEN"
      )]: sessionStorage.getItem("BAAS_SESSION_TOKEN"),
    }),
    body: payload ? JSON.stringify(payload) : null,
  };

  if (!stringify) requestOptions.body = payload;

  return requestOptions;
};

export {
  baasApiOptions,
  baasUrlFormatter,
  baasApiRefreshOptions,
  baasUploadFile,
  baasUrlFormatterObk,
  baasApiOptionsObk,
};
