import Backdrop from "@mui/material/Backdrop";
import { useTheme } from "@mui/private-theming";
import { Box } from "@mui/system";
import {
  mutationPostPixClaimCancel,
  mutationPostPixClaimConfirm,
} from "api/pix-api";
import BaasButton from "components/atoms/Button/Button";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasModal from "components/atoms/Modal/Modal";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import { useOtp } from "providers/oneTimePassword";
import React, { useCallback, useState, useEffect } from "react";
import ReactJson from "react-json-view";
import { toast } from "react-toastify";
import styled from "styled-components";
import Endpoint from "constants/endpoints";
import { useCustomQuery } from "helpers/useCustomQuery";
import { queryDictHubClaimRoutesSandbox } from "api/pix-api-sandbox";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import BaasBox from "components/atoms/Box/Box";
import DinamicForm from "components/organism/DinamicForm/DinamicForm";

const BoxStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  max-width: 600px;

  max-height: 75vh;
  background-color: ${(props) => props.theme.palette.grey[200]};

  box-shadow: 24;
  padding: 4px;
  border-radius: 4px;
  overflow: hidden;
  overflow-y: scroll;

  :focus-visible {
    outline: none;
  }
`;

const JsonStyled = styled(Box)`
  border-radius: 4px;
  background: ${(props) => props.theme.palette.grey[100]};
  max-height: 400px;
  overflow-y: scroll;
  border-radius: 4px;
  padding: 8px;
`;

const ClaimSearchListModal = ({ data, setModal, modal, handleRefetch }) => {
  const theme = useTheme();
  const { setOpen } = useOtp();
  const [type, setType] = useState("");
  const [refetchRoutes, setRefetchRoutes] = useState(false);
  const [loadingGeneric, setLoadingGeneric] = useState();

  const isSandboxEnvironment = Endpoint.Environment === "Sandbox";

  const handleClose = () => {
    setModal(false);
  };

  const {
    fetch: fetchRoutes,
    data: routesData,
    loading: loadingRoutes,
  } = useCustomQuery(queryDictHubClaimRoutesSandbox);

  const tableActions = useCallback(
    async (values) => {
      let response;
      if (type === "CANCEL") {
        response = await mutationPostPixClaimCancel(values);
      } else {
        response = await mutationPostPixClaimConfirm(values);
      }

      if (response.status < 300) {
        toast.success(toastMessages.requestSuccess);
      } else {
        if ("errors" in response) {
          response.errors.map((error) => {
            return toast.error(error.message);
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type]
  );

  useEffect(() => {
    if (isSandboxEnvironment && data?.id) {
      fetchRoutes({
        values: data?.id,
        objectFallback: [],
        onNotFoundError: () => {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRoutes, refetchRoutes, data?.id]);

  const handleRefetchRoutes = () => {
    setRefetchRoutes(!refetchRoutes);
    handleRefetch();
  };

  const onSubmit = useCallback(
    (values) => {
      tableActions({
        otp: values.otp,
        reason: values.reason,
        claimId: data.id,
        type: type,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [type]
  );

  return (
    <>
      <BaasModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modal}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {isSandboxEnvironment ? (
          <BoxStyled p={3} theme={theme} sx={{ maxWidth: "400px" }}>
            <BaasText>Claim Details (Object TreeView)</BaasText>
            <Box mb={2}>
              <BaasText variant="body2" fontWeight="bold">
                Possible Actions
              </BaasText>
              {loadingRoutes ? (
                <BaasSkeleton width="100%" height="65px" />
              ) : routesData && routesData.length > 0 ? (
                routesData.map((route) => (
                  <BaasBox
                    sx={{ borderTop: "1px solid #CACACA" }}
                    mt={2}
                    key={route.message}
                  >
                    <DinamicForm
                      loading={loadingGeneric}
                      setLoading={setLoadingGeneric}
                      onComplete={handleRefetchRoutes}
                      route={route}
                    />
                  </BaasBox>
                ))
              ) : (
                <>
                  <BaasText color="error" mt={1}>
                    There is no actions available to this claim.
                  </BaasText>
                </>
              )}
            </Box>
            <JsonStyled theme={theme}>
              <ReactJson
                name={null}
                src={data}
                style={{ height: "100%", overflowY: "scroll" }}
                displayObjectSize={false}
                displayDataTypes={false}
              />
            </JsonStyled>
          </BoxStyled>
        ) : (
          <Formik initialValues={{}} onSubmit={() => setOpen(true)}>
            {({ values, isSubmitting, ...props }) => (
              <Form>
                <BaasOtpModal
                  onConfirm={() => onSubmit(values, props)}
                  {...props}
                />
                <BoxStyled p={3} theme={theme} sx={{ maxWidth: "400px" }}>
                  <BaasText>Claim Details (Object TreeView)</BaasText>

                  <BaasGrid container spacing={1} p="16px 0px">
                    <BaasGrid item xs={12}>
                      <BaasTextField
                        fullWidth
                        required
                        name="reason"
                        label="Claim Reason"
                        disabled={
                          data.status === "COMPLETED" ||
                          data.status === "CANCELLED"
                        }
                        select
                      >
                        <BaasMenuItem value="USER_REQUESTED">
                          User Requested
                        </BaasMenuItem>
                        <BaasMenuItem value="ACCOUNT_CLOSURE">
                          Account Closure
                        </BaasMenuItem>
                        <BaasMenuItem value="FRAUD">Fraud</BaasMenuItem>
                        <BaasMenuItem value="DEFAULT_OPERATION">
                          Default Operation
                        </BaasMenuItem>
                      </BaasTextField>
                    </BaasGrid>
                    <BaasGrid item>
                      <BaasButton
                        backgroundColor="black"
                        variant="contained"
                        disabled={
                          data.status !== "WAITING_RESOLUTION" || !values.reason
                        }
                        onClick={() => {
                          setType("CONFIRM");
                          setOpen(true);
                        }}
                      >
                        Confirm
                      </BaasButton>
                    </BaasGrid>

                    <BaasGrid item>
                      <BaasButton
                        backgroundColor="black"
                        variant="contained"
                        disabled={
                          data.status === "COMPLETED" ||
                          data.status === "CANCELLED" ||
                          !values.reason
                        }
                        onClick={() => {
                          setType("CANCEL");
                          setOpen(true);
                        }}
                      >
                        Cancel
                      </BaasButton>
                    </BaasGrid>
                  </BaasGrid>

                  <JsonStyled theme={theme}>
                    <ReactJson
                      name={null}
                      src={data}
                      style={{ height: "100%", overflowY: "scroll" }}
                      displayObjectSize={false}
                      displayDataTypes={false}
                    />
                  </JsonStyled>
                  <BaasGrid item sx={{ marginTop: "16px" }}>
                    <BaasButton
                      size="medium"
                      variant="outlined"
                      backgroundColor="black"
                      onClick={() => handleClose()}
                    >
                      Close
                    </BaasButton>
                  </BaasGrid>
                </BoxStyled>
              </Form>
            )}
          </Formik>
        )}
      </BaasModal>
    </>
  );
};

export default ClaimSearchListModal;
