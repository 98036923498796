import { Box, Grid, MenuItem, TextField, Typography } from "@mui/material";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import BaasTransactionSuccess from "components/templates/TransactionSuccess/TransactionSuccess";
import React, { useState, useCallback } from "react";
import BaasButton from "components/atoms/Button/Button";
import { useCustomMutation } from "helpers/useCustomMutation";
import { mutationQrCodePayment } from "api/baasTester/qrPayment-api";
import FaqSection from "components/organism/FaqSection/FaqSection";
import BaasBox from "components/atoms/Box/Box";
import { toast } from "react-toastify";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import StackCard from "components/atoms/StackCard/StackCard";
const QrCodePayment = () => {
  const [emv, setEmv] = useState("");
  const [success, setSuccess] = useState(false);
  const { mutate, isSubmitting } = useCustomMutation(mutationQrCodePayment);
  const [useDebitParty, setUseDebitParty] = useState(false);
  const [customDebitParty, setCustomDebitParty] = useState({
    branch: "",
    account: "",
    name: "",
    taxId: "",
    accountType: "CACC",
  });

  const handleChange = (value) => {
    setEmv(value);
  };

  const onSubmitPayment = useCallback(() => {
    const payload = useDebitParty
      ? {
          emv: emv,
          setDebitParty: useDebitParty,
          customDebitPartyRequest: customDebitParty,
        }
      : {
          emv: emv,
          setDebitParty: false,
        };

    mutate(
      {
        ...payload,
      },
      (data) => {
        setEmv("");
        setUseDebitParty(false);
        setCustomDebitParty({
          branch: "",
          account: "",
          name: "",
          taxId: "",
          accountType: "CACC",
        });
        setSuccess(data.body?.body?.endToEndId);
      },
      (error) => {
        if (error.errorCode === "PBE7040") {
          toast.error(`Emvs created from QR Code Tester are not accepted. Please use
            the method in the FAQ section bellow to generate valid EMVs.`);
        } else {
          return toast.error(error.message);
        }
      }
    );
  }, [emv, mutate, useDebitParty, customDebitParty]);

  const updateField = (e) => {
    setCustomDebitParty({
      ...customDebitParty,
      [e.target.name]: e.target.value,
    });
  };

  const handleUseDebitParticipant = () => {
    setUseDebitParty(!useDebitParty);
    setCustomDebitParty({
      branch: "",
      account: "",
      name: "",
      taxId: "",
      accountType: "CACC",
    });
  };

  const checkIsDisabled = () => {
    if (!emv) {
      return true;
    }
    if (useDebitParty) {
      if (
        !customDebitParty.account ||
        !customDebitParty.accountType ||
        !customDebitParty.branch ||
        !customDebitParty.name ||
        !customDebitParty.taxId
      ) {
        return true;
      }
    }
  };

  return (
    <BaasFlex flex={1} flexDirection="column">
      {!success ? (
        <>
          <BaasPageHeader>Test Interface</BaasPageHeader>
          <BaasGrid
            item
            spacing={1}
            style={{ padding: "16px", marginBottom: "16px" }}
          >
            <BaasRow>
              <BaasText variant="h6">QR Code Payment</BaasText>
            </BaasRow>
            <BaasText variant="body2">
              Please, insert your EMV code to proceed for payment process.
              <Box>
                <Typography fontWeight="bold" color="error" variant="body2">
                  Emvs created from QR Code Tester are not accepted. Please use
                  the method in the FAQ section bellow to generate valid EMVs.
                </Typography>
              </Box>
            </BaasText>
          </BaasGrid>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onSubmitPayment();
            }}
          >
            <BaasContentGrid spacing={4}>
              <StackCard>
                <BaasGrid item>
                  <TextField
                    autoFocus
                    name="emv"
                    margin="dense"
                    label="Emv Code"
                    type="text"
                    multiline
                    value={emv}
                    fullWidth
                    variant="standard"
                    onChange={(e) => handleChange(e.target.value)}
                  />
                </BaasGrid>
                <Grid item mt={1}>
                  <FormGroup name="setDebitParty">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={useDebitParty}
                          onChange={handleUseDebitParticipant}
                        />
                      }
                      label="Send debit part"
                    />
                  </FormGroup>
                </Grid>
                {useDebitParty && (
                  <Grid container spacing={2} mt={2}>
                    <BaasGrid item md={6}>
                      <TextField
                        fullWidth
                        name="taxId"
                        label="Tax Id"
                        type="number"
                        value={customDebitParty.taxId}
                        onChange={(e) => updateField(e)}
                        required
                      />
                    </BaasGrid>
                    <Grid item md={6}>
                      <TextField
                        fullWidth
                        name="name"
                        label="Name"
                        value={customDebitParty.name}
                        onChange={(e) => updateField(e)}
                        required
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        fullWidth
                        name="branch"
                        label="Branch"
                        value={customDebitParty.branch}
                        type="number"
                        onChange={(e) => updateField(e)}
                        InputProps={{ min: 0 }}
                        required
                      />
                    </Grid>
                    <BaasGrid item md={6}>
                      <TextField
                        fullWidth
                        name="account"
                        label="Account"
                        type="number"
                        value={customDebitParty.account}
                        onChange={(e) => updateField(e)}
                        InputProps={{ min: 0 }}
                        required
                      />
                    </BaasGrid>
                    <BaasGrid item md={6}>
                      <TextField
                        fullWidth
                        name="accountType"
                        onChange={(e) => updateField(e)}
                        value={customDebitParty.accountType}
                        label="Account Type"
                        required
                        select
                      >
                        <MenuItem value="CACC">CACC</MenuItem>
                        <MenuItem value="SLRY">SLRY</MenuItem>
                        <MenuItem value="SVGS">SVGS</MenuItem>
                        <MenuItem value="TRAN">TRAN</MenuItem>
                      </TextField>
                    </BaasGrid>
                  </Grid>
                )}
                <BaasGrid item style={{ marginTop: "16px" }}>
                  <BaasButton
                    loading={false}
                    disabled={checkIsDisabled() || isSubmitting}
                    variant="contained"
                    type="submit"
                  >
                    {isSubmitting ? "Processing" : "Start Payment"}
                  </BaasButton>
                </BaasGrid>
                <BaasBox mt={5}>
                  <FaqSection
                    messages={[
                      {
                        title: "Creating an Indirect Immediate QR Code",
                        description:
                          "You can create an Indirect Immediate QR code following the link below",
                        externalLink:
                          "https://developer.btgpactual.com/docpi#operation/post-pix-collection-location",
                        linkLabel: "Read Documentation",
                      },
                      {
                        title: "Creating an Indirect Due Date QR Code",
                        description:
                          "You can create an Indirect Due Date QR code following the link below",
                        externalLink:
                          "https://developer.btgpactual.com/docpi#operation/02-create-pix-collection-api-cobv-location",
                        linkLabel: "Read Documentation",
                      },

                      {
                        title: "Creating a Merchant Immediate QR Code",
                        description:
                          "You can create a Merchant Immediate QR code following the link below",
                        externalLink:
                          "https://developer.btgpactual.com/docpm#operation/post-pix-api-cob-abstracted-location",
                        linkLabel: "Read Documentation",
                      },
                      {
                        title: "Creating a Merchant Indirect Due Date QR Code",
                        description:
                          "You can create a Merchant Due Date QR code following the link below",
                        externalLink:
                          "https://developer.btgpactual.com/docpm#operation/post-pix-api-bacen-abstracted-location-cobv-txid",
                        linkLabel: "Read Documentation",
                      },
                    ]}
                  />
                </BaasBox>
              </StackCard>
            </BaasContentGrid>
          </form>
        </>
      ) : (
        <BaasTransactionSuccess
          pactualId={success}
          idLabel={"E2E Id"}
          onClick={() => {
            setSuccess(undefined);
          }}
        />
      )}
    </BaasFlex>
  );
};

export default QrCodePayment;
