import { Fab } from "@mui/material";
import React from "react";
import styled from "styled-components";
const FabStyle = styled.div``;
const BaasFab = ({ children, ...props }) => {
  return (
    <FabStyle>
      <Fab {...props}>{children}</Fab>
    </FabStyle>
  );
};

export default BaasFab;
