import React, { useEffect } from "react";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import { useHistory } from "react-router";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DinamicTable from "components/organism/DinamicTable/DinamicTable";
import moment from "moment";
import { queryGetPixClaimSearchList } from "api/pix-api";
import { useCustomQuery } from "helpers/useCustomQuery";
import { AddButton } from "components/molecules/AddButton/AddButton";

const PixTestSuitClaimsList = () => {
  const history = useHistory();
  const { fetch, loading, data } = useCustomQuery(queryGetPixClaimSearchList);

  useEffect(() => {
    fetch({
      values: {
        isCredited: true,
        modifiedAfter: `${moment()
          .subtract(1, "M")
          .format("YYYY-MM-DD")}T00:00:00Z`,
        modifiedBefore: `${moment().format("YYYY-MM-DD")}T23:59:59Z`,
        limit: 50,
      },
      emptyWarning: true,
      objectPath: "body.items",
    });
  }, [fetch]);

  const onViewClick = (item) => {
    history.push({
      pathname: `/pix-test-suit-claim-details/${item.id}`,
    });
  };

  return (
    <BaasFlex flex={1} flexDirection="column">
      <BaasPageHeader>Pix</BaasPageHeader>
      <BaasContentGrid spacing={4}>
        <BaasGrid item spacing={1}>
          <BaasRow>
            <BaasText variant="h6">Pix Test Suit Claim</BaasText>
          </BaasRow>
        </BaasGrid>
        <AddButton
          label="Create Claim"
          onClick={() =>
            history.push({
              pathname: `/pix-test-suit-claim`,
            })
          }
        />
        <DinamicTable
          loading={loading}
          data={data}
          defaultSort={{
            key: "completionPeriodEnd",
            orientation: "desc",
          }}
          tableSchema={[
            {
              header: "Identification",
              key: "id",
              sort: true,
            },
            {
              header: "Entry",
              key: "key",
              tooltip: true,
              sort: true,
              style: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "noWrap",
              },
            },
            {
              header: "Ends in",
              key: "completionPeriodEnd",
              sort: true,
              formatValue: (value) => moment(value).format("YYYY-MM-DD"),
            },
            {
              header: "Donor",
              key: "donorParticipant",
            },
            {
              header: "Status",
              key: "status",
              sort: true,
            },
          ]}
          extraActions={[
            {
              onClick: onViewClick,
              icon: VisibilityIcon,
              tooltip: "View",
            },
          ]}
        />
      </BaasContentGrid>
    </BaasFlex>
  );
};

export default PixTestSuitClaimsList;
