import { Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
const GridStyle = styled(Grid)``;

const BaasGrid = ({ children, ...props }) => {
  return <GridStyle {...props}>{children}</GridStyle>;
};

export default BaasGrid;
