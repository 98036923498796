import { useTheme } from "@mui/system";
import { mutationPostPixDictEntryCreate } from "api/dictv2-api";
import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import BaasTransactionSuccess from "components/templates/TransactionSuccess/TransactionSuccess";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import { formatPixKeys } from "helpers/formatPixKeys";
import moment from "moment";
import { useOtp } from "providers/oneTimePassword";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import guidGenerator from "services/guidGenerator";
import * as yup from "yup";
import AccountTypeSelectedLabel from "components/molecules/AccountTypeSelectedLabel/AccountTypeSelectedLabel";
import { useAccount } from "providers/account";
import StackCard from "components/atoms/StackCard/StackCard";

export default function EntryCreateV2() {
  const theme = useTheme();
  const history = useHistory();
  const { setOpen, isOtpRequired } = useOtp();
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialValues = useMemo(() => {
    return {};
  }, []);
  const { accountType } = useAccount();

  const validationSchema = yup.object().shape({
    tradeName: yup.string().max(15, "Maximum characters length is 15"),
  });

  const handleBack = () => {
    history.push("/pix/v2/entry");
  };

  useEffect(() => {
    if (accountType === "Merchant") {
      handleBackToDashboard();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType]);

  const handleBackToDashboard = () => {
    history.push("/dashboard");
  };

  const mutateDictEntry = useCallback(async (values, formik) => {
    const response = await mutationPostPixDictEntryCreate(values);
    if (response.status < 300) {
      setSuccess(true);
    } else {
      if ("errors" in response) {
        response.errors.forEach((error) => {
          toast.error(error.message);
        });
      } else {
        toast.error(toastMessages.requestError);
      }
    }
    setIsSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(
    (values) => {
      setIsSubmitting(true);
      mutateDictEntry({
        otp: values.otp,
        reason: values.reason,
        key: formatPixKeys(values),
        branch: values.branch,
        accountNumber: values.accountNumber,
        accountType: values.accountType,
        taxId: values.taxId,
        name: values.name,
        tradeName: values.tradeName,
        openingDate: moment().format("YYYY-MM-DDTHH:mm:ss[Z]"),
        requestId: guidGenerator(),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutateDictEntry]
  );

  return (
    <>
      <BaasFlex flexDirection="column" width="100%">
        <BaasPageHeader>DICT Hub</BaasPageHeader>
        <BaasContentGrid spacing={4}>
          <BaasGrid p="16px 0px" item>
            <BaasGrid display="flex">
              <BaasText variant="h6">Creat New DICT Entry</BaasText>
              <AccountTypeSelectedLabel />
            </BaasGrid>
          </BaasGrid>
          <StackCard>
            <BaasText variant="body2">
              Create new Pix keys by clicking bellow. New keys are stored and
              synced with Brazilian Central Bank DICT repository.
            </BaasText>
            {success ? (
              <BaasTransactionSuccess redirectUrl="/pix/v2/entry" />
            ) : (
              <Formik
                initialValues={initialValues}
                onSubmit={isOtpRequired ? () => setOpen(true) : onSubmit}
                validationSchema={validationSchema}
              >
                {({ values, setFieldValue, ...props }) => (
                  <Form>
                    <BaasOtpModal
                      onConfirm={() => onSubmit(values, props)}
                      {...props}
                    />
                    <BaasRow>
                      <BaasRow container column={12} spacing={3}>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="reason"
                            label="Reason"
                            required
                            select
                          >
                            <BaasMenuItem value="USER_REQUESTED">
                              User Requested
                            </BaasMenuItem>
                            {/* <BaasMenuItem value="ACCOUNT_CLOSURE">
                              Account closure
                            </BaasMenuItem>
                            <BaasMenuItem value="BRANCH_TRANSFER">
                              Branch transfer
                            </BaasMenuItem> */}
                            <BaasMenuItem value="RECONCILIATION">
                              Reconciliation
                            </BaasMenuItem>
                            {/* <BaasMenuItem value="DEFAULT_OPERATION">
                              Default Operation
                            </BaasMenuItem> */}
                            {/* <BaasMenuItem value="FRAUD">Fraud</BaasMenuItem>
                            <BaasMenuItem value="RFB_VALIDATION">RFB Validation (Receita Federal Brasileira)</BaasMenuItem> */}
                          </BaasTextField>
                        </BaasGrid>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="keyType"
                            label="Key Type"
                            required
                            select
                          >
                            <BaasMenuItem value="keyCpf">CPF</BaasMenuItem>
                            <BaasMenuItem value="keyCnpj">CNPJ</BaasMenuItem>
                            <BaasMenuItem value="keyPhone">Phone</BaasMenuItem>
                            <BaasMenuItem value="keyEmail">Email</BaasMenuItem>
                            <BaasMenuItem value="keyEvp">EVP</BaasMenuItem>
                          </BaasTextField>
                        </BaasGrid>
                        {/* CPF */}
                        {values.keyType === "keyCpf" ? (
                          <BaasGrid item xs={12}>
                            <InputMask
                              mask="999.999.999-99"
                              onChange={(e) => (values.key = e.target.value)}
                            >
                              <BaasTextField
                                fullWidth
                                name="key"
                                label="Key"
                                required
                              />
                            </InputMask>
                          </BaasGrid>
                        ) : null}
                        {/* CNPJ */}
                        {values.keyType === "keyCnpj" ? (
                          <BaasGrid item xs={12}>
                            <InputMask
                              mask="99.999.999/9999-99"
                              onChange={(e) => (values.key = e.target.value)}
                            >
                              <BaasTextField
                                fullWidth
                                name="key"
                                label="Key"
                                required
                              />
                            </InputMask>
                          </BaasGrid>
                        ) : null}
                        {/* Phone */}
                        {values.keyType === "keyPhone" ? (
                          <BaasGrid item xs={12}>
                            <InputMask
                              mask="(99) 99999-9999"
                              onChange={(e) => (values.key = e.target.value)}
                            >
                              <BaasTextField
                                fullWidth
                                name="key"
                                label="Key"
                                required
                              />
                            </InputMask>
                          </BaasGrid>
                        ) : null}
                        {/* Email */}
                        {values.keyType === "keyEmail" ? (
                          <BaasGrid item xs={12}>
                            <BaasTextField
                              fullWidth
                              name="key"
                              label="Key"
                              required
                              type="email"
                              placeholder=" email@example.com"
                            />
                          </BaasGrid>
                        ) : null}
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="branch"
                            label="Branch"
                            required
                          />
                        </BaasGrid>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="accountNumber"
                            label="Account"
                            required
                          />
                        </BaasGrid>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="accountType"
                            label="Account Type"
                            required
                            select
                          >
                            <BaasMenuItem value="CACC">
                              CACC (Checking Account)
                            </BaasMenuItem>
                            <BaasMenuItem value="SLRY">
                              SLRY (Salary)
                            </BaasMenuItem>
                            <BaasMenuItem value="SVGS">
                              SVGS (Savings)
                            </BaasMenuItem>
                            <BaasMenuItem value="TRAN">
                              TRAN (Transaction)
                            </BaasMenuItem>
                          </BaasTextField>
                        </BaasGrid>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="name"
                            label="Name"
                            required
                          />
                        </BaasGrid>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="tradeName"
                            label="Trade Name"
                          />
                        </BaasGrid>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="taxId"
                            label="Tax Id"
                            type="number"
                            required
                          />
                        </BaasGrid>
                      </BaasRow>
                      <BaasRow
                        container
                        sx={{ justifyContent: "space-between" }}
                        flexDirection="row"
                      >
                        <BaasGrid item p="16px 0px">
                          <BaasButton
                            type="submit"
                            size="medium"
                            variant="outlined"
                            backgroundColor="black"
                            onClick={() => handleBack()}
                          >
                            Back
                          </BaasButton>
                        </BaasGrid>
                        <BaasGrid item p="16px 0px">
                          <BaasButton
                            variant="contained"
                            backgroundColor="black"
                            type="submit"
                            loading={isSubmitting}
                            onClick
                          >
                            Create
                          </BaasButton>
                        </BaasGrid>
                      </BaasRow>
                    </BaasRow>
                  </Form>
                )}
              </Formik>
            )}
          </StackCard>
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
}
