import BaasFlex from "components/atoms/Flex/Flex";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasUserInfoTooltip from "components/molecules/UserInfoTooltip/UserInfoTooltip";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import React, { useEffect, useState } from "react";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import Endpoints from "../../../constants/endpoints";

const SandboxCm = () => {
  const [docEndpoint, setDocEndpoint] = useState();
  const [sessionTitle, setSessionTitle] = useState();
  const url = window.location.pathname;

  useEffect(() => {
    docEndpointSelector();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const docEndpointSelector = () => {
    switch (true) {
      case url.includes("/sandboxcm"):
        setSessionTitle("Sandbox Commom Methods");
        setDocEndpoint("/cm/-doc");
        break;
      case url.includes("/sandboxob"):
        setSessionTitle("Onboarding");
        setDocEndpoint("/ob/-doc");
        break;
      case url.includes("/sandboxtr"):
        setSessionTitle("Transactions");
        setDocEndpoint("/tr/-doc");
        break;
      case url.includes("/sandboxpc"):
        setSessionTitle("PIX Commom");
        setDocEndpoint("/pc/-doc");
        break;
      case url.includes("/sandboxpi"):
        setSessionTitle("PIX Indirect Participant");
        setDocEndpoint("/pi/-doc");
        break;
      case url.includes("/sandboxpm"):
        setSessionTitle("PIX Merchant Clients");
        setDocEndpoint("/pm/-doc");
        break;
      default:
        return "failed";
    }
  };

  return (
    <>
      <BaasUserInfoTooltip />
      <BaasFlex flexDirection="column" width="100%">
        <BaasPageHeader>{sessionTitle}</BaasPageHeader>
        <BaasContentGrid spacing={4}>
          {docEndpoint ? (
            <SwaggerUI url={Endpoints.BaaSApiGateway + docEndpoint} />
          ) : null}
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
};

export default SandboxCm;
