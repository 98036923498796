import queryToString from "helpers/queryToString";
import { baasApiCall } from "services/apiCall";

export const querySearchTransfers = (queryParams) => {
  return baasApiCall("GET", `/tr/transfer/search${queryToString(queryParams)}`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const querySearchBankSlipOperations = (queryParams) => {
  return baasApiCall(
    "GET",
    `/tr/paymentslip/search${queryToString(queryParams)}`
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const querySearchPixOperations = (queryParams) => {
  return baasApiCall("GET", `/pc/pix/search${queryToString(queryParams)}`)
    .then((data) => data.json())
    .catch((error) => error.json());
};
