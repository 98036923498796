import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import React from "react";

const LandingProducts = () => {
  const products = [
    {
      name: "Account, Balance and Reconciliation",
      description: "Have online information about your wallet.",
    },
    {
      name: "Depositary Bank",
      description:
        "Fully automated integration for Acquirers that negotiates its credit card receivables.",
    },
    {
      name: "Know your Customer",
      description:
        "Use our APIs to power your KYC process without having to integrate with several vendors.",
    },
    {
      name: "Non API based solutions",
      description:
        "BTG can also support Payment Institutions with Credit Card Settlement at CIP (Banco Liquidante), Escrow for card scheme collateral purposes (Trust), Selic 28 (Custódia de Moeda Eletrônica) and Constitution of SCD (Sociedade de Crédito Direto).",
    },
    {
      name: "Pix Indirect Participant",
      description:
        "Use BTG Pactual as an intermediary to operate Pix along Brazilian Central Bank.",
    },
    {
      name: "PIX Merchant Client",
      description:
        "Send and receive Pix transactions as a BTG Pactual merchant client through API.",
    },
    {
      name: "Payment Slip (Boleto)",
      description:
        "Issue and pay Payment Slips, one of the most common forms of payment in Brazil.",
    },
    {
      name: "Money Transfers",
      description:
        "Send and receive money transfers using our Internal (TEF) and External Transfer (TED) APIs.",
    },
  ];

  return (
    <>
      <BaasGrid
        container
        backgroundColor="grey.300"
        display="flex"
        alignItems="center"
        flexDirection="column"
        p={8}
      >
        <BaasGrid p="0px 0px 40px 0px">
          <BaasText variant="h4" color="grey.800">
            Products
          </BaasText>
        </BaasGrid>

        <BaasGrid
          display="flex"
          flexWrap="wrap"
          maxWidth="1100px"
          justifyContent="center"
        >
          {products.map((product) => (
            <BaasGrid width="225px" m={3}>
              <BaasText variant="h6">{product.name}</BaasText>
              <BaasText variant="body2">{product.description}</BaasText>
            </BaasGrid>
          ))}
        </BaasGrid>
      </BaasGrid>
    </>
  );
};

export default LandingProducts;
