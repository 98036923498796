import React from "react";
import styled from "styled-components";
import { useTheme } from "@mui/system";

const BaasTableRowStyle = styled.div`
  display: flex;
  cursor: pointer;
  min-width: ${(props) => props.minWidth};
  background: ${(props) => props.theme.palette.grey[100]};
  :hover {
    background-color: ${(props) => props.theme.palette.grey[400]};
  }
`;

const BaasTableRow = ({ children, ...props }) => {
  const theme = useTheme();
  return (
    <BaasTableRowStyle theme={theme} {...props}>
      {children}
    </BaasTableRowStyle>
  );
};

export default BaasTableRow;
