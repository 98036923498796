import { useTheme } from "@mui/system";
import styled from "styled-components";
import BaasText from "../Text/BaasText";
import React from "react";
import { ReactComponent as CancelIcon } from "assets/icons/Cancel.svg";
import { ReactComponent as DoneIcon } from "assets/icons/Check.svg";
import { ReactComponent as WarningIcon } from "assets/icons/Attention.svg";
import { ReactComponent as EditIcon } from "assets/icons/Edit.svg";

import BaasFlex from "../Flex/Flex";
const BaasNotificationStyle = styled.div`
  background-color: ${(props) => props.theme.palette[props.color].main}20;
  opacity: 80%;
  border-radius: 4px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  padding: 16px 14px;

  border: 1px solid ${(props) => props.theme.palette[props.color].main};
  font-size: 12px;
  color: ${(props) => props.theme.palette[props.color].main};

  margin: 4px 0px;

  cursor: ${(props) => (props.onClick ? "pointer" : "unset")};
  :hover {
    opacity: 100%;
  }
`;
const BaasNotification = ({
  status = { error: false, saved: false, warning: false, edited: false },
  label,
  icons: Icons,
  ...props
}) => {
  const theme = useTheme();

  return (
    <BaasNotificationStyle theme={theme} {...props}>
      <BaasText variant="caption">{label}</BaasText>
      <BaasFlex>
        {status.error && <CancelIcon />}

        {status.warning && <WarningIcon />}

        {status.saved && <DoneIcon />}

        {status.edited && <EditIcon />}
      </BaasFlex>
    </BaasNotificationStyle>
  );
};

export default BaasNotification;
