import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/material";
import { useTheme } from "@mui/system";
import {
  mutationDeleteMerchantCustomerById,
  queryGetMerchantCustomerById,
} from "api/pix-api";
import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import BaasText from "components/atoms/Text/BaasText";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import DialogConfirmation from "components/organism/DialogConfirmation/DialogConfirmation";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { useCustomMutation } from "helpers/useCustomMutation";
import { useCustomQuery } from "helpers/useCustomQuery";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";

const CustomerDetails = () => {
  const theme = useTheme();
  const { customerId } = useParams();
  const history = useHistory();
  const [open, setOpen] = useState();

  const { fetch, data, loading } = useCustomQuery(queryGetMerchantCustomerById);

  const { mutate, isSubmitting } = useCustomMutation(
    mutationDeleteMerchantCustomerById
  );

  const onDelete = useCallback(
    (id) => {
      mutate(id, (response) =>
        history.push({
          pathname: `/pix/customers-management`,
        })
      );
    },
    [history, mutate]
  );

  const handleDeleteConfirmation = () => {
    setOpen(customerId);
  };

  useEffect(() => {
    if (customerId) {
      fetch({
        values: customerId,
        objectPath: "body",
        objectFallback: {},
      });
    }
  }, [customerId, fetch]);

  const customerValues = [
    { label: "Customer Id", value: data?.merchantCustomerId },
    { label: "Name", value: data?.name },
    { label: "Tax ID", value: data?.taxId },
    { label: "Status", value: data?.status },
    {
      label: "Last update at",
      value: moment(data?.lastUpdateTimestamp).format("YYYY-MM-DD"),
    },
    {
      label: "Aditional Data",
      value:
        data?.additionalData && data?.additionalData.length > 0
          ? data?.additionalData[0]
          : null,
    },
    {
      label: "Created at",
      value: moment(data?.createTimestamp).format("YYYY-MM-DD"),
    },
  ];

  const onEditClick = () => {
    history.push({
      pathname: `/pix/customers-management-edit/${customerId}`,
    });
  };

  return (
    <BaasFlex flex={1} flexDirection="column">
      <BaasContentGrid spacing={4}>
        <BaasGrid item spacing={1}>
          <BaasRow>
            <BaasText variant="h6">Pix Customers Management</BaasText>
          </BaasRow>
        </BaasGrid>
        <BaasGrid sx={{ background: theme.palette.grey[100] }} p="16px">
          <Box mb={3}>
            <BaasButton
              startIcon={<ArrowBackIcon />}
              size="small"
              onClick={() =>
                history.push({
                  pathname: `/pix/customers-management`,
                })
              }
            >
              Back
            </BaasButton>
          </Box>
          <BaasText variant="body2" fontWeight="bold">
            Merchant customer details
          </BaasText>
          <BaasRow>
            <BaasGrid sx={{ background: theme.palette.grey[100] }}>
              <BaasRow container column={12} spacing={3}>
                {loading ? (
                  <>
                    <BaasGrid item xs={12} sm={12}>
                      <BaasSkeleton width="100%" height="65px" />
                    </BaasGrid>
                    <BaasGrid item xs={12} sm={12}>
                      <BaasSkeleton width="100%" height="65px" />
                    </BaasGrid>
                    <BaasGrid item xs={12} sm={12}>
                      <BaasSkeleton width="100%" height="65px" />
                    </BaasGrid>
                    <BaasGrid item xs={12} sm={12}>
                      <BaasSkeleton width="100%" height="65px" />
                    </BaasGrid>
                  </>
                ) : (
                  data &&
                  customerValues.map((row) => (
                    <BaasGrid item xs={12} sm={6} key={row.label}>
                      <BaasInfoDisplay
                        fullWidth
                        label={row.label}
                        value={row.value || "Not available"}
                      />
                    </BaasGrid>
                  ))
                )}
              </BaasRow>
            </BaasGrid>
          </BaasRow>
          <Box display="flex">
            <BaasButton
              startIcon={<DeleteIcon />}
              variant="outlined"
              disabled={data?.status === "DELETED"}
              loading={isSubmitting}
              color="error"
              onClick={handleDeleteConfirmation}
            >
              Delete
            </BaasButton>
            <Box ml={2}>
              <BaasButton
                startIcon={<EditIcon />}
                type="submit"
                size="medium"
                onClick={onEditClick}
                variant="outlined"
                isSubmiting={isSubmitting}
              >
                Update
              </BaasButton>
            </Box>
          </Box>
        </BaasGrid>
      </BaasContentGrid>
      <DialogConfirmation
        open={Boolean(open)}
        id={open}
        setOpen={setOpen}
        onConfirm={() => {
          setOpen(false);
          onDelete(open);
        }}
        title="Delete this Customer Merchant?"
        content="You have selected to delete this Customer Merchant, if this was the action that you wanted to do, please confirm your choice or cancel and return to the page."
      />
    </BaasFlex>
  );
};

export default CustomerDetails;
