import parseJwt from "helpers/parseJwt";
import Endpoints from "../constants/endpoints";

const recoverProfileFromSession = () => {
  let profile = null;

  if (sessionStorage.getItem("BAAS_LOGGED_IN") === "true") {
    profile = {
      authToken: {
        refresh_token: sessionStorage.getItem("BAAS_REFRESH_TOKEN"),
        session_token: sessionStorage.getItem("BAAS_SESSION_TOKEN"),
        token_type: sessionStorage.getItem("BAAS_TOKEN_TYPE"),
        expires_in: sessionStorage.getItem("BAAS_EXPIRES_IN"),
        access_token: sessionStorage.getItem("BAAS_ACCESS_TOKEN"),
      },
      roles: JSON.parse(sessionStorage.getItem("BAAS_USER_ROLES")),
      userId: sessionStorage.getItem("BAAS_USER_ID"),
      fullName: sessionStorage.getItem("BAAS_FULLNAME"),
      email: sessionStorage.getItem("BAAS_EMAIL"),
      headerToSessionToken: sessionStorage.getItem(
        "BAAS_HEADER_TO_SESSION_TOKEN"
      ),
      headerToTransactionCode: sessionStorage.getItem(
        "BAAS_HEADER_TO_TRANSACTION_CODE"
      ),
    };
  }
  return profile;
};

async function refreshToken(oldRefreshToken) {
  const options = {
    method: "POST",
    AccessControlAllowOrigin: "*",
    headers: new Headers({
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    }),
    body: new URLSearchParams({
      refresh_token: oldRefreshToken,
      grant_type: "refresh_token",
    }),
  };

  let data = {
    status: null,
    body: null,
  };

  try {
    const apiReturn = await fetch(
      Endpoints.BaaSApiGateway + "/cm/authentication/token/refresh",
      options
    );
    data.status = apiReturn.status;
    data.body = await apiReturn.json();
  } catch (err) {
    console.log(err);
  }

  return data;
}

const storeProfileInSession = (profile) => {
  const parsedToken = parseJwt(profile.authToken.access_token);

  sessionStorage.setItem("BAAS_USER_ROLES", JSON.stringify(parsedToken.role));
  sessionStorage.setItem("BAAS_LOGGED_IN", "true");
  sessionStorage.setItem("BAAS_REFRESH_TOKEN", profile.authToken.refresh_token);
  sessionStorage.setItem("BAAS_TOKEN_TYPE", profile.authToken.token_type);
  sessionStorage.setItem("BAAS_EXPIRES_IN", profile.authToken.expires_in);
  sessionStorage.setItem("BAAS_ACCESS_TOKEN", profile.authToken.access_token);
  sessionStorage.setItem("BAAS_USER_ID", profile.userId);
  sessionStorage.setItem("BAAS_FULLNAME", profile.fullName);
  sessionStorage.setItem("BAAS_EMAIL", profile.email);
  sessionStorage.setItem(
    "BAAS_HEADER_TO_SESSION_TOKEN",
    profile.headerToSessionToken
  );
  sessionStorage.setItem(
    "BAAS_HEADER_TO_TRANSACTION_CODE",
    profile.headerToTransactionCode
  );
  sessionStorage.setItem("BAAS_SESSION_TOKEN", profile.authToken.session_token);
};

const storeRefreshTokenInSession = (tokenData) => {
  const parsedToken = parseJwt(tokenData.access_token);
  sessionStorage.setItem("BAAS_REFRESH_TOKEN", tokenData.refresh_token);
  sessionStorage.setItem("BAAS_TOKEN_TYPE", tokenData.token_type);
  sessionStorage.setItem("BAAS_EXPIRES_IN", tokenData.expires_in);
  sessionStorage.setItem("BAAS_ACCESS_TOKEN", tokenData.access_token);
  sessionStorage.setItem("BAAS_SESSION_TOKEN", tokenData.session_token);
  sessionStorage.setItem("BAAS_USER_ROLES", JSON.stringify(parsedToken.role));
};

export {
  recoverProfileFromSession,
  refreshToken,
  storeRefreshTokenInSession,
  storeProfileInSession,
};
