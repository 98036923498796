import { Box, useTheme } from "@mui/material";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";

const MetricsPanelSkeleton = () => {
  const theme = useTheme();

  return (
    <Box ml={2}>
      <BaasGrid
        container
        spacing={2}
        sx={{
          background: theme.palette.grey[100],
          paddingTop: "0px",
          paddingRight: "16px",
        }}
      >
        <BaasGrid item xs={12} sm={12} md={3}>
          <BaasSkeleton width="100%" height="200px" />
        </BaasGrid>
        <BaasGrid item xs={12} sm={12} md={6} pr={2}>
          <BaasSkeleton width="100%" height="200px" />
        </BaasGrid>
        <BaasGrid item xs={12} sm={12} md={3}>
          <BaasSkeleton width="100%" height="200px" />
        </BaasGrid>
      </BaasGrid>
    </Box>
  );
};
export default MetricsPanelSkeleton;
