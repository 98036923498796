import styled from "@emotion/styled";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export const StyledHeaderContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyledSortWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  margin-top: 2px;
`;

export const StyledArrowUp = styled(ArrowDropUpIcon)`
  cursor: pointer;
  color: ${(props) =>
    props.active
      ? props.theme.palette.primary.main
      : props.theme.palette.grey[600]};
`;

export const StyledArrowDown = styled(ArrowDropDownIcon)`
  cursor: pointer;
  color: ${(props) =>
    props.active
      ? props.theme.palette.primary.main
      : props.theme.palette.grey[600]};
`;
