import { Alert, CircularProgress } from "@mui/material";
import { useTheme } from "@mui/system";
import { mutationPostPaymentPix, queryGetSearchBank } from "api/obk-api";
import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasCardWithIcon from "components/molecules/CardWithIcon/CardWithIcon";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import StoreIcon from "assets/icons/Store.svg";
import { useAccount } from "providers/account";

export default function MockStoreBankLogin() {
  const theme = useTheme();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [warningOpen, setWarningOpen] = useState(true);
  const { accountSelected } = useAccount();
  const [state, setState] = useState({
    paymentMethod: JSON.parse(sessionStorage.getItem("paymentMethod")),
    obkAmount: JSON.parse(sessionStorage.getItem("obkAmount")),
    useBaas: JSON.parse(sessionStorage.getItem("useBaas")),
    bankOptions: [],
    selectedBank: null,
    pixKey: "baaspisp@email.com", //E-commerce credit account
    clientRequestId: `OBK-${Date.now()}`,
    redirectUrl: window.location.origin + "/mock-obk/TransactionConfirmed",
    remittanceInformation: "InicPag BTG (DICT PF)",
  });

  useEffect(() => {
    searchBank();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchBank = async () => {
    const response = await queryGetSearchBank();
    setState({
      ...state,
      bankOptions: response.body,
    });
  };

  const initiatePayment = async (values) => {
    setIsSubmitting(true);
    const response = await mutationPostPaymentPix(values, {
      useBaas: state.useBaas,
    });

    if (response.status < 300) {
      setIsSubmitting(false);
      sessionStorage.setItem("pactualIdPisp", response.body.pactualId);
      window.location.replace(response.body.body.consentRedirectUrl);
      toast.success(toastMessages.redirectSuccess);
    } else {
      if ("errors" in response) {
        response.errors.map((error) => {
          return toast.error(error.message);
        });
      }
      setIsSubmitting(false);
    }
  };

  const onSubmit = (values) => {
    initiatePayment({
      pixKey: state.pixKey,
      authorizationServerId: state.selectedBank.authorizationServerId,
      amount: state.obkAmount,
      clientRequestId: state.clientRequestId,
      redirectUrl: state.redirectUrl,
      paymentDate: `${moment().format("YYYY-MM-DD")}`,
      remittanceInformation: state.remittanceInformation,
      loggedUser: {
        identification: values.taxId,
        type: values.taxId.length <= 11 ? "0" : "1",
      },
      creditorAccount: {
        personType: "LEGAL_ENTITY",
        cpfCnpj: accountSelected.taxId, // => TaxId cliente
        name: accountSelected.name, // => Client Name
        ispb: accountSelected.taxId.slice(0, 8), // => Primeiros 8 digitos do taxId do cliente
        issuer: accountSelected.bank, // => bank
        number: accountSelected.branch, // => Branch
        accountType: "CACC",
      },
    });
  };

  return (
    <>
      <BaasFlex flexDirection="column" width="100%">
        <BaasPageHeader>Open Finance Test Suite</BaasPageHeader>
        <BaasContentGrid spacing={4}>
          <BaasGrid p="16px 0px" item>
            <BaasText variant="h6">PISP Side of the flow</BaasText>
          </BaasGrid>

          <BaasCardWithIcon title={"Mock E-Commerce"} img={StoreIcon} />

          <BaasGrid container spacing={3}>
            <BaasGrid item xs={12} md={8}>
              <BaasText variant="body1" p="18px 0px">
                This page is part of BTG's BaaS Open Banking Mock interface. It
                aims to exemplify to clients/developers the implementation flow
                for these features.
              </BaasText>
            </BaasGrid>
          </BaasGrid>

          {/* Logo display */}

          <BaasGrid container spacing={3}>
            <BaasGrid item xs={12} md={10}>
              <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
                {state.selectedBank ? (
                  <div style={{ textAlign: "center", padding: "16px" }}>
                    <img
                      src={state.selectedBank.logoUrl}
                      alt="Logo"
                      width={"100px"}
                      height={"100px"}
                    />
                  </div>
                ) : null}

                {!state.selectedBank ? (
                  <BaasGrid item xs={12}>
                    {state.bankOptions.length > 0 ? (
                      <Formik initialValues={{}} onSubmit={() => searchBank()}>
                        {({ values, ...props }) => (
                          <Form>
                            <BaasTextField
                              fullWidth
                              name="bank"
                              label="Select bank"
                              onChange={(e) =>
                                setState({
                                  ...state,
                                  selectedBank: e.target.value,
                                })
                              }
                              select
                            >
                              {state.bankOptions.map((bank) => {
                                if (bank) {
                                  return (
                                    <BaasMenuItem value={bank}>
                                      {bank.displayName}
                                    </BaasMenuItem>
                                  );
                                }
                              })}
                            </BaasTextField>
                          </Form>
                        )}
                      </Formik>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <CircularProgress color="inherit" />
                        <BaasText textAlign="center" variant="h6">
                          Loading...
                        </BaasText>
                      </div>
                    )}
                  </BaasGrid>
                ) : (
                  <BaasGrid item xs={12}>
                    {warningOpen ? (
                      <Alert
                        onClose={() => setWarningOpen(false)}
                        severity="warning"
                        style={{ margin: "8px 0" }}
                      >
                        The same Tax Id will be used to verify the login in the
                        TPP - Bank Login screen.
                      </Alert>
                    ) : null}
                    <BaasText variant="subtitle1">
                      Please confirm the requestors Tax Id.
                    </BaasText>

                    <Formik initialValues={{}} onSubmit={onSubmit}>
                      {({ values, ...props }) => (
                        <Form>
                          <BaasTextField
                            fullWidth
                            name="taxId"
                            label="Tax Id"
                            type="text"
                            required
                          />
                          <BaasButton
                            type="submit"
                            variant="contained"
                            backgroundColor="black"
                            loading={isSubmitting}
                          >
                            Confirm
                          </BaasButton>
                        </Form>
                      )}
                    </Formik>
                  </BaasGrid>
                )}
              </BaasGrid>
            </BaasGrid>
          </BaasGrid>
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
}
