import { IconButton } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { useTheme } from "@mui/system";
import BaasTooltip from "../Tooltip/Tooltip";
const IconButtonStyle = styled.div`
  button {
    color: ${(props) => props.color || props.theme.palette.grey[100]};
  }
`;

const BaasIconButton = ({ color, children, tooltip, ...props }) => {
  const theme = useTheme();

  return (
    <IconButtonStyle color={color} theme={theme}>
      {tooltip ? (
        <BaasTooltip title={tooltip} placement="top" arrow>
          <IconButton {...props}>{children}</IconButton>
        </BaasTooltip>
      ) : (
        <IconButton {...props}>{children}</IconButton>
      )}
    </IconButtonStyle>
  );
};

export default BaasIconButton;
