import BaasRow from "components/atoms/Grid/Row";
import BaasGrid from "components/atoms/Grid/Grid";
import React, { useCallback, useMemo, useState } from "react";
import BaasTextField from "components/atoms/TextField/TextField";
import { Form, Formik } from "formik";
import BaasButton from "components/atoms/Button/Button";
import { useTheme } from "@mui/system";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import BaasText from "components/atoms/Text/BaasText";
import { mutationPostPaymentPaymentSlipAccountById } from "api/transaction-api";
import { useMutation } from "react-query";
import { useAccount } from "providers/account";
import { useOtp } from "providers/oneTimePassword";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import guidGenerator from "services/guidGenerator";
import BaasTransactionSuccess from "components/templates/TransactionSuccess/TransactionSuccess";
import BaasDatePicker from "components/atoms/DatePicker/DatePicker";
import { toastMessages } from "constants/toastMessages";
import { toast } from "react-toastify";
import BaasTextFieldCurrency from "components/molecules/TextFieldCurrency/TextFieldCurrency";
import * as yup from "yup";
import moment from "moment";

const DashboardPaymentslip = () => {
  const theme = useTheme();
  const { accountSelected } = useAccount();
  const { setOpen, isOtpRequired } = useOtp();
  const [success, setSuccess] = useState({ state: false, pactualId: "" });

  const initialValues = useMemo(() => {
    return {};
  }, []);

  const validationSchema = yup.object().shape({
    dueDate: yup.date().min(moment(), "erro"),
  });

  const { mutate: mutateCashOut } = useMutation((e) =>
    mutationPostPaymentPaymentSlipAccountById(
      { accountId: accountSelected.accountId },
      e
    )
  );

  const onSubmit = useCallback(
    (values, formik) => {
      mutateCashOut(
        {
          otp: values.otp || undefined,

          clientRequestId: guidGenerator(),
          amount: values.amount * 100,
          dueDate: moment(values.dueDate).format("YYYY-MM-DD"),
          description: values.description,
        },
        {
          onSuccess: (data) => {
            toast.success(toastMessages.requestSuccess);
            formik.resetForm();
            setOpen(false);
            setSuccess({ pactualId: data.body.pactualId, state: true });
          },
          onError: () => {
            toast.error(toastMessages.requestError);
          },
          onSettled: () => {
            formik.setSubmitting(false);
          },
        }
      );
    },
    [mutateCashOut, setOpen]
  );

  return (
    <>
      <BaasFlex flex={1} flexDirection="column">
        <BaasPageHeader>Transactions</BaasPageHeader>

        <BaasContentGrid spacing={4}>
          <Formik
            initialValues={initialValues}
            onSubmit={isOtpRequired ? () => setOpen(true) : onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, isSubmitting, ...props }) => (
              <Form>
                <BaasOtpModal
                  onConfirm={() => onSubmit(values, props)}
                  {...props}
                />
                <BaasGrid item spacing={1}>
                  <BaasRow>
                    <BaasText variant="h6">Payment slip cash in</BaasText>
                    <BaasFlex justifyContent="space-between">
                      <BaasText variant="caption">
                        {`/tr/payment/payment-slip/account/{accountId}`}
                      </BaasText>
                      <BaasText variant="caption">
                        Webhook entity: <strong>Payment slip</strong>
                      </BaasText>
                    </BaasFlex>
                  </BaasRow>
                </BaasGrid>

                {!success.state ? (
                  <BaasRow>
                    <BaasGrid
                      p="16px"
                      sx={{ background: theme.palette.grey[100] }}
                    >
                      <BaasText variant="body2">
                        This method simulates a Payment Slip Cash In impacting
                        your account. Known in Brazil as a "Boleto".
                      </BaasText>
                      <BaasRow container column={12} spacing={3}>
                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextFieldCurrency
                            fullWidth
                            required
                            name="amount"
                            label="Amount"
                          />
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            required
                            name="description"
                            label="Description"
                          />
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasDatePicker
                            fullWidth
                            required
                            name="dueDate"
                            label="Due date"
                            minDate={moment()}
                          />
                        </BaasGrid>
                      </BaasRow>
                      <BaasRow
                        container
                        sx={{ justifyContent: "space-between" }}
                        flexDirection="row"
                      >
                        <BaasGrid p={0} item>
                          <BaasButton
                            type="submit"
                            size="medium"
                            variant="contained"
                            backgroundColor="black"
                            loading={isSubmitting}
                          >
                            Transfer
                          </BaasButton>
                        </BaasGrid>
                      </BaasRow>
                    </BaasGrid>
                  </BaasRow>
                ) : (
                  <>
                    <BaasTransactionSuccess pactualId={success.pactualId} />
                  </>
                )}
              </Form>
            )}
          </Formik>
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
};

export default DashboardPaymentslip;
