import axios from "axios";
import { baasUrlFormatter } from "helpers/baasApiHelpers";

export const baasApiCallDev = async (
  method,
  endpoint,
  payload = false,
  otp,
  noError = false,
  stringfy
) => {
  const otpSelect = () => {
    if (payload.otp) {
      return payload.otp;
    } else if (otp) {
      return otp;
    } else {
      return null;
    }
  };

  console.log(otpSelect());

  const response = await axios({
    url: baasUrlFormatter(endpoint),
    method: method,
    data: payload,
    headers: {
      ...headers(otpSelect()),
    },
  })
    .then((response) => {
      // handle success
      if (response.status >= 200 && response.status < 300) return response.data;
    })
    .catch((response) => {
      // handle error
      // throw error.response.data;
      throw response;
    });

  return response;
};

// =====================================================

const headers = (otp) => {
  if (otp !== null)
    return {
      "Content-Type": "application/json",
      "Request-From": "Portal",
      Authorization: "Bearer " + sessionStorage.getItem("BAAS_ACCESS_TOKEN"),
      [sessionStorage.getItem(
        "BAAS_HEADER_TO_SESSION_TOKEN"
      )]: sessionStorage.getItem("BAAS_SESSION_TOKEN"),
      [sessionStorage.getItem("BAAS_HEADER_TO_TRANSACTION_CODE")]: otp,
    };

  return {
    "Content-Type": "application/json",
    "Request-From": "Portal",
    Authorization: "Bearer " + sessionStorage.getItem("BAAS_ACCESS_TOKEN"),
    [sessionStorage.getItem(
      "BAAS_HEADER_TO_SESSION_TOKEN"
    )]: sessionStorage.getItem("BAAS_SESSION_TOKEN"),
  };
};
