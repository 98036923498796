import React, { memo } from "react";
import { QueryCache, QueryClient, QueryClientProvider } from "react-query";

const ReactQueryProvider = memo(({ children }) => {
  const cache = new QueryCache();

  const defaultOptions = {
    queries: {
      suspense: false,
      refetchOnWindowFocus: false,
    },
  };

  const client = new QueryClient({ cache, defaultOptions });

  return <QueryClientProvider client={client}>{children}</QueryClientProvider>;
});
export default ReactQueryProvider;
