import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { Box, useTheme } from "@mui/system";
import {
  mutationPostPixSandboxReversal,
  queryGetPaymentByEndToEndId,
} from "api/pix-api";
import BaasBox from "components/atoms/Box/Box";
import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import FormActions from "components/molecules/FormActions";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasTextFieldCurrency from "components/molecules/TextFieldCurrency/TextFieldCurrency";
import FaqSection from "components/organism/FaqSection/FaqSection";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import BaasTransactionSuccess from "components/templates/TransactionSuccess/TransactionSuccess";
import { Form, Formik } from "formik";
import { useCustomMutation } from "helpers/useCustomMutation";
import { useCustomQuery } from "helpers/useCustomQuery";
import React, { useCallback, useState } from "react";
import { toast } from "react-toastify";
import guidGenerator from "services/guidGenerator";
import * as yup from "yup";

const PixTestSuitReversal = () => {
  const theme = useTheme();
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState();

  const [inputValue, setInputValue] = useState("");

  const handleChange = (value) => {
    setInputValue(value);
  };

  const {
    fetch: fetchTransaction,
    data: transactionData,
    loading: loadingTransaction,
  } = useCustomQuery(queryGetPaymentByEndToEndId);

  const handleLoadTransaction = () => {
    if (inputValue) {
      fetchTransaction({
        values: inputValue,
        objectPath: "body",
        objectFallback: {},
        onError: () => {
          setData(undefined);
        },
        onCompleted: (response) => setData(response),
      });
    }
  };

  const transactionValues = [
    { label: "Transaction Amount", value: data?.body?.amount },
    { label: "End to End Id", value: data?.body?.endToEndId },
    { label: "Payment Type", value: data?.body?.paymentType },
    { label: "Initiation Type", value: data?.body?.initiationType },
    { label: "Credit party", value: data?.body?.creditParty.name },
    { label: "Debit party", value: data?.body?.debitParty.name },
  ];

  const { mutate, isSubmitting } = useCustomMutation(
    mutationPostPixSandboxReversal
  );

  const onSubmit = useCallback(
    (values) => {
      mutate(
        {
          endToEndId: data?.body?.endToEndId,
          aditionalInformation: values.aditionalInformation,
          amount: values.amount,
          nature: values.nature,
          reason: values.reason,
          reversalDescription: values.reversalDescription,
          clientRequestId: guidGenerator(),
        },
        (response) => {
          setSuccess(response.body.pactualId);
          toast.success("Please, Check your PIX Reversal Webhook.");
        }
      );
    },
    [mutate, data?.body?.endToEndId]
  );

  return (
    <BaasFlex flex={1} flexDirection="column">
      <BaasPageHeader>Pix</BaasPageHeader>
      <BaasContentGrid spacing={4}>
        <BaasGrid item spacing={1}>
          <BaasRow>
            <BaasText variant="h6">Pix Test Suit Reversal In</BaasText>
          </BaasRow>
        </BaasGrid>
        {!success ? (
          <BaasGrid sx={{ background: theme.palette.grey[100] }} p={2}>
            <BaasText variant="body2" fontWeight="bold" color="textSecondary">
              This test suite simulates the end step of the Pix Reversal flow.
              For that to happen successfully, first some steps must have
              occured: <br />
              <br />
              1) Generate an E2Eid <br />
              2) Start a Pix Payment <br /> 3) Confirm Pix Payment <br />
              <br />
              Once all the steps above are complete, load the Pix Out
              transaction E2Eid and click complete to finish Reversal request.
              In case of doubts, check our tips on the bottom of the page. Each
              option will redirect to the described method on our documentation.
            </BaasText>
            <BaasGrid item md={12}>
              <Box mt={2}>
                <TextField
                  autoFocus
                  name="input"
                  margin="dense"
                  id="name"
                  label={"Ent to End Id"}
                  type="text"
                  fullWidth
                  variant="standard"
                  onChange={(e) => handleChange(e.target.value)}
                />
              </Box>
            </BaasGrid>
            <BaasGrid item md={12}>
              <Box mt={2}>
                <BaasButton
                  loading={loadingTransaction || isSubmitting}
                  variant="outlined"
                  disabled={!inputValue}
                  onClick={() => handleLoadTransaction()}
                >
                  Load PIX Transaction
                </BaasButton>
              </Box>
            </BaasGrid>
            {loadingTransaction ? (
              <>
                <BaasGrid item xs={12} sm={12}>
                  <BaasSkeleton width="100%" height="65px" />
                </BaasGrid>
                <BaasGrid item xs={12} sm={12}>
                  <BaasSkeleton width="100%" height="65px" />
                </BaasGrid>
                <BaasGrid item xs={12} sm={12}>
                  <BaasSkeleton width="100%" height="65px" />
                </BaasGrid>
                <BaasGrid item xs={12} sm={12}>
                  <BaasSkeleton width="100%" height="65px" />
                </BaasGrid>
              </>
            ) : (
              <>
                {data && data?.body ? (
                  <Box mt={3}>
                    <Typography
                      color={theme.palette.grey[600]}
                      fontWeight="bold"
                    >
                      Transaction Details
                    </Typography>
                    <BaasGrid container mt={2} mb={2}>
                      {transactionValues.map((row) => (
                        <BaasGrid item xs={12} sm={6} md={4} key={row.label}>
                          <Box mt={2}>
                            <BaasInfoDisplay
                              fullWidth
                              label={row.label}
                              value={row.value || "Not available"}
                            />
                          </Box>
                        </BaasGrid>
                      ))}
                    </BaasGrid>
                    <Formik
                      initialValues={{
                        reason: "BE08",
                        amount: data?.body?.amount,
                        reversalDescription: "",
                        aditionalInformation: "",
                        nature: "ORIGINAL",
                      }}
                      onSubmit={onSubmit}
                      validationSchema={yup.object().shape({
                        amount: yup
                          .number()
                          .max(
                            transactionData.body?.amount,
                            "Reversal amount cannot be greater than transaction amount."
                          ),
                      })}
                    >
                      {({
                        values,
                        errors,
                        isValid,
                        setFieldValue,
                        resetForm,
                        ...props
                      }) => (
                        <Form>
                          <BaasRow>
                            <Typography
                              color={theme.palette.grey[600]}
                              fontWeight="bold"
                            >
                              Reversal Request
                            </Typography>
                            <BaasGrid
                              sx={{ background: theme.palette.grey[100] }}
                            >
                              <BaasRow container column={12} spacing={3}>
                                <BaasGrid item xs={12} md={6}>
                                  <BaasTextFieldCurrency
                                    fullWidth
                                    required
                                    name="amount"
                                    label="Amount"
                                    errors={errors}
                                  />
                                </BaasGrid>
                                <BaasGrid item xs={12} md={6}>
                                  <BaasTextField
                                    fullWidth
                                    name="reason"
                                    label="Reason"
                                    required
                                    select
                                  >
                                    <BaasMenuItem value="BE08">
                                      BE08 - BankError
                                    </BaasMenuItem>
                                    <BaasMenuItem value="FR01">
                                      FR01 - Fraud
                                    </BaasMenuItem>
                                    <BaasMenuItem value="MD06">
                                      MD06 - RefundRequestByEndCustomer
                                    </BaasMenuItem>
                                    <BaasMenuItem value="SL02">
                                      SL02 -
                                      SpecificServiceOfferedByCreditorAgent
                                    </BaasMenuItem>
                                  </BaasTextField>
                                </BaasGrid>
                                <BaasGrid item xs={12} md={6}>
                                  <BaasTextField
                                    fullWidth
                                    name="nature"
                                    label="Nature"
                                    select
                                  >
                                    <BaasMenuItem value="WITHDRAWAL">
                                      WITHDRAWAL
                                    </BaasMenuItem>
                                    <BaasMenuItem value="ORIGINAL">
                                      ORIGINAL
                                    </BaasMenuItem>
                                  </BaasTextField>
                                </BaasGrid>
                                <BaasGrid item xs={12} md={6}>
                                  <BaasTextField
                                    fullWidth
                                    name="reversalDescription"
                                    label="Reversal Description"
                                  />
                                </BaasGrid>
                                <BaasGrid item xs={12} md={6}>
                                  <BaasTextField
                                    fullWidth
                                    name="aditionalInformation"
                                    label="Aditional Information"
                                  />
                                </BaasGrid>
                              </BaasRow>
                              <FormActions
                                onCancel={resetForm}
                                submitLabel="Create"
                                submitDisabled={!isValid || !values.amount}
                                isSubmitting={isSubmitting}
                              />
                            </BaasGrid>
                          </BaasRow>
                        </Form>
                      )}
                    </Formik>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    width="100%"
                    justifyContent="center"
                    mt={2}
                  >
                    <Box mr={2}>
                      <HourglassEmptyIcon color="error" />
                    </Box>
                    <Typography color={"error"} fontWeight="bold">
                      No transaction data available, please load a valid End to
                      End from a PIX OUT transaction.
                    </Typography>
                  </Box>
                )}
              </>
            )}
            <BaasBox mt={2}>
              <FaqSection
                messages={[
                  {
                    title: "How to generate an E2Eid",
                    description:
                      "Read the docs about the creation of a new EndToEndId to use on DICT Get and Payment endpoints.",
                    externalLink:
                      "https://developer.btgpactual.com/docpi#operation/pix-payment-end-to-end",
                    linkLabel: "Read Documentation",
                  },
                  {
                    title: "How to start a Pix Payment",
                    description:
                      "Read the docs about Pix payment using a key as the destination.",
                    externalLink:
                      "https://developer.btgpactual.com/docpi#operation/pix-payment-key",
                    linkLabel: "Read Documentation",
                  },
                  {
                    title: "How to confirm a Pix Payment",
                    description:
                      "Read the docs about Pix payment started using a key",
                    externalLink:
                      "https://developer.btgpactual.com/docpi#operation/pix-payment-key-confirm",
                    linkLabel: "Read Documentation",
                  },
                  {
                    title: "Pix Reversal Documentation.",
                    description: "Read the docs about the PIX Reversal.",
                    externalLink:
                      "https://developer.btgpactual.com/docpi#tag/PixReversal",
                    linkLabel: "Read Documentation",
                  },
                ]}
              />
            </BaasBox>
          </BaasGrid>
        ) : (
          <BaasTransactionSuccess
            pactualId={success}
            onClick={() => {
              setSuccess(undefined);
              setData(undefined);
            }}
          />
        )}
      </BaasContentGrid>
    </BaasFlex>
  );
};

export default PixTestSuitReversal;
