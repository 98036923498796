const { default: styled } = require("@emotion/styled");
const { Box } = require("@mui/material");

export const BoxStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 500px;
  max-width: 700px;

  max-height: 80vh;
  background-color: ${(props) => props.theme.palette.grey[200]};

  box-shadow: 24;
  padding: 4px;
  border-radius: 4px;
  overflow: hidden;
  overflow-y: scroll;

  :focus-visible {
    outline: none;
  }
`;

export const JsonStyled = styled(Box)`
  border-radius: 4px;
  background: ${(props) => props.theme.palette.grey[100]};
  max-height: 400px;
  overflow-y: scroll;
  border-radius: 4px;
  padding: 8px;
`;
