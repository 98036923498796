import { useTheme } from "@mui/system";
import React from "react";
import styled from "styled-components";
import BaasButton from "../Button/Button";

const ButtonRoundStyle = styled.div`
  & .MuiButton-root {
    border-radius: 50px;
  }
`;

const BaasButtonRound = ({
  disabled = false,
  variant,
  backgroundColor,
  children,
  ...props
}) => {
  const theme = useTheme();
  return (
    <ButtonRoundStyle disabled={disabled} theme={theme}>
      <BaasButton
        variant={variant}
        backgroundColor={backgroundColor}
        {...props}
      >
        {children}
      </BaasButton>
    </ButtonRoundStyle>
  );
};

export default BaasButtonRound;
