import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { useTheme } from "@mui/system";
import { useField, useFormikContext } from "formik";
import DatePicker from "@mui/lab/DatePicker";
import BaasText from "../Text/BaasText";
import { IconButton, TextField } from "@mui/material";
import moment from "moment";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
const DatePickerStyle = styled.div`
  margin: 8px 0px;

  & .MuiFilledInput-root {
    color: white;
    border-color: red;
    border-radius: 12px;
    background-color: ${(props) => props.theme.palette.grey[200]};
  }
  & .Mui-focused .MuiFilledInput-notchedOutline {
    border-style: solid;
  }
  & .MuiInputBase-root .MuiFilledInput-root:before {
    border-style: none;
  }
`;

const BaasDatePicker = ({
  name,
  variant = "standard",
  type = "text",
  customOnChange,
  clearable = false,
  ...props
}) => {
  const theme = useTheme();

  const [{ value }, { error }, { setValue }] = useField(name);

  const { submitCount } = useFormikContext();

  const showError = useMemo(() => !!error && submitCount > 0, [
    submitCount,
    error,
  ]);

  const helperText = useMemo(() => {
    if (showError) {
      return <BaasText variant="body2">{error}</BaasText>;
    }

    return null;
  }, [showError, error]);

  const onChange = useCallback(
    (event) => {
      if (customOnChange) {
        customOnChange(moment(event).format());
      }
      setValue(moment(event).format() || null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  const onClear = useCallback(() => {
    setValue(null);
  }, [setValue]);

  return (
    <DatePickerStyle theme={theme}>
      <DatePicker
        sx={{ "& .MuiFilledInput-root:before": { borderStyle: "none" } }}
        variant={variant}
        name={name}
        error={showError && error}
        helperText={helperText}
        onChange={onChange}
        value={value}
        type={type}
        renderInput={(params) => (
          <div
            style={{
              position: "relative",
              display: "inline-block",
              width: "100%",
            }}
          >
            <TextField variant={variant} fullWidth {...params} />
            {value && clearable && (
              <IconButton
                style={{
                  position: "absolute",
                  top: ".5rem",
                  margin: "auto",
                  right: "2rem",
                }}
                onClick={onClear}
              >
                <HighlightOffIcon />
              </IconButton>
            )}
          </div>
        )}
        {...props}
      />
    </DatePickerStyle>
  );
};

export default BaasDatePicker;
