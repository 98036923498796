import { mutationCreateQrCodeImmediate } from "api/baasTester/qrTester-api";
import BaasBox from "components/atoms/Box/Box";
import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasTextFieldCurrency from "components/molecules/TextFieldCurrency/TextFieldCurrency";
import FaqSection from "components/organism/FaqSection/FaqSection";
import { Form, Formik } from "formik";
import { useCustomMutation } from "helpers/useCustomMutation";
import React, { useCallback, useMemo, useState } from "react";
import * as yup from "yup";

const ImmediateEmvPanel = ({ setSuccess }) => {
  const [flowType, setFlowType] = useState("TRANSFER");
  const { mutate, isSubmitting } = useCustomMutation(
    mutationCreateQrCodeImmediate
  );

  const validationSchema = yup.object().shape({
    amount: yup
      .number()
      .min(0.01, "Minimum value is R$0.01")
      .max(10, "Maximum value is R$10.00")
      .required("Amount is Required"),
    debtorTaxId: yup
      .string()
      .min(11, "Tax Id must have more than 11 characters")
      .max(14, "Tax Id must have less than 14 characters")
      .required("Tax Id is required"),
  });

  const validationSchemaWithChange = yup.object().shape({
    amount: yup
      .number()
      .min(0.01, "Minimum value is R$0.01")
      .max(10, "Maximum value is R$10.00")
      .required("Amount is Required"),
    debtorTaxId: yup
      .string()
      .min(11, "Tax Id must have more than 11 characters")
      .max(14, "Tax Id must have less than 14 characters")
      .required("Tax Id is required"),
    amountChange: yup
      .number()
      .min(0.01, "Minimum value is R$0.01")
      .max(10, "Maximum value is R$10.00")
      .required("Change amount is Required"),
  });

  const initialValues = useMemo(() => {
    return {
      amount: "",
      clientRequestId: "",
      debtorTaxId: "",
      debtorName: "",
      amountChange: "",
    };
  }, []);

  const onSubmit = useCallback(
    (values, resetForm) => {
      let amountValues = {
        original: values.amount.toFixed(2),
      };
      if (flowType === "CHANGE") {
        amountValues = {
          original: values.amount.toFixed(2),
          change: {
            vldnAmount: values.amountChange.toFixed(2),
            vlcpAmount: values.amount.toFixed(2),
          },
        };
      }
      if (flowType === "WITHDRAWAL") {
        amountValues = {
          original: "0.00",
          withdrawal: {
            vldnAmount: values.amount.toFixed(2),
          },
        };
      }
      mutate(
        {
          amount: {
            ...amountValues,
          },
          debtor: {
            cpf: values.debtorTaxId.length < 14 ? values.debtorTaxId : null,
            cnpj: values.debtorTaxId.length >= 14 ? values.debtorTaxId : null,
            name: values.debtorName,
          },
        },
        (data) => {
          resetForm();
          setSuccess(data.body?.body?.loc?.body?.emv);
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [flowType]
  );

  return (
    <BaasFlex flexDirection="column" width="100%">
      <BaasRow>
        <BaasText variant="h6">Immediate Collection</BaasText>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { resetForm }) => {
            onSubmit(values, resetForm);
          }}
          validationSchema={
            flowType === "CHANGE"
              ? validationSchemaWithChange
              : validationSchema
          }
        >
          {({ values, setFieldValue, isValid, errors, ...props }) => (
            <Form>
              <>
                <BaasText variant="body2">
                  Create an immediate collection passing location informations
                </BaasText>
                <BaasRow container column={12} spacing={2}>
                  <BaasGrid item xs={12} md={6}>
                    <BaasTextField
                      fullWidth
                      name="flowType"
                      label="Immediate Collection Flow"
                      required
                      defaultValue={flowType}
                      onChange={(e) => setFlowType(e.target.value)}
                      select
                    >
                      <BaasMenuItem value="TRANSFER">TRANSFER</BaasMenuItem>
                      <BaasMenuItem value="CHANGE">CHANGE</BaasMenuItem>
                      <BaasMenuItem value="WITHDRAWAL">WITHDRAWAL</BaasMenuItem>
                    </BaasTextField>
                  </BaasGrid>
                  <BaasGrid item xs={12} md={6}>
                    <BaasTextFieldCurrency
                      fullWidth
                      name="amount"
                      label="Amount"
                      required
                      errors={errors}
                    />
                  </BaasGrid>

                  <BaasGrid item xs={12} md={6}>
                    <BaasTextField
                      fullWidth
                      name="debtorName"
                      label="Debtor Name"
                      required
                    />
                  </BaasGrid>
                  <BaasGrid item xs={12} md={6}>
                    <BaasTextField
                      fullWidth
                      name="debtorTaxId"
                      label="Debtor Tax Id"
                      required
                      displayError={true}
                    />
                  </BaasGrid>
                </BaasRow>
                {flowType === "CHANGE" && (
                  <BaasGrid item xs={12}>
                    <BaasTextFieldCurrency
                      fullWidth
                      name="amountChange"
                      label="Amount Change"
                      required
                      errors={errors}
                    />
                  </BaasGrid>
                )}
                <BaasGrid p={0} item>
                  <BaasButton
                    type="submit"
                    size="medium"
                    variant="contained"
                    backgroundColor="primary"
                    loading={isSubmitting}
                    disabled={!isValid}
                  >
                    Create
                  </BaasButton>
                </BaasGrid>
              </>
            </Form>
          )}
        </Formik>
      </BaasRow>
      <BaasBox mt={2}>
        <FaqSection
          messages={[
            {
              title: "How to create an immediate collection",
              description:
                "Read the docs about the creation of an immediate collection passing location informations",
              externalLink:
                "https://developer.btgpactual.com/docpi#operation/post-pix-collection-location",
              linkLabel: "Read Documentation",
            },
          ]}
        />
      </BaasBox>
    </BaasFlex>
  );
};

export default ImmediateEmvPanel;
