import {
  mutationPostAuthenticationUser,
  mutationPostTokenRefresh,
} from "api/security-api";
import BaasLoading from "components/molecules/Loading/Loading";
import redirectDashboardValidation from "helpers/redirectDashboardValidation";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import {
  storeProfileInSession,
  storeRefreshTokenInSession,
  recoverProfileFromSession,
} from "services/refreshToken";
import Endpoints from "constants/endpoints";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const queryClient = useQueryClient();
  const [tokenTimer, setTokenTimer] = useState(960000);
  const [originToRedirect, setOriginToRedirect] = useState();

  const history = useHistory();

  useEffect(() => {
    if (
      history.location.pathname.includes("/mock-obk/login") &&
      Endpoints.Environment === "UAT"
    ) {
      const regexUUIDV4 = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
      const searchParams = history.location.search.split("?id=");
      if (regexUUIDV4.test(searchParams[1])) {
        setOriginToRedirect(
          `${history.location.pathname}?id=${searchParams[1]}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const profile = recoverProfileFromSession();
    if (profile) {
      refreshToken();
      setAuthenticated(true);
      if (redirectDashboardValidation(history.location.pathname)) {
        history.push(history.location);
      }
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (authenticated) {
      const interval = setInterval(() => {
        refreshToken();
      }, tokenTimer);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticated]);

  const { mutate: handleLogin } = useMutation(
    async (a) => await mutationPostAuthenticationUser(a),
    {
      onSuccess: (data) => {
        storeProfileInSession(data);
        setTokenTimer(data.authToken.expires_in * 800);
        setAuthenticated(true);
        if (Boolean(originToRedirect)) {
          history.push(originToRedirect);
        } else {
          history.push("/dashboard");
        }
      },
      onError: (e) => {
        console.log("error");
      },
    }
  );

  const handleLogOut = () => {
    sessionStorage.clear();
    queryClient.clear();
    setAuthenticated(false);
    history.push("/login");
  };

  const { mutate: refreshToken } = useMutation(
    (a) => mutationPostTokenRefresh(a),
    {
      onSuccess: (data) => {
        setTokenTimer(data.expires_in * 800);
        storeRefreshTokenInSession(data);
      },
      onError: () => {
        handleLogOut();
      },
    }
  );

  if (loading) {
    return <BaasLoading />;
  }

  return (
    <AuthContext.Provider value={{ authenticated, handleLogin, handleLogOut }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  const {
    authenticated,
    setAuthenticated,
    handleLogin,
    handleLogOut,
  } = context;
  return { authenticated, setAuthenticated, handleLogin, handleLogOut };
};

export { AuthProvider, useAuth };
