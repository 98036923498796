import BaasText from "components/atoms/Text/BaasText";
import React, { useCallback, useState } from "react";
import { useTheme } from "@mui/system";
import BaasGrid from "components/atoms/Grid/Grid";
import { toast } from "react-toastify";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import BaasButton from "components/atoms/Button/Button";
import BaasDatePicker from "components/atoms/DatePicker/DatePicker";
import { queryGetEfxListByDateFromAndDateTo } from "api/efx-api";
import moment from "moment";
import EfxInterfaceDownloadList from "./EfxInterfaceDownloadList";

export default function EfxInterfaceDownload() {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  function validateDate(rangeDate) {
    if (moment(rangeDate.dateFrom).isAfter(rangeDate.dateTo)) {
      toast.error(toastMessages.efx.validateDate.error);
      return false;
    }
    return true;
  }

  const dataByRange = useCallback(async (rangeDate) => {
    const response = await queryGetEfxListByDateFromAndDateTo(rangeDate);
    if (response.body?.length > 0) {
      setData(response.body);
    } else if ("errors" in response) {
      response.errors.map((error) => {
        toast.error(error.message);
      });
      setData([]);
    } else {
      toast.error(toastMessages.search.searchEmpty);
    }
  }, []);

  const onSubmit = useCallback(
    async (values) => {
      setIsSubmitting(true);
      const rangeDate = {
        dateFrom: moment(values.dateFrom).format("YYYY-MM-DD"),
        dateTo: moment(values.dateTo).format("YYYY-MM-DD"),
      };
      if (validateDate(rangeDate)) dataByRange(rangeDate);
      setIsSubmitting(false);
    },
    [dataByRange]
  );

  return (
    <>
      <Formik initialValues={{}} onSubmit={onSubmit} enableReinitialize>
        {({ values, ...props }) => (
          <Form>
            <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
              <BaasText>
                List and download eFX files. Use date ranges to search available
                files.
              </BaasText>

              <BaasGrid container spacing={2}>
                <BaasGrid item xs={12} sm={6}>
                  <BaasDatePicker name="dateFrom" label="Date From" fullWidth />
                </BaasGrid>
                <BaasGrid item xs={12} sm={6}>
                  <BaasDatePicker name="dateTo" label="Date To" fullWidth />
                </BaasGrid>
              </BaasGrid>
              <BaasGrid item p="16px 0px">
                <BaasButton
                  variant="contained"
                  backgroundColor="black"
                  type="submit"
                  loading={isSubmitting}
                >
                  Search
                </BaasButton>
              </BaasGrid>
            </BaasGrid>
          </Form>
        )}
      </Formik>
      {data.length > 0 && (
        <EfxInterfaceDownloadList data={data} loading={isSubmitting} />
      )}
    </>
  );
}
