export const csvmaker = function (data) {
  let csvRows = [];

  let headers = [];
  data.forEach((element) => {
    const keys = Object.keys(element);
    keys.forEach((key) => {
      if (!headers.includes(key)) {
        headers.push(key);
      }
    });
  });
  csvRows.push(headers.join(","));

  data.forEach((element) => {
    let obj;
    headers.forEach((key) => {
      obj = {
        ...obj,
        [key]: element[key] || "N/A",
      };
    });
    const values = Object.values(obj).join(",");
    csvRows.push(values);
  });

  return csvRows.join("\n");
};

export const downloadCsvFile = function (data) {
  const blob = new Blob([data], { type: "text/csv" });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", "download.csv");
  a.click();
};

export const csvmakerParseObject = function (data) {
  let csvRows = [];

  let headers = [];
  data.forEach((element) => {
    const keys = Object.keys(element);
    keys.forEach((key) => {
      if (!headers.includes(key)) {
        headers.push(key);
      }
    });
  });
  csvRows.push(headers.join(","));

  data.forEach((element) => {
    let obj = {};
    headers.forEach((key) => {
      // Se o valor for um objeto, converta-o em JSON e envolva em aspas
      if (typeof element[key] === "object" && element[key] !== null) {
        obj[key] = `"${JSON.stringify(element[key]).replace(/"/g, '""')}"`; // Escapa aspas duplas
      } else {
        obj[key] = element[key] || "N/A"; // Preencha com "N/A" se não existir
      }
    });

    const values = Object.values(obj).join(",");
    csvRows.push(values);
  });

  return csvRows.join("\n");
};
