import { Skeleton } from "@mui/material";
import React from "react";
import styled from "styled-components";
const SkeletonStyle = styled.div``;
const BaasSkeleton = ({ children, ...props }) => {
  return (
    <SkeletonStyle>
      <Skeleton {...props}>{children}</Skeleton>
    </SkeletonStyle>
  );
};

export default BaasSkeleton;
