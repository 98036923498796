import React from "react";
import BaasText from "components/atoms/Text/BaasText";
import BaasAmbientIndicator from "components/molecules/AmbientIndicator/AmbientIndicator";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasMenu from "components/atoms/Menu/Menu";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import PersonIcon from "@mui/icons-material/Person";
import BaasIconButton from "components/atoms/IconButton/IconButton";
import { useAuth } from "providers/auth";
import { useAccount } from "providers/account";
import { currencyFormatter } from "helpers/currencyFormatter";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/private-theming";
import { ReactComponent as VisibilityOff } from "assets/icons/Visibility/Off.svg";
import { ReactComponent as VisibilityOn } from "assets/icons/Visibility/On.svg";
import BaasModalChangeAccount from "components/organism/ModalChangeAccount/ModalChangeAccount";
import { useEffect } from "react";
import { useQueryGetAccountInfo } from "api/security-api";
import { checkPermission } from "services/interfaceController";

const BaasPageHeader = ({ children, label }) => {
  const { handleLogOut } = useAuth();
  const { data } = useQueryGetAccountInfo();
  const theme = useTheme();
  const {
    accountSelected,
    setAccountSelected,
    balance,
    showBalance,
    handleShowBalance,
  } = useAccount();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openAccountModal, setOpenAccountModal] = React.useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    selectAccountOnRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const selectAccountOnRedirect = async () => {
    if (data) {
      const currentSelectedAccount = data.body.items.find(
        (acc) => acc.accountId === accountSelected.accountId
      );
      setAccountSelected(
        currentSelectedAccount
          ? {
              index: data.body.items.indexOf(currentSelectedAccount),
              ...currentSelectedAccount,
            }
          : { index: 0, ...data.body.items[0] }
      );
    }
  };

  const Logout = () => {
    setAccountSelected({
      index: 0,
      viewBalance: true,
    });
    handleLogOut();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cannotAccessBalance = checkPermission(["no-balance-v"]);

  return isMobile ? (
    <div style={{ height: "75px" }} />
  ) : (
    <>
      <BaasGrid
        display="flex"
        justifyContent="space-between"
        p="14px"
        backgroundColor="grey.300"
        sx={{ width: "100%" }}
      >
        <BaasGrid>
          <BaasText variant="h6">{children || label || ""}</BaasText>
        </BaasGrid>

        <BaasGrid display="flex" alignItems="center">
          <BaasMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <BaasMenuItem onClick={() => setOpenAccountModal(true)}>
              Change Selected Account
            </BaasMenuItem>
            <BaasMenuItem onClick={() => Logout()}>Logout</BaasMenuItem>
          </BaasMenu>

          <BaasIconButton
            variant="contained"
            size="small"
            backgroundColor="white"
            onClick={handleClick}
            sx={{ border: `1px solid ${theme.palette.grey[400]}` }}
          >
            <PersonIcon fontSize="medium" color="action" />
          </BaasIconButton>
          {cannotAccessBalance ? (
            <></>
          ) : (
            <>
              <BaasGrid
                p="0px 8px"
                onClick={() => setOpenAccountModal(true)}
                style={{ cursor: "pointer" }}
              >
                <BaasText variant="caption">
                  {showBalance
                    ? `R$ ${currencyFormatter(balance / 100)}`
                    : "********"}
                </BaasText>
                <BaasText variant="caption" align="right">
                  {"bank" in accountSelected
                    ? `${accountSelected.bank} | ${accountSelected.branch} | ${accountSelected.account}`
                    : `*** | ** | *********`}
                </BaasText>
              </BaasGrid>

              <BaasIconButton
                variant="text"
                backgroundColor="black"
                size="small"
                onClick={handleShowBalance}
              >
                {showBalance ? (
                  <VisibilityOff stroke={theme.palette.grey[800]} />
                ) : (
                  <VisibilityOn stroke={theme.palette.grey[800]} />
                )}
              </BaasIconButton>
            </>
          )}
          <BaasAmbientIndicator />
        </BaasGrid>
      </BaasGrid>

      <BaasModalChangeAccount
        state={openAccountModal}
        setState={setOpenAccountModal}
        redirect={false}
        title="Change account"
        description="Select the account you want to use."
      />
    </>
  );
};

export default BaasPageHeader;
