import React from "react";
import { Chart } from "react-google-charts";

export function BarChart(chartData) {
  const formatData = (items) => {
    const formattedItems = items.map((item) => [
      item.time,
      item.count,
      chartData.chartData?.color,
      null,
    ]);

    return [
      [
        "Element",
        "Count",
        { role: "style" },
        {
          sourceColumn: 0,
          role: "annotation",
          type: "string",
          calc: "stringify",
        },
      ],
      ...formattedItems,
    ];
  };
  const data = formatData(chartData.chartData.items);
  const options = {
    title: chartData.chartData?.title || "N/A",
    width: "100%",
    height: "100%",
    bar: { groupWidth: "40%" },
    legend: { position: "none" },
  };

  return (
    <Chart
      style={{ paddingTop: "0px" }}
      chartType="ColumnChart"
      width="100%"
      height="200px"
      data={data}
      options={options}
    />
  );
}
