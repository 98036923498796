import BaasButton from "components/atoms/Button/Button";
import BaasGrid from "components/atoms/Grid/Grid";
import { useTheme } from "@mui/system";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

export const StepsNavigator = ({
    handlePrevious = () => { },
    handleNext = () => { },
    nextDisabled,
    nextLabel = "NEXT STEP",
    previousLabel = "PREVIOUS STEP",
    previousDisabled,
}) => {
    const theme = useTheme();

    return (
        <BaasGrid item xs={12} md={12} mb={2} pt={2}
            width="100%"
            display="flex"
            justifyContent="space-between"
        >
            <BaasButton
                variant="text"
                color="primary"
                disabled={previousDisabled}
                size="large"
                onClick={handlePrevious}
                startIcon={<NavigateBeforeIcon />}
            >
                {previousLabel}
            </BaasButton>
            <BaasButton
                color="primary"
                disabled={nextDisabled}
                onClick={handleNext}
                size="large"
                variant="text"
                endIcon={<NavigateNextIcon />}
            >
                {nextLabel}
            </BaasButton>
        </BaasGrid >
    )
}