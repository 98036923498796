export const getAccountType = (markups) => {
  let accountType = "";

  if (
    (markups || []).includes("PIX-PI:true") ||
    (markups || []).includes("DICT-HUB:true")
  ) {
    accountType = "DICT Hub";
  }

  if ((markups || []).includes("PIX-MERCHANT:true")) {
    accountType = "Merchant";
  }
  return accountType;
};
