import React, { useMemo, useState } from "react";
import BaasModal from "components/atoms/Modal/Modal";
import Backdrop from "@mui/material/Backdrop";
import styled from "styled-components";
import { useTheme } from "@mui/private-theming";
import { Box } from "@mui/system";
import BaasText from "components/atoms/Text/BaasText";
import BaasButton from "components/atoms/Button/Button";
import { useHistory } from "react-router";
import BaasGrid from "components/atoms/Grid/Grid";
import { useAccount } from "providers/account";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import { useQueryGetAccountInfo } from "api/security-api";
import getSafe from "helpers/getSafe";
import BaasTextFieldDefault from "components/atoms/TextField/TextFieldDefault";
import PropTypes from "prop-types";
import { getAccountType } from "services/getAccountType";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const BoxStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  max-width: 600px;

  max-height: 75vh;
  background-color: ${(props) => props.theme.palette.grey[200]};

  box-shadow: 24;
  padding: 4px;
  border-radius: 4px;
  overflow: hidden;
  overflow-y: scroll;

  :focus-visible {
    outline: none;
  }
`;

export const BaasModalChangeAccount = ({
  setState,
  state,
  condition,
  title,
  description,
  disabledCondition,
  children,
  redirect = true,
  onSubmitCompleted,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { data, isLoading } = useQueryGetAccountInfo();
  const [field, setField] = useState();
  const { accountSelected, setAccountSelected } = useAccount();

  const accounts = useMemo(() => {
    if (condition && !isLoading) {
      return getSafe(data, "body.items", []).filter((account) =>
        condition(account)
      );
    } else if (isLoading) {
      return [];
    } else {
      return getSafe(data, "body.items", []);
    }
  }, [data, condition, isLoading]);

  const handleClose = () => {
    onSubmitCompleted?.();
    setState(false);
    if (redirect) history.push("/dashboard");
  };

  const handleChange = (event) => {
    setField(event.target.value);
  };

  const handleSubmit = () => {
    getSafe(data, "body.items", []).map((a, index) => {
      if (a.account === field.account) {
        setAccountSelected({ index, ...field });
      }
      onSubmitCompleted?.();
      return null;
    });

    setState(false);
  };

  return (
    <>
      <BaasModal
        aria-labelledby="transition-disclaimer-title"
        aria-describedby="transition-disclaimer-description"
        open={state}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <BoxStyled p={3} theme={theme} sx={{ maxWidth: "400px" }}>
          {!!title && (
            <BaasText variant="h6" sx={{ marginBottom: "16px" }}>
              {title}
            </BaasText>
          )}

          {!!description && (
            <BaasText sx={{ marginBottom: "16px" }}>{description}</BaasText>
          )}
          <BaasTextFieldDefault
            label="Account"
            name="account"
            variant="outlined"
            onChange={handleChange}
            sx={{ marginBottom: "16px" }}
            select
            fullWidth
          >
            {accounts.map((account) => {
              const accountType = getAccountType(account.markups)
                ? `(${getAccountType(account.markups)})`
                : "";
              return (
                <BaasMenuItem
                  value={account}
                  disabled={
                    disabledCondition ? disabledCondition(account) : false
                  }
                >
                  <BaasGrid
                    display="flex"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <BaasText
                      color={"grey.600"}
                    >{`${account.bank}/${account.branch}/${account.account} `}</BaasText>

                    <Box display="flex">
                      <BaasText color={"grey.600"}>{accountType}</BaasText>
                      {accountSelected?.accountId === account.accountId && (
                        <CheckCircleIcon
                          style={{
                            marginLeft: "8px",
                            color: theme.palette.success.main,
                          }}
                        />
                      )}
                    </Box>
                  </BaasGrid>
                </BaasMenuItem>
              );
            })}
          </BaasTextFieldDefault>
          {children}

          <BaasGrid container spacing={1}>
            <BaasGrid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
            >
              <BaasButton
                backgroundColor="black"
                variant="outlined"
                onClick={handleClose}
              >
                Cancel
              </BaasButton>

              <BaasButton
                backgroundColor="black"
                variant="contained"
                disabled={!field}
                onClick={handleSubmit}
              >
                Change account
              </BaasButton>
            </BaasGrid>
          </BaasGrid>
        </BoxStyled>
      </BaasModal>
    </>
  );
};

export default BaasModalChangeAccount;

BaasModalChangeAccount.propTypes = {
  state: PropTypes.bool,
  setState: PropTypes.func,
  condition: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
};
