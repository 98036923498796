import BaasText from "components/atoms/Text/BaasText";
import React, { useCallback, useState } from "react";
import { useTheme } from "@mui/system";
import { toast } from "react-toastify";
import { toastMessages } from "constants/toastMessages";
import { queryGetReceipt } from "api/receipt-api";
import { Form, Formik } from "formik";
import { useAccount } from "providers/account";
import { useOtp } from "providers/oneTimePassword";
import moment from "moment";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasButton from "components/atoms/Button/Button";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasCheckbox from "components/atoms/Checkbox/Checkbox";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import ConverterBase64ToPdf from "helpers/ConverterBase64ToPdf";
import StackCard from "components/atoms/StackCard/StackCard";

export default function ReceiptDownloadInterface() {
  const { accountSelected } = useAccount();
  const theme = useTheme();
  const { setOpen } = useOtp();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const mutateGetReceipt = useCallback(async (payload, otp) => {
    const response = await queryGetReceipt(payload, otp);
    if (!response.errors) {
      ConverterBase64ToPdf(
        `${payload.pactualId}-Receipt`,
        response,
        "application/pdf"
      );
      toast.success(toastMessages.requestSuccess);
    } else {
      if ("errors" in response) {
        response.errors.map((error) => toast.error(error.message));
      } else toast.error(toastMessages.requestError);
    }
    setIsSubmitting(false);
  }, []);

  const onSubmit = useCallback(
    (values) => {
      setIsSubmitting(true);
      mutateGetReceipt(
        {
          accountId: accountSelected.accountId,
          date: moment(values.date).format("YYYY-MM-DD"),
          pactualId: values.pactualId,
          templateType: values.templateType,
          transactionsTypes: values.transactionsTypes,
          hidePersonalInfo: values.hidePersonalInfo || false,
        },
        values.otp
      );
      setIsSubmitting(false);
    },
    [accountSelected.accountId, mutateGetReceipt]
  );

  return (
    <>
      <Formik
        initialValues={{}}
        onSubmit={() => setOpen(true)}
        enableReinitialize
      >
        {({ values, ...props }) => (
          <Form>
            <BaasOtpModal
              onConfirm={() => onSubmit(values, props)}
              {...props}
            />
            <StackCard>
              <BaasGrid>
                <BaasText p="0px 0px 16px">
                  Search and download Receipt PDF file by passing the
                  transactions pactualId.
                </BaasText>
                <BaasGrid container spacing={2}>
                  <BaasGrid item xs={12} sm={4}>
                    <BaasTextField
                      fullWidth
                      name="transactionsTypes"
                      label="Transactions Types"
                      required
                      select
                    >
                      <BaasMenuItem value="externalTransfer">
                        External Transfer
                      </BaasMenuItem>
                      <BaasMenuItem value="internalTransfer">
                        Internal Transfer
                      </BaasMenuItem>
                      <BaasMenuItem value="paymentSlipPay">
                        PaymentSlip Pay
                      </BaasMenuItem>
                      <BaasMenuItem value="paymentSlipReversed">
                        PaymentSlip Reversed
                      </BaasMenuItem>
                      <BaasMenuItem value="pixPayment">
                        Pix Payment
                      </BaasMenuItem>
                      <BaasMenuItem value="pixReversal">
                        Pix Reversal
                      </BaasMenuItem>
                    </BaasTextField>
                  </BaasGrid>
                  <BaasGrid item xs={12} sm={4}>
                    <BaasTextField
                      fullWidth
                      name="pactualId"
                      label="Pactual Id"
                      required
                    />
                  </BaasGrid>
                  <BaasGrid item xs={12} sm={4}>
                    <BaasTextField
                      fullWidth
                      name="templateType"
                      label="Language"
                      required
                      select
                    >
                      <BaasMenuItem value="0">English</BaasMenuItem>
                      <BaasMenuItem value="1">Portuguese</BaasMenuItem>
                    </BaasTextField>
                  </BaasGrid>
                  <BaasGrid item xs={12}>
                    <BaasCheckbox
                      name="hidePersonalInfo"
                      label="Hide Personal Info"
                      fullWidth
                    />
                  </BaasGrid>
                </BaasGrid>
                <BaasGrid item p="16px 0px">
                  <BaasButton
                    variant="contained"
                    backgroundColor="black"
                    type="submit"
                    loading={isSubmitting}
                  >
                    Download
                  </BaasButton>
                </BaasGrid>
              </BaasGrid>
            </StackCard>
          </Form>
        )}
      </Formik>
    </>
  );
}
