import { useTheme } from "@mui/system";
import { queryClaimRoutesSandbox, queryGetPixClaimById } from "api/pix-api";
import BaasBox from "components/atoms/Box/Box";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import BaasText from "components/atoms/Text/BaasText";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import DinamicForm from "components/organism/DinamicForm/DinamicForm";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { useCustomQuery } from "helpers/useCustomQuery";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import BaasButton from "components/atoms/Button/Button";
import ListIcon from "@mui/icons-material/List";
import { BackButton } from "components/molecules/BackButton/BackButton";

const PixTestSuitClaimDetails = () => {
  const theme = useTheme();
  const history = useHistory();
  const { claimId } = useParams();
  const [refetchClaim, setRefetchClaim] = useState(false);
  const [refetchRoutes, setRefetchRoutes] = useState(false);
  const [loadingGeneric, setLoadingGeneric] = useState();

  const {
    fetch: fetchClaim,
    data: claimData,
    loading: loadingClaim,
  } = useCustomQuery(queryGetPixClaimById);

  useEffect(() => {
    if (claimId) {
      fetchClaim({
        values: claimId,
        objectPath: "body",
        objectFallback: {},
      });
    }
  }, [fetchClaim, claimId, refetchClaim]);

  const {
    fetch: fetchRoutes,
    data: routesData,
    loading: loadingRoutes,
  } = useCustomQuery(queryClaimRoutesSandbox);

  useEffect(() => {
    fetchRoutes({
      values: claimId,
      objectPath: "body",
      onNotFoundError: () =>
        toast.warning("There is no actions available to this claim"),
    });
  }, [fetchRoutes, claimId, refetchRoutes]);

  const handleRefetchRoutes = () => {
    setRefetchClaim(!refetchClaim);
    setRefetchRoutes(!refetchRoutes);
  };

  const claimValues = [
    { label: "Claim Id", value: claimData?.id },
    { label: "Entry", value: claimData?.key },
    { label: "Entry type", value: claimData?.keyType },
    { label: "Status", value: claimData?.status },
    { label: "Type", value: claimData?.type },
  ];

  return (
    <BaasFlex flex={1} flexDirection="column">
      <BaasContentGrid spacing={4}>
        <BaasGrid item spacing={1}>
          <BaasRow>
            <BaasText variant="h6">Pix Test Suit Claim</BaasText>
          </BaasRow>
        </BaasGrid>
        <BaasGrid sx={{ background: theme.palette.grey[100] }} p="16px">
          <BackButton path="/pix-test-suit-claims-list" />
          <BaasText variant="body2" fontWeight="bold">
            Decide what action to take about the claim.
          </BaasText>
          <BaasRow>
            <BaasGrid sx={{ background: theme.palette.grey[100] }}>
              <BaasRow container column={12} spacing={3}>
                {loadingClaim ? (
                  <>
                    <BaasGrid item xs={12} sm={12}>
                      <BaasSkeleton width="100%" height="65px" />
                    </BaasGrid>
                    <BaasGrid item xs={12} sm={12}>
                      <BaasSkeleton width="100%" height="65px" />
                    </BaasGrid>
                    <BaasGrid item xs={12} sm={12}>
                      <BaasSkeleton width="100%" height="65px" />
                    </BaasGrid>
                    <BaasGrid item xs={12} sm={12}>
                      <BaasSkeleton width="100%" height="65px" />
                    </BaasGrid>
                  </>
                ) : (
                  claimData &&
                  claimValues.map((row) => (
                    <BaasGrid item xs={12} sm={6} key={row.label}>
                      <BaasInfoDisplay
                        fullWidth
                        label={row.label}
                        value={row.value || "Not available"}
                      />
                    </BaasGrid>
                  ))
                )}
              </BaasRow>
            </BaasGrid>
          </BaasRow>
          <BaasBox mb={3}>
            <BaasButton
              variant="outlined"
              onClick={() => history.push("/pix-test-suit-claims-list")}
              startIcon={<ListIcon />}
            >
              Go to Claims List
            </BaasButton>
          </BaasBox>
          <BaasText variant="body2" fontWeight="bold">
            Possible Actions
          </BaasText>
          {loadingRoutes ? (
            <BaasSkeleton width="100%" height="65px" />
          ) : routesData && routesData.length > 0 ? (
            routesData.map((route) => (
              <BaasBox
                sx={{ borderTop: "1px solid #CACACA" }}
                mt={2}
                key={route.message}
              >
                <DinamicForm
                  onComplete={handleRefetchRoutes}
                  route={route}
                  loading={loadingGeneric}
                  setLoading={setLoadingGeneric}
                />
              </BaasBox>
            ))
          ) : (
            <BaasText color="error" mt={1}>
              There is no actions available to this claim.
            </BaasText>
          )}
        </BaasGrid>
      </BaasContentGrid>
    </BaasFlex>
  );
};

export default PixTestSuitClaimDetails;
