import { Box, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router";

export const BackButton = ({ label, onBack, path }) => {
  const history = useHistory();

  return (
    <Box mb={2}>
      <Button
        color="primary"
        variant="text"
        startIcon={<ArrowBackIcon />}
        onClick={onBack ? onBack : () => history.push(path)}
      >
        Back
      </Button>
    </Box>
  );
};
