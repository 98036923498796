import BaasRow from "components/atoms/Grid/Row";
import BaasGrid from "components/atoms/Grid/Grid";
import React, { useCallback, useMemo, useState } from "react";
import BaasTextField from "components/atoms/TextField/TextField";
import { Form, Formik } from "formik";
import BaasButton from "components/atoms/Button/Button";
import { useTheme } from "@mui/system";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextFieldAccountTypeTo from "components/molecules/TextFieldAccountTypeTo/TextFieldAccountTypeTo";
import { useMutation } from "react-query";
import { useAccount } from "providers/account";
import { useOtp } from "providers/oneTimePassword";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import guidGenerator from "services/guidGenerator";
import BaasTransactionSuccess from "components/templates/TransactionSuccess/TransactionSuccess";
import { mutationPostTransferExternalOut } from "api/transaction-api";
import getSafe from "helpers/getSafe";
import { toastMessages } from "constants/toastMessages";
import { toast } from "react-toastify";
import * as yup from "yup";
import BaasTextFieldCurrency from "components/molecules/TextFieldCurrency/TextFieldCurrency";

const DashboardExternalTransferCashOut = () => {
  const theme = useTheme();
  const { accountSelected } = useAccount();
  const { setOpen, isOtpRequired } = useOtp();
  const [success, setSuccess] = useState({ state: false });

  const validationSchema = yup.object().shape({
    taxId: yup
      .string()
      .min(11, "Tax Id must be at least 11 characters")
      .required(),
    branch: yup.string().min(4, "Branch must be at least 4 numbers").required(),
    bank: yup.string().min(3, "Bank must be at least 3 numbers").required(),
  });

  const initialValues = useMemo(() => {
    return {};
  }, []);

  const { mutate: mutateCashOut } = useMutation((e) =>
    mutationPostTransferExternalOut(e)
  );

  const onSubmit = useCallback(
    (values, formik) => {
      mutateCashOut(
        {
          otp: values.otp || null,
          clientRequestId: guidGenerator(),
          branchFrom: accountSelected.branch,
          accountFrom: accountSelected.account,
          bankTo: values.bank,
          branchTo: values.branch,
          accountTo: values.account,
          taxIdTo: values.taxId,
          nameTo: values.name,
          amount: values.amount * 100,
          personTypeTo: getSafe(values, "taxId.length", "") === 11 ? "F" : "J",
          accountTypeTo: values.accountType,
        },
        {
          onSuccess: (data) => {
            toast.success(toastMessages.requestSuccess);
            formik.resetForm();
            setOpen(false);
            setSuccess({ pactualId: data.body.pactualId, state: true });
          },
          onError: () => {
            toast.error(toastMessages.requestError);
          },
          onSettled: () => {
            formik.setSubmitting(false);
          },
        }
      );
    },
    [mutateCashOut, accountSelected, setOpen]
  );

  return (
    <>
      <BaasFlex flex={1} flexDirection="column">
        <BaasPageHeader>Transactions</BaasPageHeader>

        <BaasContentGrid spacing={4}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={isOtpRequired ? () => setOpen(true) : onSubmit}
          >
            {({ values, isSubmitting, ...props }) => (
              <Form>
                <BaasOtpModal
                  onConfirm={() => onSubmit(values, props)}
                  {...props}
                />
                <BaasGrid item spacing={1}>
                  <BaasRow>
                    <BaasText variant="h6">External Transfer Cash out</BaasText>
                    <BaasFlex justifyContent="space-between">
                      <BaasText variant="caption">
                        /tr/transfer/external
                      </BaasText>
                      <BaasText variant="caption">
                        Webhook entity: <strong> External Tranfer</strong>
                      </BaasText>
                    </BaasFlex>
                  </BaasRow>
                </BaasGrid>
                {!success.state ? (
                  <BaasRow>
                    <BaasGrid
                      p="16px"
                      sx={{ background: theme.palette.grey[100] }}
                    >
                      <BaasText variant="body2">
                        Known in Brazil as TED, this methods simulates an
                        External Tranfer from your account to another.
                      </BaasText>

                      <BaasRow container column={12} spacing={3}>
                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextFieldCurrency
                            fullWidth
                            required
                            name="amount"
                            label="Amount to"
                          />
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            required
                            name="bank"
                            label="Bank to"
                            type="number"
                          />
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            required
                            name="branch"
                            label="Branch to"
                            type="number"
                          />
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            required
                            name="account"
                            label="Account to"
                            type="number"
                          />
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            required
                            name="name"
                            label="Name to"
                          />
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            required
                            name="taxId"
                            label="Tax Id to"
                            type="number"
                          />
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextFieldAccountTypeTo
                            fullWidth
                            required
                            name="accountTypeTo"
                            label="Accout type to"
                          />
                        </BaasGrid>
                      </BaasRow>
                      <BaasRow
                        container
                        sx={{ justifyContent: "space-between" }}
                        flexDirection="row"
                      >
                        <BaasGrid p={0} item>
                          <BaasButton
                            type="submit"
                            size="medium"
                            variant="contained"
                            backgroundColor="black"
                            loading={isSubmitting}
                          >
                            Transfer
                          </BaasButton>
                        </BaasGrid>
                      </BaasRow>
                    </BaasGrid>
                  </BaasRow>
                ) : (
                  <>
                    <BaasTransactionSuccess />
                  </>
                )}
              </Form>
            )}
          </Formik>
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
};

export default DashboardExternalTransferCashOut;
