import { Box } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Checkbox from "@mui/material/Checkbox";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasTextFieldCurrency from "components/molecules/TextFieldCurrency/TextFieldCurrency";
import React from "react";
import { abatementModalities } from "../constants/modalities";

const AbatementFormInputs = ({
  values,
  activeModalities,
  toggleModality,
  errors,
}) => {
  const modalityIsActive = activeModalities.includes("ABATEMENT");

  return (
    <BaasGrid item xs={12}>
      <Accordion
        style={{ width: "100%", marginTop: "16px" }}
        expanded={modalityIsActive}
      >
        <AccordionSummary>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <Checkbox
                defaultChecked={modalityIsActive}
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleModality("ABATEMENT");
                }}
              />
              <BaasText variant="body1" color="textSecondary">
                <b>ABATEMENT</b>
              </BaasText>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <BaasRow
            container
            column={12}
            spacing={2}
            style={{ marginTop: "-32px" }}
          >
            <BaasGrid item md={6}>
              <BaasTextField
                fullWidth
                name="abatementModality"
                label="Modality"
                variant="standard"
                disabled={!modalityIsActive}
                select
                errors={errors}
              >
                {abatementModalities.map((modality) => (
                  <BaasMenuItem value={modality.value}>
                    {modality.label}
                  </BaasMenuItem>
                ))}
              </BaasTextField>
            </BaasGrid>
            <BaasGrid item md={6}>
              {values.abatementModality === "FIXED_VALUE" ? (
                <BaasTextFieldCurrency
                  fullWidth
                  name="abatementAmountPerc"
                  label="Amount"
                  required={Boolean(values.abatementModality)}
                  disabled={!modalityIsActive}
                  errors={errors}
                />
              ) : (
                <BaasTextField
                  fullWidth
                  name="abatementAmountPerc"
                  label="Amount (%)"
                  required={Boolean(values.abatementModality)}
                  type="number"
                  errors={errors}
                  disabled={!modalityIsActive}
                />
              )}
            </BaasGrid>
          </BaasRow>
        </AccordionDetails>
      </Accordion>
    </BaasGrid>
  );
};

export default AbatementFormInputs;
