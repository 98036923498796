import React from "react";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import { useTheme } from "@mui/system";

const BaasCardFaq = ({ item, children }) => {
  const theme = useTheme();
  return (
    <BaasGrid
      spacing={2}
      p={3}
      sx={{
        height: "100%",
        border: ` 1px solid ${theme.palette.grey[400]}`,
        borderRadius: "4px",
      }}
    >
      <BaasGrid
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <BaasGrid>
          <BaasGrid p="8px 0px">
            <BaasText variant="h6">{item.title}</BaasText>
          </BaasGrid>
          <BaasText variant="body2" color="grey.600">
            {item.description}
          </BaasText>
        </BaasGrid>
        {children}
      </BaasGrid>
    </BaasGrid>
  );
};

export default BaasCardFaq;
