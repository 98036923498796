import { Box } from "@mui/material";
import { useTheme } from "@mui/system";
import { useQueryGetAccountBalance } from "api/security-api";
import { ReactComponent as VisibilityOff } from "assets/icons/Visibility/Off.svg";
import { ReactComponent as VisibilityOn } from "assets/icons/Visibility/On.svg";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasIconButton from "components/atoms/IconButton/IconButton";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import StackCard from "components/atoms/StackCard/StackCard";
import BaasText from "components/atoms/Text/BaasText";
import { currencyFormatter } from "helpers/currencyFormatter";
import getSafe from "helpers/getSafe";
import { useAccount } from "providers/account";
import React from "react";
import styled from "styled-components";
import { ReactComponent as LogoWhite } from "../../../assets/btg-logo-sm-white.svg";
import { ReactComponent as LogoBlack } from "../../../assets/btg-logo-sm.svg";

const CardStyle = styled(StackCard)`
  background: ${(props) =>
    props.selected
      ? props.theme.palette.primary.main
      : props.theme.palette.grey[100]};
  cursor: ${(props) => (props.selected ? "default" : "pointer")};
  border-radius: 4px;
  height: 120px;
  width: 210px;
  min-width: 210px;
  margin: 4px;
  :hover {
    background: ${(props) =>
      props.selected
        ? props.theme.palette.primary.main
        : props.theme.palette.grey[300]};
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const BaasCardBalance = ({ account, index }) => {
  const theme = useTheme();
  const {
    accountSelected,
    setAccountSelected,
    handleShowBalance,
    accountType,
    showBalance,
  } = useAccount();

  if (!accountSelected.accountId && index === 0) {
    setAccountSelected({ index, ...account });
  }

  const { data, isLoading } = useQueryGetAccountBalance(
    {
      accountId: account.accountId,
    },
    { refetchOnWindowFocus: true, retry: 1 }
  );

  const isSelected = accountSelected.index === index;

  const handleClick = () => {
    setAccountSelected({
      index,
      ...account,
    });
  };

  const getBalanceValue = () => {
    if (!showBalance) {
      return "*****";
    }

    const balance = getSafe(data, "body.balance");
    if (balance === undefined || balance === null) {
      return "Balance N/A";
    }

    return `${currencyFormatter(balance / 100)} BRL`;
  };

  const totalBalance = getBalanceValue();

  return (
    <CardStyle
      selected={isSelected}
      theme={theme}
      onClick={handleClick}
      background={
        isSelected ? theme.palette.primary.main : theme.palette.grey[100]
      }
    >
      {isSelected ? (
        <BaasGrid display="flex" justifyContent="space-between">
          <BaasText variant="caption" color="grey.100">
            <strong>SELECTED</strong>
          </BaasText>
          <BaasText variant="caption" color="grey.100">
            {accountType}
          </BaasText>
        </BaasGrid>
      ) : (
        <div style={{ height: "24px" }} />
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        {" "}
        {isLoading ? (
          <BaasSkeleton width="120px" animation={isLoading ? "pulse" : false}>
            <BaasText variant="h6">A</BaasText>
          </BaasSkeleton>
        ) : (
          <BaasText
            color={isSelected ? "grey.100" : "grey.700"}
            variant="h6"
            fontSize={totalBalance?.length > 17 ? "18px" : "20px"}
          >
            {totalBalance}
          </BaasText>
        )}
        <BaasIconButton
          variant="text"
          backgroundColor="black"
          size="small"
          onClick={handleShowBalance}
        >
          {showBalance ? (
            <VisibilityOff
              stroke={
                isSelected ? theme.palette.grey[100] : theme.palette.grey[800]
              }
            />
          ) : (
            <VisibilityOn
              stroke={
                isSelected ? theme.palette.grey[100] : theme.palette.grey[800]
              }
            />
          )}
        </BaasIconButton>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        height="35px"
      >
        <BaasText color={isSelected ? "grey.100" : "grey.700"} fontSize="12px">
          {account.bank || "N/A"} | {account.branch || "N/A"} |{" "}
          {account.account || "N/A"}
        </BaasText>
        {isSelected ? <LogoBlack /> : <LogoWhite />}
      </Box>
    </CardStyle>
  );
};

export default BaasCardBalance;
