import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import React from "react";

const LandingOnlyFewSteps = () => {
  const onlyFewSteps = [
    {
      number: 1,
      description: "Explore our public APIs in the documentation section.",
    },
    {
      number: 2,
      description: "Sign up for free on our Sandbox in just a few seconds.",
    },
    {
      number: 3,
      description: "Have the experience of testing our APIs.",
    },
    {
      number: 4,
      description:
        "Contact us if interested in integrating so we can better know your needs and guide you through the steps.",
    },
  ];

  return (
    <>
      <BaasGrid
        container
        backgroundColor="grey.200"
        display="flex"
        alignItems="center"
        flexDirection="column"
        p={8}
      >
        <BaasGrid p="0px 0px 48px 0px">
          <BaasText variant="h4" color="grey.800">
            Only few steps to Go
          </BaasText>
        </BaasGrid>

        <BaasGrid display="flex" flexWrap="wrap" justifyContent="center">
          {onlyFewSteps.map((step) => (
            <>
              <BaasGrid backgroundColor="grey.100" p={2} m={2} width="240px">
                <BaasText variant="h6">{step.number}</BaasText>

                <BaasText variant="body1" color="grey.600">
                  {step.description}
                </BaasText>
              </BaasGrid>
            </>
          ))}
        </BaasGrid>
      </BaasGrid>
    </>
  );
};

export default LandingOnlyFewSteps;
