import queryToString from "helpers/queryToString";
import { useQuery } from "react-query";
import { baasApiCall, baasApiCallGetCertificate } from "services/apiCall";

// Authentication
export const mutationPostAuthenticationUser = (payload) => {
  return baasApiCall("POST", "/cm/authentication/user", payload).then((data) =>
    data.json()
  );
};

//Webhooks Notifications
export const mutationPostwebhookNotificationByEntityPing = (entity) => {
  return baasApiCall("POST", `/cm/webhook/notification/${entity}/ping`).then(
    (data) => data
  );
};

//Webhooks Resources
export const queryGetWebhookResource = () => {
  return baasApiCall("GET", "/cm/webhook/resource").then((data) => data.json());
};

export const useQueryGetWebhookResource = () => {
  return useQuery("getWebhookResource", () => queryGetWebhookResource());
};
useQueryGetWebhookResource.queryKey = "getWebhookResource";

export const queryGetWebhookSubscriptionerrorsById = (entity) => {
  return baasApiCall(
    "GET",
    `/cm/webhook/subscription/errors/${entity}`
  ).then((data) => data.json());
};

export const useQueryGetWebhookSubscriptionerrorsById = ({ entity }) => {
  return useQuery("getWebhookSubscriptionerrorsById", () =>
    queryGetWebhookSubscriptionerrorsById(entity)
  );
};
useQueryGetWebhookResource.queryKey = "getWebhookResource";

//Webhooks Subscription
export const queryGetWebhookSubscription = () => {
  return baasApiCall("GET", "/cm/webhook/subscription").then((data) =>
    data.json()
  );
};

export const useQueryGetWebhookSubscription = () => {
  return useQuery("getWebhookSubscription", () =>
    queryGetWebhookSubscription()
  );
};
useQueryGetWebhookSubscription.queryKey = "getWebhookSubscription";

export const mutationPostWebhookSubscription = (payload) => {
  return baasApiCall("POST", "/cm/webhook/subscription", payload, true)
    .then((data) => data.json())
    .catch((error) => error.json());
};

//Webhooks Resend
export const queryGetWebhookNotificationByEntityReplay = (
  entity,
  queryParams
) => {
  return baasApiCall(
    "GET",
    `/cm/webhook/notification/${entity}/replay${queryToString(queryParams)}`
  ).then((data) => data.json());
};

export const mutationPutWebhookNotificationByEntityReplay = (
  entity,
  queryParams,
  payload
) => {
  return baasApiCall(
    "PUT",
    `/cm/webhook/notification/${entity}/replay${queryToString(queryParams)}`,
    payload
  ).then((data) => data);
};

//mTLS Certificate

export const mutationPostUserPublicKey = (payload) => {
  return baasApiCall(
    "POST",
    `/cm/user/publicKey`,
    payload.certificate,
    payload.otp,
    true,
    false
  ).then((data) => data.json());
};

export const queryGetCertificate = () => {
  return baasApiCallGetCertificate().then((data) => data);
};
