import BaasText from "components/atoms/Text/BaasText";
import React, { useCallback, useState } from "react";
import { useTheme } from "@mui/system";
import BaasGrid from "components/atoms/Grid/Grid";
import { toast } from "react-toastify";
import moment from "moment";
import { Form, Formik } from "formik";
import BaasButton from "components/atoms/Button/Button";
import { useOtp } from "providers/oneTimePassword";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import BaasDatePicker from "components/atoms/DatePicker/DatePicker";
import { toastMessages } from "constants/toastMessages";
import { mutationPostMovementReportByRange } from "api/cnab-api";
import MovementReportSearch from "./MovementReportSearch";
import intervalDate from "../../../../helpers/intervalDate";

export default function MovementReportGenerate() {
  const theme = useTheme();
  const QTDE_DAYS_VALID = 31;
  const [buttonGenerate, setButtonGenerate] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setOpen } = useOtp();

  const mutateMovementReportGenerate = useCallback(async (payload, otp) => {
    const response = await mutationPostMovementReportByRange(payload, otp);
    if (response.status < 300) {
      toast.success(
        "Your report will begin shortly. Follow the status on the list."
      );
    } else {
      if ("errors" in response) {
        response.errors.map((error) => toast.error(error.message));
      } else toast.error(toastMessages.cnab.download.error);
    }
    setIsSubmitting(false);
  }, []);

  const onSubmit = useCallback(
    (values) => {
      setIsSubmitting(true);
      if (intervalDate(values.startDate, values.endDate, QTDE_DAYS_VALID)) {
        mutateMovementReportGenerate(
          {
            startDate: moment(values.startDate).format("YYYY-MM-DD"),
            endDate: moment(values.endDate).format("YYYY-MM-DD"),
          },
          values.otp
        );
      } else {
        setIsSubmitting(false);
        toast.error("Invalid data range. Maximum 30 days.");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutateMovementReportGenerate]
  );

  const getMaxDate = (date) => {
    const nowDate = moment();
    const endDate = moment(date).add(1, "month");
    if (endDate.isSameOrAfter(nowDate)) {
      return nowDate;
    }
    return endDate;
  };

  return (
    <>
      <Formik
        initialValues={{}}
        onSubmit={() => setOpen(true)}
        enableReinitialize
      >
        {({ values, isSubmitting, setFieldValue, resetForm, ...props }) => (
          <Form>
            <BaasOtpModal
              onConfirm={() => onSubmit(values, props)}
              {...props}
            />
            <BaasGrid
              p="16px"
              sx={{ background: theme.palette.grey[100], marginBottom: "16px" }}
            >
              <BaasText>
                Use this session to generate Cnab Movement Report files.
              </BaasText>
              {buttonGenerate ? (
                <>
                  <BaasGrid item>
                    <BaasButton
                      style={{ margin: "16px 0px" }}
                      variant="contained"
                      onClick={() => {
                        setButtonGenerate(false);
                      }}
                    >
                      Generate Movement Report
                    </BaasButton>
                  </BaasGrid>
                </>
              ) : (
                <>
                  <BaasGrid container p="16px 0px" spacing={3}>
                    <BaasGrid item xs={12} sm={6}>
                      <BaasDatePicker
                        name="startDate"
                        label="Start date"
                        customOnChange={(date) => {
                          setFieldValue("endDate", getMaxDate(moment(date)));
                        }}
                        maxDate={moment()}
                        fullWidth
                      />
                    </BaasGrid>
                    <BaasGrid item xs={12} sm={6}>
                      <BaasDatePicker
                        name="endDate"
                        label="End date"
                        fullWidth
                        minDate={moment(values.startDate)}
                        maxDate={getMaxDate(values.startDate)}
                      />
                    </BaasGrid>
                  </BaasGrid>
                  <BaasGrid item display="flex">
                    <BaasButton
                      style={{ margin: "16px 0px", marginRight: "24px" }}
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        setButtonGenerate(true);
                      }}
                    >
                      Cancel
                    </BaasButton>
                    <BaasButton
                      style={{ margin: "16px 0px" }}
                      variant="contained"
                      backgroundColor="black"
                      loading={isSubmitting}
                      onClick={() => setOpen(true)}
                    >
                      Generate
                    </BaasButton>
                  </BaasGrid>
                </>
              )}
            </BaasGrid>
          </Form>
        )}
      </Formik>
      {buttonGenerate ? <MovementReportSearch /> : null}
    </>
  );
}
