import React, { useCallback, useState } from "react";
import BaasGrid from "components/atoms/Grid/Grid";
import { ReactComponent as NotificationIcon } from "assets/icons/Notification.svg";
import { ReactComponent as TrashIcon } from "assets/icons/Trash.svg";
import { toast } from "react-toastify";
import { toastMessages } from "constants/toastMessages";
import { mutationPostwebhookNotificationByEntityPing } from "api/webhooks-api";
import BaasButton from "components/atoms/Button/Button";
import ErrorIcon from "@mui/icons-material/Error";
import { useFormikContext } from "formik";
import WebhookConfigResourceContentActionsLogModal from "./WebhookConfigResourceContentActionsLogModal";
import { useMutation } from "react-query";
import { useTheme } from "@mui/private-theming";
import WebhookConfigResourceContentActionsResendModal from "./WebhookConfigResourceContentActionsResendModal";

const WebhookConfigResourceContentActions = () => {
  const { values, setFieldValue } = useFormikContext();
  const [modalLog, setModalLog] = useState({ state: false, entity: "" });
  const [modalResend, setModalResend] = useState({ state: false, entity: "" });
  const theme = useTheme();

  const { mutate: sendPing } = useMutation(
    (e) => mutationPostwebhookNotificationByEntityPing(e),
    {
      onSuccess: () => {
        toast.success(toastMessages.webhook.pingSuccess);
      },
      onError: () => {
        toast.error(toastMessages.webhook.pingError);
      },
    }
  );

  const handleDeleteWebhook = useCallback(async () => {
    setFieldValue(
      `resources[${values.webhookOptions.index}].subscription.webhookUrl`,
      ""
    );
    setFieldValue(
      `resources[${values.webhookOptions.index}].subscription.secret`,
      ""
    );
    await values.webhookOptions.events.map((item, index) =>
      setFieldValue(
        `resources[${values.webhookOptions.index}].events[${index}].checked`,
        false
      )
    );
    setFieldValue(`webhookOptions`, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, setFieldValue]);

  return (
    <BaasGrid display="flex" p="8px 0px" flexWrap="wrap">
      <BaasGrid p="4px 8px 4px 0px">
        <BaasButton
          variant="outlined"
          disabled={values.webhookOptions.subscription.blockedManyErrors}
          onClick={() =>
            setModalResend({
              state: true,
              entity: values.webhookOptions.entity,
            })
          }
          startIcon={<ErrorIcon stroke={theme.palette.primary.main} />}
        >
          Resend
        </BaasButton>
      </BaasGrid>
      {values.webhookOptions.subscription.webhookUrl && (
        <BaasGrid p="4px 8px 4px 0px">
          <BaasButton
            variant="outlined"
            color="warning"
            onClick={() => sendPing(values.webhookOptions.entity)}
            startIcon={<NotificationIcon color={theme.palette.warning.main} />}
          >
            Send ping
          </BaasButton>
        </BaasGrid>
      )}
      {!!values.webhookOptions.subscription.qtyConsecutiveErrors && (
        <BaasGrid p="4px 8px 4px 0px">
          <BaasButton
            variant="outlined"
            color="error"
            onClick={() =>
              setModalLog({ state: true, entity: values.webhookOptions.entity })
            }
            startIcon={<ErrorIcon />}
          >
            Check logs
          </BaasButton>
        </BaasGrid>
      )}
      <BaasGrid p="4px 8px 4px 0px">
        <BaasButton
          variant="outlined"
          backgroundColor="black"
          onClick={handleDeleteWebhook}
          startIcon={<TrashIcon />}
        >
          Delete webhook
        </BaasButton>
      </BaasGrid>
      <WebhookConfigResourceContentActionsLogModal
        entity={values.webhookOptions.entity}
        modal={modalLog}
        setModal={setModalLog}
      />
      <WebhookConfigResourceContentActionsResendModal
        entity={values.webhookOptions.entity}
        modal={modalResend}
        setModal={setModalResend}
      />
    </BaasGrid>
  );
};

export default WebhookConfigResourceContentActions;
