import * as yup from "yup";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import { Formik, Form } from "formik";
import BaasButton from "components/atoms/Button/Button";
import BaasBox from "components/atoms/Box/Box";
import { genericBaasApiCall, genericBaasApiCallWithPayload } from "api/pix-api";
import { toast } from "react-toastify";

const DinamicForm = ({ route, loading, setLoading, onComplete }) => {
  const { message, body, path, httpMethod } = route;
  const parsedBody = body && JSON.parse(body);

  const dispatchAction = async (params) => {
    setLoading(true);
    const apiCallFunction = params.payload
      ? genericBaasApiCallWithPayload
      : genericBaasApiCall;

    try {
      const response = await apiCallFunction(params);
      if (response.status < 300) {
        toast.success("Operation finished with success");
        onComplete?.();
      } else {
        if ("errors" in response) {
          response.errors.map((error) => {
            return toast.error(error.message);
          });
        }
      }
      setLoading(false);
    } catch (e) {
      toast.error(e.toString());
      setLoading(false);
    }
  };

  const validationSchema = yup.object().shape({});

  const getInputs = () => {
    if (parsedBody) {
      const inputs = Object.keys(parsedBody).map(function (key, index) {
        return key;
      });
      return inputs;
    }
    return [];
  };

  const getInputComponent = (inputType) => {
    return BaasTextField;
  };

  const getInputProps = (inputType, inputKey) => {
    if (inputType === "select") {
      return parsedBody[inputKey].options.map((option) => (
        <BaasMenuItem value={option} style={{ textTransform: "capitalize" }}>
          {option}
        </BaasMenuItem>
      ));
    }
    return null;
  };

  const handleSubmit = (values) => {
    dispatchAction({
      route: {
        method: httpMethod.method,
        path: path,
      },
      payload: values,
    });
  };

  const inputs = getInputs();

  const getInitialValues = () => {
    let values = {};

    inputs.forEach((i) => {
      if (parsedBody[i].value) {
        values = { ...values, [i]: parsedBody[i].value };
      }
    });
    return values;
  };

  return (
    <>
      {parsedBody ? (
        <Formik
          initialValues={getInitialValues()}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, ...props }) => (
            <Form>
              {inputs &&
                inputs.length > 0 &&
                inputs.map((inputKey) => {
                  const input = parsedBody[inputKey];
                  const inputType = input.inputType;
                  const visibilityTrigger = input.visibilityTrigger;
                  const InputComponent = getInputComponent(inputType);
                  if (
                    visibilityTrigger &&
                    values[visibilityTrigger.key] !== visibilityTrigger.value
                  ) {
                    return null;
                  }
                  return (
                    <BaasGrid item xs={12} md={12} key={inputKey}>
                      <InputComponent
                        fullWidth
                        name={inputKey}
                        label={input.label}
                        disabled={input.disabled}
                        required={input.required}
                        select={input.inputType === "select"}
                      >
                        {getInputProps(inputType, inputKey)}
                      </InputComponent>
                    </BaasGrid>
                  );
                })}
              <BaasBox mt={2}>
                <BaasButton
                  type="submit"
                  size="medium"
                  variant="contained"
                  loading={loading}
                >
                  {message}
                </BaasButton>
              </BaasBox>
            </Form>
          )}
        </Formik>
      ) : (
        <BaasBox mt={2}>
          <BaasButton
            onClick={() => handleSubmit()}
            size="medium"
            variant="contained"
            loading={loading}
          >
            {message}
          </BaasButton>
        </BaasBox>
      )}
    </>
  );
};

export default DinamicForm;
