import BaasButton from "components/atoms/Button/Button";
import AddIcon from "@mui/icons-material/Add";

export const AddButton = ({ label, onClick }) => {
  return (
    <BaasButton variant="outlined" startIcon={<AddIcon />} onClick={onClick}>
      {label}
    </BaasButton>
  );
};
