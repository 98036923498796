import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Box, RadioGroup } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import {
  querySearchBankSlipOperations,
  querySearchPixOperations,
  querySearchTransfers,
} from "api/search/queries";
import BaasButton from "components/atoms/Button/Button";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import StackCard from "components/atoms/StackCard/StackCard";
import BaasTextField from "components/atoms/TextField/TextField";
import TextAreaSearch from "components/molecules/TextAreaSearch/TextAreaSearch";
import { Form, Formik } from "formik";
import { handleMultipleQueries } from "helpers/multipleRequest";
import { splitStringByComma } from "helpers/splitStringByComma";
import { useCustomPagination } from "helpers/useCustomPagination";
import React, { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import DashboardSearchList from "./DashboardSearchList";

const operationsType = [
  { label: "Pix", name: "pix" },
  { label: "Ted/Tef", name: "tedTef" },
  { label: "Payment Slip", name: "paymentSlip" },
];

const statusByOperationsType = {
  pix: [
    { value: "ACTIVE", label: "Active (QR Code only)" },
    { value: "COMPLETED", label: "Completed (QR Code only)" },
    { value: "CONFIRMED", label: "Confirmed" },
    { value: "ERROR", label: "Error" },
    { value: "INITIATED", label: "Initiated" },
    { value: "PROCESSING", label: "Processing" },
  ],
  tedTef: [
    { value: "CONFIRMED", label: "Confirmed" },
    { value: "ENQUEUED", label: "Enqueued" },
    { value: "ERROR", label: "Error" },
    { value: "PROCESSING", label: "Processing" },
    { value: "REVERTED", label: "Reverted" },
  ],
  paymentSlip: [
    { value: "CREATED", label: "Created (Payment slip issue only)" },
    { value: "ENQUEUED", label: "Enqueued" },
    { value: "ERROR", label: "Error" },
    { value: "PAID", label: "Paid" },
    { value: "PROCESSING", label: "Processing" },
    { value: "PROCESSING_PAYMENT", label: "Processing payment" },
    { value: "REVERTED", label: "Reverted" },
  ],
};

const DashboardSearch = () => {
  const [searchData, setSearchData] = useState();
  const [searchType, setSearchType] = useState("single");
  const [loading, setLoading] = useState(false);
  const [operationType, setOperationType] = useState();
  const singleSearch = searchType === "single";

  const { fetch: fetchTransfers } = useCustomPagination(querySearchTransfers);
  const { fetch: fetchBankSlips } = useCustomPagination(
    querySearchBankSlipOperations
  );
  const { fetch: fetchPix } = useCustomPagination(querySearchPixOperations);
  const limit = 10;
  const [paginatedQueryVariables, setPaginatedQueryVariables] = useState();

  const initialValues = useMemo(() => {
    return {
      operationType: "",
      status: "",
      keyword: "",
    };
  }, []);

  const queries = useMemo(() => {
    return {
      tedTef: querySearchTransfers,
      pix: querySearchPixOperations,
      paymentSlip: querySearchBankSlipOperations,
    };
  }, []);

  const getFetchByOperationType = useCallback(
    (operationType) => {
      if (operationType === "tedTef") {
        return fetchTransfers;
      }
      if (operationType === "pix") {
        return fetchPix;
      }
      if (operationType === "paymentSlip") {
        return fetchBankSlips;
      }
    },
    [fetchBankSlips, fetchPix, fetchTransfers]
  );

  const handleSingleFetch = useCallback(
    (searchValues, keyword, operationType) => {
      setSearchData(undefined);
      const fetch = getFetchByOperationType(operationType);
      setPaginatedQueryVariables({
        values: { ...searchValues, keyword: keyword, limit },
        onCompleted: (data) => setSearchData(data),
      });
      fetch({
        values: { ...searchValues, keyword: keyword, limit },
        onCompleted: (data) => setSearchData(data),
        onNotFoundError: () =>
          toast.warning("Sorry, we couldn´t find any results"),
      });
      setLoading(false);
    },
    [getFetchByOperationType]
  );

  const handleMultipleFetches = useCallback(
    async (searchValues, keywordsList, operationType) => {
      const response = await handleMultipleQueries(
        searchValues,
        keywordsList,
        queries[operationType]
      );
      setLoading(false);
      setSearchData({ items: response });
    },
    [queries]
  );

  const onSubmit = useCallback(
    async (values) => {
      const searchValues = {
        status: values.status || undefined,
      };
      let keyword =
        values.keyword.trim().length > 0 ? values.keyword : undefined;

      if (!keyword) {
        return toast.error("Keyword field is required");
      }
      setOperationType(values.operationType);
      setLoading(true);
      if (!singleSearch) {
        keyword = splitStringByComma(values.keyword);
        await handleMultipleFetches(
          searchValues,
          keyword,
          values.operationType
        );
      } else {
        handleSingleFetch(searchValues, keyword, values.operationType);
      }
    },
    [handleMultipleFetches, handleSingleFetch, singleSearch]
  );

  const updateOperationType = (setFieldValue, value) => {
    setFieldValue("operationType", value);
    setFieldValue("status", "");
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, setFieldValue, ...props }) => (
          <Form>
            <BaasGrid>
              <StackCard>
                <BaasGrid container spacing={1}>
                  <BaasGrid item xs={12} sm={6}>
                    <BaasTextField
                      fullWidth
                      name="operationType"
                      label="Operation Type"
                      select
                      variant="outlined"
                      onChange={(e) => {
                        updateOperationType(setFieldValue, e.target.value);
                      }}
                    >
                      {operationsType.map((operation) => (
                        <BaasMenuItem
                          key={operation.name}
                          value={operation.name}
                        >
                          {operation.label}
                        </BaasMenuItem>
                      ))}
                    </BaasTextField>
                  </BaasGrid>
                  <BaasGrid item xs={12} sm={6}>
                    <BaasTextField
                      fullWidth
                      name="status"
                      variant="outlined"
                      label="Status"
                      disabled={!values.operationType}
                      select
                      onChange={(e) => {
                        setFieldValue("status", e.target.value);
                      }}
                    >
                      <BaasMenuItem key="statusAll" value="">
                        All
                      </BaasMenuItem>
                      {values.operationType &&
                        statusByOperationsType[values.operationType].map(
                          (statusItem) => (
                            <BaasMenuItem
                              key={statusItem.value}
                              value={statusItem.value}
                            >
                              {statusItem.label}
                            </BaasMenuItem>
                          )
                        )}
                    </BaasTextField>
                  </BaasGrid>
                  <BaasGrid item xs={12}>
                    <Box mt={1} mb={1}>
                      <FormControl>
                        <FormLabel>
                          Search type (Pactual Id, E2E, ClientRequestId,
                          Digitable Line, Transaction Identification)
                        </FormLabel>
                        <RadioGroup
                          row
                          value={searchType}
                          onChange={(e) => setSearchType(e.target.value)}
                        >
                          <FormControlLabel
                            value="single"
                            control={<Radio />}
                            label="Single"
                          />
                          <FormControlLabel
                            value="multiple"
                            control={<Radio />}
                            label="Multiple"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  </BaasGrid>
                  <BaasGrid item xs={12}>
                    {singleSearch ? (
                      <BaasTextField
                        name="keyword"
                        fullWidth
                        type="text"
                        variant="outlined"
                        label="Keyword"
                      />
                    ) : (
                      <TextAreaSearch
                        fullWidth
                        name="keyword"
                        label={"Keywords (Comma separated values)"}
                        validateLimit={!singleSearch}
                        onChange={(e) => {
                          setFieldValue("keyword", e.target.value);
                        }}
                      />
                    )}
                  </BaasGrid>
                </BaasGrid>
                <BaasRow
                  container
                  sx={{ justifyContent: "space-between" }}
                  flexDirection="row"
                >
                  <BaasGrid p={0} item>
                    <BaasButton
                      variant="outlined"
                      size="medium"
                      type="reset"
                      startIcon={<ClearIcon />}
                    >
                      Clear
                    </BaasButton>
                  </BaasGrid>
                  <BaasGrid p={0} item>
                    <BaasButton
                      type="submit"
                      startIcon={<SearchIcon />}
                      size="medium"
                      variant="contained"
                      disabled={!values.operationType || !values.keyword}
                    >
                      Search
                    </BaasButton>
                  </BaasGrid>
                </BaasRow>
              </StackCard>
            </BaasGrid>
            <Box>
              <DashboardSearchList
                data={searchData}
                loading={loading}
                limit={limit}
                displayPagination={singleSearch}
                fetch={getFetchByOperationType(operationType)}
                queryVariables={paginatedQueryVariables}
                operationType={operationType}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default DashboardSearch;
