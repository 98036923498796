import Carousel, { autoplayPlugin } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import { useEffect, useState } from "react";
import guidGenerator from "services/guidGenerator";
import LandingOurClientsCarrossel from "./LandingOurClientsCarrossel";
import LandingOurClientsStyles from "./LandingOurClientsStyles";
import partnerLogos from "./partnerLogos";

const pages = [
  [
    "99",
    "aliPay",
    "banqi",
    "clara",
    "ngcash",
    "recargaPay",
    "transfeera",
    "wePayOut",
    "z1",
  ],
  [
    "acqio",
    "adyen",
    "agili",
    "cloudwalk",
    "entrepay",
    "pinBank",
    "stripe",
    "sumup",
    "worldpay"
  ],
  [
    "belvo",
    "celcoin",
    "csu",
    "dotz",
    "jeeves",
    "onniBank",
    "orbitall",
    "seven",
    "swap",
  ],
  [
    "amfi",
    "b3da",
    "bcodex",
    "digitalEazy",
    "logBank",
    "mercadoBitcoin",
    "mynt",
    "okx",
    "parfin",
  ],
  [
    "blu",
    "credifit",
    "creditas",
    "geru",
    "friday",
    "niky",
    "noverde",
    "ppro",
    "zippi"
  ],
  [
    "aalgar",
    "al5",
    "autopass",
    "iupay",
    "llz",
    "mosaico",
    "prudential",
    "rooftop",
    "treviso",
  ],
];

const LandingOurClients = () => {
  const classes = LandingOurClientsStyles();

  const [numberOfSlides, setNumberOfSlides] = useState();
  const [clientLogoPages, setClientLogoPages] = useState([]);
  const [clientLogoPagesMobile, setClientLogoPagesMobile] = useState([]);
  const [displayCarousel, setDisplayCarousel] = useState();
  const [, setHeroClass] = useState();

  useEffect(() => {
    handleResize();
    handleDisplayCarousel();
    logoSorting();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoSorting = () => {
    let clientLogosPages = [];
    let clientLogosPagesMobile = [];

    pages.map((page) => {
      let logoPage = [];

      partnerLogos.map((partner) => {
        if (page.includes(partner.name)) logoPage.push(partner);
      });
      clientLogosPages.push(logoPage);
    });

    pages.flat().map((page) => {
      partnerLogos.map((partner) => {
        if (page.includes(partner.name)) clientLogosPagesMobile.push(partner);
      });
    });

    setClientLogoPagesMobile(clientLogosPagesMobile);
    setClientLogoPages(clientLogosPages);
  };

  const handleResize = () => {
    let numberOfSlides;
    let heroClass = classes.heroContent;

    if (window.innerWidth > 1600) {
      numberOfSlides = 4;
    } else if (window.innerWidth <= 1600 && window.innerWidth > 1200) {
      numberOfSlides = 3;
      heroClass = classes.heroContentMd;
    } else if (window.innerWidth <= 1200 && window.innerWidth >= 640) {
      numberOfSlides = 2;
      heroClass = classes.heroContentMd;
    } else if (window.innerWidth <= 640) {
      numberOfSlides = 1;
      heroClass = classes.heroContentMd;
    }
    setNumberOfSlides(numberOfSlides);
    setHeroClass(heroClass);
    return;
  };

  const handleDisplayCarousel = () => {
    let displayCarousel = false;

    if (window.innerWidth < 800) {
      displayCarousel = true;
    }

    setDisplayCarousel(displayCarousel);
  };

  window.addEventListener("resize", handleResize);
  window.addEventListener("resize", handleDisplayCarousel);

  return (
    <>
      <BaasGrid
        container
        diplay="flex"
        justifyContent="center"
        alignItems="center"
        p={6}
        style={{
          backgroundColor: "#fff",
          minHeight: "50vh",
        }}
      >
        <BaasGrid p="0px 0px 40px 0px">
          <BaasText variant="h4" color="grey.800">
            Our clients
          </BaasText>
        </BaasGrid>

        {displayCarousel ? (
          <BaasGrid item xs={11} sm={11} md={11} lg={10}>
            <Carousel
              autoPlay={2500}
              animationSpeed={1000}
              slidesPerPage={numberOfSlides}
              plugins={[
                {
                  resolve: autoplayPlugin,
                  options: {
                    interval: 2500,
                  },
                },
                "infinite",
              ]}
            >
              {clientLogoPagesMobile.map((partnerLogo) => (
                <img
                  alt=""
                  src={partnerLogo.logo}
                  key={guidGenerator()}
                  className={classes["carousselLogo"]}
                />
              ))}
            </Carousel>
          </BaasGrid>
        ) : (
          <>
            {clientLogoPages.length > 0 ? (
              <BaasGrid item xs={11} sm={11} md={11} lg={12}>
                <LandingOurClientsCarrossel pages={clientLogoPages} />
              </BaasGrid>
            ) : null}
          </>
        )}
      </BaasGrid>
    </>
  );
};

export default LandingOurClients;
