import { FormControlLabel } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import React from "react";
import styled from "styled-components";
import { useTheme } from "@mui/system";

const SelectStyle = styled.div``;

const BaasCheckboxDefault = ({
  name,
  options,
  variant = "standard",
  label,
  optionName,
  isLoading,
  ...props
}) => {
  const theme = useTheme();

  return (
    <SelectStyle theme={theme}>
      <FormControlLabel
        label={label}
        control={
          <Checkbox
            sx={{
              color: theme.palette.grey[800],
              "&.Mui-checked": {
                color: theme.palette.grey[800],
              },
            }}
            variant={variant}
            name={name}
            {...props}
          />
        }
      />
    </SelectStyle>
  );
};

export default BaasCheckboxDefault;
