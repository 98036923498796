import BaasText from "components/atoms/Text/BaasText";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import React from "react";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";

import CnabInterfaceDownload from "./CnabInterfaceDownload";
import CnabInterfaceUpload from "./CnabInterfaceUpload";
const CnabInterface = () => {
  return (
    <>
      <BaasFlex flexDirection="column" width="100%">
        <BaasPageHeader>CNAB</BaasPageHeader>
        <BaasContentGrid spacing={4}>
          <BaasGrid p="16px 0px" item>
            <BaasText variant="h6">CNAB Upload Interface</BaasText>
          </BaasGrid>

          <CnabInterfaceUpload />

          <BaasGrid p="16px 0px" item>
            <BaasText variant="h6">CNAB Download Interface</BaasText>
          </BaasGrid>

          <CnabInterfaceDownload />
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
};

export default CnabInterface;
