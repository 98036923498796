export function queryToString(obj) {
  let str =
    "?" +
    Object.keys(obj)
      .reduce(function (a, k) {
        if (obj[k] !== undefined) {
          a.push(k + "=" + obj[k]);
          return a;
        } else {
          return a;
        }
      }, [])
      .join("&");
  return str;
}

export default queryToString;
