export function sortByName(a, b) {
  const A = a.name?.toUpperCase();
  const B = b.name?.toUpperCase();

  let comparison = 0;
  if (A > B) {
    comparison = 1;
  } else if (A < B) {
    comparison = -1;
  }
  return comparison;
}
