import { useQuery } from "react-query";
import { baasApiCall } from "services/apiCall";

//External Cash Out
export const mutationPostTransferExternalOut = async (payload) => {
  return baasApiCall("POST", "/tr/transfer/external", payload).then((data) =>
    data.json()
  );
};

//External Cash In
export const mutationPostPlaygroundTedCashIn = async (payload) => {
  return baasApiCall(
    "POST",
    "/cm/playground/ted/cashin",
    payload
  ).then((data) => data.json());
};

//Paymentslip
export const mutationPostPaymentPaymentSlipAccountById = async (
  { accountId },
  payload
) => {
  return baasApiCall(
    "POST",
    `/tr/payment/payment-slip/account/${accountId}`,
    payload
  ).then((data) => data.json());
};

//Internal Cash Out
export const mutationPostTransferInternal = async (payload) => {
  return baasApiCall("POST", "/tr/transfer/internal", payload).then((data) =>
    data.json()
  );
};

//Pix Cash In
export const mutationPostPlaygroundPixCashIn = async (payload) => {
  return baasApiCall(
    "POST",
    `/cm/playground/pix/cashin`,
    payload
  ).then((data) => data.json());
};

//Pix Cash Out
export const mutationPostPixCashOut = async (payload) => {
  return baasApiCall("POST", `/pi/pix/payment`, payload).then((data) =>
    data.json()
  );
};

export const queryGetTransfer = async ({ route, pathParams, filter }) => {
  if (pathParams.pactualIdList) {
    let pactualIdList = { body: { items: [] } };
    for (let i = 0; i < pathParams.pactualIdList.length; i++) {
      const data = await baasApiCall(
        "GET",
        `${route}/${pathParams.pactualIdList[i].pactualId}`
      )
        .then((item) => item.json())
        .catch(() => null);

      if (data) {
        pactualIdList.body.items.push(data.body);
      }
    }
    return pactualIdList;
  } else if (pathParams.range) {
    const data = baasApiCall(
      "GET",
      `${route}/search?sortBy=createTimestamp&dateTo=${
        pathParams.range.dateTo
      }&dateFrom=${pathParams.range.dateFrom}&limit=10${
        filter?.start ? `&start=${filter.start}` : ""
      }${filter?.totalFound ? `&totalFound=${filter.totalFound}` : ""}${
        filter?.totalReturned ? `&totalReturned=${filter.totalReturned}` : ""
      }`
    )
      .then((data) => data.json())
      .catch(() => []);

    return data;
  } else if (pathParams.endToEndIdList) {
    let endToEndIdList = { body: { items: [] } };
    for (let i = 0; i < pathParams.endToEndIdList.length; i++) {
      const data = await baasApiCall(
        "GET",
        `${route}/${pathParams.endToEndIdList[i].pactualId}`
      )
        .then((item) => item.json())
        .catch(() => null);

      if (data) {
        if (Array.isArray(data.body)) {
          data.body.map((item) => {
            endToEndIdList.body.items.push(item);
          });
        } else {
          endToEndIdList.body.items.push(data.body);
        }
      }
    }
    return endToEndIdList;
  }
};

export const useQueryGetTransfer = (queryParams, config) => {
  return useQuery("getTransfer", () => queryGetTransfer(queryParams), {
    ...config,
    retry: false,
  });
};
useQueryGetTransfer.queryKey = "getTransfer";
