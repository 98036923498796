import Backdrop from "@mui/material/Backdrop";
import { useTheme } from "@mui/private-theming";
import { Box } from "@mui/system";
import { mutationPostInfractionReportClose } from "api/dictv2-api";
import { queryDictHubInfractionRoutesSandbox } from "api/dictv2-api-sandbox";
import BaasBox from "components/atoms/Box/Box";
import BaasButton from "components/atoms/Button/Button";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasModal from "components/atoms/Modal/Modal";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import DinamicForm from "components/organism/DinamicForm/DinamicForm";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import Endpoint from "constants/endpoints";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import { useCustomQuery } from "helpers/useCustomQuery";
import { useAccount } from "providers/account";
import { useOtp } from "providers/oneTimePassword";
import React, { useCallback, useEffect, useState } from "react";
import ReactJson from "react-json-view";
import { toast } from "react-toastify";
import styled from "styled-components";

const BoxStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  max-width: 600px;

  max-height: 75vh;
  background-color: ${(props) => props.theme.palette.grey[200]};

  box-shadow: 24;
  padding: 4px;
  border-radius: 4px;
  overflow: hidden;
  overflow-y: scroll;

  :focus-visible {
    outline: none;
  }
`;

const JsonStyled = styled(Box)`
  border-radius: 4px;
  background: ${(props) => props.theme.palette.grey[100]};
  max-height: 400px;
  overflow-y: scroll;
  border-radius: 4px;
  padding: 8px;
`;

const InfractionReportSearchListModal = ({
  data,
  handleRefetch,
  loadingSearch,
  modal,
  setModal,
  ispb,
  hideActions,
}) => {
  const theme = useTheme();
  const { setOpen } = useOtp();
  const { accountType } = useAccount();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refetchRoutes, setRefetchRoutes] = useState(false);
  const [loadingGeneric, setLoadingGeneric] = useState();

  const handleClose = () => {
    setModal({ state: false });
  };

  const isSandboxEnvironment = Endpoint.Environment === "Sandbox";

  const filteredData = data.find((item) => item.id === modal.id);

  const mutateClose = useCallback(async (values) => {
    const response = await mutationPostInfractionReportClose(values);
    if (response.status < 300) {
      toast.success(toastMessages.requestSuccess);
      setOpen(false);
      handleClose();
    } else {
      if ("errors" in response) {
        response.errors.map((error) => {
          return toast.error(error.message);
        });
      }
    }
    setIsSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(
    (values) => {
      setIsSubmitting(true);
      mutateClose({
        otp: values.otp,
        infractionReportId: filteredData.id,
        analysisResult: values.analysisResult,
        analysisDetails: values.analysisDetails,
        fraudType:
          values.analysisResult === "DISAGREED" ? null : values.fraudType,
        accountType: accountType,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setOpen, mutateClose, filteredData]
  );

  const errorMessage = () => {
    if (
      filteredData.status !== "ACKNOWLEDGED" &&
      filteredData.status === "OPEN"
    ) {
      return "Close Option unavailable, first this transaction must be acknowledged by the requested party.";
    } else if (
      filteredData.status !== "ACKNOWLEDGED" &&
      filteredData.status === "CANCELLED"
    ) {
      return "Infraction Report cancelled, no further actions needed.";
    } else if (
      filteredData.status !== "ACKNOWLEDGED" &&
      filteredData.status === "CLOSED"
    ) {
      return "Infraction Report closed, no further actions needed.";
    } else if (
      (filteredData.reportedBy === "CREDITED_PARTICIPANT" &&
        filteredData.creditedParticipant === ispb) ||
      (filteredData.reportedBy === "DEBITED_PARTICIPANT" &&
        filteredData.debitedParticipant === ispb)
    ) {
      return "Close Option unavailable, your client requested this infraction report.";
    }
  };

  const {
    fetch: fetchRoutes,
    data: routesData,
    loading: loadingRoutes,
  } = useCustomQuery(queryDictHubInfractionRoutesSandbox);

  useEffect(() => {
    if (isSandboxEnvironment && filteredData) {
      fetchRoutes({
        values: filteredData?.id,
        objectFallback: [],
        onNotFoundError: () => {},
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchRoutes, refetchRoutes, filteredData?.id]);

  const handleRefetchRoutes = () => {
    setRefetchRoutes(!refetchRoutes);
    handleRefetch();
  };

  if (hideActions) {
    return (
      <BaasModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modal.state}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <BoxStyled p={3} theme={theme} sx={{ maxWidth: "400px" }}>
          <BaasText>Infraction Details (Object TreeView)</BaasText>
          <JsonStyled theme={theme} style={{ marginTop: "16px" }}>
            <ReactJson
              name={null}
              src={filteredData}
              style={{ height: "100%", overflowY: "scroll" }}
              displayObjectSize={false}
              displayDataTypes={false}
            />
          </JsonStyled>
        </BoxStyled>
      </BaasModal>
    );
  }
  return (
    <>
      <BaasModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modal.state}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {accountType !== "Merchant" ? (
          <>
            {isSandboxEnvironment ? (
              <BoxStyled p={3} theme={theme} sx={{ maxWidth: "400px" }}>
                <BaasText>Infraction Details (Object TreeView)</BaasText>
                <Box mb={2}>
                  <BaasText variant="body2" fontWeight="bold">
                    Possible Actions
                  </BaasText>
                  {loadingRoutes ? (
                    <BaasSkeleton width="100%" height="65px" />
                  ) : routesData && routesData.length > 0 ? (
                    routesData.map((route) => (
                      <BaasBox
                        sx={{ borderTop: "1px solid #CACACA" }}
                        mt={2}
                        key={route.message}
                      >
                        <DinamicForm
                          loading={loadingSearch || loadingGeneric}
                          setLoading={setLoadingGeneric}
                          onComplete={handleRefetchRoutes}
                          route={route}
                        />
                      </BaasBox>
                    ))
                  ) : (
                    <>
                      <BaasText color="error" mt={1}>
                        There is no actions available to this infraction.
                      </BaasText>
                    </>
                  )}
                </Box>
                <JsonStyled theme={theme}>
                  <ReactJson
                    name={null}
                    src={filteredData}
                    style={{ height: "100%", overflowY: "scroll" }}
                    displayObjectSize={false}
                    displayDataTypes={false}
                  />
                </JsonStyled>
              </BoxStyled>
            ) : (
              <Formik initialValues={{}} onSubmit={() => {}}>
                {({ values, ...props }) => (
                  <Form>
                    <BaasOtpModal
                      onConfirm={() => onSubmit(values, props)}
                      {...props}
                    />
                    <BoxStyled p={3} theme={theme} sx={{ maxWidth: "400px" }}>
                      <BaasText>Infraction Details (Object TreeView)</BaasText>

                      <BaasGrid container spacing={1} p="16px 0px">
                        <BaasGrid item xs={12}>
                          <BaasTextField
                            fullWidth
                            required
                            name="analysisResult"
                            label="Analysis results"
                            disabled={
                              (filteredData.reportedBy ===
                                "CREDITED_PARTICIPANT" &&
                                filteredData.creditedParticipant === ispb) ||
                              (filteredData.reportedBy ===
                                "DEBITED_PARTICIPANT" &&
                                filteredData.debitedParticipant === ispb) ||
                              filteredData.status !== "ACKNOWLEDGED"
                            }
                            select
                          >
                            <BaasMenuItem value="AGREED">Agreed</BaasMenuItem>
                            <BaasMenuItem value="DISAGREED">
                              Disagreed
                            </BaasMenuItem>
                          </BaasTextField>
                        </BaasGrid>
                        <BaasGrid item xs={12}>
                          <BaasTextField
                            fullWidth
                            name="analysisDetails"
                            label="Analysis details"
                            disabled={
                              (filteredData.reportedBy ===
                                "CREDITED_PARTICIPANT" &&
                                filteredData.creditedParticipant === ispb) ||
                              (filteredData.reportedBy ===
                                "DEBITED_PARTICIPANT" &&
                                filteredData.debitedParticipant === ispb) ||
                              filteredData.status !== "ACKNOWLEDGED"
                            }
                          />
                        </BaasGrid>
                        {values.analysisResult !== "DISAGREED" && (
                          <BaasGrid item xs={12}>
                            <BaasTextField
                              fullWidth
                              required={
                                values.analysisResult === "DISAGREED"
                                  ? false
                                  : true
                              }
                              name="fraudType"
                              label="Fraud type"
                              select
                              disabled={
                                (filteredData.reportedBy ===
                                  "CREDITED_PARTICIPANT" &&
                                  filteredData.creditedParticipant === ispb) ||
                                (filteredData.reportedBy ===
                                  "DEBITED_PARTICIPANT" &&
                                  filteredData.debitedParticipant === ispb) ||
                                filteredData.status !== "ACKNOWLEDGED"
                              }
                            >
                              <BaasMenuItem value="APPLICATION_FRAUD">
                                Application Fraud
                              </BaasMenuItem>
                              <BaasMenuItem value="MULE_ACCOUNT">
                                Mule Account
                              </BaasMenuItem>
                              <BaasMenuItem value="SCAMMER_ACCOUNT">
                                Scammer account
                              </BaasMenuItem>
                              <BaasMenuItem value="OTHER">Other</BaasMenuItem>
                              <BaasMenuItem value="UNKNOWN">
                                Unknown
                              </BaasMenuItem>
                            </BaasTextField>
                          </BaasGrid>
                        )}
                        <BaasGrid item xs={12}>
                          <BaasButton
                            backgroundColor="black"
                            variant="contained"
                            loading={isSubmitting}
                            disabled={
                              (filteredData.reportedBy ===
                                "CREDITED_PARTICIPANT" &&
                                filteredData.creditedParticipant === ispb) ||
                              (filteredData.reportedBy ===
                                "DEBITED_PARTICIPANT" &&
                                filteredData.debitedParticipant === ispb) ||
                              filteredData.status !== "ACKNOWLEDGED"
                            }
                            onClick={() => setOpen(true)}
                          >
                            Close Infraction
                          </BaasButton>
                        </BaasGrid>
                        <BaasGrid item xs={12}>
                          <BaasText color="error">{errorMessage()}</BaasText>
                        </BaasGrid>
                      </BaasGrid>

                      <JsonStyled theme={theme}>
                        <ReactJson
                          name={null}
                          src={filteredData}
                          style={{ height: "100%", overflowY: "scroll" }}
                          displayObjectSize={false}
                          displayDataTypes={false}
                        />
                      </JsonStyled>
                    </BoxStyled>
                  </Form>
                )}
              </Formik>
            )}
          </>
        ) : (
          <BoxStyled p={3} theme={theme} sx={{ maxWidth: "400px" }}>
            <BaasText mb={3}>Infraction Details (Object TreeView)</BaasText>
            <JsonStyled theme={theme}>
              <ReactJson
                name={null}
                src={filteredData}
                style={{ height: "100%", overflowY: "scroll" }}
                displayObjectSize={false}
                displayDataTypes={false}
              />
            </JsonStyled>
          </BoxStyled>
        )}
      </BaasModal>
    </>
  );
};

export default InfractionReportSearchListModal;
