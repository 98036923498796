import BaasText from "components/atoms/Text/BaasText";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import React from "react";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasFlex from "components/atoms/Flex/Flex";
import { useTheme } from "@mui/system";
import BaasGrid from "components/atoms/Grid/Grid";
import CnabSettlementDownload from "./CnabSettlementDownload";
const CnabSettlement = () => {
  const theme = useTheme();

  return (
    <>
      <BaasFlex flexDirection="column" width="100%">
        <BaasPageHeader>CNAB</BaasPageHeader>
        <BaasContentGrid spacing={4}>
          <BaasGrid p="16px 0px" item>
            <BaasText variant="h6">CNAB Settlement</BaasText>
          </BaasGrid>

          <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
            <BaasText>
              Use this session to check and download CNAB Settlement files.
              These files contain the status for the settlement of emitted
              Payment Slips uploaded through CNAB files in the CNAB Interface
              page. These settlement files are generated daily.
            </BaasText>

            <BaasText p="8px 0px">
              List and download CNAB files. Use date ranges to search available
              files.
            </BaasText>
          </BaasGrid>

          <CnabSettlementDownload />
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
};

export default CnabSettlement;
