import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import BaasGrid from "components/atoms/Grid/Grid";
import { sortByName } from "helpers/sortByName";
import { useInterval } from "helpers/useInterval";
import { useEffect, useState } from "react";
import guidGenerator from "services/guidGenerator";
import LandingOurClientsStyles from "./LandingOurClientsStyles";

const LandingOurClientsCarrossel = (props) => {
  const classes = LandingOurClientsStyles();

  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [interval] = useState(6500);
  const [animationSpeed] = useState(1500);

  useEffect(() => {
    setTotalPages(props.pages.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(() => {
    nextSlide();
  }, interval);

  const ClientLogoColection = (page) => {
    return (
      <div>
        <BaasGrid container>
          {page.page.sort(sortByName).map((partnerLogo) => (
            <BaasGrid item xs={4} sm={4} key={guidGenerator()}>
              <div style={{ textAlign: "center" }}>
                <img
                  alt=""
                  src={partnerLogo.logo}
                  className={classes["staticLogo"]}
                />
              </div>
            </BaasGrid>
          ))}
        </BaasGrid>
      </div>
    );
  };

  const changeSlide = (value) => {
    setPageNumber(value);
  };

  const nextSlide = () => {
    let x = 0;
    if (pageNumber + 1 < totalPages) {
      x = pageNumber + 1;
    } else {
      x = 0;
    }
    setPageNumber(x);
  };

  return (
    <>
      <Carousel
        onChange={(value) => changeSlide(value)}
        value={pageNumber}
        animationSpeed={animationSpeed}
        plugins={["infinite"]}
      >
        {props.pages.map((page) => (
          <ClientLogoColection page={page} />
        ))}
      </Carousel>
      <Dots
        value={pageNumber}
        onChange={(value) => changeSlide(value)}
        number={totalPages}
      />
    </>
  );
};

export default LandingOurClientsCarrossel;
