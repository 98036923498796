import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactQueryProvider from "./reactQuery";
import Theme from "./theme";
import AdapterMoment from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AuthProvider } from "./auth";
import { AccountProvider } from "./account";
import { ReactQueryDevtools } from "react-query/devtools";
import { OtpProvider } from "./oneTimePassword";
import "react-toastify/dist/ReactToastify.css";
import BaasToastContainer from "components/atoms/ToastContainer/ToastContainer";

function Providers({ children }) {
  return (
    <Theme>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ReactQueryProvider>
            <OtpProvider>
              <AuthProvider>
                <ReactQueryDevtools initialIsOpen={false} />
                <BaasToastContainer />
                <AccountProvider>{children}</AccountProvider>
              </AuthProvider>
            </OtpProvider>
          </ReactQueryProvider>
        </LocalizationProvider>
      </BrowserRouter>
    </Theme>
  );
}

export default Providers;
