import { Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";
const TextStyle = styled.div``;
const BaasText = ({ children, ...props }) => {
  return (
    <TextStyle>
      <Typography {...props}>{children}</Typography>
    </TextStyle>
  );
};

export default BaasText;
