import { useTheme } from "@mui/system";
import { queryGetPixClaimSearchList } from "api/dictv2-api";
import BaasButton from "components/atoms/Button/Button";
import BaasDatePicker from "components/atoms/DatePicker/DatePicker";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import ClaimSearchList from "./ClaimSearchList";
import { useAccount } from "providers/account";
import _ from "lodash";
import StackCard from "components/atoms/StackCard/StackCard";

const ClaimSearch = () => {
  const { accountType } = useAccount();

  const theme = useTheme();
  const history = useHistory();

  const [data, setData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [queryVariables, setQueryVariables] = useState();
  const [pagination, setPagination] = useState();

  const handleAppendData = (response, oldData) => {
    if (accountType === "Indirect") {
      setPagination({
        hasMoreElements: response.body?.hasMoreElements,
        lastModified:
          response.body?.items[response.body.items.length - 1]?.lastModified,
      });
      setData(
        oldData
          ? _.uniqBy([...oldData, ...response.body?.items], "id")
          : response.body?.items
      );
    } else {
      setData(response.body?.items);
    }
  };

  const handleRedirect = useCallback(() => {
    history.push("/pix/v2/dict-claim/create");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const searchClaims = useCallback(async (values, oldData) => {
    const response = await queryGetPixClaimSearchList(values);
    if (response.status < 300) {
      if (response.body.items.length === 0)
        toast.warning(toastMessages.search.searchEmpty);
      handleAppendData(response, oldData);
    } else {
      if ("errors" in response) {
        response.errors.map((error) => {
          return toast.error(error.message);
        });
      }
      if (oldData) {
        return;
      } else {
        setData([]);
      }
    }
    setIsSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(
    (values) => {
      setIsSubmitting(true);
      const params = {
        modifiedAfter: `${moment(values.modifiedAfter).format(
          "YYYY-MM-DD"
        )}T00:00:00Z`,
        modifiedBefore: `${moment(values.modifiedBefore).format(
          "YYYY-MM-DD"
        )}T23:59:59Z`,
        status: values.status,
        limit: values.limit,
        isDonor: values.participantRole === "isDonor" ? true : undefined,
        isClaimer: values.participantRole === "isClaimer" ? true : undefined,
        type: values.includeDetails,
        taxId: values.taxId,
      };
      setQueryVariables(params);
      searchClaims(params);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [searchClaims]
  );

  const handleRefetch = () => {
    setIsSubmitting(true);
    searchClaims(queryVariables);
  };

  const handleLoadMore = () => {
    const queryPaginatedVariables =
      accountType === "Indirect"
        ? {
            ...queryVariables,
            modifiedAfter: pagination.lastModified,
          }
        : {
            ...queryVariables,
            nextPage: pagination.nextPage,
          };
    searchClaims(queryPaginatedVariables, data);
    setQueryVariables(queryPaginatedVariables);
  };

  return (
    <>
      <Formik
        initialValues={{
          limit: "20",
        }}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, ...props }) => (
          <Form>
            <StackCard>
              <BaasText>
                Use the interface bellow to list all the Pix Claims opened or
                requested by your logged client.
              </BaasText>

              <BaasGrid item p="16px 0px">
                <BaasButton
                  onClick={handleRedirect}
                  variant="contained"
                  backgroundColor="black"
                  type="submit"
                >
                  Create new claim
                </BaasButton>
              </BaasGrid>
              <BaasGrid container spacing={3}>
                <BaasGrid item xs={12} sm={6}>
                  <BaasDatePicker
                    name="modifiedAfter"
                    label="Modified after"
                    fullWidth
                  />
                </BaasGrid>

                <BaasGrid item xs={12} sm={6}>
                  <BaasDatePicker
                    name="modifiedBefore"
                    label="Modified before"
                    fullWidth
                  />
                </BaasGrid>

                {accountType === "Indirect" && (
                  <BaasGrid item xs={12} sm={6}>
                    <BaasTextField
                      fullWidth
                      name="taxId"
                      label="Tax Id"
                      type="text"
                    />
                  </BaasGrid>
                )}

                <BaasGrid item xs={12} sm={6}>
                  <BaasTextField
                    fullWidth
                    name="participantRole"
                    label="Participant role"
                    select
                  >
                    <BaasMenuItem value="isDonor">Donor</BaasMenuItem>
                    <BaasMenuItem value="isClaimer">Claimer</BaasMenuItem>
                  </BaasTextField>
                </BaasGrid>

                <BaasGrid item xs={12} sm={6}>
                  <BaasTextField fullWidth name="status" label="Status" select>
                    <BaasMenuItem value="">--</BaasMenuItem>
                    <BaasMenuItem value="OPEN">Open</BaasMenuItem>
                    <BaasMenuItem value="WAITING_RESOLUTION">
                      Waiting Resolution
                    </BaasMenuItem>
                    <BaasMenuItem value="CONFIRMED">Confirmed</BaasMenuItem>
                    <BaasMenuItem value="CANCELLED">Cancelled</BaasMenuItem>
                    <BaasMenuItem value="COMPLETED">Completed</BaasMenuItem>
                  </BaasTextField>
                </BaasGrid>

                <BaasGrid item xs={12} sm={6}>
                  <BaasTextField
                    fullWidth
                    name="limit"
                    label="Max items displayed"
                    select
                    required
                  >
                    <BaasMenuItem value="20">20</BaasMenuItem>
                    <BaasMenuItem value="50">50</BaasMenuItem>
                    <BaasMenuItem value="100">100</BaasMenuItem>
                  </BaasTextField>
                </BaasGrid>
              </BaasGrid>
              <BaasGrid item p="16px 0px">
                <BaasButton
                  variant="contained"
                  backgroundColor="black"
                  type="submit"
                  loading={isSubmitting}
                >
                  Search
                </BaasButton>
              </BaasGrid>
            </StackCard>
          </Form>
        )}
      </Formik>
      {data.length > 0 && (
        <ClaimSearchList
          data={data}
          loading={isSubmitting}
          handleRefetch={handleRefetch}
          loadMore={handleLoadMore}
          pagination={pagination}
          useLoadMore={accountType === "Indirect"}
        />
      )}
    </>
  );
};

export default ClaimSearch;
