import { useTheme } from "@mui/material";
import React from "react";
import styled from "styled-components";
import BaasText from "../Text/BaasText";

const BaasTableColStyle = styled.div`
  padding: 12px 16px;

  border-bottom: 1px solid ${(props) => props.theme.palette.grey[300]};
  flex: ${(props) => props.flex || 1};
  width: ${(props) => props.width};
`;

const BaasTableCol = ({ children, ...props }) => {
  const theme = useTheme();
  return (
    <BaasTableColStyle theme={theme} {...props}>
      <BaasText variant="caption">{children}</BaasText>
    </BaasTableColStyle>
  );
};

export default BaasTableCol;
