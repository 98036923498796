import { routes } from "constants/routes";

const redirectDashboardValidation = (path) => {
  const findRoute = routes.find((route) => route.path === path);
  if (!findRoute || findRoute.privateRoute) {
    return true;
  }
  return false;
};

export default redirectDashboardValidation;
