import { FormControl, InputAdornment, Zoom } from "@mui/material";
import { Input, InputLabel } from "@mui/material";
import BaasIconButton from "components/atoms/IconButton/IconButton";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import React, { useState } from "react";
import BaasTooltip from "components/atoms/Tooltip/Tooltip";

const BaasTextFieldCopy = ({ value, label }) => {
  const [copy, setCopy] = useState({
    color: "action",
    text: "Copy",
    state: false,
  });
  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setCopy({ color: "success", text: "Copied", state: true });
  };
  return (
    <div
      style={{
        backgroundColor: copy.state ? "rgb(0 150 94 / 10%)" : "inherit",
        borderRadius: "4px",
        width: "100%",
        padding: "8px",
      }}
    >
      <FormControl variant="standard" fullWidth>
        <InputLabel>{label}</InputLabel>
        <Input
          value={value}
          endAdornment={
            <InputAdornment position="start">
              <BaasIconButton size="large" onClick={() => handleCopy()}>
                <BaasTooltip
                  TransitionComponent={Zoom}
                  title={copy.text}
                  placement="top"
                  arrow
                >
                  <FileCopyIcon
                    color={copy.color}
                    sx={{ marginBottom: "12px", marginRight: "-12px" }}
                  />
                </BaasTooltip>
              </BaasIconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  );
};

export default BaasTextFieldCopy;
