import { useTheme } from "@mui/system";
import BaasButton from "components/atoms/Button/Button";
import BaasContainer from "components/atoms/Container/Container";
import { default as BaasGrid } from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import * as yup from "yup";
import React, { useCallback } from "react";
import { ReactComponent as Logo } from "assets/btg-logo-md.svg";
import { useHistory } from "react-router";
import { getPathParams } from "helpers/getPathParams";
import { useQueryGetUserMfaToken } from "api/security-api";
import { useMutation } from "react-query";
import QRCode from "qrcode.react";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import styled from "styled-components";
import { mutationUserMfa } from "api/security-api";
import { toastMessages } from "constants/toastMessages";

const NewOtp = () => {
  const theme = useTheme();
  const history = useHistory();
  const queryToken = getPathParams("t");

  const { data, isError, isLoading } = useQueryGetUserMfaToken(
    {
      params: queryToken,
    },
    { retry: false }
  );

  if (isError) {
    toast.error("Your link is broken");
    history.push("/login");
  }

  const validationSchema = yup.object().shape({
    otpToken: yup
      .string()
      .min(6, "Token must have 6 numbers")
      .required("No password provided."),
  });

  const { mutate } = useMutation((e) => mutationUserMfa(e), {
    onError: (error) => {
      if (error.status === 400) {
        toast.error(toastMessages.newPassword.error[400]);
      } else if (error.status === 403) {
        toast.error(toastMessages.newPassword.error[403]);
      } else if (error.status === 404) {
        toast.error(toastMessages.newPassword.error[404]);
      } else if (error.status === 409) {
        toast.error(toastMessages.newPassword.error[409]);
      } else if (error.status === 428) {
        toast.error(toastMessages.newPassword.error[428]);
      }
    },
    onSuccess: () => {
      toast.success(toastMessages.newOtp.success);
      history.push("/login");
    },
  });

  const onSubmit = useCallback(
    (values, formik) => {
      mutate(
        {
          userId: data.userId,
          code: values.otpToken,
          transactionSession: data.transactionSession,
        },
        {
          onSettled: () => {
            formik.setSubmitting(false);
          },
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  return (
    <>
      <BaasContainer width="100%">
        <Formik
          initialValues={{}}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, values, ...props }) => (
            <Form>
              <BaasGrid container sx={{ marginTop: "32px" }}>
                <BaasGrid xs={0} md={2} />
                <BaasGrid
                  container
                  xs={12}
                  md={8}
                  sx={{
                    backgroundColor: theme.palette.grey[300],
                    borderRadius: "4px",
                  }}
                  p="32px"
                >
                  <BaasGrid item xs={12} style={{ marginBottom: "16px" }}>
                    <Logo />
                  </BaasGrid>

                  <BaasGrid item p="0px 0px 32px 0px">
                    <BaasText variant="h4" color="grey.700">
                      One time password activation
                    </BaasText>
                  </BaasGrid>

                  <BaasGrid item xs={12} sm={7} md={12} lg={7}>
                    <BaasText
                      variant="body1"
                      color="grey.700"
                      sx={{ mb: "16px" }}
                    >
                      Now we are going to register your new one time password.
                      This is a unique password that changes overtime and is
                      used to authenticate users in our production environment.
                    </BaasText>

                    <BaasText
                      variant="body1"
                      color="grey.700"
                      sx={{ mb: "16px" }}
                    >
                      Simply scan the QR code using one of many authenticators,
                      such as Google Authenticator or Microsoft Authenticator
                      and fill the field bellow with the token displayed on the
                      app
                    </BaasText>

                    <BaasText
                      variant="body1"
                      color="grey.700"
                      sx={{ mb: "16px" }}
                    >
                      And place your OTP token bellow.
                    </BaasText>
                  </BaasGrid>

                  <BaasGrid
                    item
                    xs={12}
                    sm={5}
                    md={12}
                    lg={5}
                    display="flex"
                    justifyContent="center"
                  >
                    {isLoading ? (
                      <BaasSkeleton
                        variant="rectangular"
                        width={200}
                        height={200}
                      />
                    ) : (
                      <QRCodeStyle value={data.qrCodeOtpUri} size={200} />
                    )}
                  </BaasGrid>

                  <BaasGrid item xs={12} p="8px 0px">
                    <BaasTextField
                      fullWidth
                      required
                      type="number"
                      name="otpToken"
                      label="OTP token"
                    />
                  </BaasGrid>

                  <BaasGrid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="center"
                    p="16px 0px 0px 0px"
                  >
                    <BaasButton
                      type="submit"
                      size="medium"
                      variant="contained"
                      loading={isSubmitting}
                    >
                      Register OTP
                    </BaasButton>
                  </BaasGrid>
                </BaasGrid>
              </BaasGrid>
            </Form>
          )}
        </Formik>
      </BaasContainer>
    </>
  );
};

export default NewOtp;

const QRCodeStyle = styled(QRCode)`
  text-align: center;
  padding: 16px;
  box-shadow: 0 1px 4px 0 rgba(50, 50, 50, 0.2);
  background: white;
  border-radius: 8px;
`;
