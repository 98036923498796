const { StyledQRCode } = require("./styles")

const QRCode = ({ value, size = 200 }) => {
    return (
        <StyledQRCode
            value={value}
            size={size}
        />
    )
}

export default QRCode;