import { baasApiCall } from "services/apiCall";

export const queryDictHubInfractionRoutesSandbox = (infractionReportId) => {
  return baasApiCall(
    "GET",
    `/pc/mdw/dict-hub/infraction-report/routes/${infractionReportId}`
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryDictHubRefundRoutesSandbox = (refundId) => {
  return baasApiCall("GET", `/pc/mdw/dict-hub/refund/routes/${refundId}`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryDictHubClaimRoutesSandbox = (claimId) => {
  return baasApiCall("GET", `/pc/mdw/dict-hub/claim/routes/${claimId}`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationSandboxPostPixPayment = (payload) => {
  return baasApiCall("POST", `/pc/mdw/qrcode/payment`, payload, true)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryInfractionReportRoutesSandbox = (infractionReportId) => {
  return baasApiCall(
    "GET",
    `/pc/mdw/infraction-report/routes/${infractionReportId}`
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryRefundRoutesSandbox = (refundId) => {
  return baasApiCall("GET", `/pc/mdw/refund/routes/${refundId}`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetRefundExistence = (transactionId) => {
  return baasApiCall(
    "GET",
    `/pc/mdw/refund/validate-refund-created/${transactionId}`
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostPixSandboxRefund = (payload) => {
  return baasApiCall("POST", "/pc/mdw/refund/create", payload)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostPixSandboxReversal = (payload) => {
  return baasApiCall(
    "POST",
    `/pc/mdw/pix/payment/end-to-end/return/in/${payload.endToEndId}`,
    payload,
    true
  )
    .then((data) => data.json())
    .catch((error) => error);
};

export const queryClaimRoutesSandbox = (claimId) => {
  return baasApiCall("GET", `/pc/mdw/claim/routes/${claimId}`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationPostPixClaimCreateSandbox = (payload) => {
  return baasApiCall("POST", "/pc/mdw/claim/create", payload, true)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const queryGetQrCodeInfo = (queryParams) => {
  return baasApiCall("GET", `/pc/mdw/qrcode/info/${queryParams}`)
    .then((data) => data.json())
    .catch((error) => error.json());
};
