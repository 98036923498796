import { mutationPostPixClaimCreateSandbox } from "api/pix-api";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import PixCreateClaimPanel from "components/organism/PixCreateClaimPanel/PixCreateClaimPanel";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import BaasTransactionSuccess from "components/templates/TransactionSuccess/TransactionSuccess";
import { useCustomMutation } from "helpers/useCustomMutation";
import React, { useCallback, useState } from "react";

const PixTestSuitClaim = () => {
  const [selectedPixKey, setSelectedPixKey] = useState();
  const [success, setSuccess] = useState(false);

  const { mutate, isSubmitting } = useCustomMutation(
    mutationPostPixClaimCreateSandbox
  );

  const onSubmit = useCallback(
    () => {
      if (selectedPixKey && selectedPixKey.key) {
        mutate(
          {
            key: selectedPixKey.key,
          },
          (response) => setSuccess(response.body.id)
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedPixKey]
  );

  return (
    <BaasFlex flex={1} flexDirection="column">
      <BaasPageHeader>Pix</BaasPageHeader>
      {!success ? (
        <BaasContentGrid spacing={4}>
          <BaasGrid item spacing={1}>
            <BaasRow>
              <BaasText variant="h6">Pix Test Suit Claim</BaasText>
            </BaasRow>
          </BaasGrid>
          <PixCreateClaimPanel
            selectedPixKey={selectedPixKey}
            setSelectedPixKey={setSelectedPixKey}
            isSubmitting={isSubmitting}
            onSubmit={onSubmit}
          />
        </BaasContentGrid>
      ) : (
        <BaasTransactionSuccess
          redirectUrl={`/pix-test-suit-claim-details/${success}`}
          buttonLabel="Visit Claim Details page"
        />
      )}
    </BaasFlex>
  );
};

export default PixTestSuitClaim;
