import React from "react";
import { useField } from "formik";
import BaasCheckboxDefault from "./CheckboxDefault";

const BaasCheckbox = ({ name, variant = "standard", ...props }) => {
  const [{ value }, , { setValue }] = useField(name);

  return (
    <BaasCheckboxDefault
      checked={value}
      onChange={(event) => setValue(event.target.checked)}
      variant={variant}
      name={name}
      {...props}
    />
  );
};

export default BaasCheckbox;
