import BaasFab from "components/atoms/Fab/Fab";
import InfoIcon from "@mui/icons-material/Info";
import React, { useState } from "react";
import { useAccount } from "providers/account";
import { useQueryGetUserInfo } from "api/security-api";
import BaasText from "components/atoms/Text/BaasText";
import BaasGrid from "components/atoms/Grid/Grid";
import { Tooltip } from "@mui/material";

const BaasUserInfoTooltip = () => {
  const { accountSelected } = useAccount();
  const { data, isLoading } = useQueryGetUserInfo();
  const [open, setOpen] = useState(false);

  const handleTooltipOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        open={open}
        interactive
        title={
          <>
            <BaasGrid container p={2} spacing={1}>
              <BaasGrid item xs={12}>
                <BaasText variant="subtitle2">Secret Key:</BaasText>
                <BaasText variant="caption" style={{ wordBreak: "break-all" }}>
                  {!isLoading ? data.clientSecret : null}
                </BaasText>
              </BaasGrid>

              <BaasGrid item xs={12}>
                <BaasText variant="subtitle2">Client Id:</BaasText>
                <BaasText variant="caption">
                  {!isLoading ? data.clientId : null}
                </BaasText>
              </BaasGrid>

              <BaasGrid item xs={12}>
                <BaasText variant="subtitle2">Account Id:</BaasText>
                <BaasText variant="caption">
                  {accountSelected.accountId}
                </BaasText>
              </BaasGrid>

              <BaasGrid item xs={12}>
                <BaasText variant="subtitle2">Account number:</BaasText>
                <BaasText variant="caption">{accountSelected.account}</BaasText>
              </BaasGrid>

              <BaasGrid item xs={12}>
                <BaasText variant="subtitle2">Account branch:</BaasText>
                <BaasText variant="caption">{accountSelected.branch}</BaasText>
              </BaasGrid>
            </BaasGrid>
          </>
        }
        placement="top-start"
      >
        <BaasFab
          sx={{ position: "fixed", bottom: "16px", right: "18px" }}
          color="primary"
          onClick={handleTooltipOpen}
        >
          <InfoIcon />
        </BaasFab>
      </Tooltip>
    </>
  );
};

export default BaasUserInfoTooltip;
