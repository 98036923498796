import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import { useHistory } from "react-router";

const PixTransactionInputModal = ({ open, setOpen, setTxId }) => {
  const [inputValue, setInputValue] = useState();
  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (value) => {
    setInputValue(value);
  };

  const handleSubmitTxId = () => {
    setTxId(inputValue);
    setOpen(false);
    history.push({
      pathname: `/pix-test-suit-infraction/${inputValue}`,
    });
  };
  // b6f2ae6270044f6caafdc8eb3ad5cfad

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Please, insert a Transaction Id</DialogTitle>
      <DialogContent>
        <DialogContentText>
          With a transaction Id, you can interact with the infraction process,
          if you do not have, you must simulate a payment first.
        </DialogContentText>
        <TextField
          autoFocus
          name="txId"
          margin="dense"
          id="name"
          label="Transaction Id"
          type="text"
          fullWidth
          variant="standard"
          onChange={(e) => handleChange(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() =>
            history.push({
              pathname: `/pix-test-suit`,
            })
          }
        >
          Simulate a payment first
        </Button>
        <Button
          disabled={!inputValue}
          onClick={() => handleSubmitTxId(inputValue)}
        >
          Load Transaction
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PixTransactionInputModal;
