import adyen from "assets/logo_partners/formatted/adyen.png";
import algar from "assets/logo_partners/formatted/algar.png";
import aliPay from "assets/logo_partners/formatted/aliPay.png";
import banqi from "assets/logo_partners/formatted/banqi.png";
import celcoin from "assets/logo_partners/formatted/celcoin.png";
import cloudwalk from "assets/logo_partners/formatted/cloudwalk.png";
import cora from "assets/logo_partners/formatted/cora.png";
import dock from "assets/logo_partners/formatted/dock.png";
import dotz from "assets/logo_partners/formatted/dotz.png";
import hash from "assets/logo_partners/formatted/hash.png";
import n26 from "assets/logo_partners/formatted/n26.png";
import noverde from "assets/logo_partners/formatted/noverde.png";
import prudential from "assets/logo_partners/formatted/prudential.png";
import recargaPay from "assets/logo_partners/formatted/recargaPay.png";
import transfeera from "assets/logo_partners/formatted/transfeera.png";
import wePayOut from "assets/logo_partners/formatted/wePayOut.png";
import fastCash from "assets/logo_partners/formatted/fastCash.png";
import parfin from "assets/logo_partners/formatted/parfin.png";
import swap from "assets/logo_partners/formatted/swap.png";
import wu from "assets/logo_partners/formatted/wu.png";
import aquipay from "assets/logo_partners/formatted/aquipay.png";
import csu from "assets/logo_partners/formatted/csu.png";
import biz from "assets/logo_partners/formatted/biz.png";
import orbitall from "assets/logo_partners/formatted/orbitall.png";
import speedy from "assets/logo_partners/formatted/speedy.png";
import blu from "assets/logo_partners/formatted/blu.png";
import seven from "assets/logo_partners/formatted/seven.png";
import stripe from "assets/logo_partners/formatted/stripe.png";
import sumup from "assets/logo_partners/formatted/sumup.png";
import noveNove from "assets/logo_partners/formatted/noveNove.png";
import contaSimples from "assets/logo_partners/formatted/contaSimples.png";
import creditas from "assets/logo_partners/formatted/creditas.png";
import mercadoBitcoin from "assets/logo_partners/formatted/mercadoBitcoin.png";
import z1 from "assets/logo_partners/formatted/z1.png";
import onniBank from "assets/logo_partners/formatted/onniBank.png";
import mosaico from "assets/logo_partners/formatted/mosaico.png";
import treviso from "assets/logo_partners/formatted/treviso.png";
import verdeCard from "assets/logo_partners/formatted/verdeCard.png";
import jeeves from "assets/logo_partners/formatted/jeeves.png";
import niky from "assets/logo_partners/formatted/niky.png";

import belvo from "assets/logo_partners/formatted/belvo.png";
import cumbuca from "assets/logo_partners/formatted/cumbuca.png";
import friday from "assets/logo_partners/formatted/friday.png";
import geru from "assets/logo_partners/formatted/geru.png";
import iupay from "assets/logo_partners/formatted/iupay.png";
import ngcash from "assets/logo_partners/formatted/ngcash.png";
import ewally from "assets/logo_partners/formatted/ewally.png";
import rooftop from "assets/logo_partners/formatted/rooftop.png";
import pinBank from "assets/logo_partners/formatted/pinBank.png";
import logBank from "assets/logo_partners/formatted/logBank.png";
import acqio from "assets/logo_partners/formatted/acqio.png";
import agili from "assets/logo_partners/formatted/agili.png";
import al5 from "assets/logo_partners/formatted/al5.png";
import amfi from "assets/logo_partners/formatted/amfi.png";
import b3da from "assets/logo_partners/formatted/b3da.png";
import bitso from "assets/logo_partners/formatted/bitso.png";
import entrepay from "assets/logo_partners/formatted/entrepay.png";
import ppro from "assets/logo_partners/formatted/ppro.png";
import clara from "assets/logo_partners/formatted/clara.png"
import bcodex from "assets/logo_partners/formatted/bcodex.png"
import digitalEazy from "assets/logo_partners/formatted/digitalEazy.png"
import mynt from "assets/logo_partners/formatted/mynt.png"
import okx from "assets/logo_partners/formatted/okx.png"
import credifit from "assets/logo_partners/formatted/credifit.png"
import zippi from "assets/logo_partners/formatted/zippi.png"
import autopass from "assets/logo_partners/formatted/autopass.png"
import llz from "assets/logo_partners/formatted/llz.png"
import worldpay from "assets/logo_partners/formatted/worldpay.png"

const partnerLogos = [
  { logo: worldpay, name: "worldpay" },
  { logo: clara, name: "clara" },
  { logo: bcodex, name: "bcodex" },
  { logo: digitalEazy, name: "digitalEazy" },
  { logo: mynt, name: "mynt" },
  { logo: okx, name: "okx" },
  { logo: credifit, name: "credifit" },
  { logo: zippi, name: "zippi" },
  { logo: autopass, name: "autopass" },
  { logo: llz, name: "llz" },
  { logo: entrepay, name: "entrepay" },
  { logo: ppro, name: "ppro" },
  { logo: bitso, name: "bitso" },
  { logo: b3da, name: "b3da" },
  { logo: amfi, name: "amfi" },
  { logo: al5, name: "al5" },
  { logo: agili, name: "agili" },
  { logo: acqio, name: "acqio" },
  { logo: pinBank, name: "pinBank" },
  { logo: logBank, name: "logBank" },
  { logo: belvo, name: "belvo" },
  { logo: cumbuca, name: "cumbuca" },
  { logo: friday, name: "friday" },
  { logo: geru, name: "geru" },
  { logo: iupay, name: "iupay" },
  { logo: ngcash, name: "ngcash" },
  { logo: ewally, name: "ewally" },
  { logo: rooftop, name: "rooftop" },
  {
    logo: jeeves,
    name: "jeeves",
  },
  {
    logo: niky,
    name: "niky",
  },
  {
    logo: verdeCard,
    name: "verdeCard",
  },
  {
    logo: treviso,
    name: "treviso",
  },
  {
    logo: mosaico,
    name: "mosaico",
  },
  {
    logo: onniBank,
    name: "onniBank",
  },
  {
    logo: z1,
    name: "z1",
  },
  {
    logo: mercadoBitcoin,
    name: "mercadoBitcoin",
  },
  {
    logo: creditas,
    name: "creditas",
  },
  {
    logo: contaSimples,
    name: "contaSimples",
  },
  {
    logo: noveNove,
    name: "99",
  },
  {
    logo: sumup,
    name: "sumup",
  },
  {
    logo: biz,
    name: "biz",
  },
  {
    logo: orbitall,
    name: "orbitall",
  },
  {
    logo: speedy,
    name: "speedy",
  },
  {
    logo: csu,
    name: "csu",
  },
  {
    logo: wu,
    name: "wu",
  },
  {
    logo: swap,
    name: "swap",
  },
  {
    logo: parfin,
    name: "parfin",
  },
  {
    logo: fastCash,
    name: "fastCash",
  },
  {
    logo: adyen,
    name: "adyen",
  },
  {
    logo: algar,
    name: "aalgar",
  },
  {
    logo: aliPay,
    name: "aliPay",
  },
  {
    logo: banqi,
    name: "banqi",
  },
  {
    logo: celcoin,
    name: "celcoin",
  },
  {
    logo: cloudwalk,
    name: "cloudwalk",
  },
  {
    logo: cora,
    name: "cora",
  },
  {
    logo: dock,
    name: "dock",
  },
  {
    logo: dotz,
    name: "dotz",
  },
  {
    logo: n26,
    name: "n26",
  },
  {
    logo: noverde,
    name: "noverde",
  },
  {
    logo: prudential,
    name: "prudential",
  },
  {
    logo: recargaPay,
    name: "recargaPay",
  },
  {
    logo: transfeera,
    name: "transfeera",
  },
  {
    logo: wePayOut,
    name: "wePayOut",
  },
  {
    logo: blu,
    name: "blu",
  },
  {
    logo: seven,
    name: "seven",
  },
  {
    logo: stripe,
    name: "stripe",
  },
];

export default partnerLogos;
