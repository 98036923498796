import React from "react";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import { useTheme } from "@mui/system";
import BaasButton from "components/atoms/Button/Button";

const BaasCardDocumentation = ({ item }) => {
  const theme = useTheme();

  const sessionsFormatter = (sessions) => {
    let formattedSessions = "";
    sessions.map((session) => {
      formattedSessions = formattedSessions + session + ", ";
    });
    return formattedSessions.slice(0, -2) + ".";
  };

  return (
    <BaasGrid
      spacing={2}
      p={3}
      sx={{
        height: "100%",
        border: ` 1px solid ${theme.palette.grey[400]}`,
        borderRadius: "4px",
      }}
    >
      <BaasGrid
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <BaasGrid>
          <BaasGrid p="8px 0px">
            <BaasText variant="h6">{item.title}</BaasText>
          </BaasGrid>
          <BaasText variant="body2" color="grey.600">
            {item.description}
          </BaasText>

          <BaasGrid p="8px 0px" display="flex" flexWrap="wrap">
            {item.sessions.length > 0 && (
              <BaasText variant="body2" color="grey.700">
                Sessions: {sessionsFormatter(item.sessions)}
              </BaasText>
            )}
          </BaasGrid>
        </BaasGrid>

        <BaasGrid>
          <BaasButton
            href={item.page}
            variant="outlined"
            backgroundColor="black"
          >
            Go to doc
          </BaasButton>
        </BaasGrid>
      </BaasGrid>
    </BaasGrid>
  );
};

export default BaasCardDocumentation;
