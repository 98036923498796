import { useQuery } from "react-query";
import {
  baasUrlFormatter,
  baasApiRefreshOptions,
} from "helpers/baasApiHelpers";
import { baasApiCall } from "services/apiCall";

// Authentication
export const mutationPostAuthenticationUser = (payload) => {
  return baasApiCall("POST", "/cm/authentication/user", payload).then((data) =>
    data.json()
  );
};

// First access
export const mutationUserPassword = (payload) => {
  return baasApiCall("POST", "/cm/user/password", payload).then((data) => {
    if (data.status === 204) {
      return data;
    }
    if (data.status === 200) {
      return data.json();
    }
  });
};

// Change password
export const mutationUserPasswordNew = (payload) => {
  return baasApiCall("POST", "/cm/user/password/new", payload).then((data) =>
    data.json()
  );
};

export const mutationUserMfa = (payload) => {
  return baasApiCall("POST", "/cm/user/mfa", payload).then((data) => {
    if (data.status === 204) {
      return data;
    }
    if (data.status === 200) {
      return data.json();
    }
  });
};

export const queryGetUserPasswordToken = ({ params }) => {
  return baasApiCall("GET", `/cm/user/password/token/${params}`).then((data) =>
    data.json()
  );
};

export const useQueryGetUserPasswordToken = (pathParams, config) => {
  return useQuery(
    "getAccountBalance",
    () => queryGetUserPasswordToken(pathParams),
    config
  );
};
useQueryGetUserPasswordToken.queryKey = "GetUserPasswordToken";

export const queryGetUserMfaToken = ({ params }) => {
  return baasApiCall("GET", `/cm/user/mfa/token/${params}`).then((data) =>
    data.json()
  );
};

export const useQueryGetUserMfaToken = (pathParams, config) => {
  return useQuery(
    "getAccountBalance",
    () => queryGetUserMfaToken(pathParams),
    config
  );
};
useQueryGetUserMfaToken.queryKey = "GetUserMfaToken";

//Register new otp
export const mutationPostUserMfaNew = (payload) => {
  return baasApiCall("POST", "/cm/user/mfa/new", payload).then((data) =>
    data.json()
  );
};

//Register new User
export const mutationPostUser = (payload) => {
  return baasApiCall("POST", "/cm/user", payload).then((data) => data.json());
};

// Refresh Auth Token
export const mutationPostTokenRefresh = async () => {
  return fetch(
    baasUrlFormatter("/cm/authentication/token/refresh"),
    baasApiRefreshOptions()
  ).then((item) => item.json());
};

//User
export const queryGetUserInfo = () => {
  return baasApiCall("GET", "/cm/user").then((data) => data.json());
};

export const useQueryGetUserInfo = (config) => {
  return useQuery("getUserInfo", () => queryGetUserInfo(), config);
};
useQueryGetUserInfo.queryKey = "getUserInfo";

//User Secret
export const queryGetUserSecret = (otp) => {
  return baasApiCall(
    "GET",
    "/cm/user/with-credentials",
    false,
    otp
  ).then((data) => data.json());
};

export const useQueryGetUserSecret = (config) => {
  return useQuery("getUserSecret", () => queryGetUserSecret(), config);
};
useQueryGetUserSecret.queryKey = "getUserSecret";

//Account
export const queryGetAccountInfo = () => {
  return baasApiCall("GET", "/cm/account").then((data) => data.json());
};

export const useQueryGetAccountInfo = (config) => {
  return useQuery("getAccountInfo", () => queryGetAccountInfo(), config);
};
useQueryGetAccountInfo.queryKey = "getAccountInfo";

//Account Balance
export const queryGetAccountBalance = ({ accountId }) => {
  return baasApiCall("GET", `/cm/account/${accountId}/balance`).then((data) =>
    data.json()
  );
};

export const useQueryGetAccountBalance = (pathParams, config) => {
  return useQuery(
    ["getAccountBalance", pathParams],
    () => queryGetAccountBalance(pathParams),
    config
  );
};
useQueryGetAccountBalance.queryKey = "getAccountBalance, {pathParams}";
