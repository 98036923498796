import { baasApiCall } from "services/apiCall";

function getEndpoint(payload) {
  switch (payload.transactionsTypes) {
    case "externalTransfer":
      return `/cm/receipt/transfer/external/base64/${payload.pactualId}/${payload.templateType}?hidePersonalInfo=${payload.hidePersonalInfo}`;
    case "internalTransfer":
      return `/cm/receipt/transfer/internal/base64/${payload.pactualId}/${payload.templateType}?hidePersonalInfo=${payload.hidePersonalInfo}`;
    case "paymentSlipPay":
      return `/cm/receipt/paymentslip/pay/base64/${payload.pactualId}/${payload.templateType}?hidePersonalInfo=${payload.hidePersonalInfo}`;
    case "paymentSlipReversed":
      return `/cm/receipt/paymentslip/base64/${payload.pactualId}/${payload.templateType}?hidePersonalInfo=${payload.hidePersonalInfo}`;
    case "pixPayment":
      return `/cm/receipt/pix/base64/${payload.pactualId}/${payload.templateType}?hidePersonalInfo=${payload.hidePersonalInfo}`;
    case "pixReversal":
      return `/cm/receipt/pix/reversal/base64/${payload.pactualId}/${payload.templateType}?hidePersonalInfo=${payload.hidePersonalInfo}`;
    default:
      return "";
  }
}

export const queryGetReceipt = (payload, otp) => {
  const endpoint = getEndpoint(payload);
  return baasApiCall("GET", endpoint, false, otp)
    .then((data) => data.json())
    .catch((error) => error.json());
};
