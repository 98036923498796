import { useTheme, Card } from "@mui/material";
import React from "react";

const StackCard = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <Card
      {...props}
      elevation={1}
      sx={{
        background: props.background || theme.palette.grey[100],
        padding: 2,
        ...props.sx,
      }}
    >
      {children}
    </Card>
  );
};

export default StackCard;
