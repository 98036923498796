function capitalizeString(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const labelFormatter = (key) => {
  const words = capitalizeString(key).match(/[A-Z][a-z]+/g);
  let label = "";
  words.map((word) => {
    label = label + word + " ";
    return label;
  });
  return label;
};

export { labelFormatter };
