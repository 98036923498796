import EditIcon from "@mui/icons-material/Edit";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import {
  mutationPostPixInfractionReportSandbox,
  queryGetPixTransaction,
} from "api/pix-api";
import BaasBox from "components/atoms/Box/Box";
import BaasButton from "components/atoms/Button/Button";
import BaasRow from "components/atoms/Grid/Row";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import FaqSection from "components/organism/FaqSection/FaqSection";
import BaasTransactionSuccess from "components/templates/TransactionSuccess/TransactionSuccess";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import PixInfractionPanelSkeleton from "./skeleton";
import EntityCard from "components/molecules/EntityCard/EntityCard";
import { useCustomQuery } from "helpers/useCustomQuery";
import { BackButton } from "components/molecules/BackButton/BackButton";

const PixInfractionReportPanel = ({ props }) => {
  const theme = useTheme();
  const { faqMessages, txId, setOpen } = props;
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validationSchema = yup.object().shape({});

  useEffect(() => {
    if (txId) {
      fetchTransaction({
        values: txId,
        objectFallback: {},
        onNotFoundError: () => {
          setOpen(true);
          toast.error("Invalid Transaction");
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txId]);

  const {
    fetch: fetchTransaction,
    data: txData,
    loading: loadingTx,
  } = useCustomQuery(queryGetPixTransaction);

  const mutateInfractionReport = useCallback(async (values) => {
    setIsSubmitting(true);
    const response = await mutationPostPixInfractionReportSandbox(values);
    if (response.status < 300) {
      toast.success(toastMessages.requestSuccess);
      setOpen(false);
      setSuccess(true);
    } else {
      if ("errors" in response) {
        response.errors.map((error) => {
          return toast.error(error.message);
        });
      }
    }
    setIsSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getInfractionValues = (fraudulentAccount) => {
    const party = fraudulentAccount === "CREDIT" ? "creditParty" : "debitParty";
    return {
      taxIdNumber: txData.body?.[party].taxId,
      reportedBy: "CREDITED_PARTICIPANT",
      debitedParticipant: txData.body?.debitParty?.bank,
      creditedParticipant: txData.body?.creditParty?.bank,
      transactionDate: txData.createTimestamp,
      infractingAccountData: {
        branch: txData.body?.[party].branch,
        accountNumber: txData.body?.[party].account,
      },
    };
  };

  const onSubmit = useCallback(
    (values) => {
      const txId = txData?.body.endToEndId;
      const infractionData = getInfractionValues(values.fraudulentAccount);
      mutateInfractionReport({
        infractionType: "FRAUD",
        reportDetails: values.reportDetails,
        transactionId: txId,
        transactionResult: "SETTLED",
        infractionData,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutateInfractionReport, txData]
  );

  const transactionValues = [
    { label: "Transaction Id", value: txId },
    { label: "Amount", value: `R$ ${txData?.body?.amount}` },
    { label: "Pactual Id", value: txData?.pactualId },
    { label: "E2e Id", value: txData?.body?.endToEndId },
    { label: "Report by", value: "Credited participant" },
    { label: "Credit Party", value: txData?.body?.creditParty?.key },
    { label: "Debit Party", value: txData?.body?.debitParty?.taxId },
    { label: "Infraction Type", value: "Fraud" },
    { label: "Transaction Result", value: "Settled" },
  ];

  if (loadingTx) {
    return <PixInfractionPanelSkeleton />;
  }

  return (
    <>
      <Formik
        initialValues={{}}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ values, ...props }) => (
          <Form>
            {!success ? (
              <BaasRow sx={{ background: theme.palette.grey[100] }}>
                <Box ml={2}>
                  <BackButton path="/pix-test-suit-infractions-list" />
                </Box>
                <BaasRow
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-end"
                  sx={{ background: theme.palette.grey[100] }}
                >
                  <BaasBox mr={2}>
                    <EntityCard
                      active={true}
                      label={"Credited Client"}
                      tooltipTitle="You are now impersonating an action taken by the credited party of this flow."
                    />
                  </BaasBox>
                  <EntityCard
                    active={false}
                    label={"Debited Client"}
                    tooltipTitle="You are now impersonating an action taken by the debited party of this flow."
                  />
                </BaasRow>
                <BaasGrid p="16px">
                  <BaasButton
                    sx={{ marginBottom: 2 }}
                    onClick={() => setOpen(true)}
                    startIcon={<EditIcon />}
                    variant="outlined"
                  >
                    Change Pix Transaction
                  </BaasButton>
                  <BaasText variant="body2">
                    Creates an infraction report. Both the debited and the
                    credited participant can create an infraction report.
                  </BaasText>
                  <BaasRow container column={12} spacing={3}>
                    {txData &&
                      transactionValues.map((row) => (
                        <BaasGrid item xs={12} sm={6}>
                          <BaasInfoDisplay
                            fullWidth
                            label={row.label}
                            value={row.value || "Not available"}
                          />
                        </BaasGrid>
                      ))}
                    {!txData && (
                      <BaasGrid item xs={12} sm={12}>
                        <Typography color="error" mt={2}>
                          Transaction not available
                        </Typography>
                      </BaasGrid>
                    )}
                    {txData && (
                      <>
                        <BaasGrid item xs={12} sm={12}></BaasGrid>
                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            name="fraudulentAccount"
                            label="Fraudulent Account"
                            select
                            required
                          >
                            <BaasMenuItem value="CREDIT">Credit</BaasMenuItem>
                            <BaasMenuItem value="DEBIT">Debit</BaasMenuItem>
                          </BaasTextField>
                        </BaasGrid>
                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            required
                            name="reportDetails"
                            label="Report details"
                          />
                        </BaasGrid>
                      </>
                    )}
                  </BaasRow>
                  <BaasRow
                    container
                    sx={{ justifyContent: " center" }}
                    flexDirection="row"
                  >
                    <BaasGrid p={0} item>
                      <BaasButton
                        type="submit"
                        size="medium"
                        variant="contained"
                        disabled={!txData || txData?.length === 0}
                        loading={isSubmitting}
                      >
                        Create
                      </BaasButton>
                    </BaasGrid>
                  </BaasRow>
                  <BaasBox mt={2}>
                    <FaqSection messages={faqMessages} />
                  </BaasBox>
                </BaasGrid>
              </BaasRow>
            ) : (
              <BaasTransactionSuccess
                buttonLabel="Go to infractions list"
                redirectUrl="/pix-test-suit-infractions-list"
              />
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PixInfractionReportPanel;
