import {
  Box,
  Button,
  FormControlLabel,
  TextField,
  Typography,
  Tooltip,
  IconButton,
  useTheme,
  Checkbox,
} from "@mui/material";

import BaasGrid from "components/atoms/Grid/Grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import StackCard from "components/atoms/StackCard/StackCard";

export default function ReportConfigCard({
  dailyData,
  weeklyData,
  onClickDelete,
  handleUpdate,
  isSubmitting,
}) {
  const theme = useTheme();

  const reportType = dailyData.reportType || weeklyData.reportType || "";
  const description = dailyData.description || "";
  const email = dailyData.email || weeklyData.email || "";

  const [updatedValues, setUpdatedValues] = useState({
    email: email,
    dailyDataChecked: dailyData.checked,
    weeklyDataChecked: weeklyData.checked,
  });

  const checkSaveDisabled = () => {
    if (!updatedValues.weeklyDataChecked && !updatedValues.dailyDataChecked) {
      return true;
    }
    if (
      updatedValues.email.trim().length === 0 &&
      updatedValues.weeklyDataChecked &&
      updatedValues.dailyDataChecked
    ) {
      return true;
    }

    if (
      dailyData.checked !== updatedValues.dailyDataChecked ||
      weeklyData.checked !== updatedValues.weeklyDataChecked ||
      updatedValues.email !== email
    ) {
      return false;
    } else {
      return true;
    }
  };

  const labels = {
    AUTHORIZED_PARTICIPANTS: "Authorized Participants",
    SCORE: "Score",
    INFRACTION: "Infraction",
  };

  const checkIsValidEmail = () => {
    const email = updatedValues.email;

    if (!updatedValues.weeklyDataChecked && !updatedValues.dailyDataChecked) {
      return true;
    }
    if (!email.includes("@") || !email.includes(".")) {
      return false;
    }
    const parts = email.split("@");
    if (parts.length !== 2) {
      return false;
    }

    const [localPart, domain] = parts;
    if (localPart.length === 0 || domain.length === 0) {
      return false;
    }
    const domainParts = domain.split(".");
    if (domainParts.length < 2) {
      return false;
    }

    for (const part of domainParts) {
      if (part.length === 0) {
        return false;
      }
    }
    return true;
  };

  const isValidEmail = checkIsValidEmail();
  return (
    <Box style={{ position: "relative" }}>
      <Typography
        sx={{
          marginTop: "24px",
          marginBottom: "16px",
        }}
        fontWeight="bold"
      >
        {labels[reportType]}
      </Typography>
      <StackCard>
        <BaasGrid container spacing={1}>
          <BaasGrid item xs={12}>
            <Typography mb={1}>{description}</Typography>
          </BaasGrid>
          <BaasGrid item xs={6}>
            <TextField
              label="Email"
              type="email"
              variant="standard"
              value={updatedValues.email}
              fullWidth
              helperText={isValidEmail ? "" : "Invalid email"}
              error={!isValidEmail}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) =>
                setUpdatedValues({
                  ...updatedValues,
                  email: e.target.value,
                })
              }
            />
          </BaasGrid>
          <BaasGrid item xs={12}>
            <FormControlLabel
              label="Daily"
              control={
                <Checkbox
                  checked={updatedValues.dailyDataChecked}
                  onChange={(e) =>
                    setUpdatedValues({
                      ...updatedValues,
                      dailyDataChecked: !updatedValues.dailyDataChecked,
                    })
                  }
                />
              }
            />
            <FormControlLabel
              label="Weekly"
              control={
                <Checkbox
                  checked={updatedValues.weeklyDataChecked}
                  onChange={(e) =>
                    setUpdatedValues({
                      ...updatedValues,
                      weeklyDataChecked: !updatedValues.weeklyDataChecked,
                    })
                  }
                />
              }
            />
          </BaasGrid>
          <BaasGrid item xs={12}>
            <Box display="flex" justifyContent="space-between">
              <Button
                variant="outlined"
                disabled={checkSaveDisabled() || isSubmitting || !isValidEmail}
                onClick={() =>
                  handleUpdate({
                    email: updatedValues.email,
                    dailyDataId: dailyData.id,
                    weeklyDataId: weeklyData.id,
                    dailyDataChecked: updatedValues.dailyDataChecked,
                    weeklyDataChecked: updatedValues.weeklyDataChecked,
                    reportType: reportType,
                    originalDailyData: dailyData,
                    originalWeeklyData: weeklyData,
                  })
                }
              >
                {isSubmitting ? "Updating..." : "Save"}
              </Button>
              <Box>
                <Tooltip title="Remove Report Config">
                  <IconButton
                    component="span"
                    disabled={!dailyData.id && !weeklyData.id}
                    onClick={() =>
                      onClickDelete({
                        ids: [dailyData.id, weeklyData.id],
                        reportType: reportType,
                      })
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </BaasGrid>
        </BaasGrid>
      </StackCard>
    </Box>
  );
}
