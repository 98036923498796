import Backdrop from "@mui/material/Backdrop";
import { useTheme } from "@mui/private-theming";
import { Box } from "@mui/system";
import BaasButton from "components/atoms/Button/Button";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasModal from "components/atoms/Modal/Modal";
import BaasText from "components/atoms/Text/BaasText";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import { Form, Formik } from "formik";
import { useOtp } from "providers/oneTimePassword";
import React from "react";
import ReactJson from "react-json-view";
import styled from "styled-components";

const BoxStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 500px;
  max-width: 700px;

  max-height: 80vh;
  background-color: ${(props) => props.theme.palette.grey[200]};

  box-shadow: 24;
  padding: 4px;
  border-radius: 4px;
  overflow: hidden;
  overflow-y: scroll;

  :focus-visible {
    outline: none;
  }
`;

const JsonStyled = styled(Box)`
  border-radius: 4px;
  background: ${(props) => props.theme.palette.grey[100]};
  max-height: 400px;
  overflow-y: scroll;
  border-radius: 4px;
  padding: 8px;
`;

export default function EntrySearchListInfoModal({ modal, setModal }) {
  const theme = useTheme();
  const { setOpen } = useOtp();
  const handleClose = () => {
    setModal({ ...modal, state: false });
  };

  return (
    <>
      <BaasModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modal.state}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Formik initialValues={{}} onSubmit={() => setOpen(true)}>
          <Form>
            <BaasOtpModal />
            <BoxStyled p={3} theme={theme}>
              <BaasGrid container spacing={2} p="16px 0px">
                <BaasText>Pix Details (Object TreeView)</BaasText>
              </BaasGrid>

              <JsonStyled theme={theme}>
                <ReactJson
                  name={null}
                  src={modal.data}
                  style={{ height: "100%", overflowY: "scroll" }}
                  displayObjectSize={false}
                  displayDataTypes={false}
                />
              </JsonStyled>
              <BaasGrid
                item
                container
                sx={{ justifyContent: "space-between" }}
                flexDirection="row"
                p="16px 0px"
              >
                <BaasButton
                  type="submit"
                  size="medium"
                  variant="outlined"
                  backgroundColor="black"
                  onClick={() => handleClose()}
                >
                  Close
                </BaasButton>
              </BaasGrid>
            </BoxStyled>
          </Form>
        </Formik>
      </BaasModal>
    </>
  );
}
