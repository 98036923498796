import React, { useCallback, useMemo } from "react";
import Select from "@mui/material/Select";
import styled from "styled-components";
import { useTheme } from "@mui/system";
import { useField, useFormikContext } from "formik";
import { Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
const SelectStyle = styled.div``;

const BaasSelect = ({
  name,
  variant = "standard",
  type = "text",
  ...props
}) => {
  const theme = useTheme();

  const [{ value }, { error }, { setValue }] = useField(name);

  const { submitCount } = useFormikContext();

  const showError = useMemo(() => !!error && submitCount > 0, [
    submitCount,
    error,
  ]);

  const helperText = useMemo(() => {
    if (showError) {
      return <Typography variant="body2">{error}</Typography>;
    }

    return null;
  }, [showError, error]);

  const onChange = useCallback(
    (event) => {
      setValue(event.target.value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  return (
    <SelectStyle theme={theme}>
      <FormControl variant="standard" sx={{ width: "100%", margin: "8px 0px" }}>
        <InputLabel id="demo-simple-select-standard-label" {...props}>
          {props.label}
        </InputLabel>
        <Select
          sx={{ "& .MuiFilledInput-root:before": { borderStyle: "none" } }}
          variant={variant}
          name={name}
          error={showError && error}
          helperText={helperText}
          onChange={onChange}
          value={value}
          type={type}
          {...props}
        />
      </FormControl>
    </SelectStyle>
  );
};

export default BaasSelect;
