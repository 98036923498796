import BaasBox from "components/atoms/Box/Box";
import BaasGrid from "components/atoms/Grid/Grid";
import { StepsNavigator } from "components/molecules/StepsNavigator/StepsNavigator";
import FaqSection from "components/organism/FaqSection/FaqSection";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import BaasText from "components/atoms/Text/BaasText";
import BaasButton from "components/atoms/Button/Button";
import {
  StyledInfoWrapper,
  StyledLabel,
  StyledPanelTitle,
  StyledPanelWrapper,
  StyledQrWrapper,
  StyledValue,
} from "./styles";
import QRCode from "components/atoms/QRCode";
import { queryGetLocationBrCodeImage } from "api/pix-api";
import PixQrCodeViewPanelSkeleton from "./skeleton";
import { mutationSandboxPostPixPayment } from "api/pix-api";
import { toastMessages } from "constants/toastMessages";
import PixQrCodeTxPooling from "components/organism/PixQrCodeTxPooling.js/PixQrCodeTxPooling";
import PaymentIcon from "@mui/icons-material/Payment";

export const PixPaymentPanel = ({ props }) => {
  const {
    emvQrCode,
    handleNext,
    handlePrevious,
    faqMessages,
    dinamicQrData,
    // setNextEnabled,
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingQr, setLoadingQr] = useState(false);
  const [qrCodeData, setQrCodeData] = useState();
  const [qrImageUrl, setQrImageUrl] = useState(undefined);
  const [paymentTxId, setPaymentTxId] = useState();

  const queryGetQRCodeImage = useCallback(async (values) => {
    setLoadingQr(true);
    const responseImage = await queryGetLocationBrCodeImage(values);
    if (responseImage.status < 300) {
      setQrImageUrl(responseImage.url);
    } else {
      if ("errors" in responseImage) {
        responseImage.errors.map((error) => toast.error(error.message));
      } else toast.error("Your link is broken");
    }

    setLoadingQr(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dinamicQrData.body?.loc?.pactualId) {
      queryGetQRCodeImage(dinamicQrData.body.loc.pactualId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dinamicQrData]);

  const onPrevious = () => {
    setQrCodeData(undefined);
    handlePrevious();
  };

  const mutatePayment = useCallback(
    async (values) => {
      const response = await mutationSandboxPostPixPayment(values);
      if (response.status < 300) {
        setPaymentTxId(response.body.body.transactionIdentification);
        toast.success(toastMessages.requestSuccess);
      } else {
        if ("errors" in response) {
          response.errors.map((error) => {
            return toast.error(error.message);
          });
        }
      }
      setIsSubmitting(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [qrCodeData]
  );

  const onSubmit = useCallback(
    async (values) => {
      setIsSubmitting(true);
      mutatePayment({
        emv: dinamicQrData?.body.loc.body.emv,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [qrCodeData]
  );

  return (
    <BaasGrid>
      {paymentTxId ? (
        <PixQrCodeTxPooling paymentTxId={paymentTxId} />
      ) : loadingQr ? (
        <PixQrCodeViewPanelSkeleton />
      ) : (
        <>
          <StyledPanelWrapper container spacing={1} p={2}>
            <StyledPanelTitle item md={12} mb={2}>
              <BaasText variant="body2" fontWeight="bold">
                With this QR Code you can proceed to payment process.
              </BaasText>
            </StyledPanelTitle>
            <StyledInfoWrapper item xs={12} sm={12} md={6}>
              <BaasBox minWidth="100px">
                <StyledLabel>
                  Amount:{" "}
                  <StyledValue>
                    R${dinamicQrData?.body?.amount?.original}
                  </StyledValue>
                </StyledLabel>
                <StyledLabel>
                  Merchant Name:{" "}
                  <StyledValue>
                    {dinamicQrData?.body?.loc?.body?.merchant?.name}
                  </StyledValue>
                </StyledLabel>
                <StyledLabel>
                  Country:
                  <StyledValue>
                    {dinamicQrData?.body?.loc?.body?.merchant?.city}
                  </StyledValue>
                </StyledLabel>
              </BaasBox>
            </StyledInfoWrapper>
            <StyledQrWrapper item xs={12} sm={12} md={6} mb={2}>
              {qrImageUrl && <QRCode value={qrImageUrl} />}
            </StyledQrWrapper>
          </StyledPanelWrapper>
          <BaasBox ml={1.5} display="flex" justifyContent="center" width="100%">
            <BaasButton
              variant="contained"
              onClick={onSubmit}
              startIcon={<PaymentIcon />}
              loading={isSubmitting}
            >
              Pay QR Code
            </BaasButton>
          </BaasBox>
        </>
      )}
      <StepsNavigator
        previousLabel={emvQrCode ? "Restart Payment" : "Previous Step"}
        handleNext={handleNext}
        handlePrevious={
          emvQrCode ? () => window.location.reload(false) : onPrevious
        }
        previousDisabled={false}
        nextDisabled={true}
      />
      <BaasBox>
        <FaqSection messages={faqMessages} />
      </BaasBox>
    </BaasGrid>
  );
};

export default PixPaymentPanel;
