import { useTheme } from "@mui/system";
import { mutationPostPixDictRefund } from "api/dictv2-api";
import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasTextFieldCurrency from "components/molecules/TextFieldCurrency/TextFieldCurrency";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import BaasTransactionSuccess from "components/templates/TransactionSuccess/TransactionSuccess";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import { useAccount } from "providers/account";
import { useOtp } from "providers/oneTimePassword";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import * as yup from "yup";
import AccountTypeSelectedLabel from "components/molecules/AccountTypeSelectedLabel/AccountTypeSelectedLabel";

const RefundCreateV2 = () => {
  const theme = useTheme();
  const { accountType } = useAccount();
  const { setOpen, isOtpRequired } = useOtp();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const history = useHistory();

  const initialValues = useMemo(() => {
    return {};
  }, []);

  const validationSchema = yup.object().shape({});

  const mutateRefund = useCallback(async (values) => {
    const response = await mutationPostPixDictRefund(values);
    if (response.status < 300) {
      toast.success(toastMessages.requestSuccess);
      setSuccess(true);
    } else {
      if ("errors" in response) {
        response.errors.map((error) => {
          return toast.error(error.message);
        });
      }
    }
    setIsSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (accountType === "Merchant") {
      handleBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountType]);

  const handleBack = () => {
    history.push("/pix/v2/refund");
  };

  const onSubmit = useCallback(
    (values) => {
      setIsSubmitting(true);
      mutateRefund({
        otp: values.otp,
        refundReason: values.refundReason,
        transactionId: values.transactionId,
        refundAmount: values.refundAmount.toFixed(2),
        refundDetails: values.refundDetails,
        accountType: accountType,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutateRefund, setOpen]
  );

  const refundReasons = [
    {
      value: "FRAUD",
      label: "Fraud",
    },
    {
      value: "OPERATIONAL_FLAW",
      label: "Operational Flaw",
    },
    {
      value: "REFUND_CANCELLED",
      label: "Refund Cancelled",
    },
    {
      value: "INVALID_REQUEST",
      label: "Invalid Request"
    },
    {
      value: "OTHER",
      label: "Other"
    }
  ];

  const filteredReasons =
    accountType === "Merchant"
      ? refundReasons.filter((reason) => {
        return reason.value !== "OPERATIONAL_FLAW";
      })
      : refundReasons;

  return (
    <>
      <BaasFlex flex={1} flexDirection="column">
        <BaasPageHeader>DICT Hub</BaasPageHeader>

        <BaasContentGrid spacing={4}>
          <Formik
            initialValues={initialValues}
            onSubmit={isOtpRequired ? () => setOpen(true) : onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, ...props }) => (
              <Form>
                <BaasOtpModal
                  onConfirm={() => onSubmit(values, props)}
                  {...props}
                />
                <BaasGrid p="16px 0px" item xs={12}>
                  <BaasGrid display="flex" flexDirection="row">
                    <BaasText variant="h6">Create New Refund (MED)</BaasText>
                    <AccountTypeSelectedLabel />
                  </BaasGrid>
                </BaasGrid>

                {!success ? (
                  <BaasRow>
                    <BaasGrid
                      p="16px"
                      sx={{ background: theme.palette.grey[100] }}
                    >
                      <BaasText variant="body2">
                        The refund request can be made by the paying user's PSP,
                        on its own initiative or at the user's request, in cases
                        where there is a well-founded suspicion of using the
                        device to practice fraud and in those where there is an
                        operational failure in the information technology system
                        of any of the participants involved in the transaction.
                        A return request can have the following states in the
                        DICT:
                      </BaasText>

                      <BaasRow container column={12} spacing={3}>
                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            name="refundReason"
                            label="Refund reason"
                            select
                            required
                          >
                            {filteredReasons.map((reason) => (
                              <BaasMenuItem
                                key={reason.value}
                                value={reason.value}
                              >
                                {reason.label}
                              </BaasMenuItem>
                            ))}
                          </BaasTextField>
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            required
                            name="transactionId"
                            label="Transaction Id (End-to-End Id/Return Identification)"
                          />
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextFieldCurrency
                            fullWidth
                            name="refundAmount"
                            label="Refund amount"
                          />
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            name="refundDetails"
                            label="Refund details"
                          />
                        </BaasGrid>
                      </BaasRow>

                      <BaasRow
                        container
                        sx={{ justifyContent: "space-between" }}
                        flexDirection="row"
                      >
                        <BaasGrid p={0} item>
                          <BaasButton
                            type="submit"
                            size="medium"
                            variant="outlined"
                            backgroundColor="black"
                            onClick={handleBack}
                            loading={isSubmitting}
                          >
                            Back
                          </BaasButton>
                        </BaasGrid>
                        <BaasGrid p={0} item>
                          <BaasButton
                            type="submit"
                            size="medium"
                            variant="contained"
                            backgroundColor="black"
                            loading={isSubmitting}
                          >
                            Create
                          </BaasButton>
                        </BaasGrid>
                      </BaasRow>
                    </BaasGrid>
                  </BaasRow>
                ) : (
                  <BaasTransactionSuccess redirectUrl="/pix/v2/refund" />
                )}
              </Form>
            )}
          </Formik>
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
};

export default RefundCreateV2;
