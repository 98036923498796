import CheckIcon from "@mui/icons-material/Check";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { CircularProgress } from "@mui/material";
import { useTheme } from "@mui/system";
import { queryGetPixTransaction } from "api/pix-api";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import { AddButton } from "components/molecules/AddButton/AddButton";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import { currencyFormatter } from "helpers/currencyFormatter";
import { useInterval } from "helpers/useInterval";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";

export default function PixQrCodeTxPooling({ paymentTxId }) {
  const theme = useTheme();
  const history = useHistory();
  const [delay, setDelay] = useState(2000);
  const [retryCount, setRetryCount] = useState(0);
  const [state, setState] = useState({
    urlPactuaId: null,
    urlStatus: null,
    trStatus: null,
    trData: null,
    poolingCount: 0,
    error: false,
    useBaas: JSON.parse(sessionStorage.getItem("useBaas")),
  });

  useEffect(() => {
    toastControl(state.trStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.trStatus]);

  const updateTr = async () => {
    const trData = await queryGetPixTransaction(paymentTxId);
    if (trData.status < 300 && state.poolingCount < 30) {
      if (trData.body.status === "CONFIRMED" || trData.body.status === "ERROR")
        setState({
          ...state,
          trStatus: trData.body.status,
          poolingCount: state.poolingCount + 1,
          trData: trData,
        });
      setDelay(null);
    } else {
      if (retryCount < 5 && trData.status === 404) {
        setRetryCount(retryCount + 1);
      } else {
        setDelay(null);
        setState({
          ...state,
          error: true,
        });
        if ("errors" in trData) {
          trData.errors.map((error) => {
            return toast.error(error.message);
          });
        }
      }
    }
  };

  useInterval(updateTr, delay);

  const toastControl = (status) => {
    if (status) {
      if (status === "CONFIRMED") {
        return toast.success(`Transaction status update: ${status}`);
      } else if (status === "ERROR") {
        return toast.error(`Transaction status update: ${status}`);
      } else {
        return toast.info(`Transaction status update: ${status}`);
      }
    }
  };

  const redirectToInfraction = () => {
    history.push({
      pathname: `/pix-test-suit-infraction/${state.trData?.body?.body?.transactionIdentification}`,
    });
  };

  return (
    <>
      <BaasFlex flexDirection="column" width="100%">
        <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
          <div style={{ textAlign: "center" }}>
            {state.trData?.body.status === "CONFIRMED" ? (
              <>
                <CheckIcon color="success" fontSize="large" />
                <BaasText textAlign="center" variant="h5" color="success.main">
                  Request successful!
                </BaasText>
              </>
            ) : null}
            {state.trData?.body.status !== "CONFIRMED" &&
            state.trData?.body.status !== "ERROR" &&
            !state.error ? (
              <div
                style={{
                  minHeight: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress color="inherit" />
                <BaasText textAlign="center" variant="h6">
                  {state.trData
                    ? state.trData.status
                    : "Loading transaction status..."}
                </BaasText>
              </div>
            ) : null}
            {state.error || state.trData?.body.status === "ERROR" ? (
              <>
                <DoDisturbIcon sx={{ color: "#D22D4B" }} fontSize="large" />
                <BaasText textAlign="center" variant="h5" color="error.main">
                  {state.trData?.status === "ERROR"
                    ? "An error ocurred in this transaction"
                    : "Failed to load transaction status..."}
                </BaasText>
              </>
            ) : null}
          </div>
        </BaasGrid>
        {state.trData !== null ? (
          <>
            <BaasRow item spacing={1}>
              <BaasText variant="h6">Transaction Information</BaasText>
            </BaasRow>
            <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
              <BaasRow container spacing={1}>
                <BaasGrid item xs={12} md={6}>
                  <BaasInfoDisplay
                    label={"EndToEnd Id:"}
                    value={state.trData.body.body?.endToEndId}
                  />
                </BaasGrid>
                <BaasGrid item xs={12} md={6}>
                  <BaasInfoDisplay
                    label={"Transaction Id:"}
                    value={state.trData.body.body.transactionIdentification}
                  />
                </BaasGrid>
                <BaasGrid item xs={12} md={6}>
                  <BaasInfoDisplay
                    label={"Amount:"}
                    value={`R$ ${currencyFormatter(
                      state.trData.body.body.amount
                    )}`}
                  />
                </BaasGrid>
              </BaasRow>
            </BaasGrid>
            <BaasRow
              item
              spacing={1}
              display="flex"
              justifyContent="center"
              width="100%"
            >
              <AddButton
                onClick={redirectToInfraction}
                label="Create Infraction"
              />
            </BaasRow>
            <BaasRow item spacing={1}>
              <BaasText variant="h6">Creditor Account Information</BaasText>
            </BaasRow>
            <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
              <BaasRow container spacing={1}>
                <BaasGrid item xs={12} md={6}>
                  <BaasInfoDisplay
                    label={"Name:"}
                    value={state.trData.body.body.creditParty.name}
                  />
                </BaasGrid>
                <BaasGrid item xs={12} md={6}>
                  <BaasInfoDisplay
                    label={"CPF/CNPJ:"}
                    value={state.trData.body.body.creditParty.taxId}
                  />
                </BaasGrid>
                <BaasGrid item xs={12} md={6}>
                  <BaasInfoDisplay
                    label={"Account Number:"}
                    value={state.trData.body.body.creditParty.account}
                  />
                </BaasGrid>
                <BaasGrid item xs={12} md={6}>
                  <BaasInfoDisplay
                    label={"Account Type:"}
                    value={state.trData.body.body.creditParty.accountType}
                  />
                </BaasGrid>
                <BaasGrid item xs={12} md={6}>
                  <BaasInfoDisplay
                    label={"Person Type:"}
                    value={state.trData.body.body.creditParty.personType}
                  />
                </BaasGrid>
              </BaasRow>
            </BaasGrid>
          </>
        ) : null}
      </BaasFlex>
    </>
  );
}
