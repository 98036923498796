import { Tooltip } from "@mui/material";
import React from "react";
import styled from "styled-components";
const TooltipStyle = styled.div``;
const BaasTooltip = ({ children, ...props }) => {
  return (
    <TooltipStyle>
      <Tooltip {...props}>{children}</Tooltip>
    </TooltipStyle>
  );
};

export default BaasTooltip;
