import BaasGrid from "components/atoms/Grid/Grid";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import React, { useCallback, useEffect, useState } from "react";
import ReportConfigCard from "./components/ReportConfigCard";

const initialObject = {
  0: {
    description:
      "This report contains the updated list of regulated Betting companies. This list is updated daily by the Brazilian Central Bank.",
    email: "",
    periodicity: "DAILY",
    checked: false,
    id: "",
    reportType: "AUTHORIZED_PARTICIPANTS",
  },
  1: {
    email: "",
    periodicity: "WEEKLY",
    checked: false,
    id: "",
    reportType: "AUTHORIZED_PARTICIPANTS",
  },
  2: {
    description:
      "This report contains the relation of Infraction Reports that a BTG Client has had during it's ongoing operation.",
    email: "",
    periodicity: "DAILY",
    checked: false,
    id: "",
    reportType: "INFRACTION",
  },
  3: {
    email: "",
    periodicity: "WEEKLY",
    checked: false,
    id: "",
    reportType: "INFRACTION",
  },
  4: {
    description:
      "This report contains the relation of TaxIds Score that transactioned through this client's operation.",
    email: "",
    periodicity: "DAILY",
    checked: false,
    id: "",
    reportType: "SCORE",
  },
  5: {
    email: "",
    periodicity: "WEEKLY",
    checked: false,
    id: "",
    reportType: "SCORE",
  },
};

export default function PixLimitValidatorReportsConfigList({
  data,
  loading,
  setSelectedItem,
  handleRefetch,
  onClickDelete,
  isSubmitting,
  onSubmitSaveReportConfig,
}) {
  const [initialState, setInitialState] = useState(initialObject);

  const handleSaveReportConfig = useCallback(
    (values, resetForm) => {
      let itemsToDelete = [];
      let itemsToUpdate = [];
      let itemsToCreate = [];
      if (values.dailyDataId !== "" && !values.dailyDataChecked) {
        if (values.originalDailyData.checked) {
          itemsToDelete.push(values.dailyDataId);
        }
      }

      if (values.weeklyDataId !== "" && !values.weeklyDataChecked) {
        if (values.originalWeeklyData) {
          itemsToDelete.push(values.weeklyDataId);
        }
      }

      const originalEmail =
        values.originalDailyData.email || values.originalWeeklyData.email;

      if (values.email !== originalEmail) {
        if (values.dailyDataId) {
          itemsToUpdate.push({
            id: values.dailyDataId,
            email: values.email,
            reportType: values.reportType,
            periodicity: values.dailyDataChecked ? "DAILY" : "WEEKLY",
          });
        }
      }

      if (values.dailyDataId === "" && values.dailyDataChecked) {
        if (!values.originalDailyData.checked) {
          itemsToCreate.push({
            email: values.email,
            reportType: values.reportType,
            periodicity: "DAILY",
          });
        }
      }

      if (values.weeklyDataId === "" && values.weeklyDataChecked) {
        if (!values.originalWeeklyData.checked) {
          itemsToCreate.push({
            email: values.email,
            reportType: values.reportType,
            periodicity: "WEEKLY",
          });
        }
      }

      onSubmitSaveReportConfig(itemsToCreate, itemsToDelete, itemsToUpdate);
    },
    [onSubmitSaveReportConfig]
  );

  const getMergedObject = (config) => {
    const configFound = data.find(
      (obj) =>
        obj.reportType === config?.reportType &&
        obj.periodicity === config?.periodicity
    );

    if (!configFound) {
      return config;
    } else {
      return {
        email: configFound.email,
        periodicity: configFound.periodicity,
        checked: true,
        id: configFound.id,
        reportType: configFound.reportType,
        description: config.description,
      };
    }
  };

  const populateMergedObject = () => {
    if (data.length === 0) {
      setInitialState(initialObject);
    }
    if (data.length > 0) {
      let newState = [];
      [0, 1, 2, 3, 4, 5].forEach((idx) => {
        const mergedObject = getMergedObject(initialObject[idx]);
        newState.push(mergedObject);
      });
      setInitialState(newState);
    }
  };
  useEffect(() => {
    populateMergedObject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (loading) {
    return (
      <>
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
        <BaasSkeleton width="100%" height="65px" />
      </>
    );
  }

  return (
    <BaasGrid>
      <ReportConfigCard
        handleUpdate={handleSaveReportConfig}
        isSubmitting={isSubmitting}
        dailyData={initialState[0]}
        weeklyData={initialState[1]}
        onClickDelete={onClickDelete}
      />
      <ReportConfigCard
        handleUpdate={handleSaveReportConfig}
        isSubmitting={isSubmitting}
        dailyData={initialState[2]}
        weeklyData={initialState[3]}
        onClickDelete={onClickDelete}
      />
      <ReportConfigCard
        handleUpdate={handleSaveReportConfig}
        isSubmitting={isSubmitting}
        dailyData={initialState[4]}
        weeklyData={initialState[5]}
        onClickDelete={onClickDelete}
      />
    </BaasGrid>
  );
}
