import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { useTheme } from "@mui/system";
import React from "react";
import styled from "styled-components";
import SaveIcon from "@mui/icons-material/Save";
const especialColors = (props) => {
  if (props.disabled && props.backgroundColor) {
    return `
      opacity: 0.7;
      `;
  }
  if (props.backgroundColor === "black" && props.variant === "contained") {
    return `
      background-color: ${props.theme.palette.grey[700]};
      color: ${props.theme.palette.grey[100]};
      :hover {
      background-color: ${props.theme.palette.grey[800]};
      }
      `;
  }
  if (props.backgroundColor === "white" && props.variant === "contained") {
    return `
      background-color: ${props.theme.palette.grey[100]};
      color: ${props.theme.palette.grey[800]};
      :hover {
      background-color: ${props.theme.palette.grey[300]};
      }
      `;
  }
  if (props.backgroundColor === "black" && props.variant === "outlined") {
    return `
      border: 1px solid ${props.theme.palette.grey[700]};
      color: ${props.theme.palette.grey[700]};
      :hover {
      border: 1px solid  ${props.theme.palette.grey[800]};
      background: ${props.theme.palette.grey[200]};
      }
      `;
  }
  if (props.backgroundColor === "white" && props.variant === "outlined") {
    return `
      border: 1px solid ${props.theme.palette.grey[200]};
      color: ${props.theme.palette.grey[200]};
      :hover {
      color: ${props.theme.palette.grey[100]};
      border: 1px solid ${props.theme.palette.grey[100]};
      background-color: rgba(255,255,255,0.1);
      }
      `;
  }
  if (props.backgroundColor === "black" && props.variant === "text") {
    return `
      color: ${props.theme.palette.grey[700]};
      :hover {
      background: ${props.theme.palette.grey[200]};
      }
      `;
  }
  if (props.backgroundColor === "white" && props.variant === "text") {
    return `
      color: ${props.theme.palette.grey[200]};
      :hover {
      color: ${props.theme.palette.grey[100]};
      background-color: rgba(255,255,255,0.1);
      }
      `;
  }
};

const ButtonStyle = styled.div`
  & .MuiButton-root {
    border-radius: 4px;
    padding-inline: 16px;

    ${(props) => especialColors(props)}
  }
  & .MuiButton-containedSizeLarge {
    padding-inline: 24px;
    height: 56px;
  }
  & .MuiButton-outlinedSizeLarge {
    padding-inline: 24px;
    height: 56px;
  }
  & .MuiButton-textSizeLarge {
    padding-inline: 24px;
    height: 56px;
  }
  & .MuiButton-containedSizeMedium {
    padding-inline: 24px;
    height: 48px;
  }
  & .MuiButton-outlinedSizeMedium {
    padding-inline: 24px;
    height: 48px;
  }
  & .MuiButton-textSizeMedium {
    padding-inline: 24px;
    height: 48px;
  }
`;

const BaasButton = ({
  disabled = false,
  variant,
  backgroundColor,
  children,
  loading,
  ...props
}) => {
  const theme = useTheme();
  return (
    <ButtonStyle
      disabled={disabled}
      variant={variant}
      backgroundColor={backgroundColor}
      theme={theme}
    >
      {loading ? (
        <LoadingButton
          loading
          variant={variant}
          loadingPosition="start"
          startIcon={<SaveIcon />}
          {...props}
        >
          {children}
        </LoadingButton>
      ) : (
        <Button disabled={disabled} variant={variant} {...props}>
          {children}
        </Button>
      )}
    </ButtonStyle>
  );
};

export default BaasButton;
