import { Alert, Grid } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { useTheme } from "@mui/private-theming";
import {
  mutationPutWebhookNotificationByEntityReplay,
  queryGetWebhookNotificationByEntityReplay,
} from "api/webhooks-api";
import BaasButton from "components/atoms/Button/Button";
import BaasCheckbox from "components/atoms/Checkbox/Checkbox";
import BaasDateTimePicker from "components/atoms/DatePicker/DateTimePicker";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasModal from "components/atoms/Modal/Modal";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";

const BoxStyled = styled(Grid)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  max-width: 600px;

  max-height: 70vh;
  background-color: ${(props) => props.theme.palette.grey[200]};

  box-shadow: 24;
  padding: 4px;
  border-radius: 4px;
  overflow: hidden;
  overflow-y: scroll;

  :focus-visible {
    outline: none;
  }
`;

const WebhookConfigResourceContentActionsResendModal = ({
  entity,
  setModal,
  modal,
}) => {
  const theme = useTheme();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(true);

  const handleClose = useCallback(() => {
    setData();
    setModal({ state: false });
    setSnackbarOpen(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData, setModal]);

  const handleCheck = async (values) => {
    setLoading(true);
    const queryParams = {
      dateFrom: moment(values.dateFrom)
        .add(3, "hours")
        .format("YYYY-MM-DDTHH:mm:ss"), // YYYY-MM-DD or YYYY-MM-DDTHH:mm:ss
      dateTo: moment(values.dateTo)
        .add(3, "hours")
        .format("YYYY-MM-DDTHH:mm:ss"),
      onlyNotDelivered: values.onlyNotDelivered,
    };

    const response = await queryGetWebhookNotificationByEntityReplay(
      entity,
      queryParams
    );
    setData(response);
    setLoading(false);
  };

  const handleResend = async (values) => {
    setLoading(true);
    const queryParams = {
      dateFrom: moment(values.dateFrom)
        .add(3, "hours")
        .format("YYYY-MM-DDTHH:mm:ss"), // YYYY-MM-DD or YYYY-MM-DDTHH:mm:ss
      dateTo: moment(values.dateTo)
        .add(3, "hours")
        .format("YYYY-MM-DDTHH:mm:ss"),
      onlyNotDelivered: values.onlyNotDelivered,
    };
    const payload = {
      otp: values.otp,
    };
    const response = await mutationPutWebhookNotificationByEntityReplay(
      entity,
      queryParams,
      payload
    );
    if (response.status < 300) {
      toast.success(toastMessages.requestSuccess);
      handleClose();
      setLoading(false);
    } else {
      if ("errors" in response) {
        response.errors.map((error) => {
          return toast.error(error.message);
        });
      }
      setLoading(false);
    }
  };

  const initialValues = useMemo(() => {
    return {
      dateFrom: null,
      dateTo: null,
      onlyNotDelivered: true,
    };
  }, []);

  return (
    <>
      <BaasModal
        open={modal.state}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <BoxStyled p={3} theme={theme}>
          <BaasGrid p="4px 0px">
            <BaasText variant="h6">{entity} </BaasText>
          </BaasGrid>

          <BaasGrid p="4px 0px">
            <BaasText variant="body1">
              Get a preview of number of webhook notifications that can be
              resent for processing.
            </BaasText>
          </BaasGrid>
          <Formik enableReinitialize initialValues={initialValues}>
            {({ values, initialValues, isSubmitting, resetForm, ...props }) => (
              <Form>
                <BaasGrid p="4px 0px">
                  <BaasDateTimePicker
                    name="dateFrom"
                    label="Date from"
                    disabled={data?.totalItems > 0}
                    fullWidth
                  />
                </BaasGrid>
                <BaasGrid p="4px 0px">
                  <BaasDateTimePicker
                    name="dateTo"
                    label="Date to"
                    maxDate={moment(values.dateFrom).add(2, "days")}
                    minDate={moment(values.dateFrom)}
                    disabled={data?.totalItems > 0}
                    fullWidth
                  />
                </BaasGrid>
                <BaasGrid p="4px 0px">
                  <BaasCheckbox
                    name="onlyNotDelivered"
                    label="Only not delivered"
                    disabled={data?.totalItems > 0}
                    fullWidth
                  />
                </BaasGrid>
                {!!data && (
                  <BaasGrid p="4px 0px">
                    <BaasGrid display="flex">
                      <BaasText variant="body1">Date from:</BaasText>
                      <BaasText
                        variant="body1"
                        style={{ marginLeft: "8px", opacity: "0.7" }}
                      >
                        {moment(data?.dateFrom).format("MM/DD/YYYY - HH:mm:ss")}
                      </BaasText>
                    </BaasGrid>
                    <BaasGrid display="flex">
                      <BaasText variant="body1">Date to:</BaasText>
                      <BaasText
                        variant="body1"
                        style={{ marginLeft: "8px", opacity: "0.7" }}
                      >
                        {moment(data?.dateTo).format("MM/DD/YYYY - HH:mm:ss")}
                      </BaasText>
                    </BaasGrid>

                    <BaasGrid display="flex">
                      <BaasText variant="body1">Entity:</BaasText>
                      <BaasText
                        variant="body1"
                        style={{ marginLeft: "8px", opacity: "0.7" }}
                      >
                        {data?.entity}
                      </BaasText>
                    </BaasGrid>

                    <BaasGrid display="flex">
                      <BaasText variant="body1">Total items:</BaasText>
                      <BaasText
                        variant="body1"
                        style={{ marginLeft: "8px", opacity: "0.7" }}
                      >
                        {data?.totalItems}
                      </BaasText>
                    </BaasGrid>

                    <BaasGrid display="flex">
                      <BaasText variant="body1">Replay process:</BaasText>
                      <BaasText
                        variant="body1"
                        style={{ marginLeft: "8px", opacity: "0.7" }}
                      >
                        {data?.replayProcess}
                      </BaasText>
                    </BaasGrid>
                  </BaasGrid>
                )}
                {data?.totalItems > 0 && (
                  <BaasGrid display="flex" justifyContent="space-between">
                    <BaasTextField
                      name="otp"
                      label="OTP"
                      variant="outlined"
                      fullWidth
                    />
                  </BaasGrid>
                )}

                <BaasGrid
                  p="16px 0px 0px 0px"
                  display="flex"
                  justifyContent="flex-end"
                >
                  {!data?.totalItems > 0 ? (
                    <BaasButton
                      onClick={() => handleCheck(values)}
                      variant="contained"
                      size="large"
                      style={{ margin: "6px 0px 0px 0px" }}
                      loading={loading}
                      disabled={!values.dateFrom || !values.dateTo}
                    >
                      {loading ? "Checking" : "Check"}
                    </BaasButton>
                  ) : (
                    <BaasButton
                      onClick={() => handleResend(values)}
                      variant="contained"
                      backgroundColor="black"
                      size="large"
                      style={{ margin: "6px 0px 0px 8px" }}
                      loading={loading}
                    >
                      Resend
                    </BaasButton>
                  )}
                </BaasGrid>
              </Form>
            )}
          </Formik>
        </BoxStyled>
      </BaasModal>
    </>
  );
};

export default WebhookConfigResourceContentActionsResendModal;
