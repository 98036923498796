import { Box } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import React from "react";

const DebitPartFormInput = ({ values, expanded, toggleExpand, errors }) => {
  return (
    <BaasGrid item xs={12}>
      <Box display="flex" flexDirection="column">
        <>
          <Box
            display="flex"
            alignItems="center"
            style={{ marginLeft: "-12px", marginTop: "24px" }}
          >
            <Checkbox
              defaultChecked={expanded}
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                toggleExpand();
              }}
            />
            <BaasText variant="body1" color="textSecondary">
              <b>Custom Debit Participant (Only BTG Accounts)</b>
            </BaasText>
          </Box>

          {expanded && (
            <BaasRow
              container
              column={12}
              spacing={2}
              style={{ marginTop: "-32px" }}
            >
              <BaasGrid item xs={12} md={6}>
                <BaasTextField
                  fullWidth
                  name="customDebitPartName"
                  label="Name"
                  required
                />
              </BaasGrid>
              <BaasGrid item sm={6}>
                <BaasTextField
                  fullWidth
                  required
                  name="customDebitPartTaxId"
                  label="Tax Id"
                />
              </BaasGrid>
              <BaasGrid item xs={12} sm={6}>
                <BaasTextField
                  fullWidth
                  required
                  name="customDebitPartBranch"
                  label="Branch"
                  type="number"
                />
              </BaasGrid>
              <BaasGrid item xs={12} sm={6}>
                <BaasTextField
                  fullWidth
                  required
                  name="customDebitPartAccount"
                  label="Account"
                  type="number"
                />
              </BaasGrid>

              <BaasGrid item md={6}>
                <BaasTextField
                  fullWidth
                  name="customDebitPartAccountType"
                  label="AccountType"
                  variant="standard"
                  disabled={!expanded}
                  required
                  select
                  errors={errors}
                >
                  {[
                    { label: "CACC", value: "CACC" },
                    { label: "SLRY", value: "SLRY" },
                    { label: "SVGS", value: "SVGS" },
                    { label: "TRAN", value: "TRAN" },
                  ].map((modality) => (
                    <BaasMenuItem value={modality.value}>
                      {modality.label}
                    </BaasMenuItem>
                  ))}
                </BaasTextField>
              </BaasGrid>
            </BaasRow>
          )}
        </>
      </Box>
    </BaasGrid>
  );
};

export default DebitPartFormInput;
