import React, { useCallback, useState } from "react";
import { useTheme } from "@mui/system";
import BaasGrid from "components/atoms/Grid/Grid";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import BaasButton from "components/atoms/Button/Button";
import BaasDatePicker from "components/atoms/DatePicker/DatePicker";
import moment from "moment";
import { queryGetMovementReport } from "api/cnab-api";
import { toastMessages } from "constants/toastMessages";
import MovementReportList from "./MovementReportList";
import BaasText from "components/atoms/Text/BaasText";
import intervalDate from "../../../../helpers/intervalDate";

export default function MovementReportSearch() {
  const QTDE_DAYS_VALID = 31;
  const theme = useTheme();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [componentState, setComponentState] = useState([]);

  function setComponent(_array) {
    let array = [];
    _array.forEach((element) => {
      if (!("body" in element)) {
        array.push({ ...element, body: { fileName: "" } });
      } else array.push(element);
    });
    setComponentState(array);
  }

  const mutateGetMovementReport = useCallback(async (values) => {
    const response = await queryGetMovementReport(values);
    if (response.status < 300) {
      setComponent(response.body);
      toast.success(toastMessages.requestSuccess);
    } else {
      if ("errors" in response) {
        response.errors.map((error) => toast.error(error.message));
      } else toast.error("Your link is broken");
    }
    setIsSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(
    (values) => {
      setIsSubmitting(true);
      // if (intervalDate(values.startDate, values.endDate, QTDE_DAYS_VALID)) {
      mutateGetMovementReport({
        startDate: moment(values.startDate).format("YYYY-MM-DD"),
        endDate: moment(values.endDate).format("YYYY-MM-DD"),
      });
      // } else {
      //   setIsSubmitting(false);
      //   toast.error("Invalid data range");
      // }
    },
    [mutateGetMovementReport]
  );

  return (
    <>
      <Formik initialValues={{}} onSubmit={onSubmit} enableReinitialize>
        {({ values, ...props }) => (
          <Form>
            <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
              <BaasText>
                Use this session to list Cnab Movement Report files.
              </BaasText>
              <BaasGrid container p="16px 0px" spacing={3}>
                <BaasGrid item xs={12} sm={6}>
                  <BaasDatePicker
                    name="startDate"
                    label="Start date"
                    maxDate={moment()}
                    fullWidth
                  />
                </BaasGrid>
                <BaasGrid item xs={12} sm={6}>
                  <BaasDatePicker
                    name="endDate"
                    label="End date"
                    fullWidth
                    maxDate={moment()}
                  />
                </BaasGrid>
              </BaasGrid>
              <BaasGrid item p="16px 0px">
                <BaasButton
                  variant="contained"
                  backgroundColor="black"
                  type="submit"
                  loading={isSubmitting}
                >
                  Search
                </BaasButton>
              </BaasGrid>
            </BaasGrid>
          </Form>
        )}
      </Formik>
      {componentState.length > 0 ? (
        <MovementReportList data={componentState} />
      ) : null}
    </>
  );
}
