import { useMediaQuery } from "@mui/material";
import imageMobile1 from "assets/img/landing/imageMobile1.png";
import image1 from "assets/img/landing/landing1.png";
import BaasButtonRound from "components/atoms/ButtonRound/ButtonRound";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";

const LandingIntroduction = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <>
      <BaasGrid
        container
        sx={{
          backgroundImage: `url(${isMobile ? "none" : image1})`,
          backgroundColor: "black",
          backgroundSize: "cover",
        }}
        height={isMobile ? "100%" : "70vh"}
        display="flex"
        alignItems="center"
      >
        {isMobile && (
          <BaasGrid
            id="teste"
            sx={{
              backgroundSize: "cover",
              backgroundImage: `url(${imageMobile1})`,
              height: "200px",
              width: "100%",
            }}
          >
            <BaasGrid
              sx={{
                height: "200px",
                width: "100%",
                background:
                  "linear-gradient(360deg, #000 0%, rgba(0, 0, 0, 0) 67.71%)",
              }}
            />
          </BaasGrid>
        )}

        <BaasGrid md={1} />

        <BaasGrid md={4} p={4}>
          <BaasGrid p="4px 0px">
            <BaasText
              variant="h6"
              color="grey.100"
              style={{ fontSize: "30px" }}
            >
              BTG Pactual - Banking as a Service
            </BaasText>
          </BaasGrid>
          <BaasGrid p="4px 0px">
            <BaasText
              variant="subtitle1"
              color="grey.100"
              style={{ opacity: "0.7", fontSize: "18px" }}
            >
              Come with us and discover how easy it is to use our powerful REST
              APIs. Simple steps will take your business to a whole new level.
            </BaasText>
          </BaasGrid>
          <BaasGrid p="18px 0px">
            <a variant="button" href="/faq">
              <BaasButtonRound variant="outlined" backgroundColor="white">
                CONTACT US
              </BaasButtonRound>
            </a>
          </BaasGrid>
        </BaasGrid>
      </BaasGrid>
    </>
  );
};

export default LandingIntroduction;
