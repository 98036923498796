import { useTheme } from "@mui/system";
import { useQueryGetAccountInfo } from "api/security-api";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import { Form, Formik } from "formik";
import getSafe from "helpers/getSafe";
import React from "react";

const UserCredentialsAccountInfo = () => {
  const theme = useTheme();
  const { data } = useQueryGetAccountInfo();
  return (
    <>
      <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
        <BaasRow container spacing={1}>
          <BaasText
            sx={{ paddingLeft: "8px", marginBottom: "8px" }}
            variant="subtitle1"
          >
            With your account’s credentials you can perform certain operations
            that demand specific information regarding the users account
            information. Copy the information bellow and use it during your
            testing. Check the documentation session for more information
            regarding the available functionalities.
          </BaasText>

          {getSafe(data, "body.items", []).length > 0 ? (
            <BaasGrid item xs={12}>
              <Formik
                initialValues={{
                  selectedAccount: getSafe(data, "body.items[0]"),
                }}
              >
                {({ values }) => (
                  <Form>
                    <BaasGrid container p={1} spacing={3}>
                      <BaasGrid item xs={4}>
                        <BaasTextField
                          fullWidth
                          name="selectedAccount"
                          label="Selected Account"
                          select
                        >
                          {getSafe(data, "body.items", []).map((account) => (
                            <BaasMenuItem
                              value={account}
                            >{`${account.branch} / ${account.account}`}</BaasMenuItem>
                          ))}
                        </BaasTextField>
                      </BaasGrid>

                      {!!values.selectedAccount && (
                        <>
                          <BaasGrid item xs={12}>
                            <BaasInfoDisplay
                              label={"Account Id:"}
                              value={values.selectedAccount.accountId}
                            />
                          </BaasGrid>

                          <BaasGrid item xs={12} sm={6}>
                            <BaasInfoDisplay
                              label={"Bank:"}
                              value={values.selectedAccount.bank}
                            />
                          </BaasGrid>

                          <BaasGrid item xs={12} sm={6}>
                            <BaasInfoDisplay
                              label={"Branch:"}
                              value={values.selectedAccount.branch}
                            />
                          </BaasGrid>

                          <BaasGrid item xs={12} sm={6}>
                            <BaasInfoDisplay
                              label={"Account Number:"}
                              value={values.selectedAccount.account}
                            />
                          </BaasGrid>

                          <BaasGrid item xs={12} sm={6}>
                            <BaasInfoDisplay
                              label={"Account Type:"}
                              value={values.selectedAccount.accountType}
                            />
                          </BaasGrid>

                          <BaasGrid item xs={12} sm={6}>
                            <BaasInfoDisplay
                              label={"Beneficiary Treaty Code:"}
                              value={
                                values.selectedAccount.beneficiaryTreatyCode
                              }
                            />
                          </BaasGrid>

                          <BaasGrid item xs={12} sm={6}>
                            <BaasInfoDisplay
                              label={"Default Account:"}
                              value={
                                values.selectedAccount.defaultAccount
                                  ? "Yes"
                                  : "Not default"
                              }
                            />
                          </BaasGrid>

                          <BaasGrid item xs={12} sm={6}>
                            <BaasInfoDisplay
                              label={"Person Type:"}
                              value={values.selectedAccount.personType}
                            />
                          </BaasGrid>

                          <BaasGrid item xs={12} sm={6}>
                            <BaasInfoDisplay
                              label={"Tax Id:"}
                              value={values.selectedAccount.taxId}
                            />
                          </BaasGrid>
                        </>
                      )}
                    </BaasGrid>
                  </Form>
                )}
              </Formik>
            </BaasGrid>
          ) : null}
        </BaasRow>
      </BaasGrid>
    </>
  );
};

export default UserCredentialsAccountInfo;
