import Endpoint from "constants/endpoints";

const isSandbox = Endpoint.Environment === "Sandbox";

export const routesHeader = [
  {
    name: "Documentation",
    path: isSandbox ? "https://developer.btgpactual.com/dochub" : "/dochub",
    isExternal: isSandbox,
  },
  {
    name: "Sandbox",
    path: "https://sandbox.developer.btgpactual.com/login",
    isExternal: true,
  },
  // { name: "Blog", path: "https://medium.com/btgdev", isExternal: true },
  {
    name: "Career",
    path: "https://www.btgpactual.com/pessoas/vagas",
    isExternal: true,
  },
  {
    name: "Status Page",
    path: "http://status.btgpactual.com/",
    isExternal: true,
  },
  {
    name: "Contact Us",
    path: isSandbox ? "https://developer.btgpactual.com/faq" : "/faq",
    isExternal: isSandbox,
  },
];
