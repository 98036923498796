import { baasApiCall } from "services/apiCall";

export const queryClientQuestionsComplete = () => {
  return baasApiCall("GET", `/cm/baas-tester/client-question/complete`)
    .then((data) => data.json())
    .catch((error) => error);
};

export const mutationAnswerQuestion = (payload) => {
  return baasApiCall(
    "POST",
    `/cm/baas-tester/answer/${payload.questionId}`,
    payload.answer
  )
    .then((data) => data.json())
    .catch((error) => error.json());
};
