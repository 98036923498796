import { useTheme } from "@emotion/react";
import { ReactComponent as BtgPactual } from "assets/icons/BtgLogos/BTGPactual.svg";
import { ReactComponent as Facebook } from "assets/icons/socialMedia/facebook.svg";
import { ReactComponent as Instagram } from "assets/icons/socialMedia/instagram.svg";
import { ReactComponent as Linkedin } from "assets/icons/socialMedia/linkedin.svg";
import { ReactComponent as Twitter } from "assets/icons/socialMedia/twitter.svg";
import { ReactComponent as YouTube } from "assets/icons/socialMedia/youtube.svg";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import React from "react";
import styled from "styled-components";

const FooterStyle = styled.footer`
  color: ${(props) => props.theme.palette.grey[100]};

  background: ${(props) => props.theme.palette.grey[700]};

  bottom: 0px;
  width: 100%;
  padding: 32px 0px;
`;

const Line = styled.div`
  background: ${(props) => props.theme.palette.grey[500]};

  margin-bottom: 24px;
  margin-top: 4px;

  height: 1px;
  width: 100%;
`;

const BaasFooterLanding = ({ fixed }) => {
  const theme = useTheme();

  return (
    <FooterStyle
      theme={theme}
      className="footer_class"
      style={{ position: fixed ? "fixed" : "" }}
    >
      <BaasGrid display="flex" justifyContent="center">
        <BaasGrid
          container
          spacing={4}
          sx={{ maxWidth: "1000px", marginRight: "32px", marginLeft: "0px" }}
        >
          {/* <BaasGrid item xs={12} md={6} lg={3}>
            <BaasText>Relacionamento</BaasText>

            <Line theme={theme} />

            {relacionamento.map((info) => (
              <BaasGrid p="2px 0px">
                <BaasText variant="caption" color="grey.100">
                  {info.title}
                </BaasText>
                {!!info.descript && (
                  <BaasText variant="caption" color="grey.400">
                    {info.descript}
                  </BaasText>
                )}
              </BaasGrid>
            ))}
          </BaasGrid>

          <BaasGrid item xs={12} md={6} lg={3}>
            <BaasText>SAC</BaasText>

            <Line theme={theme} />

            {sac.map((info) => (
              <BaasGrid p="2px 0px">
                <BaasText variant="caption" color="grey.300">
                  {info.title}
                </BaasText>
                {!!info.descript && (
                  <BaasText variant="caption" color="grey.100">
                    {info.descript}
                  </BaasText>
                )}
              </BaasGrid>
            ))}
          </BaasGrid>

          <BaasGrid item xs={12} md={6} lg={3}>
            <BaasText>Ouvidoria</BaasText>

            <Line theme={theme} />

            {ouvidoria.map((info) => (
              <BaasGrid p="2px 0px">
                <BaasText variant="caption" color="grey.300">
                  {info.title}
                </BaasText>
                {!!info.descript && (
                  <BaasText variant="caption" color="grey.100">
                    {info.descript}
                  </BaasText>
                )}
              </BaasGrid>
            ))}
          </BaasGrid> */}

          <BaasGrid item xs={12} sm={6}>
            <BaasText style={{ marginBottom: "16px" }}>Links Rápidos</BaasText>

            {/* <Line theme={theme} /> */}

            {links.links.map((link) => (
              <a href={link.url}>
                <link.icon height="24" width="32" />
              </a>
            ))}

            {/* {links.info.map((info) => (
              <BaasGrid p="2px 0px">
                <BaasText variant="caption" color="grey.300">
                  {info.title}
                </BaasText>
                {!!info.descript && (
                  <BaasText variant="caption" color="grey.100">
                    {info.descript}
                  </BaasText>
                )}
              </BaasGrid>
            ))} */}
          </BaasGrid>

          <BaasGrid item xs={12} sm={2}>
            <BaasText>Outros sites do BTG Pactual</BaasText>
          </BaasGrid>

          {otherSites.map((site) => (
            <BaasGrid item xs={12} sm={4}>
              <a href={site.url}>
                <site.icon height="46" width="84" />
              </a>
            </BaasGrid>
          ))}

          <BaasGrid item xs={12}>
            <Line theme={theme} />
          </BaasGrid>

          <BaasGrid item xs={12}>
            <BaasText variant="caption">
              São Paulo: Av. Brigadeiro Faria Lima, 3.477 - 14° Andar, CEP
              04538-133 | Rio de Janeiro: Praia de Botafogo, 501 - 6°, CEP
              22250-040 | © 2022 BTG Pactual - CNPJ 30.306.294/0001-45
            </BaasText>
          </BaasGrid>
          <BaasGrid item xs={12}>
            <Line theme={theme} />
          </BaasGrid>
        </BaasGrid>
      </BaasGrid>
    </FooterStyle>
  );
};

export default BaasFooterLanding;

// const sac = [
//   { title: "Reclamações, sugestões e elogios: ", descript: "0800-772-2827" },
//   {
//     title: "SAC - Deficiência auditiva ou de fala: ",
//     descript: "0800-047-4335",
//   },

//   { title: "Horário de funcionamento: 24 horas por dia, 7 dias por semana" },
// ];

// const ouvidoria = [
//   { title: "Se não ficou satisfeito, ligue para:", descript: "0800-722-0048" },
//   {
//     title: "Horário de funcionamento:",
//     descript: "De segunda a sexta-feira (exceto feriados), das 9h às 18h",
//   },
//   { title: "Fale com a Ouvidoria" },
// ];

// const relacionamento = [
//   { title: "4007-2511 ", descript: "(Regiões metropolitanas)" },
//   {
//     title: "0800-001-2511",
//     descript: "(Demais localidades)",
//   },
//   {
//     title: "WhatsApp: 11 4007-2511",
//     descript: "(Não é preciso inserir o dígito 9 antes do número)",
//   },
// ];

const links = {
  links: [
    { icon: Instagram, url: "https://www.instagram.com/btgpactualdigital/" },
    { icon: Facebook, url: "https://www.facebook.com/BTGPactualdigital/" },
    { icon: Twitter, url: "https://twitter.com/BTGPDigital" },
    {
      icon: YouTube,
      url: "https://www.youtube.com/channel/UCBDwB2-hvTgX3BqWankbCww",
    },
    {
      icon: Linkedin,
      url: "ttps://www.linkedin.com/company/btgpactualdigital/",
    },
  ],
  info: [
    { title: "Termos e politicas " },
    {
      title: "Mapa do site",
    },
    {
      title: "Ajuda",
    },
  ],
};

const otherSites = [
  { icon: BtgPactual, url: "https://www.btgpactual.com/home" },
  // { icon: BtgPactualDigital, url: "https://www.btgpactualdigital.com/" },
  // { icon: BtgPactualEmpresas, url: "https://www.btgmaisbusiness.com/" },
  // {
  //   icon: BtgMais,
  //   url:
  //     "https://www.btgmais.com/?gclid=EAIaIQobChMIm9PhntrD9QIV0QRyCh1stwNIEAAYASAAEgIMevD_BwE",
  // },
];
