import BaasText from "components/atoms/Text/BaasText";
import React, { useCallback, useState } from "react";
import { useTheme } from "@mui/system";
import BaasGrid from "components/atoms/Grid/Grid";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import BaasButton from "components/atoms/Button/Button";
import BaasDatePicker from "components/atoms/DatePicker/DatePicker";
import { queryGetImportFileByRange } from "api/cnab-api";
import moment from "moment";
import CnabInterfaceDownloadList from "./CnabInterfaceDownloadList";
import { isNewCnabProfile } from "helpers/isNewCnabProfile";
const CnabInterfaceDownload = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dataByRange = useCallback(async (values) => {
    const response = await queryGetImportFileByRange(values);
    if (response.body?.length > 0) {
      setData(response.body);
    } else if ("errors" in response) {
      response.errors.map((error) => {
        toast.error(error.message);
      });
      setData([]);
    } else {
      toast.warning("No results found");
    }
    setIsSubmitting(false);
    return response;
  }, []);

  const onSubmit = useCallback((values, formik) => {
    setIsSubmitting(true);
    dataByRange({
      dateFrom: moment(values.dateFrom).format("YYYY-MM-DD"),
      dateTo: moment(values.dateTo).format("YYYY-MM-DD"),
      newCnab: isNewCnabProfile(),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Formik initialValues={{}} onSubmit={onSubmit} enableReinitialize>
        {({ values, ...props }) => (
          <Form>
            <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
              <BaasText>
                List and download CNAB files. Use date ranges to search
                available files.
              </BaasText>

              <BaasGrid container spacing={2}>
                <BaasGrid item xs={12} sm={6}>
                  <BaasDatePicker
                    name="dateFrom"
                    label="Date from"
                    fullWidth
                    maxDate={moment()}
                  />
                </BaasGrid>
                <BaasGrid item xs={12} sm={6}>
                  <BaasDatePicker
                    name="dateTo"
                    label="Date to"
                    fullWidth
                    maxDate={moment()}
                  />
                </BaasGrid>
              </BaasGrid>
              <BaasGrid item p="16px 0px">
                <BaasButton
                  variant="contained"
                  backgroundColor="black"
                  type="submit"
                  loading={isSubmitting}
                >
                  Search
                </BaasButton>
              </BaasGrid>
            </BaasGrid>
          </Form>
        )}
      </Formik>
      {data.length > 0 && <CnabInterfaceDownloadList data={data} />}
    </>
  );
};

export default CnabInterfaceDownload;
