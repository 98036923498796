import _ from "lodash";
import { toast } from "react-toastify";

export const handleMultipleQueries = async (searchValues, list, query) => {
  const searchParamsList = list
    .map((keyword) => {
      const validatedKeyword =
        keyword.trim().length > 0 ? keyword.trim() : undefined;
      return {
        ...searchValues,
        keyword: validatedKeyword,
      };
    })
    .filter(function (params) {
      return params.keyword !== undefined;
    });

  const fetch = async (values) => {
    const response = await query(values);
    return response;
  };

  const slicedList = searchParamsList.slice(0, 10);

  const responsesList = await Promise.all(
    slicedList.map(async (params) => {
      const response = await fetch(params);
      return response;
    })
  );

  const responseItems = responsesList.map((e) => {
    return e.body.items;
  });
  const items = _.uniqBy([].concat.apply([], responseItems), "pactualId");
  if (items.length === 0) {
    toast.warning("Sorry, we couldn´t find any results");
  }
  return items;
};
