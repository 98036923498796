import { useTheme } from "@mui/system";
import BaasBox from "components/atoms/Box/Box";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import BaasText from "components/atoms/Text/BaasText";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import DinamicForm from "components/organism/DinamicForm/DinamicForm";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { useCustomQuery } from "helpers/useCustomQuery";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import BaasButton from "components/atoms/Button/Button";
import ListIcon from "@mui/icons-material/List";
import { queryGetPixDictRefundById } from "api/pix-api";
import { queryRefundRoutesSandbox } from "api/pix-api";
import { BackButton } from "components/molecules/BackButton/BackButton";

const PixTestSuitRefundDetails = () => {
  const theme = useTheme();
  const history = useHistory();
  const { refundId } = useParams();
  const [refetchRefund, setRefetchRefund] = useState(false);
  const [refetchRoutes, setRefetchRoutes] = useState(false);
  const [loadingGeneric, setLoadingGeneric] = useState();

  const {
    fetch: fetchRefund,
    data: refundData,
    loading: loadingRefund,
  } = useCustomQuery(queryGetPixDictRefundById);

  useEffect(() => {
    if (refundId) {
      fetchRefund({
        values: refundId,
        objectPath: "body",
        objectFallback: {},
      });
    }
  }, [fetchRefund, refundId, refetchRefund]);

  const {
    fetch: fetchRoutes,
    data: routesData,
    loading: loadingRoutes,
  } = useCustomQuery(queryRefundRoutesSandbox);

  useEffect(() => {
    fetchRoutes({
      values: refundId,
      objectPath: "body",
      onNotFoundError: () =>
        toast.warning("There is no actions available to refund process"),
    });
  }, [fetchRoutes, refundId, refetchRoutes]);

  const handleRefetchRoutes = () => {
    setRefetchRefund(!refetchRefund);
    setRefetchRoutes(!refetchRoutes);
  };

  const refundValues = [
    { label: "Refund Id", value: refundData?.id },
    { label: "Transaction Id", value: refundData?.transactionId },
    { label: "Amount", value: `R$ ${refundData.refundAmount}` },
    { label: "Refund Details", value: refundData.refundDetails },
    { label: "Contested participant", value: refundData.contestedParticipant },
    { label: "Reason", value: refundData.refundReason },
    {
      label: "Requesting participant",
      value: refundData.requestingParticipant,
    },
    { label: "Status", value: refundData.status },
    { label: "Analysis Details", value: refundData.analysisDetails },
    { label: "Analysis Result", value: refundData.analysisResult },
  ];

  return (
    <BaasFlex flex={1} flexDirection="column">
      <BaasContentGrid spacing={4}>
        <BaasGrid item spacing={1}>
          <BaasRow>
            <BaasText variant="h6">Pix Test Suit Refund</BaasText>
          </BaasRow>
        </BaasGrid>
        <BaasGrid sx={{ background: theme.palette.grey[100] }} p="16px">
          <BackButton path="/pix-test-suit-refund" />
          <BaasText variant="body2" fontWeight="bold">
            Decide what action to take about the refund.
          </BaasText>
          <BaasRow>
            <BaasGrid sx={{ background: theme.palette.grey[100] }}>
              <BaasRow container column={12} spacing={3}>
                {loadingRefund ? (
                  <>
                    <BaasGrid item xs={12} sm={12}>
                      <BaasSkeleton width="100%" height="65px" />
                    </BaasGrid>
                    <BaasGrid item xs={12} sm={12}>
                      <BaasSkeleton width="100%" height="65px" />
                    </BaasGrid>
                    <BaasGrid item xs={12} sm={12}>
                      <BaasSkeleton width="100%" height="65px" />
                    </BaasGrid>
                    <BaasGrid item xs={12} sm={12}>
                      <BaasSkeleton width="100%" height="65px" />
                    </BaasGrid>
                  </>
                ) : (
                  refundData &&
                  refundValues.map((row) => (
                    <BaasGrid item xs={12} sm={6} key={row.label}>
                      <BaasInfoDisplay
                        fullWidth
                        label={row.label}
                        value={row.value || "Not available"}
                      />
                    </BaasGrid>
                  ))
                )}
              </BaasRow>
            </BaasGrid>
          </BaasRow>
          <BaasBox mb={3}>
            <BaasButton
              variant="outlined"
              onClick={() => history.push("/pix-test-suit-refund")}
              startIcon={<ListIcon />}
            >
              Go to Refund List
            </BaasButton>
          </BaasBox>
          <BaasText variant="body2" fontWeight="bold">
            Possible Actions
          </BaasText>
          {loadingRoutes ? (
            <BaasSkeleton width="100%" height="65px" />
          ) : routesData && routesData.length > 0 ? (
            routesData.map((route) => (
              <BaasBox
                sx={{ borderTop: "1px solid #CACACA" }}
                mt={2}
                key={route.message}
              >
                <DinamicForm
                  onComplete={handleRefetchRoutes}
                  route={route}
                  loading={loadingGeneric}
                  setLoading={setLoadingGeneric}
                />
              </BaasBox>
            ))
          ) : (
            <BaasText color="error" mt={1}>
              There is no actions available to this refund.
            </BaasText>
          )}
        </BaasGrid>
      </BaasContentGrid>
    </BaasFlex>
  );
};

export default PixTestSuitRefundDetails;
