import Backdrop from "@mui/material/Backdrop";
import { useTheme } from "@mui/private-theming";
import { Box } from "@mui/system";
import { mutationPutPixDictEntryUpdate } from "api/dictv2-api";
import BaasButton from "components/atoms/Button/Button";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasModal from "components/atoms/Modal/Modal";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import Endpoints from "constants/endpoints";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import { useOtp } from "providers/oneTimePassword";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as yup from "yup";

const BoxStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  max-width: 700px;

  max-height: 80vh;
  background-color: ${(props) => props.theme.palette.grey[200]};

  box-shadow: 24;
  padding: 4px;
  border-radius: 4px;
  overflow: hidden;
  overflow-y: scroll;

  :focus-visible {
    outline: none;
  }
`;

export default function EntrySearchListUpdateModal({
  modal,
  setModal,
  refresh,
}) {
  const theme = useTheme();
  const { isOtpRequired, setOpen } = useOtp();
  const validationSchema = yup.object().shape({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isSandboxEnvironment = Endpoints.Environment === "Sandbox";

  const handleClose = () => {
    setIsSubmitting(false);
    setModal({ state: false });
  };

  const [dataModalUpdate, setDataModalUpdate] = useState({
    key: " ",
    keyType: " ",
    reason: 1,
    account: {
      branch: " ",
      accountNumber: " ",
      accountType: 1,
      openingDate: "",
    },
    owner: {
      taxIdNumber: " ",
      name: " ",
    },
  });

  useEffect(() => {
    if (modal.state) setDataModalUpdate(modal.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  const setTypeAccount = () => {
    let accountType = 0;
    switch (dataModalUpdate.account.accountType) {
      case "CACC":
        accountType = 1;
        break;
      case "SLRY":
        accountType = 2;
        break;
      case "SVGS":
        accountType = 3;
        break;
      case "TRAN":
        accountType = 4;
        break;

      default:
        accountType = 1;
        break;
    }
    return accountType;
  };

  const initialValues = useMemo(() => {
    return {
      reason: 1,
      branch: dataModalUpdate.account.branch,
      accountNumber: dataModalUpdate.account.accountNumber,
      accountType: setTypeAccount(),
      openingDate: dataModalUpdate.account.openingDate,
      name: dataModalUpdate.owner.name,
      taxIdNumber: dataModalUpdate.owner.taxIdNumber,
    };
  }, [dataModalUpdate]);

  const onSubmit = useCallback(
    async (values) => {
      const payload = {
        otp: values.otp,
        reason: values.reason,
        branch: values.branch,
        accountNumber: values.accountNumber,
        accountType: values.accountType,
        openingDate: values.openingDate,
        key: values.key,
        owner: {
          name: values.name,
          taxId: values.taxIdNumber,
        },
      };

      const response = await mutationPutPixDictEntryUpdate(
        dataModalUpdate.key,
        payload
      );
      if (response.status < 300) {
        handleClose();
        refresh();
        toast.success(toastMessages.requestSuccess);
      }
      if (response.status >= 300) {
        toast.error(toastMessages.requestError);
      }
      setIsSubmitting(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataModalUpdate.key]
  );

  return (
    <>
      <BaasModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modal.state}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={
            isOtpRequired && !isSandboxEnvironment
              ? () => setOpen(true)
              : onSubmit
          }
          validationSchema={validationSchema}
        >
          {({ values, ...props }) => (
            <Form>
              <BaasOtpModal
                onConfirm={() => onSubmit(values, props)}
                {...props}
              />
              <BoxStyled p={3} theme={theme} sx={{ maxWidth: "700px" }}>
                <BaasText>Update Key</BaasText>
                <BaasGrid container spacing={4}>
                  <BaasGrid item xs={12} md={6}>
                    <BaasInfoDisplay label="Key:" value={dataModalUpdate.key} />
                  </BaasGrid>
                  <BaasGrid item xs={12} md={6}>
                    <BaasInfoDisplay
                      label="Key Type:"
                      value={dataModalUpdate.keyType}
                    />
                  </BaasGrid>
                  <BaasGrid item xs={12} md={6}>
                    <BaasTextField
                      fullWidth
                      name="reason"
                      label="Reason"
                      required
                      select
                    >
                      <BaasMenuItem value="1">User Requested</BaasMenuItem>
                      {/* <BaasMenuItem value="2">ACCOUNT_CLOSURE</BaasMenuItem> */}
                      <BaasMenuItem value="3">Branch Transfer</BaasMenuItem>
                      <BaasMenuItem value="5">Reconciliation</BaasMenuItem>
                      {/* <BaasMenuItem value="6">DEFAULT_OPERATION</BaasMenuItem> */}
                      {/* <BaasMenuItem value="7">FRAUD</BaasMenuItem>
                      <BaasMenuItem value="RFB_VALIDATION">RFB Validation (Receita Federal Brasileira)</BaasMenuItem> */}
                    </BaasTextField>
                  </BaasGrid>
                  <BaasGrid item xs={12} md={6}>
                    <BaasTextField
                      fullWidth
                      name="branch"
                      label="Branch"
                      required
                    />
                  </BaasGrid>
                  <BaasGrid item xs={12} md={6}>
                    <BaasTextField
                      fullWidth
                      name="accountNumber"
                      label="Account Number"
                      required
                    />
                  </BaasGrid>
                  <BaasGrid item xs={12} md={6}>
                    <BaasTextField
                      fullWidth
                      name="accountType"
                      label="Account Type"
                      required
                      select
                    >
                      <BaasMenuItem value="1">CACC</BaasMenuItem>
                      <BaasMenuItem value="2">SLRY</BaasMenuItem>
                      <BaasMenuItem value="3">SVGS</BaasMenuItem>
                      <BaasMenuItem value="4">TRAN</BaasMenuItem>
                    </BaasTextField>
                  </BaasGrid>
                  <BaasGrid item xs={12} md={6}>
                    <BaasTextField
                      fullWidth
                      name="name"
                      label="Owner Name"
                      required
                    />
                  </BaasGrid>
                  <BaasGrid item xs={12} md={6}>
                    <BaasTextField
                      fullWidth
                      name="taxIdNumber"
                      label="TaxId"
                      required
                    />
                  </BaasGrid>
                  <BaasGrid
                    item
                    container
                    sx={{ justifyContent: "space-between" }}
                    flexDirection="row"
                  >
                    <BaasButton
                      type="submit"
                      size="medium"
                      variant="outlined"
                      backgroundColor="black"
                      onClick={() => handleClose()}
                    >
                      Close
                    </BaasButton>
                    <BaasButton
                      backgroundColor="black"
                      variant="contained"
                      loading={isSubmitting}
                      disabled={
                        !values.reason ||
                        !values.branch ||
                        !values.accountNumber ||
                        !values.accountType ||
                        !values.name ||
                        !values.taxIdNumber
                      }
                      onClick={
                        isSandboxEnvironment
                          ? () => {
                            setIsSubmitting(true);
                            onSubmit(values);
                          }
                          : () => {
                            setIsSubmitting(true);
                            setOpen(true);
                          }
                      }
                    >
                      Update
                    </BaasButton>
                  </BaasGrid>
                </BaasGrid>
              </BoxStyled>
            </Form>
          )}
        </Formik>
      </BaasModal>
    </>
  );
}
