import * as yup from "yup";
import BaasRow from "components/atoms/Grid/Row";
import BaasGrid from "components/atoms/Grid/Grid";
import React, { useCallback, useMemo, useState } from "react";
import BaasTextField from "components/atoms/TextField/TextField";
import { Form, Formik } from "formik";
import { useTheme } from "@mui/system";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasText from "components/atoms/Text/BaasText";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import { useOtp } from "providers/oneTimePassword";
import guidGenerator from "services/guidGenerator";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import InputMask from "react-input-mask";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import moment from "moment";
import { toast } from "react-toastify";
import BaasTransactionSuccess from "components/templates/TransactionSuccess/TransactionSuccess";
import { toastMessages } from "constants/toastMessages";
import { formatPixKeys } from "helpers/formatPixKeys";
import FormActions from "components/molecules/FormActions";
import { useAccount } from "providers/account";
import { mutationSandboxPostPixDictEntryCreate } from "api/pix-api";
import { getKeyMask } from "helpers/getKeyMask";

export default function PixEntryCreateForm({ onCancel }) {
  const theme = useTheme();
  const { setOpen, isOtpRequired } = useOtp();
  const { accountSelected } = useAccount();
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [evp] = useState(guidGenerator());

  const initialValues = useMemo(() => {
    return {
      branch: accountSelected?.branch,
      taxId: accountSelected?.taxId,
      accountNumber: accountSelected?.account,
      accountType:
        accountSelected?.accountType === "CC"
          ? "CACC"
          : accountSelected?.accountType,
      name: accountSelected?.name.replace(/[{()}]/g, ""),
    };
  }, []);

  const validationSchema = yup.object().shape({});

  const mutateDictEntry = useCallback(async (values, formik) => {
    const response = await mutationSandboxPostPixDictEntryCreate(values);
    if (response.status < 300) {
      setSuccess(true);
    } else {
      if ("errors" in response) {
        response.errors.forEach((error) => {
          toast.error(error.message);
        });
      } else {
        toast.error(toastMessages.requestError);
      }
    }
    setIsSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(
    (values) => {
      setIsSubmitting(true);
      const date = moment();
      const parsedDate = date.toISOString().split(".")[0] + "Z";
      mutateDictEntry({
        reason: values.reason,
        key: formatPixKeys(values),
        branch: values.branch,
        accountNumber: values.accountNumber,
        accountType: values.accountType,
        taxId: values.taxId.toString(),
        name: values.name,
        openingDate: parsedDate,
        requestId: guidGenerator(),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutateDictEntry]
  );

  const isKeyWithMask = (keyType) => {
    return (
      keyType === "keyCpf" || keyType === "keyCnpj" || keyType === "keyPhone"
    );
  };

  return (
    <>
      <BaasFlex flexDirection="column" width="100%">
        <BaasGrid sx={{ background: theme.palette.grey[100] }} p={2}>
          {success ? (
            <BaasTransactionSuccess onClick={onCancel} />
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={isOtpRequired ? () => setOpen(true) : onSubmit}
              validationSchema={validationSchema}
            >
              {({ values, setFieldValue, ...props }) => (
                <Form>
                  <BaasOtpModal
                    onConfirm={() => onSubmit(values, props)}
                    {...props}
                  />
                  <BaasText variant="body2">
                    Create new Pix keys by clicking bellow. New keys are stored
                    and synced with Brazilian Central Bank DICT repository.
                  </BaasText>
                  <BaasRow>
                    <BaasGrid sx={{ background: theme.palette.grey[100] }}>
                      <BaasRow container column={12} spacing={3}>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="reason"
                            label="Reason"
                            required
                            select
                          >
                            <BaasMenuItem value="USER_REQUESTED">
                              User requested
                            </BaasMenuItem>
                            <BaasMenuItem value="ACCOUNT_CLOSURE">
                              Account closure
                            </BaasMenuItem>
                            <BaasMenuItem value="BRANCH_TRANSFER">
                              Branch transfer
                            </BaasMenuItem>
                            <BaasMenuItem value="RECONCILIATION">
                              Reconciliation
                            </BaasMenuItem>
                            <BaasMenuItem value="DEFAULT_OPERATION">
                              Default Operation
                            </BaasMenuItem>
                            <BaasMenuItem value="FRAUD">Fraud</BaasMenuItem>
                          </BaasTextField>
                        </BaasGrid>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="keyType"
                            label="Key Type"
                            required
                            select
                          >
                            <BaasMenuItem value="keyCpf">CPF</BaasMenuItem>
                            <BaasMenuItem value="keyCnpj">CNPJ</BaasMenuItem>
                            <BaasMenuItem value="keyPhone">Phone</BaasMenuItem>
                            <BaasMenuItem value="keyEmail">Email</BaasMenuItem>
                            <BaasMenuItem value="keyEvp">EVP</BaasMenuItem>
                          </BaasTextField>
                        </BaasGrid>
                        {isKeyWithMask(values.keyType) ? (
                          <BaasGrid item xs={12} md={6}>
                            <InputMask
                              mask={getKeyMask(values.keyType)}
                              onChange={(e) => (values.key = e.target.value)}
                            >
                              <BaasTextField
                                fullWidth
                                name="key"
                                label="Key"
                                required
                              />
                            </InputMask>
                          </BaasGrid>
                        ) : null}
                        {values.keyType === "keyEmail" ? (
                          <BaasGrid item xs={12} md={6}>
                            <BaasTextField
                              fullWidth
                              name="key"
                              label="Key"
                              required
                              type="email"
                              placeholder=" email@example.com"
                            />
                          </BaasGrid>
                        ) : null}
                        {values.keyType === "keyEvp" ? (
                          <BaasGrid item xs={12} md={6}>
                            <BaasInfoDisplay label={"EVP Key:"} value={evp} />
                          </BaasGrid>
                        ) : null}
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="accountType"
                            label="Account Type"
                            required
                            select
                          >
                            <BaasMenuItem value="CACC">CACC</BaasMenuItem>
                            <BaasMenuItem value="SLRY">SLRY</BaasMenuItem>
                            <BaasMenuItem value="SVGS">SVGS</BaasMenuItem>
                            <BaasMenuItem value="TRAN">TRAN</BaasMenuItem>
                          </BaasTextField>
                        </BaasGrid>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="branch"
                            label="Branch"
                            required
                          />
                        </BaasGrid>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="accountNumber"
                            label="Account"
                            required
                          />
                        </BaasGrid>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="name"
                            label="Name"
                            required
                          />
                        </BaasGrid>
                        <BaasGrid item xs={12} md={6}>
                          <BaasTextField
                            fullWidth
                            name="taxId"
                            label="Tax Id"
                            type="number"
                            required
                          />
                        </BaasGrid>
                      </BaasRow>
                      <FormActions
                        onCancel={onCancel}
                        submitLabel="Create"
                        isSubmitting={isSubmitting}
                      />
                    </BaasGrid>
                  </BaasRow>
                </Form>
              )}
            </Formik>
          )}
        </BaasGrid>
      </BaasFlex>
    </>
  );
}
