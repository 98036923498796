import BaasText from "components/atoms/Text/BaasText";
import React, { useCallback, useState } from "react";
import { useTheme } from "@mui/system";
import BaasGrid from "components/atoms/Grid/Grid";
import { useMutation } from "react-query";
import { toast } from "react-toastify";

import { Form, Formik } from "formik";
import BaasButton from "components/atoms/Button/Button";
import { useOtp } from "providers/oneTimePassword";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";

import { toastMessages } from "constants/toastMessages";
import BaasTextField from "components/atoms/TextField/TextField";
import { mutationPostImportFile } from "api/efx-api";
import guidGenerator from "services/guidGenerator";

export default function EfxInterfaceUpload() {
  const theme = useTheme();
  const [upload, setUpload] = useState(false);
  const [pactualId, setPactualId] = useState("");
  const [clientRequestId, setClientRequestId] = useState();
  const [file, setFile] = useState(null);
  const { setOpen } = useOtp();

  const getFiles = (e) => {
    setFile(e.target.files[0]);
  };
  const { mutate } = useMutation((e) => mutationPostImportFile(e));

  const onSubmit = useCallback(
    (values, formik) => {
      mutate(
        {
          otp: values.otp,
          File: file,
          Description: values.description,
          ImportType: "PaymentFacilitator",
          ImportSubType: "PaymentFacilitator",
          ClientRequestId: clientRequestId,
        },
        {
          onSuccess: (data) => {
            if ("errors" in data) {
              data.errors.forEach((error) => {
                toast.error(`${error.errorCode} - ${error.message}`);
              });
            } else {
              if ("body" in data) {
                setPactualId(data.body.pactualId);
                toast.success(toastMessages.efx.upload.success);
              }
              setUpload(false);
              setFile(null);
              formik.resetForm();
            }
          },

          onSettled: () => {
            formik.setSubmitting(false);
          },
        }
      );
    },
    [file]
  );

  return (
    <>
      <Formik
        initialValues={{}}
        onSubmit={() => setOpen(true)}
        enableReinitialize
      >
        {({ values, isSubmitting, resetForm, ...props }) => (
          <Form>
            <BaasOtpModal
              onConfirm={() => onSubmit(values, props)}
              {...props}
            />
            <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
              <BaasText>
                Upload your eFX SErvice files here. Please note that a
                description must be submitted with each file.
              </BaasText>
              {upload ? (
                <>
                  <BaasGrid container p="8px 0px" spacing={3}>
                    <BaasGrid item xs={12} sm={6}>
                      <BaasTextField
                        fullWidth
                        name="description"
                        label="Description"
                      />
                    </BaasGrid>
                  </BaasGrid>
                  <BaasGrid container spacing={3}>
                    <BaasGrid item xs={12} sm={6}>
                      <BaasTextField
                        fullWidth
                        name="file"
                        label="File"
                        type="file"
                        onChange={(e) => getFiles(e)}
                      />
                    </BaasGrid>
                  </BaasGrid>
                  <BaasGrid item display="flex">
                    <BaasButton
                      style={{ margin: "16px 0px", marginRight: "24px" }}
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        resetForm();
                        setFile(null);
                        setUpload(false);
                      }}
                    >
                      Cancel
                    </BaasButton>
                    <BaasButton
                      style={{ margin: "16px 0px" }}
                      variant="contained"
                      backgroundColor="black"
                      disabled={
                        file !== null && values.description ? false : true
                      }
                      onClick={() => setOpen(true)}
                    >
                      Upload
                    </BaasButton>
                  </BaasGrid>
                </>
              ) : (
                <>
                  <BaasGrid item>
                    <BaasButton
                      style={{ margin: "16px 0px" }}
                      variant="contained"
                      onClick={() => {
                        setClientRequestId(guidGenerator());
                        setUpload(true);
                      }}
                    >
                      Upload eFX file
                    </BaasButton>
                  </BaasGrid>
                </>
              )}
              {!!pactualId && (
                <BaasGrid container p="8px 0px" spacing={3}>
                  <BaasGrid item xs={12}>
                    <BaasText>
                      <strong>Recent uploaded files:</strong>
                    </BaasText>
                    <BaasText>{pactualId}</BaasText>
                  </BaasGrid>
                </BaasGrid>
              )}
            </BaasGrid>
          </Form>
        )}
      </Formik>
    </>
  );
}
