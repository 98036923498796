import React, { useCallback } from "react";
import ReactJson from "react-json-view";
import BaasModal from "components/atoms/Modal/Modal";
import Backdrop from "@mui/material/Backdrop";
import styled from "styled-components";
import { useTheme } from "@mui/private-theming";
import { Box } from "@mui/system";
import BaasText from "components/atoms/Text/BaasText";
import BaasButton from "components/atoms/Button/Button";
import BaasGrid from "components/atoms/Grid/Grid";
import { Form, Formik } from "formik";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import { useOtp } from "providers/oneTimePassword";
import { mutationPostPaymentPaymentSlipCnabFileByPactualId } from "api/cnab-api";
import { toast } from "react-toastify";
import { toastMessages } from "constants/toastMessages";
import moment from "moment";
import converterFromBase64 from "helpers/converterFromBase64";
import { isNewCnabProfile } from "helpers/isNewCnabProfile";

const BoxStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  max-width: 600px;

  height: 75vh;
  background-color: ${(props) => props.theme.palette.grey[200]};

  box-shadow: 24;
  padding: 4px;
  border-radius: 4px;
  overflow: hidden;
  overflow-y: scroll;

  :focus-visible {
    outline: none;
  }
`;

const JsonStyled = styled(Box)`
  border-radius: 4px;
  background: ${(props) => props.theme.palette.grey[100]};
  max-height: 400px;
  overflow-y: scroll;
  border-radius: 4px;
  padding: 8px;
`;

const CnabInterfaceDownloadListModal = ({ data, setModal, modal }) => {
  const theme = useTheme();
  const { setOpen } = useOtp();
  const handleClose = () => {
    setModal({ state: false });
  };

  const download = useCallback(async (values) => {
    const response = await mutationPostPaymentPaymentSlipCnabFileByPactualId(
      values
    );
    if (response.body) {
      toast.success("Your download will start shortly.");
      converterFromBase64(
        `baas_cnab_payment_slip_${moment().format()}.txt`,
        response.body,
        "txt"
      );
    } else {
      toast.error(toastMessages.cnab.download.error);
    }
    return response;
  }, []);

  const onSubmit = useCallback(
    (values, formik) => {
      download({
        fileName: isNewCnabProfile()
          ? data.body?.retornoS3Key
          : data.body?.fileNameIssued,
        otp: values.otp,
        newCnab: isNewCnabProfile(),
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modal]
  );

  const canDownload = () => {
    if (isNewCnabProfile()) {
      return Boolean(data?.status === "COMPLETED");
    } else {
      return Boolean(data.body?.fileNameIssued);
    }
  };

  return (
    <>
      <BaasModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modal.state}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Formik initialValues={{}} onSubmit={() => setOpen(true)}>
          {({ values, isSubmitting, ...props }) => (
            <Form>
              <BaasOtpModal
                onConfirm={() => onSubmit(values, props)}
                {...props}
              />
              <BoxStyled p={3} theme={theme} sx={{ maxWidth: "400px" }}>
                <BaasText>Transaction Details (Object TreeView)</BaasText>
                <BaasGrid p="16px 0px">
                  {!canDownload() ? (
                    <BaasText color="error" p="0px 0px 12px 0px">
                      The submitted archive is not yet processed. Please wait a
                      few minutes or contact user support.
                    </BaasText>
                  ) : null}
                  <BaasButton
                    backgroundColor="black"
                    variant="contained"
                    onClick={() => setOpen(true)}
                    disabled={!canDownload() ? true : false}
                  >
                    Download
                  </BaasButton>
                </BaasGrid>
                <JsonStyled theme={theme}>
                  <ReactJson
                    name={null}
                    src={data}
                    style={{ height: "100%", overflowY: "scroll" }}
                    displayObjectSize={false}
                    displayDataTypes={false}
                  />
                </JsonStyled>
              </BoxStyled>
            </Form>
          )}
        </Formik>
      </BaasModal>
    </>
  );
};

export default CnabInterfaceDownloadListModal;
