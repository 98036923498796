import React, { useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import BaasModal from "components/atoms/Modal/Modal";
import BaasText from "components/atoms/Text/BaasText";
import { Fade } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import styled from "styled-components";
import { useOtp } from "providers/oneTimePassword";
import BaasButton from "components/atoms/Button/Button";
import BaasRow from "components/atoms/Grid/Row";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasFlex from "components/atoms/Flex/Flex";
import { useField } from "formik";

const BoxStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400;
  background-color: ${(props) => props.theme.palette.grey[100]};

  box-shadow: 24;
  padding: 4px;
  border-radius: 4px;

  :focus-visible {
    outline: none;
  }
`;

const BaasOtpModal = ({ onConfirm, ...props }) => {
  const theme = useTheme();
  const regex = new RegExp("^[0-9]{6}$");
  const { open, setOpen } = useOtp();
  const [otp, , { setValue }] = useField("otp");

  const handleClose = () => {
    props.setSubmitting(false);
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      setValue("");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <BaasModal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <BoxStyled p={3} theme={theme} sx={{ maxWidth: "400px" }}>
          <BaasText id="transition-modal-title" variant="h6" component="h2">
            OTP is required
          </BaasText>
          <BaasText id="transition-modal-title" variant="body2" component="h2">
            Simply scan the QR code using one of many authenticators. Such as
            Google Authenticator or Microsoft Authenticator and fill the field
            bellow with the token displayed on the app.
          </BaasText>
          <BaasRow>
            <BaasTextField
              name="otp"
              fullWidth
              type="text"
              variant="outlined"
              label="OTP"
            />
          </BaasRow>
          <BaasFlex justifyContent="space-between">
            <BaasButton
              variant="outlined"
              color="error"
              onClick={handleClose}
              sx={{ marginRight: "8px" }}
            >
              Cancel
            </BaasButton>
            <BaasButton
              variant="contained"
              disabled={!regex.test(otp.value)}
              onClick={() => {
                onConfirm();
                handleClose();
              }}
              sx={{ marginLeft: "8px" }}
            >
              Confirm
            </BaasButton>
          </BaasFlex>
        </BoxStyled>
      </Fade>
    </BaasModal>
  );
};

export default BaasOtpModal;
