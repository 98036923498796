import { MenuItem } from "@mui/material";
import React from "react";
import styled from "styled-components";

const MenuItemStyle = styled.div`
  /* padding: 8px; */
`;

const BaasMenuItem = ({ ...props }) => {
  return (
    <MenuItemStyle>
      <MenuItem {...props} />
    </MenuItemStyle>
  );
};

export default BaasMenuItem;
