import * as yup from "yup";

export const formatYupSchemaByModalities = (
  validationSchema,
  activeModalities
) => {
  let schema;

  if (activeModalities.includes("ABATEMENT")) {
    schema = {
      ...schema,
      abatementModality: yup.string().required("Modality is Required"),
      abatementAmountPerc: yup.number().required("Amount is Required"),
    };
  }

  if (activeModalities.includes("FINE")) {
    schema = {
      ...schema,
      fineModality: yup.string().required("Modality is Required"),
      fineAmountPerc: yup.number().required("Amount is Required"),
    };
  }

  if (activeModalities.includes("INTEREST")) {
    schema = {
      ...schema,
      interestModality: yup.string().required("Modality is Required"),
      interestAmountPerc: yup.number().required("Amount is Required"),
    };
  }

  const mergedSchema = {
    ...validationSchema,
    ...schema,
  };

  return yup.object().shape(mergedSchema);
};
