import { queryGetDictEntryPixByAccount } from "api/pix-api";
import { useCallback, useEffect, useState } from "react";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasButton from "components/atoms/Button/Button";
import EntryCreateForm from "components/organism/PixEntryCreateForm/PixEntryCreateForm";
import BaasText from "components/atoms/Text/BaasText";
import {
  StyledKeyLabel,
  StyledTypeLabel,
  StyledActionsWrapper,
} from "./styles";
import BaasBox from "components/atoms/Box/Box";
import FaqSection from "components/organism/FaqSection/FaqSection";
import { useAccount } from "providers/account";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import { toast } from "react-toastify";
import AutocompleteDefault from "components/atoms/Autocomplete/AutocompleteDefault";
import { AddButton } from "components/molecules/AddButton/AddButton";
import { BackButton } from "components/molecules/BackButton/BackButton";

const PixCreateClaimPanel = (props) => {
  const {
    setSelectedPixKey,
    faqMessages,
    onSubmit,
    selectedPixKey,
    isSubmitting,
  } = props;
  const [isCreatingKey, setIsCreatingKey] = useState(false);
  const [loadingEntries, setLoadingEntries] = useState(false);
  const [entriesData, setEntriesData] = useState();
  const { accountSelected } = useAccount();

  const listEntries = useCallback(async (params) => {
    try {
      const response = await queryGetDictEntryPixByAccount(params);
      setEntriesData(response);
    } catch (err) {
      toast.error(err);
    } finally {
      setLoadingEntries(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoadingEntries(true);
    if (accountSelected && accountSelected.branch && accountSelected.account) {
      const params = {
        branch: accountSelected?.branch,
        account: accountSelected.account,
        keyType: "email",
      };
      listEntries(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountSelected, isCreatingKey]);

  const toogleCreateKey = () => {
    setSelectedPixKey(undefined);
    setIsCreatingKey(!isCreatingKey);
  };

  const handleSelectOption = (value) => {
    setSelectedPixKey({
      keyType: value.type,
      key: value.key,
    });
  };

  const entryOptions = (entriesData || []).map((item) => {
    return {
      key: item.entry.key,
      type: item.entry.keyType,
      label: `${item.entry.key} - ${item.entry.keyType}`,
    };
  });

  const entriesListComponent =
    entriesData && !loadingEntries && entriesData.length > 0 ? (
      <>
        <BaasText variant="body2" fontWeight="bold" mb={3}>
          Decide what PIX key you want to use in the claim process.
        </BaasText>
        <AutocompleteDefault
          name="key"
          label="Pix Key"
          isLoading={loadingEntries}
          onClear={() => setSelectedPixKey(undefined)}
          onSelect={handleSelectOption}
          options={entryOptions}
          renderOption={(props, option) => (
            <BaasBox
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <StyledKeyLabel flexDirection="row" display="flex">
                {option.key}
                <StyledTypeLabel>- {option.type}</StyledTypeLabel>
              </StyledKeyLabel>
            </BaasBox>
          )}
        />
      </>
    ) : (
      <BaasText color="error">Please, create a PIX key to proceed.</BaasText>
    );

  return (
    <BaasGrid>
      <BackButton path="/pix-test-suit-claims-list" />
      {isCreatingKey ? (
        <EntryCreateForm onCancel={toogleCreateKey} />
      ) : (
        <>
          <BaasGrid item xs={12} md={12} mb={2}>
            {loadingEntries
              ? Array.from(Array(1).keys()).map((item) => (
                  <BaasSkeleton
                    key={item}
                    width="100%"
                    mt={1}
                    height={"54.5px"}
                  />
                ))
              : entriesListComponent}
          </BaasGrid>
          <StyledActionsWrapper>
            <BaasButton
              variant="contained"
              onClick={onSubmit}
              disabled={!selectedPixKey}
              isSubmiting={isSubmitting}
            >
              Create Claim
            </BaasButton>
            <AddButton label="Create PIX key" onClick={toogleCreateKey} />
          </StyledActionsWrapper>
        </>
      )}

      <BaasBox mt={2}>
        <FaqSection messages={faqMessages} />
      </BaasBox>
    </BaasGrid>
  );
};

export default PixCreateClaimPanel;
