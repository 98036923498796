import { makeStyles } from "@mui/styles";

export const landingOurClientsStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundSize: "cover",
    minHeight: "80vh",
    color: "#fff",
    padding: "25vh 0",
  },
  heroContentMd: {
    backgroundSize: "cover",
    minHeight: "70vh",
    color: "#fff",
    padding: "19vh 0",
  },
  heroBannerTitle: {
    color: "#E6E6E6",
    textShadow: "5px 0px 12px black",
    fontSize: 60,
    "@media only screen and (max-width: 768px)": {
      fontSize: "45px",
    },
  },
  heroBannerSubtitle: {
    color: "#C9C9C9",
    textShadow: "10px 7px 12px black",
    fontSize: 24,
    margin: theme.spacing(4, 8, 4, 8),
    "@media only screen and (max-width: 768px)": {
      fontSize: 18,
    },
  },
  heroButtonsGrid: {
    marginTop: theme.spacing(4),
  },
  heroButtons: {
    fontSize: "16px",
    borderRadius: theme.spacing(4),
    padding: theme.spacing(2, 4, 2, 4),
    "&:hover": {
      backgroundColor: "##374487",
      color: "#fff",
    },
    "&:focus": {
      backgroundColor: "##374487",
      color: "#fff",
    },
  },
  stepButtons: {
    borderRadius: "50%",
    width: "56px",
    height: "56px",
    padding: "8px",
    textAlign: "center",
    backgroundColor: "#3f51b5",
    color: "white",
    fontWeight: "bold",
    fontSize: "28px",
    margin: "18px",
  },
  logo: {
    textAlign: "center",
    padding: "20px",
  },
  logo2: {
    textAlign: "center",
  },
  steps: {
    padding: "12px",
    fontSize: "16px",
    fontWeight: "light",
  },
  cardSteps: {
    boxShadow: "0 0px 0px 0 rgba(255, 255, 255)",
    margin: "56px 0",
  },
  card: {
    boxShadow: "0 0px 0px 0 rgba(255, 255, 255)",
  },
  cardPublish: {
    marginTop: "10px",
    marginBottom: "30px",
    boxShadow: "0 0px 0px 0 rgba(255, 255, 255)",
  },
  fintechImg: {
    width: "450px",
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitle: {
    color: "rgba(0,0,0,.62)",
    fontSize: "30px",
    minHeight: "auto",
    fontWeight: "bold",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "30px",
    marginTop: "30px",
    textDecoration: "none",
  },
  cardTitleCustomers: {
    textAlign: "center",
    fontSize: "40px",
    color: "rgba(0,0,0,.62)",
    minHeight: "auto",
    fontWeight: "bold",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    textDecoration: "none",
    "@media only screen and (max-width: 768px)": {
      fontSize: "30px",
    },
  },
  cardTitleProducts: {
    color: "rgba(255,255,255,.95)",
    fontSize: "24px",
    textAlign: "left",
    minHeight: "auto",
    fontWeight: "bold",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "0px",
    marginTop: "24px",
    textDecoration: "none",
  },
  cardTitlePublish: {
    color: "#000",
    fontSize: "11px",
    textAlign: "left",
    minHeight: "auto",
    fontWeight: "200",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "10px",
    marginTop: "0px",
    textDecoration: "none",
  },
  cardTitleBoxPublish: {
    color: "#000",
    fontSize: "18px",
    fontWeight: "100",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    textDecoration: "none",
  },
  infoText: {
    fontSize: "18px",
  },
  sponsorTitle: {
    letterSpacing: "1px !important",
  },
  infoTextDeveloper: {
    textAlign: "center",
    fontSize: "14px",
    marginBottom: "0px",
    marginTop: "0px",
  },
  infoTextProducts: {
    color: "rgba(255,255,255,.65)",
    padding: "0 0 20px 0",
    textAlign: "justify",
    fontSize: "16px",
    fontWeight: "light",
    marginBottom: "20px",
    marginTop: "0px",
  },
  infoTextBusiness: {
    color: "rgba(0,0,0,.65)",
    padding: "0 0 20px 0",
    textAlign: "justify",
    fontSize: "16px",
    marginBottom: "20px",
    marginTop: "0px",
  },
  infoTextPublish: {
    textAlign: "justify",
    fontSize: "10px",
    fontStyle: "italic",
    marginBottom: "10px",
    marginTop: "10px",
  },
  pDatePublish: {
    textAlign: "justify",
    fontSize: "10px",
    marginTop: "0px",
  },
  button: {
    marginTop: "36px",
    marginBottom: "30px",
  },
  footer: {
    backgroundColor: "rgba(0,0,0,1)",
    color: "#fff",
    fontSize: "10px",
    textAlign: "center",
    height: "40px",
    paddingTop: "3px",
  },
  footerSpan: {
    color: "#808080",
  },
  separator: {
    textAlign: "center",
    color: "rgba(0,0,0,.62)",
    fontSize: "15px",
    paddingTop: "18px",
    paddingBottom: "12px",
  },
  imagemLogo: {
    height: "30px",
  },
  divCarousel: {
    textAlign: "center",
  },
  imgCarousel: {
    height: "350px",
    width: "800px",
  },
  titleImgCarousel: {
    fontSize: "16px",
    fontWeight: "bold",
    paddingLeft: "35px",
  },
  cardProducts: {
    padding: "0 40px",
  },
  logoTitle: {
    fontSize: "24px",
    fontWeight: "bold",
  },
  displayCarousel: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  carousselLogo: {
    maxWidth: 200,
    maxHeight: 200,
    margin: 16,
    textAlign: "center",
  },
  sponsorLogo: {
    maxWidth: 450,
    maxHeight: 450,
    margin: 16,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      maxWidth: 250,
      maxHeight: 250,
    },
  },
  staticLogo: {
    maxWidth: 240,
    maxHeight: 200,
    marginTop: 25,
    marginBottom: 25,
    textAlign: "center",
  },
}));

export default landingOurClientsStyles;
