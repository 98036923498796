import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box, Button } from "@mui/material";
import {
  mutationAuthoriseConsent,
  mutationCreateConsent,
} from "api/openFinance/openFinance-api";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import { convertArrayBufferToBase64 } from "helpers/convertArrayBufferToBase64";
import { getNavigatorInfo } from "helpers/getNavigatorInfo";
import { useCustomMutation } from "helpers/useCustomMutation";
import moment from "moment";
import { openFinanceValues } from "pages/private/OpenFinance/constants/openFinanceValues";
import { useAccount } from "providers/account";
import { useCallback, useState } from "react";
import { useWebAuthn } from "react-hook-webauthn";
import guidGenerator from "services/guidGenerator";
import { toast } from "react-toastify";

export const OpenFinanceCheckoutPanel = ({
  handleNext,
  fidoOptionsData,
  setAuthPoolingPactualId,
}) => {
  const sessionParams = JSON.parse(sessionStorage.getItem("opfParams") || null);
  const [fidoAssertion, setFidoAssertion] = useState();
  const [consentResponse, setConsentResponse] = useState();
  const { accountSelected } = useAccount();
  const [activeStep, setActiveStep] = useState(0);

  const {
    mutate: mutateCreateConsent,
    isSubmitting: submittingConsent,
  } = useCustomMutation(mutationCreateConsent);

  const {
    mutate: mutateAuthoriseConsent,
    isSubmitting: submittingAuthorisation,
  } = useCustomMutation(mutationAuthoriseConsent);

  const { getAssertion } = useWebAuthn({
    rpId: window.location.hostname,
    rpName: fidoOptionsData?.body?.fidoRegistrationOptions?.rp?.name,
  });

  const handleCreateConsent = useCallback(
    async (e) => {
      const creditorAccount = {
        personType: "LEGAL_ENTITY",
        cpfCnpj: accountSelected.taxId,
        name: accountSelected.name,
        ispb: "30306294",
        number: accountSelected.account,
        accountType: "CACC",
      };
      mutateCreateConsent(
        {
          enrollmentPactualId: sessionParams?.pactualId,
          creditorAccount: creditorAccount,
          amount: (sessionParams?.amount || 0) * 100,
          clientRequestId: guidGenerator(),
          loggedUser: openFinanceValues.loggedUser,
          authorizationServerId: openFinanceValues.authorizationServerId,
          debtorAccount: openFinanceValues.debtorAccount,
        },
        (response) => {
          if (response.body?.status === "ERROR") {
            toast.error("An error ocurred while consent was created");
          } else {
            setConsentResponse(response);
            setActiveStep(1);
          }
        }
      );
    },
    [accountSelected, mutateCreateConsent, sessionParams]
  );

  const onAuth = useCallback(async () => {
    if (!consentResponse.body?.body?.fidoSignOptions?.challenge) {
      toast.error(
        "An error ocurred while consent was created, no challenge available for authentication."
      );
    } else {
      const assertion = await getAssertion({
        challenge: consentResponse.body?.body?.fidoSignOptions?.challenge,
      });
      setFidoAssertion(assertion);
      setActiveStep(2);
    }
  }, [consentResponse, getAssertion]);

  const handleAuthoriseConsent = useCallback(
    async (e) => {
      const decoder = new TextDecoder("utf-8");
      const clientDataObject = JSON.parse(
        decoder.decode(fidoAssertion.response?.clientDataJSON)
      );
      const splitOrigin = clientDataObject.origin.split(":");
      const origin = `${splitOrigin[0]}:${splitOrigin[1]}`;

      mutateAuthoriseConsent(
        {
          consentPactualId: consentResponse?.body?.pactualId,
          riskSignals: getNavigatorInfo(
            moment(accountSelected.createTimestamp).format("YYYY-MM-DD")
          ),

          fidoAssertion: {
            id: fidoAssertion.id,
            rawId: fidoAssertion.id,
            type: fidoAssertion.type,
            response: {
              authenticatorData: convertArrayBufferToBase64(
                fidoAssertion.response?.authenticatorData
              ),
              clientDataJSON: window.btoa(
                JSON.stringify({
                  ...clientDataObject,
                  origin: origin,
                })
              ),
              signature: convertArrayBufferToBase64(
                fidoAssertion.response.signature
              ),
              userHandle: convertArrayBufferToBase64(
                fidoAssertion.response.userHandle
              ),
              // signature: encodeBase64(fidoAssertion.response.signature),
              // userHandle: encodeBase64(fidoAssertion.response.userHandle),
            },
          },
        },
        (response) => {
          setAuthPoolingPactualId(response?.body?.pactualId);
          handleNext(false);
        }
      );
    },
    [
      accountSelected.createTimestamp,
      consentResponse,
      handleNext,
      fidoAssertion,
      mutateAuthoriseConsent,
      setAuthPoolingPactualId,
    ]
  );

  return (
    <>
      <BaasGrid item xs={12}>
        <BaasText variant="body2">Checkout Panel, Consent and auth</BaasText>
      </BaasGrid>

      <BaasGrid item xs={12}>
        <BaasText variant="body2">Disclaimer sobre o Consent</BaasText>
      </BaasGrid>

      <BaasGrid item xs={12}>
        <Box
          width="100%"
          flexDirection="row"
          display="flex"
          padding={2}
          paddingLeft={0}
          alignItems="center"
        >
          <Button
            disabled={activeStep !== 0 || submittingConsent}
            onClick={() => handleCreateConsent()}
            variant="outlined"
            style={{ minWidth: "140px" }}
          >
            {submittingConsent ? "Consent in progress..." : "Consent"}
          </Button>
          {activeStep > 0 && (
            <CheckCircleIcon color="success" style={{ marginLeft: "16px" }} />
          )}
        </Box>
      </BaasGrid>

      <BaasGrid item xs={12}>
        <Box
          width="100%"
          flexDirection="row"
          display="flex"
          padding={2}
          paddingLeft={0}
          alignItems="center"
        >
          <Button
            disabled={activeStep !== 1 || submittingAuthorisation}
            onClick={onAuth}
            variant="outlined"
            style={{ minWidth: "140px" }}
          >
            {submittingAuthorisation
              ? "Authorisation in progress..."
              : "FIDO Auth"}
          </Button>
          {activeStep > 1 && (
            <CheckCircleIcon color="success" style={{ marginLeft: "16px" }} />
          )}
        </Box>
      </BaasGrid>

      <BaasGrid item xs={12}>
        <Box
          width="100%"
          flexDirection="row"
          display="flex"
          padding={2}
          paddingLeft={0}
        >
          <Button
            disabled={activeStep !== 2}
            onClick={() => handleAuthoriseConsent()}
            variant="outlined"
            style={{ minWidth: "140px" }}
          >
            Authorise Consent
          </Button>
          {/* {activeStep > 2 && (
            <CheckCircleIcon color="success" style={{ marginLeft: "8px" }} />
          )} */}
        </Box>
      </BaasGrid>
      {/* <BaasGrid item xs={3}>
        <Box mt={2}>
          <BaasInfoDisplay
            label={"Amount:"}
            value={`R$ ${sessionParams?.amount.toFixed(2)}`}
          />
        </Box>
      </BaasGrid>
      <BaasGrid item xs={3}>
        <Box mt={2}>
          <BaasInfoDisplay
            label={"Bank:"}
            value={values?.selectedBank?.displayName}
          />
        </Box>
      </BaasGrid> */}
      {/* <BaasGrid item xs={6}>
        <Box
          mb={2}
          mt={2}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Button
            color="primary"
            variant="outlined"
            style={{ width: "120px" }}
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={handleNext}
            style={{ width: "120px" }}
            disabled={!values?.selectedBank}
          >
            Next
          </Button>
        </Box>
      </BaasGrid> */}
    </>
  );
};
