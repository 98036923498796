import React from "react";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import { useTheme } from "@mui/system";
import BaasButton from "components/atoms/Button/Button";
import { currencyFormatter } from "helpers/currencyFormatter";

const BaasObkProductCard = ({ item, addToCart }) => {
  const theme = useTheme();
  return (
    <BaasGrid
      spacing={2}
      p={3}
      sx={{
        height: "100%",
        borderRadius: "8px",
        backgroundColor: theme.palette.grey[100],
      }}
    >
      <BaasGrid
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
      >
        <BaasGrid>
          <BaasGrid p="8px 0px">
            <img
              src={item.img}
              alt={item.description}
              style={{ marginRight: "8px", maxWidth: "100%" }}
            />
          </BaasGrid>

          <BaasText variant="body2" color="grey.600">
            {item.description}
          </BaasText>

          <BaasGrid p="8px 0px" style={{ textAlign: "right !important" }}>
            <BaasText variant="h6" color="grey.700">
              <strong> R$ {currencyFormatter(item.value)}</strong>
            </BaasText>
          </BaasGrid>
        </BaasGrid>

        <BaasGrid>
          <BaasButton
            variant="outlined"
            backgroundColor="black"
            onClick={() => addToCart(item.id)}
          >
            Add to cart
          </BaasButton>
        </BaasGrid>
      </BaasGrid>
    </BaasGrid>
  );
};

export default BaasObkProductCard;
