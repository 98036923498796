import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useTheme } from "@mui/system";
import { queryGetUserSecret } from "api/security-api";
import BaasButton from "components/atoms/Button/Button";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import SecretDisplayer from "components/molecules/SecretDisplayer/SecretDisplayer";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import { Form, Formik } from "formik";
import { useOtp } from "providers/oneTimePassword";
import { useCallback, useState } from "react";

const UserCredentialsSecrets = ({ data }) => {
  const theme = useTheme();
  const { setOpen } = useOtp();
  const { isOtpRequired } = useOtp();
  const [secrets, setSecrets] = useState([]);
  const [hidden, setHidden] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(
    async (values, formik) => {
      const data = await queryGetUserSecret(values.otp);
      setIsLoading(true);
      setOpen(false);
      setHidden(false);
      setSecrets(data.clientSecrets);
      formik.resetForm();
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setOpen]
  );

  const resetSecret = () => {
    setHidden(true);
    setSecrets([]);
  };

  return (
    <>
      <Formik
        initialValues={{}}
        onSubmit={hidden ? () => setOpen(true) : () => resetSecret()}
      >
        {({ values, isSubmitting, ...props }) => (
          <Form>
            <BaasOtpModal
              onConfirm={() => onSubmit(values, props)}
              {...props}
            />

            <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
              <BaasRow container spacing={1}>
                <BaasText
                  sx={{ paddingLeft: "8px", marginBottom: "8px" }}
                  variant="subtitle1"
                >
                  Secret key details are listed bellow. Use these credentials to
                  connect to our infrastructure.
                  {isOtpRequired
                    ? " Please, share only with qualified personnel."
                    : null}
                </BaasText>
                {isOtpRequired ? (
                  <BaasRow container style={{ marginBottom: "0" }}>
                    <BaasButton
                      type="submit"
                      color="primary"
                      variant="outlined"
                      startIcon={
                        hidden ? <VisibilityIcon /> : <VisibilityOffIcon />
                      }
                    >
                      {hidden ? "Show secret" : "Hide secret"}
                    </BaasButton>
                  </BaasRow>
                ) : null}
              </BaasRow>
            </BaasGrid>

            {isOtpRequired ? (
              <>
                {secrets.map((secret) => (
                  <SecretDisplayer data={secret} />
                ))}
              </>
            ) : (
              <>
                {(data?.clientSecrets || []).map((secret) => (
                  <SecretDisplayer data={secret} />
                ))}
              </>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
};

export default UserCredentialsSecrets;
