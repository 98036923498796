import BaasRow from "components/atoms/Grid/Row";
import BaasGrid from "components/atoms/Grid/Grid";
import React, { useCallback, useMemo, useState } from "react";
import BaasTextField from "components/atoms/TextField/TextField";
import { Form, Formik } from "formik";
import BaasButton from "components/atoms/Button/Button";
import { useTheme } from "@mui/system";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import BaasText from "components/atoms/Text/BaasText";
import { mutationPostPlaygroundPixCashIn } from "api/transaction-api";
import { useMutation } from "react-query";
import { useAccount } from "providers/account";
import { useOtp } from "providers/oneTimePassword";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import BaasTransactionSuccess from "components/templates/TransactionSuccess/TransactionSuccess";
import { toast } from "react-toastify";
import { toastMessages } from "constants/toastMessages";
import Endpoints from "constants/endpoints";
import BaasTextFieldCurrency from "components/molecules/TextFieldCurrency/TextFieldCurrency";
import * as yup from "yup";

const DashboardPixPaymentCashIn = () => {
  const theme = useTheme();
  const { accountSelected } = useAccount();
  const { setOpen, isOtpRequired } = useOtp();
  const [success, setSuccess] = useState(false);

  const initialValues = useMemo(() => {
    return {};
  }, []);

  const validationSchema = yup.object().shape({
    documentNumber: yup
      .string()
      .min(11, "Document number must be at least 11 characters")
      .required(),
    branch: yup.string().min(4, "Branch must be at least 4 numbers").required(),
    bank: yup.string().min(3, "Bank must be at least 3 numbers").required(),
  });

  const { mutate: mutateCashIn } = useMutation((e) =>
    mutationPostPlaygroundPixCashIn(e)
  );

  const onSubmit = useCallback(
    (values, formik) => {
      const ispb =
        Endpoints.Environment === "Sandbox"
          ? accountSelected.taxId.substring(0, 8)
          : "30306294";

      mutateCashIn(
        {
          otp: values.otp || undefined,

          amount: values.amount,
          creditParty: {
            personType: 0,
            accountType: 0,
            account: accountSelected.account,
            branch: accountSelected.branch,
            bank: accountSelected.bank,
            ispb: ispb,
            documentNumber: accountSelected.taxId,
            name: accountSelected.name,
          },
          debitParty: {
            personType: 0,
            accountType: 0,
            account: values.account,
            branch: values.branch,
            bank: values.bank,
            ispb: values.ispb,
            documentNumber: values.documentNumber,
            name: values.name,
          },
          payload: {
            txId: null,
            prxyId: null,
            endToEndId: null,
            rmtInf: null,
            acceptanceDateTime: null,
            svcLvl: null,
            lclInstrm: null,
            purp: null,
            vlcpAmount: 0,
            vldnAmount: 0,
            priority: null,
          },
        },
        {
          onSuccess: () => {
            toast.success(toastMessages.requestSuccess);
            formik.resetForm();
            setOpen(false);
            setSuccess(true);
          },

          onSettled: () => {
            formik.setSubmitting(false);
          },
        }
      );
    },
    [mutateCashIn, accountSelected, setOpen]
  );

  return (
    <>
      <BaasFlex flex={1} flexDirection="column">
        <BaasPageHeader>Transactions</BaasPageHeader>
        <BaasContentGrid spacing={4}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={isOtpRequired ? () => setOpen(true) : onSubmit}
          >
            {({ values, isSubmitting, ...props }) => (
              <Form>
                <BaasOtpModal
                  onConfirm={() => onSubmit(values, props)}
                  {...props}
                />
                <BaasGrid item spacing={1}>
                  <BaasRow>
                    <BaasText variant="h6">Pix cash in</BaasText>
                  </BaasRow>
                </BaasGrid>
                {!success ? (
                  <BaasRow>
                    <BaasGrid
                      p="16px"
                      sx={{ background: theme.palette.grey[100] }}
                    >
                      <BaasText variant="body2">
                        This method simulates a Pix Cash In impacting your
                        account.
                      </BaasText>

                      <BaasRow container column={12} spacing={3}>
                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextFieldCurrency
                            fullWidth
                            required
                            name="amount"
                            label="Amount"
                          />
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            required
                            name="name"
                            label="Name"
                          />
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            required
                            name="documentNumber"
                            label="Document number"
                            type="number"
                          />
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            required
                            name="ispb"
                            label="Ispb"
                            type="number"
                          />
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            required
                            name="bank"
                            label="Bank"
                            type="number"
                          />
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            required
                            name="branch"
                            label="Branch"
                            type="number"
                          />
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            required
                            name="account"
                            label="Account"
                            type="number"
                          />
                        </BaasGrid>
                      </BaasRow>
                      <BaasRow
                        container
                        sx={{ justifyContent: "space-between" }}
                        flexDirection="row"
                      >
                        <BaasGrid p={0} item>
                          <BaasButton
                            type="submit"
                            size="medium"
                            variant="contained"
                            backgroundColor="black"
                            loading={isSubmitting}
                          >
                            Transfer
                          </BaasButton>
                        </BaasGrid>
                      </BaasRow>
                    </BaasGrid>
                  </BaasRow>
                ) : (
                  <>
                    <BaasTransactionSuccess />
                  </>
                )}
              </Form>
            )}
          </Formik>
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
};

export default DashboardPixPaymentCashIn;
