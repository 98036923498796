import { Container } from "@mui/material";
import React from "react";
import styled from "styled-components";

const ContainerStyle = styled.div`
  width: ${(props) => props.width};
  .MuiContainer-root {
    padding: 0px;
  }
`;

const BaasContainer = ({ width, children, ...props }) => {
  return (
    <ContainerStyle width={width}>
      <Container {...props}>{children}</Container>
    </ContainerStyle>
  );
};

export default BaasContainer;
