import BaasRow from "components/atoms/Grid/Row";
import BaasGrid from "components/atoms/Grid/Grid";
import React, { useCallback, useMemo, useState } from "react";
import BaasTextField from "components/atoms/TextField/TextField";
import { Form, Formik } from "formik";
import BaasButton from "components/atoms/Button/Button";
import { useTheme } from "@mui/system";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import BaasText from "components/atoms/Text/BaasText";
import { mutationPostTransferInternal } from "api/transaction-api";
import { useMutation } from "react-query";
import { useAccount } from "providers/account";
import { useOtp } from "providers/oneTimePassword";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import guidGenerator from "services/guidGenerator";
import BaasTransactionSuccess from "components/templates/TransactionSuccess/TransactionSuccess";
import { toast } from "react-toastify";
import { toastMessages } from "constants/toastMessages";
import BaasTextFieldCurrency from "components/molecules/TextFieldCurrency/TextFieldCurrency";
import * as yup from "yup";

const DashboardInternalTransfer = () => {
  const theme = useTheme();
  const { accountSelected } = useAccount();
  const { setOpen, isOtpRequired } = useOtp();
  const [success, setSuccess] = useState({ state: false, pactualId: "" });

  const initialValues = useMemo(() => {
    return {};
  }, []);

  const validationSchema = yup.object().shape({
    accountTo: yup.string().max(10, "Account max 10 numbers").required(),
    branchTo: yup.string().max(6, "Branch max 4 numbers ").required(),
    amount: yup.number().min(1, "Min R$ 00,01").required(),
  });

  const { mutate: mutateCashOut } = useMutation((e) =>
    mutationPostTransferInternal(e)
  );

  const onSubmit = useCallback(
    (values, formik) => {
      mutateCashOut(
        {
          otp: values.otp || undefined,

          clientRequestId: guidGenerator(),
          branchFrom: parseInt(accountSelected.branch),
          accountFrom: parseInt(accountSelected.account),
          branchTo: values.branchTo,
          accountTo: values.accountTo,
          amount: values.amount * 100,
        },
        {
          onSuccess: (data) => {
            toast.success(toastMessages.requestSuccess);
            formik.resetForm();
            setOpen(false);
            setSuccess({ pactualId: data.body.pactualId, state: true });
          },
          onError: (data) => {
            toast.error(toastMessages.requestError);
          },
          onSettled: () => {
            formik.setSubmitting(false);
          },
        }
      );
    },
    [mutateCashOut, accountSelected, setOpen]
  );

  return (
    <>
      <BaasFlex flex={1} flexDirection="column">
        <BaasPageHeader>Transactions</BaasPageHeader>

        <BaasContentGrid spacing={4}>
          <Formik
            initialValues={initialValues}
            onSubmit={isOtpRequired ? () => setOpen(true) : onSubmit}
            validationSchema={validationSchema}
          >
            {({ values, isSubmitting, ...props }) => (
              <Form>
                <BaasOtpModal
                  onConfirm={() => onSubmit(values, props)}
                  {...props}
                />
                <BaasGrid item spacing={1}>
                  <BaasRow>
                    <BaasText variant="h6">Internal transfer cash out</BaasText>
                    <BaasFlex justifyContent="space-between">
                      <BaasText variant="caption">
                        /tr/transfer/internal
                      </BaasText>
                      <BaasText variant="caption">
                        Webhook entity: <strong>Internal Tranfer</strong>
                      </BaasText>
                    </BaasFlex>
                  </BaasRow>
                </BaasGrid>

                {!success.state ? (
                  <BaasRow>
                    <BaasGrid
                      p="16px"
                      sx={{ background: theme.palette.grey[100] }}
                    >
                      <BaasText variant="body2">
                        This method simulates an Internal Transfer sending money
                        to another account.
                      </BaasText>

                      <BaasRow container column={12} spacing={3}>
                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextFieldCurrency
                            fullWidth
                            required
                            name="amount"
                            label="Amount"
                          />
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            required
                            name="branchTo"
                            label="Branch To"
                            type="number"
                          />
                        </BaasGrid>

                        <BaasGrid item xs={12} sm={6}>
                          <BaasTextField
                            fullWidth
                            required
                            name="accountTo"
                            label="Account to"
                            type="number"
                          />
                        </BaasGrid>
                      </BaasRow>
                      <BaasRow
                        container
                        sx={{ justifyContent: "space-between" }}
                        flexDirection="row"
                      >
                        <BaasGrid p={0} item>
                          <BaasButton
                            type="submit"
                            size="medium"
                            variant="contained"
                            backgroundColor="black"
                            loading={isSubmitting}
                          >
                            Transfer
                          </BaasButton>
                        </BaasGrid>
                      </BaasRow>
                    </BaasGrid>
                  </BaasRow>
                ) : (
                  <>
                    <BaasTransactionSuccess pactualId={success.pactualId} />
                  </>
                )}
              </Form>
            )}
          </Formik>
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
};

export default DashboardInternalTransfer;
