import BaasText from "components/atoms/Text/BaasText";
import { useAccount } from "providers/account";
import React from "react";

const AccountTypeSelectedLabel = () => {
  const { accountType } = useAccount();

  if (!accountType) {
    return (
      <BaasText variant="h6" color="grey.600" sx={{ marginLeft: "8px" }}>
        No Account Selected
      </BaasText>
    );
  }
  return (
    <BaasText variant="h6" color="grey.600" sx={{ marginLeft: "8px" }}>
      ( {accountType === "Indirect" ? "DICT Hub" : accountType} Account Selected
      )
    </BaasText>
  );
};

export default AccountTypeSelectedLabel;
