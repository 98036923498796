import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";

export const StyledLabel = styled(Typography)`
    color: ${({ theme }) => theme.palette.text.secondary};
    display: flex;
    margin-top: ${({ theme }) => theme.spacing(1)};
`;

export const StyledValue = styled(BaasText)`
    color: black;
    margin-left: ${({ theme }) => theme.spacing(0.5)};
    font-weight: 700;
`;

export const StyledPanelWrapper = styled(BaasGrid)`
    width: 100%;
    border-radius: 4px;
`;

export const StyledPanelTitle = styled(BaasGrid)`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const StyledInfoWrapper = styled(BaasGrid)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const StyledQrWrapper = styled(BaasGrid)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spacing(2)};
    @media (max-width: 900px) {
        justify-content: center;
    }
`;