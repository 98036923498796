import { Menu } from "@mui/material";
import React from "react";
import styled from "styled-components";

const MenuStyle = styled.div``;

const BaasMenu = ({ ...props }) => {
  return (
    <MenuStyle>
      <Menu {...props} />
    </MenuStyle>
  );
};

export default BaasMenu;
