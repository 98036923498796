import React, { useCallback, useMemo } from "react";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import { useTheme } from "@mui/system";
import { useField, useFormikContext } from "formik";
import { Typography } from "@mui/material";

const TextFieldStyle = styled.div`
  width: 100%;
  margin: 8px 0px;
`;

const BaasTextField = ({
  name,
  variant = "standard",
  type = "text",
  displayError = false,
  ...props
}) => {
  const theme = useTheme();
  const [{ value }, { error }, { setValue }] = useField(name);

  const { submitCount } = useFormikContext();

  const showError = useMemo(() => !!error && submitCount > 0, [
    submitCount,
    error,
  ]);

  const helperText = useMemo(() => {
    if (showError || displayError) {
      return (
        <Typography variant="body2" color="error">
          {error}
        </Typography>
      );
    }

    return null;
  }, [showError, error]);

  const onChange = useCallback(
    (event) => {
      if (type === "number") {
        setValue(parseInt(event.target.value));
      } else {
        setValue(event.target.value);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue, type]
  );

  return (
    <TextFieldStyle theme={theme}>
      <TextField
        sx={{ "& .MuiFilledInput-root:before": { borderStyle: "none" } }}
        variant={variant}
        name={name}
        error={showError && error}
        helperText={helperText}
        onChange={onChange}
        value={value}
        type={type}
        {...props}
      />
    </TextFieldStyle>
  );
};

export default BaasTextField;
