import styled from "@emotion/styled";
import BaasBox from "components/atoms/Box/Box";
import BaasText from "components/atoms/Text/BaasText";

export const StyledCardWrapper = styled(BaasBox)`
    display: flex;
    padding: ${({ theme }) => theme.spacing(1)};
    padding-right: ${({ theme }) => theme.spacing(2)};
    padding-left: ${({ theme }) => theme.spacing(1.5)};
    background-color: ${props => props.active ? props.theme.palette.primary.light : props.theme.palette.grey[200]};    
    cursor: pointer;
    color: white;
    align-items: center;
    border-radius: 25px;
`;

export const StyledAvatarWrapper = styled(BaasBox)`
    background-color: ${props => props.active ? props.theme.palette.primary.main : props.theme.palette.grey[400]}; 
    border-radius: 50px;
    width: 34px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Styledlabel = styled(BaasText)`
    opacity: ${props => props.active ? 1 : 0.6}; 
`;