import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import BaasText from "components/atoms/Text/BaasText";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import BaasButton from "components/atoms/Button/Button";
import ArticleIcon from "@mui/icons-material/Article";
import BaasBox from "components/atoms/Box/Box";
import HelpIcon from "@mui/icons-material/Help";
import ErrorIcon from "@mui/icons-material/Error";
import { Box } from "@mui/material";

const FaqSection = ({ messages = [] }) => {
  const [expanded, setExpanded] = useState(undefined);

  const checkIsExpanded = (index) => {
    return Boolean(expanded === index);
  };

  const handleExpand = (index) => {
    const expanded = checkIsExpanded(index);
    setExpanded(expanded ? undefined : index);
  };

  const handleClickLink = (e, externalLink) => {
    e.stopPropagation(e);
    window.open(externalLink, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      {messages.length > 0 &&
        messages.map(
          ({ title, description, externalLink, linkLabel }, index) => {
            const expanded = checkIsExpanded(index);
            const opacity = expanded ? "1" : "0.65";
            const AccordionIcon = expanded ? ErrorIcon : HelpIcon;
            return (
              <Accordion
                key={`accordeon${title}`}
                expanded={expanded}
                onClick={() => handleExpand(index)}
                sx={{ backgroundColor: "transparent" }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  style={{
                    opacity,
                  }}
                >
                  <BaasText
                    fontWeight={expanded ? "bold" : "regular"}
                    display="flex"
                    alignItems="center"
                  >
                    <BaasBox display="flex" alignItems="center" mr={0.5}>
                      <AccordionIcon />
                    </BaasBox>
                    {title}
                  </BaasText>
                </AccordionSummary>
                <AccordionDetails>
                  <BaasText>{description}</BaasText>
                  {linkLabel && externalLink && (
                    <Box mt={2} style={{ marginLeft: "-16px" }}>
                      <BaasButton
                        startIcon={<ArticleIcon />}
                        component="a"
                        onClick={(e) => handleClickLink(e, externalLink)}
                      >
                        {linkLabel}
                      </BaasButton>
                    </Box>
                  )}
                </AccordionDetails>
              </Accordion>
            );
          }
        )}
    </>
  );
};

export default FaqSection;
