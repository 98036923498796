import { baasApiCall } from "services/apiCall";

export const queryListWebhookConfig = () => {
  return baasApiCall("GET", `/pm/pix-api/v2.x/webhook/list`)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationUpdateWebhookConfig = (payload) => {
  return baasApiCall("PUT", `/pm/pix-api/v2.x/webhook/${payload.key}`, payload)
    .then((data) => data.json())
    .catch((error) => error.json());
};

export const mutationDeleteWebhookConfig = (payload) => {
  return baasApiCall("PUT", `/pm/pix-api/v2.x/webhook/${payload.key}`, payload)
    .then((data) => data.json())
    .catch((error) => error.json());
};
