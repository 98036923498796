import { useTheme } from "@mui/private-theming";
import { queryGetCertificate } from "api/webhooks-api";
import { ReactComponent as WarningIcon } from "assets/icons/Attention.svg";
import { ReactComponent as CancelIcon } from "assets/icons/Cancel.svg";
import { ReactComponent as DoneIcon } from "assets/icons/Check.svg";
import BaasButton from "components/atoms/Button/Button";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import { useFormikContext } from "formik";
import getSafe from "helpers/getSafe";
import React from "react";
import WebhookConfigCheckbox from "./WebhookConfigCheckbox";
import WebhookConfigResourceContentActions from "./WebhookConfigResourceContentActions";
import { Alert } from "@mui/material";
import StackCard from "components/atoms/StackCard/StackCard";

const WebhookConfigResourceContent = () => {
  const { values } = useFormikContext();
  const theme = useTheme();

  async function getCertificate() {
    const response = await queryGetCertificate();
    const element = document.createElement("a");
    const file = new Blob([response], {
      type: "text/plain;charset=utf-8",
    });
    element.href = URL.createObjectURL(file);
    element.download = `baas.webhook.mtls.crt`;
    document.body.appendChild(element);
    element.click();
  }

  return (
    <>
      <BaasGrid p="16px 0px">
        <StackCard>
          <BaasGrid>
            <BaasGrid display="flex" alignItems="center">
              <BaasText variant="h6">{values.webhookOptions.name}</BaasText>

              <BaasGrid p={2}>
                {getSafe(
                  values,
                  "webhookOptions.subscription.blockedManyErrors",
                  false
                ) && <CancelIcon color={theme.palette.error.main} />}
                {getSafe(
                  values,
                  "webhookOptions.subscription.qtyConsecutiveErrors",
                  false
                ) > 0 && <WarningIcon color={theme.palette.warning.main} />}
                {!!getSafe(
                  values,
                  "webhookOptions.subscription.eventNames",
                  false
                ) && <DoneIcon color={theme.palette.success.main} />}
              </BaasGrid>
            </BaasGrid>

            <BaasText variant="subtitle1">
              {values.webhookOptions.description}
            </BaasText>
          </BaasGrid>

          {values?.webhookOptions?.name === "Pix" && (
            <Alert severity="warning" style={{ margin: "16px 0" }}>
              If you register URLs in the PIX Key Webhook interface, these URLs
              are going to override the ones submitted in this webhook. This
              only happens to the "Pix" entity webhook.{" "}
            </Alert>
          )}

          {/* ------------Actions--------------- */}
          <WebhookConfigResourceContentActions />
          {/* ---------------------------------- */}

          <BaasGrid container spacing={2} p={0}>
            <BaasGrid item xs={12} sm={6}>
              <BaasTextField
                fullWidth
                name={`resources[${values.webhookOptions.index}].subscription.webhookUrl`}
                label="Webhook Url"
                required={values.resources[
                  values.webhookOptions.index
                ].events.find((item) => item.checked === true)}
              />
            </BaasGrid>
            <BaasGrid xs={0} sm={6} />

            <BaasGrid item xs={12} sm={6}>
              <BaasTextField
                fullWidth
                name={`resources[${values.webhookOptions.index}].subscription.isMtls`}
                label="Security"
                select
              >
                <BaasMenuItem value={true}>mTLS certificate</BaasMenuItem>
                <BaasMenuItem value={false}>
                  Hash Signature (HMAC SHA256 Hash)
                </BaasMenuItem>
              </BaasTextField>
            </BaasGrid>

            <BaasGrid item xs={12} sm={6} sx={{ alignSelf: "center" }}>
              {values.resources[values.webhookOptions.index].subscription
                .isMtls ? (
                <BaasGrid p="4px 8px 4px 0px">
                  <BaasButton
                    variant="outlined"
                    backgroundColor="black"
                    onClick={() => getCertificate()}
                  >
                    Download Certificate
                  </BaasButton>
                </BaasGrid>
              ) : (
                <BaasTextField
                  fullWidth
                  name={`resources[${values.webhookOptions.index}].subscription.secret`}
                  label="Secret"
                  required={values.resources[
                    values.webhookOptions.index
                  ].events.find((item) => item.checked === true)}
                />
              )}
            </BaasGrid>
          </BaasGrid>
          {values.webhookOptions.events.map((item, index) => (
            <BaasGrid p="4px 0px">
              <WebhookConfigCheckbox
                label={`${item.name} - ${item.description}`}
                name={`resources[${values.webhookOptions.index}].events[${index}]`}
                item={item}
              />
            </BaasGrid>
          ))}
        </StackCard>
      </BaasGrid>
    </>
  );
};

export default WebhookConfigResourceContent;
