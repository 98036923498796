import { Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useField, useFormikContext } from "formik";
import BaasTextField from "components/atoms/TextField/TextField";

const TextAreaSearch = ({
  valuesLimit = 10,
  name,
  label,
  validateLimit = true,
}) => {
  const [{ value }, { error }, { setValue }] = useField(name);
  const { submitCount } = useFormikContext();
  const [limitError, setLimitError] = useState();

  const showError = useMemo(() => validateLimit && !!error && submitCount > 0, [
    submitCount,
    error,
    validateLimit,
  ]);

  const helperText = useMemo(() => {
    if (limitError && validateLimit) {
      return (
        <Typography variant="body2" color="error">
          Only the {valuesLimit} first values will be considered.
        </Typography>
      );
    }
    if (showError) {
      return (
        <Typography color="error" variant="body2">
          {error}
        </Typography>
      );
    }

    return null;
  }, [limitError, validateLimit, showError, valuesLimit, error]);

  const onChange = useCallback(
    (event) => {
      if (validateLimit) {
        const separatedValuesAmount = (
          event.target.value.match(new RegExp(",", "g")) || []
        ).length;
        if (separatedValuesAmount + 1 > valuesLimit) {
          setLimitError(true);
        } else {
          setLimitError(false);
        }
      }
      setValue(event.target.value);
    },
    [setValue, validateLimit, valuesLimit]
  );

  return (
    <BaasTextField
      name={name}
      label={label}
      placeholder={
        validateLimit ? `Maximum of ${valuesLimit} comma separated values.` : ""
      }
      defaultValue=""
      InputProps={{
        rows: 3,
      }}
      multiline
      fullWidth
      onChange={onChange}
      error={showError && error}
      helperText={helperText}
      value={value}
      variant="outlined"
    />
  );
};

export default TextAreaSearch;
