import BaasText from "components/atoms/Text/BaasText";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import React from "react";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasFlex from "components/atoms/Flex/Flex";
import { useTheme } from "@mui/system";
import BaasGrid from "components/atoms/Grid/Grid";
import MovementReportGenerate from "./MovementReportGenerate";

export default function MovementReport() {
  const theme = useTheme();

  return (
    <>
      <BaasFlex flexDirection="column" width="100%">
        <BaasPageHeader>CNAB</BaasPageHeader>
        <BaasContentGrid spacing={4}>
          <BaasGrid p="16px 0px" item>
            <BaasText variant="h6">CNAB Movement Report</BaasText>
          </BaasGrid>

          <BaasGrid
            p="16px"
            sx={{ background: theme.palette.grey[100], marginBottom: "16px" }}
          >
            <BaasText>
              Use this session to generate and download Cnab Movement Report
              files. These files contain the status for the settlement of
              emitted Payment Slips uploaded through CNAB files in the CNAB
              Interface page. List and download Cnab Movement Report. Use date
              ranges to search available files.
            </BaasText>
            <BaasText p="8px 0px">
              List and download Cnab Movement Report. Use date ranges to search
              available files.
            </BaasText>
          </BaasGrid>
          <MovementReportGenerate />
        </BaasContentGrid>
      </BaasFlex>
    </>
  );
}
