import { Modal } from "@mui/material";
import React from "react";
import styled from "styled-components";

const ModalStyle = styled.div``;

const BaasModal = ({ children, ...props }) => {
  return (
    <ModalStyle>
      <Modal {...props}>{children}</Modal>
    </ModalStyle>
  );
};

export default BaasModal;
