import { useTheme } from "@mui/system";
import {
  queryGetLatestSync,
  queryGetPixDictEntrySearchList,
} from "api/dictv2-api";
import BaasButton from "components/atoms/Button/Button";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasText from "components/atoms/Text/BaasText";
import TableFilter from "components/organism/TableFilter/TableFilter";
import { toastMessages } from "constants/toastMessages";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import EntrySearchList from "./EntrySearchList";
import DictEntrySearchListDeleteModal from "./EntrySearchListDeleteModal";
import DictEntrySearchListInfoModal from "./EntrySearchListInfoModal";
import DictEntrySearchListUpdateModal from "./EntrySearchListUpdateModal";
import StackCard from "components/atoms/StackCard/StackCard";

export default function EntrySearch() {
  const theme = useTheme();
  const history = useHistory();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [data, setData] = useState([]);
  const [taxIdFilter, setTaxIdFilter] = useState("");
  const [lastSync, setLastSync] = useState({
    cpf: null,
    cnpj: null,
    evp: null,
    email: null,
    phone: null,
  });

  const [dataModalInfo, setDataModalInfo] = useState({
    state: false,
    data: {},
  });
  const [dataModalDelete, setDataModalDelete] = useState({
    state: false,
    data: {},
  });
  const [dataModalUpdate, setDataModalUpdate] = useState({
    state: false,
    list: [],
    data: "",
  });

  const handleRedirect = useCallback(() => {
    history.push("/pix/v2/entry/create");
  }, [history]);

  const searchEntry = useCallback(async (values) => {
    const response = await queryGetPixDictEntrySearchList(values);
    if (response.status < 300) {
      if (response.body.length === 0)
        toast.warning(toastMessages.search.searchEmpty);
      setData(response.body);
    } else {
      if ("errors" in response) {
        response.errors.forEach((error) => {
          toast.error(error.message);
        });
      } else {
        toast.error(toastMessages.requestError);
      }
    }
    setIsSubmitting(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const values = taxIdFilter ? { taxId: taxIdFilter } : undefined;
    onSubmit(values);
    if (!taxIdFilter) {
      getLastSync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxIdFilter]);

  const getLastSync = useCallback(async () => {
    const response = await queryGetLatestSync();
    if (response.length > 0) {
      setLastSync({
        cpf: response.find((item) => item.keyType === "CPF")
          ?.syncVerifierLastModified,
        cnpj: response.find((item) => item.keyType === "CNPJ")
          ?.syncVerifierLastModified,
        evp: response.find((item) => item.keyType === "EVP")
          ?.syncVerifierLastModified,
        email: response.find((item) => item.keyType === "EMAIL")
          ?.syncVerifierLastModified,
        phone: response.find((item) => item.keyType === "PHONE")
          ?.syncVerifierLastModified,
      });
    } else {
      if ("errors" in response) {
        response.errors.forEach((error) => {
          toast.error(error.message);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = useCallback(
    (values = {}) => {
      setIsSubmitting(true);
      searchEntry(values);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [searchEntry]
  );

  return (
    <>
      <StackCard>
        <BaasText variant="body2">
          This interface allows verify the status of your clients' keys stored
          in our infrastructure.
        </BaasText>
        <BaasText variant="body2" p="16px 0px">
          The possibility to check the last synchronization of each key's
          internal database with Brazilian Central Bank DICT repository.
        </BaasText>
        <BaasText variant="body2">
          <p>
            <strong>CNPJ: </strong>
            {lastSync.cnpj
              ? moment(lastSync.cnpj).format("YYYY/MM/DD - HH:mm:ss")
              : "N/A"}
          </p>
          <p>
            <strong>CPF: </strong>
            {lastSync.cpf
              ? moment(lastSync.cpf).format("YYYY/MM/DD - HH:mm:ss")
              : "N/A"}
          </p>
          <p>
            <strong>Phone: </strong>
            {lastSync.phone
              ? moment(lastSync.phone).format("YYYY/MM/DD - HH:mm:ss")
              : "N/A"}
          </p>
          <p>
            <strong>Email: </strong>
            {lastSync.email
              ? moment(lastSync.email).format("YYYY/MM/DD - HH:mm:ss")
              : "N/A"}
          </p>
          <p>
            <strong>EVP: </strong>
            {lastSync.evp
              ? moment(lastSync.evp).format("YYYY/MM/DD - HH:mm:ss")
              : "N/A"}
          </p>
        </BaasText>
      </StackCard>
      <br />
      <StackCard>
        <BaasGrid item p="16px 0px">
          <BaasButton
            onClick={handleRedirect}
            variant="contained"
            backgroundColor="black"
            type="submit"
          >
            Create PIX Key
          </BaasButton>
        </BaasGrid>
        <BaasText variant="body2">
          Stored keys for the selected account will be listed below. The list
          describes the last synchronization with Brazilian Central Bank DICT
          repository.
        </BaasText>
        <BaasGrid p="16px 0px" display="flex" justifyContent="row">
          <BaasButton
            type="submit"
            size="medium"
            variant="outlined"
            backgroundColor="black"
            onClick={() => onSubmit({ includeStatistics: true })}
            loading={isSubmitting}
          >
            Refresh
          </BaasButton>
        </BaasGrid>

        <DictEntrySearchListInfoModal
          modal={dataModalInfo}
          setModal={setDataModalInfo}
        />
        <DictEntrySearchListUpdateModal
          modal={dataModalUpdate}
          setModal={setDataModalUpdate}
          refresh={onSubmit}
        />
        <DictEntrySearchListDeleteModal
          modal={dataModalDelete}
          setModal={setDataModalDelete}
          refresh={onSubmit}
        />
      </StackCard>
      <TableFilter
        filterValue={taxIdFilter}
        setFilterValue={setTaxIdFilter}
        inputLabel="Filter by Tax Id"
      />
      {data.length > 0 ? (
        <EntrySearchList
          loading={isSubmitting}
          data={data}
          setDataModalDelete={setDataModalDelete}
          setDataModalUpdate={setDataModalUpdate}
          setDataModalInfo={setDataModalInfo}
        />
      ) : (
        <BaasText mt={2} color="error">
          No data available
        </BaasText>
      )}
    </>
  );
}
