import { useTheme } from "@mui/system";
import BaasButton from "components/atoms/Button/Button";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { Form, Formik } from "formik";
import React, { useMemo, useCallback } from "react";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasCheckbox from "components/atoms/Checkbox/Checkbox";
import { BackButton } from "components/molecules/BackButton/BackButton";
import { useCustomMutation } from "helpers/useCustomMutation";
import { mutationCreateReportConfig } from "api/pixLimitValidator/pixLimitValidator";
import { toast } from "react-toastify";

const REFETCH_DELAY = 2000;

const PixLimitReportsConfigCreate = ({ onBack, handleRefetch }) => {
  const theme = useTheme();
  const { mutate, isSubmitting } = useCustomMutation(
    mutationCreateReportConfig
  );

  const initialValues = useMemo(() => {
    return {};
  }, []);

  const handleCreateReportConfig = useCallback(
    (values, resetForm) => {
      mutate(
        {
          email: values.email,
          periodicity: values.periodicity,
          reportType: values.reportType,
        },
        (data) => {
          setTimeout(() => {
            handleRefetch();
          }, [REFETCH_DELAY]);
          onBack();
          toast.success("Report config created with success.");
        },
        (error) => {
          return toast.error(error.message);
        }
      );
    },
    [handleRefetch, mutate, onBack]
  );

  return (
    <BaasFlex flexDirection="column" width="100%">
      <BaasPageHeader>Pix Limit Validator</BaasPageHeader>
      <BaasContentGrid spacing={4}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleCreateReportConfig}
        >
          {({ values, isSubmitting, ...props }) => (
            <Form>
              <BaasGrid item spacing={1}>
                <BaasRow>
                  <BaasText variant="h6">
                    Pix Limit Validator Reports Config
                  </BaasText>
                </BaasRow>
              </BaasGrid>

              <BaasRow>
                <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
                  <BackButton onBack={onBack} />
                  <BaasRow container column={12} spacing={3}>
                    <BaasGrid item xs={12}>
                      <BaasText variant="body2">
                        Use this session to create a report config.
                      </BaasText>
                    </BaasGrid>

                    <BaasGrid item xs={12} sm={6}>
                      <BaasTextField
                        fullWidth
                        name="email"
                        label="Email"
                        required
                      />
                    </BaasGrid>
                    <BaasGrid item xs={12} sm={6}>
                      <BaasTextField
                        fullWidth
                        name="periodicity"
                        label="Period"
                        select
                        required
                      >
                        <BaasMenuItem value="DAILY">Daily</BaasMenuItem>
                        <BaasMenuItem value="WEEKLY">Weekly</BaasMenuItem>
                      </BaasTextField>
                    </BaasGrid>
                    <BaasGrid item xs={12} sm={6}>
                      <BaasTextField
                        fullWidth
                        name="reportType"
                        label="Report Type"
                        select
                        required
                      >
                        <BaasMenuItem value="AUTHORIZED_PARTICIPANTS">
                          Authorized Participants
                        </BaasMenuItem>
                        <BaasMenuItem value="INFRACTION">
                          Infraction
                        </BaasMenuItem>
                        <BaasMenuItem value="SCORE">Score</BaasMenuItem>
                      </BaasTextField>
                    </BaasGrid>
                  </BaasRow>
                  <BaasRow
                    container
                    sx={{ justifyContent: "space-between" }}
                    flexDirection="row"
                  >
                    <BaasGrid p={0} item>
                      <BaasButton
                        type="submit"
                        size="medium"
                        variant="contained"
                        backgroundColor="black"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                      >
                        {isSubmitting ? "Saving..." : "Save"}
                      </BaasButton>
                    </BaasGrid>
                  </BaasRow>
                </BaasGrid>
              </BaasRow>
            </Form>
          )}
        </Formik>
      </BaasContentGrid>
    </BaasFlex>
  );
};
export default PixLimitReportsConfigCreate;
