import PixManageKeyPanel from "components/organism/PixManageKeyPanel/PixManageKeyPanel";
import PixPaymentPanel from "components/organism/PixPaymentPanel/PixPaymentPanel";
import PixQrCodeGenPanel from "components/organism/PixQrCodeGenPanel/PixQrCodeGenPanel";

export const paymentSteps = [
  {
    title: "Manage key",
    component: PixManageKeyPanel,
    entity: "CREDITED",
    faqMessages: [
      {
        title:
          "How to create a new DICT association between a key and an account.",
        description:
          "Take a look on documentation about how to use the methods.",
        externalLink:
          "https://developer.btgpactual.com/docpc#operation/pix-dict",
        linkLabel: "Read Documentation",
      },
      // {
      //   title: "How to list PIX Keys",
      //   description: "Take a look on documentation about how to use the methods.",
      //   externalLink: "https://developer.btgpactual.com/docpc#operation/pix-dict",
      //   linkLabel: "Read Documentation"
      // },
    ],
  },
  {
    title: "Dinamic QRCode PIX",
    component: PixQrCodeGenPanel,
    instruction: "Payment simulation",
    entity: "CREDITED",
    faqMessages: [
      {
        title: "How to create a dinamic PIX QR Code",
        description: `Youn can start a Pix payment using a key as the destination.`,
        externalLink:
          "https://developer.btgpactual.com/docpi#operation/post-pix-collection-location",
        linkLabel: "Read Documentation",
      },
      {
        title: "Get PIX dinamic BR code information",
        description: "The pactualId is available after the QRCode creation.",
        externalLink:
          "https://developer.btgpactual.com/docpi#tag/DynamicBRCode",
        linkLabel: "Read Documentation",
      },
    ],
  },
  {
    title: "Payment",
    component: PixPaymentPanel,
    instruction: "PIX Payment.",
    entity: "DEBITED",
    faqMessages: [
      {
        title: "About BR Code payment",
        description:
          "Send a Pix Payment started by a BRCode or using full information on request",
        externalLink:
          "https://developer.btgpactual.com/docpi#operation/pix-payment-full",
        linkLabel: "Read Documentation",
      },
    ],
  },
];
