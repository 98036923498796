import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import BaasButton from "components/atoms/Button/Button";

const DialogInput = ({
  customStyle,
  open,
  setOpen,
  title,
  loading,
  inputLabel,
  defaultValue = "",
  description,
  onCancel,
  onSave,
  onSaveLabel = "Load",
}) => {
  const [inputValue, setInputValue] = useState();

  const handleClose = () => {
    if (!loading) {
      setOpen(false);
    }
  };

  const handleChange = (value) => {
    setInputValue(value);
  };

  const handleSave = (value) => {
    onSave?.(value);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent style={customStyle}>
        <DialogContentText>{description}</DialogContentText>
        <TextField
          autoFocus
          name="input"
          margin="dense"
          id="name"
          label={inputLabel}
          type="text"
          defaultValue={defaultValue}
          fullWidth
          variant="standard"
          onChange={(e) => handleChange(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        {onCancel && (
          <BaasButton disabled={loading} onClick={onCancel}>
            Cancel
          </BaasButton>
        )}
        <BaasButton
          loading={loading}
          disabled={!inputValue}
          onClick={() => handleSave(inputValue)}
        >
          {onSaveLabel}
        </BaasButton>
      </DialogActions>
    </Dialog>
  );
};

export default DialogInput;
