import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";

const TableFilter = ({ setFilterValue, inputLabel, inputWidth = "250px" }) => {
  const [inputValue, setinputValue] = useState();

  const handleFilter = () => {
    setFilterValue(inputValue);
  };

  const handleClearFilter = () => {
    setinputValue("");
    setFilterValue("");
  };

  return (
    <Box
      mt={2}
      width="100%"
      display="flex"
      justifyContent={"flex-end"}
      alignItems="center"
    >
      <TextField
        id="outlined-name"
        label={inputLabel}
        size="small"
        value={inputValue}
        onChange={(e) => setinputValue(e.target.value)}
        style={{ minWidth: inputWidth }}
      />
      <Box ml={2}>
        <Button
          disabled={!Boolean(inputValue)}
          onClick={handleFilter}
          variant="outlined"
          startIcon={<FilterListIcon />}
        >
          Apply Filter
        </Button>
      </Box>
      <Box ml={2}>
        <Button
          color="error"
          onClick={handleClearFilter}
          variant="outlined"
          startIcon={<ClearIcon />}
        >
          Clear filter
        </Button>
      </Box>
    </Box>
  );
};

export default TableFilter;
