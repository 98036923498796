import Autocomplete from "@mui/material/Autocomplete";
import { TextField } from "@mui/material";

const AutocompleteDefault = ({
  name,
  label,
  isLoading,
  onClear,
  getOptionLabel,
  onSelect,
  options,
  renderOption,
  inputVariant = "outlined",
}) => {
  const handleSelectOption = (value, reason) => {
    if (reason === "clear" && onClear) {
      onClear();
    }
    if (reason === "selectOption" && onSelect) {
      onSelect(value);
    }
  };

  return (
    <Autocomplete
      name={name}
      label={label}
      disablePortal
      getOptionLabel={getOptionLabel}
      isLoading={isLoading}
      onChange={(event, newInputValue, reason) =>
        handleSelectOption(newInputValue, reason)
      }
      renderInput={(params) => {
        return <TextField {...params} label={label} variant={inputVariant} />;
      }}
      renderOption={renderOption}
      options={options}
    />
  );
};

export default AutocompleteDefault;
