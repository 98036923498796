import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Tooltip } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Checkbox from "@mui/material/Checkbox";
import { useTheme } from "@mui/system";
import BaasDatePicker from "components/atoms/DatePicker/DatePicker";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import BaasTextFieldCurrency from "components/molecules/TextFieldCurrency/TextFieldCurrency";
import moment from "moment";
import React from "react";
import { discountModalities } from "../constants/modalities";

const DiscountFormInputs = ({
  values,
  activeModalities,
  toggleModality,
  discountDateValues,
  setDiscountDateValues,
}) => {
  const theme = useTheme();
  const modalityIsActive = activeModalities.includes("DISCOUNT");

  const handleAddDate = () => {
    let dates = [
      ...discountDateValues,
      { date: moment().add(1, "days"), amountPerc: 0.0 },
    ];
    setDiscountDateValues(dates);
  };

  const handleRemoveDate = (index) => {
    const result = discountDateValues.filter(
      (param, indexParam) => indexParam !== index
    );
    setDiscountDateValues(result);
  };

  const handleUpdateParam = (index, value) => {
    const result = discountDateValues.map((dateDiscount, discountIndex) => {
      if (discountIndex === index) {
        return value;
      }
      return dateDiscount;
    });
    setDiscountDateValues(result);
  };

  return (
    <BaasGrid item xs={12}>
      <Accordion
        expanded={modalityIsActive}
        style={{ width: "100%", marginTop: "16px" }}
      >
        <AccordionSummary>
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <Checkbox
                defaultChecked={modalityIsActive}
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleModality("DISCOUNT");
                }}
              />
              <BaasText variant="body1" color="textSecondary">
                <b>DISCOUNT</b>
              </BaasText>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <BaasRow
            container
            column={12}
            spacing={2}
            style={{ marginTop: "-32px" }}
          >
            <BaasGrid item xs={12}>
              <Box display="flex" alignItems="center">
                <BaasTextField
                  name="discountModality"
                  label="Modality"
                  fullWidth
                  required
                  disabled={!modalityIsActive}
                  variant="standard"
                  select
                >
                  {discountModalities.map((modality) => (
                    <BaasMenuItem value={modality.value}>
                      {modality.label}
                    </BaasMenuItem>
                  ))}
                </BaasTextField>
              </Box>
            </BaasGrid>
            <>
              {values.discountModality ===
                "FIXED_VALUE_UNTIL_THE_DATES_INFORMED" ||
              values.discountModality === "PERCENTAGE_DATE_REPORTED" ? (
                <>
                  <>
                    {discountDateValues.length > 0 ? (
                      <>
                        {discountDateValues.map((input, index) => (
                          <>
                            <BaasGrid item xs={12} sm={6}>
                              <BaasDatePicker
                                fullWidth
                                label="Due Date"
                                name={`discount_dueDate_${index}`}
                                value={input.date}
                                disabled={!modalityIsActive}
                                customOnChange={(date) =>
                                  handleUpdateParam(index, {
                                    date: date,
                                    amount: input.amount,
                                  })
                                }
                                required
                              />
                            </BaasGrid>
                            <BaasGrid item xs={12} sm={6}>
                              <Box display="flex" alignItems="center">
                                {values.discountModality ===
                                "FIXED_VALUE_UNTIL_THE_DATES_INFORMED" ? (
                                  <BaasTextFieldCurrency
                                    fullWidth
                                    label="Amount"
                                    name={`discount_amount_${index}`}
                                    disabled={!modalityIsActive}
                                    defaultValue={0}
                                    onChange={(e) =>
                                      handleUpdateParam(index, {
                                        date: input.date,
                                        amountPerc: e.target.value.toFixed(2),
                                      })
                                    }
                                    required={values.discountModality}
                                    value={input.amountPerc}
                                  />
                                ) : (
                                  <BaasTextField
                                    fullWidth
                                    onChange={(e) =>
                                      handleUpdateParam(index, {
                                        date: input.date,
                                        amountPerc: e.target.value.toFixed(2),
                                      })
                                    }
                                    name={`discount_amount_${index}`}
                                    label="Amount (%)"
                                    defaultValue={0}
                                    disabled={!modalityIsActive}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    required={values.discountModality}
                                    value={input.amountPerc}
                                    type="number"
                                  />
                                )}
                                <Box
                                  height="40px"
                                  marginLeft="8px"
                                  display="flex"
                                  justifyContent={"flex-start"}
                                  style={{
                                    backgroundColor:
                                      index === 0
                                        ? theme.palette.grey[300]
                                        : theme.palette.error.main,
                                  }}
                                >
                                  <Tooltip title="Remove this date">
                                    <IconButton
                                      component="span"
                                      disabled={!modalityIsActive}
                                      onClick={() => handleRemoveDate(index)}
                                      style={{
                                        color: "white",
                                        width: "40px",
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                </Box>
                                {index === discountDateValues.length - 1 && (
                                  <Box
                                    display="flex"
                                    height="40px"
                                    marginLeft="8px"
                                    justifyContent={"flex-start"}
                                    style={{
                                      backgroundColor:
                                        index === 2
                                          ? theme.palette.grey[300]
                                          : theme.palette.primary.main,
                                    }}
                                  >
                                    <Tooltip title="Insert a new Date">
                                      <IconButton
                                        component="span"
                                        onClick={handleAddDate}
                                        disabled={
                                          !modalityIsActive || index === 2
                                        }
                                        style={{
                                          color: "white",
                                          width: "40px",
                                        }}
                                      >
                                        <AddIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                )}
                              </Box>
                            </BaasGrid>
                          </>
                        ))}
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                </>
              ) : (
                <BaasGrid item xs={12}>
                  {values.discountModality ===
                    "AMOUNT_PER_CALENDAR_DAY_ADVANCE" ||
                  values.discountModality === "AMOUNT_ADVANCE_BUSINESS_DAY" ? (
                    <BaasTextFieldCurrency
                      fullWidth
                      name="discountAmountPerc"
                      disabled={!modalityIsActive}
                      label="Amount"
                      defaultValue={0}
                      required={values.discountModality}
                    />
                  ) : (
                    <BaasTextField
                      fullWidth
                      name="discountAmountPerc"
                      disabled={!modalityIsActive}
                      label="Amount (%)"
                      defaultValue={0}
                      required={values.discountModality}
                      type="number"
                    />
                  )}
                </BaasGrid>
              )}
            </>
          </BaasRow>
        </AccordionDetails>
      </Accordion>
    </BaasGrid>
  );
};

export default DiscountFormInputs;
