import Pages from "pages";
import Providers from "providers/providers";
import React from "react";
import Endpoints from "constants/endpoints";

const App = () => {
  switch (Endpoints.Environment) {
    case "Development":
      document.title = "BTG Developer Portal (DEV)";
      break;
    case "Sandbox":
      document.title = "BTG Developer Portal (SANDBOX)";
      break;
    case "UAT":
      document.title = "BTG Developer Portal (UAT)";
      break;
    default:
      document.title = "BTG Developer Portal";
  }

  return (
    <Providers>
      <Pages />
    </Providers>
  );
};

export default App;
