import BaasGrid from "components/atoms/Grid/Grid";
import DinamicTable from "components/organism/DinamicTable/DinamicTable";
import moment from "moment";
import React, { useState } from "react";
import CnabInterfaceDownloadListModal from "./CnabInterfaceDownloadListModal";
import InfoIcon from "@mui/icons-material/Info";

const CnabInterfaceDownloadList = ({ data }) => {
  const [modal, setModal] = useState({ state: false, pactualId: "" });

  const onViewClick = (item) => {
    setModal({ state: true, pactualId: item.pactualId });
  };

  return (
    <>
      <BaasGrid p="32px 0px">
        <DinamicTable
          data={data}
          defaultSort={{
            key: "createTimestamp",
            orientation: "desc",
          }}
          tableSchema={[
            {
              header: "Name/Description",
              key: "body.importFileDescription",
              tooltip: true,
              sort: true,
              style: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "noWrap",
              },
            },
            {
              header: "Create Date",
              key: "createTimestamp",
              formatValue: (value) =>
                moment(value).format("YYYY-MM-DD HH:mm:ss"),

              sort: true,
            },
            {
              header: "Status",
              key: "status",
              sort: true,
            },
            {
              header: "File Records",
              key: "body.totalRecords",
              sort: true,
            },
            {
              header: "Records with errors",
              key: "body.totalRecordsErro",
              sort: true,
            },
          ]}
          extraActions={[
            {
              onClick: (item) => onViewClick(item),
              icon: InfoIcon,
              tooltip: "More info",
            },
          ]}
        />
      </BaasGrid>
      <CnabInterfaceDownloadListModal
        modal={modal}
        setModal={setModal}
        data={data.find((item) => item.pactualId === modal.pactualId)}
      />
    </>
  );
};

export default CnabInterfaceDownloadList;
