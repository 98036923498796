import { useTheme } from "@mui/system";
import BaasButton from "components/atoms/Button/Button";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import BaasTextFieldCopy from "components/molecules/TextFieldCopy/TextFieldCopy";

import { useHistory } from "react-router";

const BaasTransactionSuccess = ({
  customChildren,
  onClick,
  pactualId,
  idLabel,
  redirectUrl,
  message,
  messageLabel,
  buttonLabel = "Back",
}) => {
  const history = useHistory();
  const theme = useTheme();

  const handleRedirect = () => {
    if (onClick) {
      onClick();
    } else {
      if (redirectUrl === undefined || redirectUrl === "") {
        history.push("/dashboard");
      }
      history.push(redirectUrl);
    }
  };

  return (
    <BaasRow display="flex" flexDirection="column" alignItems="center">
      <BaasGrid
        p="48px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          background: theme.palette.grey[100],
          width: "100%",
          maxWidth: "500px",
        }}
      >
        <CheckIcon color="success" fontSize="large" />
        <BaasText textAlign="center" variant="h5" color="success.main">
          Request successful!
        </BaasText>
        <BaasGrid container p={4}>
          {pactualId && (
            <BaasTextFieldCopy
              value={pactualId}
              label={idLabel || "Pactual Id"}
            />
          )}
        </BaasGrid>
        {customChildren && <>{customChildren}</>}
        <BaasGrid container p={3}>
          {message && (
            <BaasTextFieldCopy value={message} label={messageLabel} />
          )}
        </BaasGrid>

        <BaasButton
          variant="outlined"
          size="medium"
          backgroundColor="black"
          onClick={() => handleRedirect()}
          sx={{ width: "200px" }}
        >
          {buttonLabel}
        </BaasButton>
      </BaasGrid>
    </BaasRow>
  );
};

export default BaasTransactionSuccess;
