import { useTheme } from "@mui/material";
import BaasText from "components/atoms/Text/BaasText";
import Endpoints from "constants/endpoints";
import React from "react";
import styled from "styled-components";

const ContainerStyled = styled.div`
  height: calc(100% + 28px);
  min-width: 100px;

  background-color: ${(props) => props.bg};
  padding: 0px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
`;
const BaasAmbientIndicator = () => {
  const theme = useTheme();
  let color;

  switch (Endpoints.Environment) {
    case "Development":
      color = theme.palette.success.light;
      break;
    case "Sandbox":
      color = "#ffb300";
      break;
    case "UAT":
      color = theme.palette.error.light;
      break;
    default:
      color = "black";
  }

  return Endpoints.Environment !== "Production" ? (
    <ContainerStyled theme={theme} bg={color}>
      <BaasText variant="subtitle1" color="grey.100">
        {Endpoints.Environment}
      </BaasText>
    </ContainerStyled>
  ) : null;
};

export default BaasAmbientIndicator;
