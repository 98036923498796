import { Typography } from "@mui/material";
import { Box, useTheme } from "@mui/system";
import {
  queryGetPixPaymentInOut,
  queryGetPixQrCode,
} from "api/pixLimitValidator/pixLimitValidator";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import BaasText from "components/atoms/Text/BaasText";
import { BackButton } from "components/molecules/BackButton/BackButton";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { currencyFormatter } from "helpers/currencyFormatter";
import { useCustomQuery } from "helpers/useCustomQuery";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";

export default function PixLimitValidatorImpactsDetails({
  selectedItem,
  onBack,
}) {
  const theme = useTheme();
  const {
    fetch: fetchInOut,
    data: inOutData,
    loading: loadingInOut,
  } = useCustomQuery(queryGetPixPaymentInOut);

  const loadQrCode = useCallback(async (values) => {
    setLoadingQrCode(true);
    const response = await queryGetPixQrCode(values);
    if (response) {
      setQrCodeData(response);
    } else {
      if ("errors" in response) {
        response.errors.map((error) => toast.error(error.message));
      } else toast.error("Error when loading QR Code transaction");
    }
    setLoadingQrCode(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [qrCodeData, setQrCodeData] = useState();
  const [loadingQrCode, setLoadingQrCode] = useState(false);

  // const {
  //   fetch: fetchQrCode,
  //   data: qrCodeData,
  //   loading: loadingQrCode,
  // } = useCustomQuery(queryGetPixQrCode);

  useEffect(() => {
    if (selectedItem.txId) {
      loadQrCode(selectedItem.txId);
    }
    if (selectedItem.endToEnd) {
      fetchInOut({
        values: selectedItem.endToEnd,
        onCompleted: (data) => console.log(data),
      });
    }
  }, []);

  // console.log(data?.body);
  const transactionValues = selectedItem.txId
    ? [
        {
          label: "Amount",
          value: `R$ ${currencyFormatter(qrCodeData?.valor?.original / 100)}`,
        },

        {
          label: "Transaction Identification",
          value: qrCodeData?.txid,
        },
        { label: "Status", value: qrCodeData?.status },
        {
          label: "Created At",
          value: qrCodeData?.calendario?.criacao
            ? moment(qrCodeData?.calendario?.criacao).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : "N/A",
        },
        { label: "DEBIT PARTICIPANT.", text: true },
        { label: "Name", value: qrCodeData?.devedor?.nome },
        { label: "Tax Id", value: qrCodeData?.devedor?.cpf },
        { label: "LOCATION INFORMATION.", text: true },
        { label: "Pactual Id", value: qrCodeData?.loc?.pactualId },
        { label: "COB Type", value: qrCodeData?.loc?.tipoCob },
        { label: "Location", value: qrCodeData?.loc?.location },
        {
          label: "EMV",
          value: qrCodeData?.pixCopiaECola || "N/A",
        },
      ]
    : [
        {
          label: "Amount",
          value: `R$ ${currencyFormatter(inOutData?.body?.amount / 100)}`,
        },
        { label: "PactualId", value: inOutData?.pactualId },
        // { label: "Entity", value: inOutData?.entity },
        { label: "Status", value: inOutData?.status },
        {
          label: "Created At",
          value: inOutData?.createTimestamp
            ? moment(inOutData?.createTimestamp).format("YYYY-MM-DD HH:mm:ss")
            : "N/A",
        },
        { label: "DEBIT PARTICIPANT.", text: true },
        { label: "Name", value: inOutData?.body?.debitParty.name },
        { label: "taxId", value: inOutData?.body?.debitParty.taxId },
        { label: "Bank", value: inOutData?.body?.debitParty.bank },
        { label: "Branch", value: inOutData?.body?.debitParty.branch },
        { label: "Account", value: inOutData?.body?.debitParty.account },
        { label: "CREDIT PART.", text: true },
        { label: "Name", value: inOutData?.body?.creditParty.name },
        { label: "taxId", value: inOutData?.body?.creditParty.taxId },
        { label: "Bank", value: inOutData?.body?.creditParty.bank },
        { label: "Branch", value: inOutData?.body?.creditParty.branch },
        { label: "Account", value: inOutData?.body?.creditParty.account },
        { label: "TRANSACTION INFORMATION.", text: true },
        {
          label: "Transaction Identification",
          value: inOutData?.body?.transactionIdentification,
        },
        { label: "Transaction Type", value: inOutData?.body?.transactionType },
        { label: "Payment Type", value: inOutData?.body?.paymentType },
        { label: "Initiation Type", value: inOutData?.body?.initiationType },
      ];

  return (
    <BaasFlex flexDirection="column" width="100%">
      <BaasContentGrid spacing={4}>
        <BaasGrid
          p="16px"
          marginTop="16px"
          sx={{ background: theme.palette.grey[100] }}
        >
          {loadingInOut || loadingQrCode ? (
            <>
              <BaasGrid item xs={12} sm={12}>
                <BaasSkeleton width="100%" height="65px" />
              </BaasGrid>
              <BaasGrid item xs={12} sm={12}>
                <BaasSkeleton width="100%" height="65px" />
              </BaasGrid>
              <BaasGrid item xs={12} sm={12}>
                <BaasSkeleton width="100%" height="65px" />
              </BaasGrid>
              <BaasGrid item xs={12} sm={12}>
                <BaasSkeleton width="100%" height="65px" />
              </BaasGrid>
            </>
          ) : (
            <>
              {" "}
              <BackButton onBack={onBack} />
              <BaasGrid container mt={2} mb={2}>
                <BaasGrid item xs={12}>
                  <Box mt={4}>
                    <Typography style={{ color: theme.palette.grey[600] }}>
                      <b>
                        {selectedItem.txId ? "QR CODE PAYMENT" : "PIX IN/OUT"}{" "}
                        INFORMATION.
                      </b>
                    </Typography>
                  </Box>
                </BaasGrid>
                {transactionValues.map((row) => {
                  return row.text ? (
                    <BaasGrid item xs={12} key={row.label}>
                      <Box mt={4}>
                        <Typography style={{ color: theme.palette.grey[600] }}>
                          <b>{row.label}</b>
                        </Typography>
                      </Box>
                    </BaasGrid>
                  ) : (
                    <>
                      <BaasGrid item xs={12} sm={6} md={4} key={row.label}>
                        <Box mt={2}>
                          <BaasInfoDisplay
                            fullWidth
                            label={row.label}
                            value={row.value || "Not available"}
                          />
                        </Box>
                      </BaasGrid>
                    </>
                  );
                })}{" "}
              </BaasGrid>
            </>
          )}
        </BaasGrid>
      </BaasContentGrid>
    </BaasFlex>
  );
}
