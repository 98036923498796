import { useTheme } from "@mui/system";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasSkeleton from "components/atoms/Skeleton/Skeleton";
import BaasText from "components/atoms/Text/BaasText";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";

const UserCredentialsClientInfo = ({ data }) => {
  const theme = useTheme();

  return (
    <>
      <BaasGrid p="16px" sx={{ background: theme.palette.grey[100] }}>
        <BaasRow container spacing={1}>
          <BaasText
            sx={{ paddingLeft: "8px", marginBottom: "8px" }}
            variant="subtitle1"
          >
            Copy the information bellow and use it during your integration
            process. Check the documentation session for more information
            regarding the available funcionalities.
          </BaasText>
          <BaasGrid container p={1} spacing={3}>
            {data ? (
              <>
                <BaasGrid item xs={12}>
                  <BaasInfoDisplay label={"Client Id:"} value={data.clientId} />
                </BaasGrid>

                <BaasGrid item xs={12} sm={6}>
                  <BaasInfoDisplay label={"Company:"} value={data.company} />
                </BaasGrid>

                <BaasGrid item xs={12} sm={6}>
                  <BaasInfoDisplay
                    label={"Client Enabled:"}
                    value={data.enabled ? "Yes" : "Not Enabled"}
                  />
                </BaasGrid>
              </>
            ) : (
              <BaasSkeleton width="300px">
                <BaasText variant="body1">A</BaasText>
              </BaasSkeleton>
            )}
          </BaasGrid>
        </BaasRow>
      </BaasGrid>
    </>
  );
};

export default UserCredentialsClientInfo;
