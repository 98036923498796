import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasSelect from "components/atoms/Select/Select";
import React from "react";
import { checkPermission } from "services/interfaceController";

const BaasTextFieldAccountTypeTo = ({ ...props }) => {
  const transactionType = [
    {
      name: "Checking Account",
      value: "CC",
      permission: ["pix-pay-r", "pix-pay-w", "all-w"],
    },
    {
      name: "Saving Account",
      value: "CP",
      permission: ["pix-pay-r", "pix-pay-w", "all-w"],
    },
  ];

  return (
    <BaasSelect {...props} renderValue={(value) => value.name}>
      {transactionType.map(
        (item) =>
          checkPermission(item.permission) && (
            <BaasMenuItem value={item}>{item.name}</BaasMenuItem>
          )
      )}
    </BaasSelect>
  );
};

export default BaasTextFieldAccountTypeTo;
