import { useQueryGetAccountBalance } from "api/security-api";
import React, { createContext, useContext, useState } from "react";
import getSafe from "helpers/getSafe";
import { useAuth } from "./auth";
import { checkPermission } from "services/interfaceController";

const AccountContext = createContext();

const AccountProvider = ({ children }) => {
  const [accountSelected, setAccountSelected] = useState({
    index: 0,
    viewBalance: true,
  });
  const [viewMode, setViewMode] = useState(
    localStorage.getItem("showBalance") === "false" ? false : true
  );
  const { authenticated } = useAuth();
  const cannotAccessBalance = checkPermission(["no-balance-v"]);

  const { data } = useQueryGetAccountBalance(
    {
      accountId: accountSelected.accountId,
    },
    { enabled: authenticated && !cannotAccessBalance }
  );

  const accountType =
    accountSelected.accountId && accountSelected.markups
      ? accountSelected.markups.find((e) => e === "PIX-PI:true")
        ? "Indirect"
        : accountSelected.markups.find((e) => e === "PIX-MERCHANT:true")
        ? "Merchant"
        : null
      : null;

  const balance = getSafe(data, "body.balance");

  const handleShowBalance = () => {
    setViewMode(!viewMode);
    localStorage.setItem("showBalance", !viewMode);
  };

  return (
    <AccountContext.Provider
      value={{
        accountSelected,
        setAccountSelected,
        accountType,
        balance,
        showBalance: viewMode,
        handleShowBalance,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

const useAccount = () => {
  const context = useContext(AccountContext);
  const {
    accountSelected,
    setAccountSelected,
    accountType,
    balance,
    showBalance,
    handleShowBalance,
  } = context;
  return {
    accountSelected,
    setAccountSelected,
    accountType,
    balance,
    showBalance,
    handleShowBalance,
  };
};

export { AccountProvider, useAccount };
