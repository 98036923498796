import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import * as React from "react";
import BaasDialog from "../BaasDialog/BaasDialog";

export default function OpenFinanceParticipantInfoDialog() {
  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <Tooltip title="Participant information">
        <IconButton
          component="span"
          onClick={() => setOpen(!open)}
          style={{
            width: "40px",
          }}
        >
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>
      <BaasDialog
        open={open}
        setOpen={setOpen}
        title="Participant Information"
        content={
          <>
            <BaasContentGrid spacing={2}>
              <BaasGrid item xs={12}>
                <Typography>customerFriendlyName</Typography>
              </BaasGrid>
              <BaasGrid item xs={12}>
                <Typography>customerFriendlyId</Typography>
              </BaasGrid>
              <BaasGrid item xs={12}>
                <Typography>Item com mesmo OrganizationId</Typography>
              </BaasGrid>
              <BaasGrid item xs={12}>
                <Typography>Item com mesmo OrganizationId</Typography>
              </BaasGrid>
              <BaasGrid item xs={12}>
                <Typography>Item com mesmo OrganizationId</Typography>
              </BaasGrid>
            </BaasContentGrid>
          </>
        }
        buttonLabel="Close"
        onClickButton={() => setOpen(false)}
      />
    </div>
  );
}
