import BaasTextField from "components/atoms/TextField/TextField";
import React from "react";

const BaasLoginTextField = ({ name, label, ...props }) => {
  //   const [hiden, setHiden] = useState(false);

  return (
    <BaasTextField
      name={name}
      //   type={hiden ? "text" : "password"}
      label={label}
      {...props}
    />
  );
};

export default BaasLoginTextField;
