import { Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
const GridStyle = styled(Grid)``;

const BaasRow = ({ children, item = true, p = "16px 0px", ...props }) => {
  return (
    <GridStyle style={{ padding: p }} item={item} {...props}>
      {children}
    </GridStyle>
  );
};

export default BaasRow;
