import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      dark: "#396FBA",
      main: "#428CF2",
      light: "#79AFFA",
    },
    secondary: {
      dark: "#96371E",
      main: "#F0642D",
      light: "#FF8C46",
    },
    error: {
      dark: "#8C0019",
      main: "#DE545E",
      light: "#F55F73",
    },
    warning: {
      dark: "#91640F",
      main: "#ECA064",
      light: "#FFC845",
    },
    success: {
      dark: "#006848",
      main: "#66BB6A",
      light: "#66BB11",
    },
    grey: {
      800: "#121212",
      700: "#2E3238",
      600: "#4C535D",
      500: "#C1C5D0",
      400: "#D6D9DF",
      300: "#E0E2E7",
      200: "#E9EBEF",
      100: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: "Moderat-Regular",
    button: {
      fontSize: "16dp",
      textTransform: "none",
    },
    body1: {
      fontSize: "15px",
    },
    h6: {
      fontWeight: "bold",
    },
  },
});

export default function Theme({ children }) {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
