import React, { useCallback, useState, useMemo } from "react";
import BaasModal from "components/atoms/Modal/Modal";
import Backdrop from "@mui/material/Backdrop";
import styled from "styled-components";
import { useTheme } from "@mui/private-theming";
import { Box } from "@mui/system";
import BaasText from "components/atoms/Text/BaasText";
import BaasGrid from "components/atoms/Grid/Grid";
import { Form, Formik } from "formik";
import BaasOtpModal from "components/organism/OtpModal/OtpModal";
import { useOtp } from "providers/oneTimePassword";
import BaasButton from "components/atoms/Button/Button";
import BaasInfoDisplay from "components/molecules/InfoDisplay/InfoDisplay";
import { toast } from "react-toastify";
import { toastMessages } from "constants/toastMessages";
import { mutationPostPixDictEntryDelete } from "api/pix-api";
import * as yup from "yup";
import Endpoints from "constants/endpoints";

const BoxStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 500px;
  max-width: 700px;

  max-height: 80vh;
  background-color: ${(props) => props.theme.palette.grey[200]};

  box-shadow: 24;
  padding: 4px;
  border-radius: 4px;
  overflow: hidden;
  overflow-y: scroll;

  :focus-visible {
    outline: none;
  }
`;

export default function EntrySearchListDeleteModal({
  modal,
  setModal,
  refresh,
}) {
  const theme = useTheme();
  const { isOtpRequired, setOpen } = useOtp();
  const validationSchema = yup.object().shape({});
  const initialValues = useMemo(() => {
    return {};
  }, []);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [key, setKey] = useState("");

  const isSandboxEnvironment = Endpoints.Environment === "Sandbox";

  const handleClose = () => setModal({ state: false });

  const mutateDictEntry = useCallback(
    async (values) => {
      const response = await mutationPostPixDictEntryDelete(key, values);
      if (response.status < 300) {
        handleClose();
        refresh();
        toast.success(toastMessages.requestSuccess);
      } else {
        if ("errors" in response) {
          response.errors.forEach((error) => {
            toast.error(`${error.errorCode} - ${error.message}`);
          });
        }
      }
      setIsSubmitting(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [key]
  );

  const onSubmit = useCallback(
    (values) => {
      mutateDictEntry({
        otp: values.otp,
        reason: 1,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutateDictEntry, setOpen]
  );

  return (
    <>
      <BaasModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modal.state}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Formik
          initialValues={initialValues}
          onSubmit={
            isOtpRequired && !isSandboxEnvironment
              ? () => setOpen(true)
              : onSubmit
          }
          validationSchema={validationSchema}
        >
          {({ values, ...props }) => (
            <Form>
              <BaasOtpModal
                onConfirm={() => {
                  onSubmit(values, props);
                }}
                {...props}
              />
              <BoxStyled p={3} theme={theme} sx={{ maxWidth: "400px" }}>
                <BaasText>Delete Key</BaasText>
                <BaasGrid container spacing={4} p="16px 0px">
                  <BaasGrid item xs={6}>
                    <BaasInfoDisplay label={"Key:"} value={modal.data.key} />
                  </BaasGrid>
                  <BaasGrid item xs={6}>
                    <BaasInfoDisplay
                      label={"Key Type:"}
                      value={modal.data.keyType}
                    />
                  </BaasGrid>
                  <BaasGrid item xs={6}>
                    <BaasInfoDisplay
                      label={"Owner Name:"}
                      value={modal.data.owner.name}
                    />
                  </BaasGrid>
                  <BaasGrid
                    item
                    container
                    sx={{ justifyContent: "space-between" }}
                    flexDirection="row"
                  >
                    <BaasButton
                      type="submit"
                      size="medium"
                      variant="outlined"
                      backgroundColor="black"
                      onClick={() => handleClose()}
                    >
                      Close
                    </BaasButton>
                    <BaasButton
                      backgroundColor="black"
                      variant="contained"
                      loading={isSubmitting}
                      onClick={() => {
                        setKey(modal.data.key);
                        setOpen(true);
                      }}
                    >
                      Delete
                    </BaasButton>
                  </BaasGrid>
                </BaasGrid>
              </BoxStyled>
            </Form>
          )}
        </Formik>
      </BaasModal>
    </>
  );
}
