export const isEdited = (initialValues, editedValues, entityIndex) => {
  const ogValue = initialValues.resources.find(
    (entity) => entity.index === entityIndex
  );
  const newValue = editedValues.resources.find(
    (entity) => entity.index === entityIndex
  );

  if (editedValues.edited.find((entity) => entity.index === entityIndex))
    return false;

  if (ogValue.subscription.webhookUrl !== newValue.subscription.webhookUrl)
    return true;

  if (ogValue.subscription.isMtls !== newValue.subscription.isMtls) return true;

  if (ogValue.subscription.isMtls !== newValue.subscription.isMtls) return true;

  if (ogValue.subscription.secret !== newValue.subscription.secret) return true;

  let eventEdited = false;
  ogValue.events.map((ogEvent) => {
    const newEvent = newValue.events.find((e) => ogEvent.name === e.name);
    if (!objectsEqual(ogEvent, newEvent)) eventEdited = true;
  });
  if (eventEdited) return true;

  return false;
};

export const validateWhPayload = (values) => {
  let response = {
    valid: true,
    message: null,
  };

  values.map((resource) => {
    if (response.valid && !valid(resource.subscription.webhookUrl)) {
      response.message = `${resource.name} Webhook URL is empty.`;
      response.valid = false;
    }
  });

  values.map((resource) => {
    if (
      response.valid &&
      !resource.subscription.isMtls &&
      !valid(resource.subscription.secret)
    ) {
      response.message = `${resource.name} Secret Key is empty.`;
      response.valid = false;
    }
  });

  if (response.valid) {
    values.map((resource) => {
      if (!resource.events.find((event) => event.checked)) {
        response.message = `${resource.name} has no events subscribed.`;
        response.valid = false;
      }
    });
  }

  return response;
};

const objectsEqual = (o1, o2) =>
  Object.keys(o1).length === Object.keys(o2).length &&
  Object.keys(o1).every((p) => o1[p] === o2[p]);

export const valid = (value) => {
  return value !== "" &&
    value !== null &&
    value !== undefined &&
    value !== false
    ? true
    : false;
};
