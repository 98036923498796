import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { StyledDialogContent, StyledIdWrapper } from "./styles";
import { Box, Typography } from "@mui/material";

export default function DialogConfirmation({
  onConfirm,
  id,
  displayIcon = true,
  idLabel = "Id",
  open,
  setOpen,
  title,
  content,
  confirmLabel = "Confirm",
  childrenContent,
  disabledConfirm = false,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <StyledDialogContent>
        <Box display="flex" flexDirection="row">
          {displayIcon && (
            <Box mr={1}>
              <ReportGmailerrorredIcon fontSize="large" color="warning" />
            </Box>
          )}
          <DialogContentText>{content}</DialogContentText>
        </Box>

        {childrenContent && (
          <Box mb={1} mt={1} width="100%">
            {childrenContent}
          </Box>
        )}
      </StyledDialogContent>

      {id && (
        <StyledIdWrapper>
          <Typography color="GrayText">
            {idLabel}: {id}
          </Typography>
        </StyledIdWrapper>
      )}
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button disabled={disabledConfirm} onClick={onConfirm}>
          {confirmLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
