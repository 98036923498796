import { Chip } from "@mui/material";
import React from "react";
import styled from "styled-components";
const ChipStyle = styled.div`
  .MuiChip-root {
    border-radius: 4px;
  }
`;
const BaasChip = ({ children, ...props }) => {
  return (
    <ChipStyle>
      <Chip {...props}>{children}</Chip>
    </ChipStyle>
  );
};

export default BaasChip;
