import { useTheme } from "@mui/system";
import { queryListFraudMarker } from "api/dictv2-api";
import BaasButton from "components/atoms/Button/Button";
import BaasDatePicker from "components/atoms/DatePicker/DatePicker";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasMenuItem from "components/atoms/MenuItem/MenuItem";
import BaasText from "components/atoms/Text/BaasText";
import BaasTextField from "components/atoms/TextField/TextField";
import { toastMessages } from "constants/toastMessages";
import { Form, Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import AntiFraudSearchList from "./AntiFraudSearchList";
import StackCard from "components/atoms/StackCard/StackCard";

const AntiFraudSearch = () => {
  const theme = useTheme();
  const [data, setData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [queryVariables, setQueryVariables] = useState();
  const [inputFilter, setInputFilter] = useState();
  const [pagination, setPagination] = useState();

  const history = useHistory();

  const handleAppendData = (response, oldData) => {
    if (oldData) {
      setPagination({
        hasMoreElements: response.body?.hasMoreElements,
        lastModified:
          response.body?.fraudMarkers[response.body.fraudMarkers.length - 1]
            ?.lastModified,
      });
      setData(
        oldData
          ? _.uniqBy([...oldData, ...response.body?.fraudMarkers], "id")
          : response.body?.fraudMarkers
      );
    } else {
      setPagination({
        hasMoreElements: response.body?.hasMoreElements,
        lastModified:
          response.body?.fraudMarkers[response.body.fraudMarkers.length - 1]
            ?.lastModified,
      });
      setData(response.body?.fraudMarkers);
    }
  };

  const searchFraudMarker = useCallback(async (values, oldData) => {
    const response = await queryListFraudMarker(values);
    if (response.status < 300) {
      if (response.body.fraudMarkers.length === 0)
        toast.warning(toastMessages.search.searchEmpty);
      handleAppendData(response, oldData);
    } else {
      if ("errors" in response) {
        response.errors.map((error) => {
          return toast.error(error.message);
        });
      }
      setData([]);
    }
    setIsSubmitting(false);
  }, []);

  const handleRedirect = useCallback(() => {
    history.push("/pix/v2/anti-fraud/create");
  }, [history]);

  const handleRefetch = () => {
    setIsSubmitting(true);
    searchFraudMarker(queryVariables);
  };

  const onSubmit = useCallback(
    (values) => {
      console.log(values);
      setIsSubmitting(true);
      const params = {
        taxId: values.taxId,
        modifiedAfter: `${moment(values.modifiedAfter).format(
          "YYYY-MM-DD"
        )}T00:00:00Z`,
        modifiedBefore: `${moment(values.modifiedBefore).format(
          "YYYY-MM-DD"
        )}T23:59:59Z`,
        status: values.status,
        limit: 20,
      };
      setQueryVariables(params);
      searchFraudMarker(params);
    },
    [searchFraudMarker]
  );

  const handleLoadMore = () => {
    const queryPaginatedVariables = {
      ...queryVariables,
      modifiedAfter: pagination.lastModified,
    };

    setQueryVariables(queryPaginatedVariables);
    setInputFilter(null);
    searchFraudMarker(queryPaginatedVariables, data);
  };

  return (
    <>
      <Formik
        initialValues={{
          limit: "20",
        }}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values, setFieldValue, ...props }) => {
          return (
            <Form>
              <StackCard>
                <BaasText>
                  Fraud can be marked against a PSP customer when there is a
                  well-founded suspicion of committing fraud in Pix transactions
                  settled outside the SPI and in scenarios of transactions that
                  were rejected and would be settled in the SPI or outside the
                  SPI. For transactions settled in the SPI, fraud marking is
                  done automatically by the DICT at the end of the infraction
                  report, if there is agreement. Fraud markers sensitize
                  counters that make up information for Pix security purposes.
                  The creator of the fraud marker can cancel it at any time
                  after its creation. Cancellation of appointment of fraud
                  sensitizes the counters associated with the user, decreasing
                  them. All created fraud markers are accounted for and returned
                  to query statistics.
                </BaasText>

                <BaasGrid item p="16px 0px">
                  <BaasButton
                    onClick={handleRedirect}
                    variant="contained"
                    backgroundColor="black"
                    type="submit"
                  >
                    Create new Fraud Marker
                  </BaasButton>
                </BaasGrid>

                <BaasGrid container spacing={3}>
                  <BaasGrid item xs={12} sm={6}>
                    <BaasDatePicker
                      name="modifiedAfter"
                      label="Modified after"
                      fullWidth
                    />
                  </BaasGrid>

                  <BaasGrid item xs={12} sm={6}>
                    <BaasDatePicker
                      name="modifiedBefore"
                      label="Modified before"
                      fullWidth
                    />
                  </BaasGrid>

                  <BaasGrid item xs={12} sm={6}>
                    <BaasTextField
                      fullWidth
                      name="taxId"
                      label="Tax Id"
                      type="text"
                    />
                  </BaasGrid>

                  <BaasGrid item xs={12} sm={6}>
                    <BaasTextField
                      fullWidth
                      name="status"
                      label="Status"
                      select
                    >
                      <BaasMenuItem value="">--</BaasMenuItem>
                      <BaasMenuItem value="REGISTERED">Registered</BaasMenuItem>
                      <BaasMenuItem value="CANCELLED">Cancelled</BaasMenuItem>
                    </BaasTextField>
                  </BaasGrid>
                </BaasGrid>
                <BaasGrid item p="16px 0px">
                  <BaasButton
                    variant="contained"
                    backgroundColor="black"
                    type="submit"
                    loading={isSubmitting}
                  >
                    Search
                  </BaasButton>
                </BaasGrid>
              </StackCard>{" "}
            </Form>
          );
        }}
      </Formik>
      {data && data?.length > 0 && (
        <AntiFraudSearchList
          data={data}
          handleRefetch={handleRefetch}
          inputFilter={inputFilter}
          loading={isSubmitting}
          loadMore={handleLoadMore}
          pagination={pagination}
          setInputFilter={setInputFilter}
        />
      )}
    </>
  );
};

export default AntiFraudSearch;
