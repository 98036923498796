import React, { useEffect } from "react";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { queryGetPixInfractionReportSearch } from "api/pix-api";
import BaasFlex from "components/atoms/Flex/Flex";
import BaasGrid from "components/atoms/Grid/Grid";
import BaasRow from "components/atoms/Grid/Row";
import BaasText from "components/atoms/Text/BaasText";
import { AddButton } from "components/molecules/AddButton/AddButton";
import BaasPageHeader from "components/molecules/PageHeader/PageHeader";
import DinamicTable from "components/organism/DinamicTable/DinamicTable";
import BaasContentGrid from "components/templates/ContentGrid/ContentGrid";
import { useCustomQuery } from "helpers/useCustomQuery";
import moment from "moment";
import { useHistory } from "react-router";

const PixTestSuitInfractionsList = () => {
  const history = useHistory();

  const { fetch, loading, data } = useCustomQuery(
    queryGetPixInfractionReportSearch
  );

  useEffect(() => {
    fetch({
      values: {
        isCredited: true,
        modifiedAfter: `${moment()
          .subtract(3, "d")
          .format("YYYY-MM-DD")}T00:00:00Z`,
        modifiedBefore: `${moment().format("YYYY-MM-DD")}T23:59:59Z`,
        limit: 50,
      },
      emptyWarning: true,
      objectPath: "body.items",
    });
  }, [fetch]);

  const onViewClick = (item) => {
    history.push({
      pathname: `/pix-test-suit-infraction-details/${item.id}`,
    });
  };

  const onRefundClick = (item) => {
    history.push({
      pathname: `/pix-test-suit-refund-create/${item.id}/${item.endToEndId}`,
    });
  };

  const conditionalAction = (item) => {
    let conditions = [
      {
        onClick: onViewClick,
        icon: VisibilityIcon,
        tooltip: "View Infraction",
      },
    ];

    if (item.analysisResult === "AGREED" && item.status === "CLOSED") {
      conditions.push({
        onClick: onRefundClick,
        icon: MonetizationOnIcon,
        tooltip: "View Refund",
      });
    }
    return conditions;
  };

  return (
    <BaasFlex flex={1} flexDirection="column">
      <BaasPageHeader>Pix</BaasPageHeader>
      <BaasContentGrid spacing={4}>
        <BaasGrid item spacing={1}>
          <BaasRow>
            <BaasText variant="h6">Pix Test Suit Infraction</BaasText>
          </BaasRow>
        </BaasGrid>
        <AddButton
          label={"Create infraction"}
          onClick={() =>
            history.push({
              pathname: `/pix-test-suit-infraction`,
            })
          }
        />
        <DinamicTable
          loading={loading}
          data={data}
          defaultSort={{
            key: "lastModified",
            orientation: "desc",
          }}
          tableSchema={[
            {
              header: "Identification",
              key: "id",
              sort: true,
            },
            {
              header: "Credited Part.",
              key: "creditedParticipant",
            },
            {
              header: "Debited Part.",
              key: "debitedParticipant",
            },
            {
              header: "Updated at",
              key: "lastModified",
              formatValue: (value) => moment(value).format("YYYY-MM-DD"),
              sort: true,
            },
            {
              header: "Status",
              key: "status",
              sort: true,
            },
          ]}
          getExtraActions={(data) => conditionalAction(data)}
        />
      </BaasContentGrid>
    </BaasFlex>
  );
};

export default PixTestSuitInfractionsList;
