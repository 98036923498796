import React, { useCallback, useState } from "react";
import ReactJson from "react-json-view";
import BaasModal from "components/atoms/Modal/Modal";
import Backdrop from "@mui/material/Backdrop";
import styled from "styled-components";
import { useTheme } from "@mui/private-theming";
import { Box } from "@mui/system";
import BaasText from "components/atoms/Text/BaasText";
import BaasButton from "components/atoms/Button/Button";
import BaasGrid from "components/atoms/Grid/Grid";
import { Form, Formik } from "formik";
import { queryGetEfxDownloadByDateAndFileName } from "api/efx-api";
import { toast } from "react-toastify";
import { toastMessages } from "constants/toastMessages";
import moment from "moment";
import converterFromBase64 from "helpers/converterFromBase64";

const BoxStyled = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px;
  max-width: 700px;

  height: 60vh;
  background-color: ${(props) => props.theme.palette.grey[200]};

  box-shadow: 24;
  padding: 4px;
  border-radius: 4px;
  overflow: hidden;
  overflow-y: scroll;

  :focus-visible {
    outline: none;
  }
`;

const JsonStyled = styled(Box)`
  border-radius: 4px;
  background: ${(props) => props.theme.palette.grey[100]};
  max-height: 400px;
  overflow-y: scroll;
  border-radius: 4px;
  padding: 8px;
`;

export default function EfxInterfaceDownloadListModal({
  modal,
  setModal,
  dataModal,
}) {
  const theme = useTheme();
  const handleClose = () => {
    setModal({ state: false });
  };
  const [isSubmitting, setIsSubmitting] = useState(false);

  function getNameAndDateFile(param) {
    let fileName = param;
    fileName = fileName.slice(fileName.lastIndexOf("/") + 1, fileName.length);
    let date = param;
    date = date.slice(date.indexOf("/") + 1, date.indexOf("/") + 9);
    return { date: date, fileName: fileName };
  }

  const download = useCallback(async (param) => {
    const { date, fileName } = getNameAndDateFile(param);
    const response = await queryGetEfxDownloadByDateAndFileName(date, fileName);
    if (response.status < 300) {
      toast.success("Your download will start shortly.");
      converterFromBase64(
        `baas_facilitator_payment_${moment().format("DDMMYYYY_HHmmss")}.xlsx`,
        response.body,
        "xlsx"
      );
    } else if ("errors" in response) {
      response.errors.forEach((error) => {
        toast.error(`${error.errorCode} - ${error.message}`);
      });
    } else {
      toast.error(toastMessages.efx.download.error);
    }
  }, []);

  const onSubmit = useCallback(() => {
    download(dataModal.body.fileNameResponse);
    setIsSubmitting(false);
  }, [dataModal, download]);

  return (
    <>
      <BaasModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modal.state}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Formik initialValues={{}} onSubmit={{}}>
          <Form>
            <BoxStyled p={3} theme={theme} sx={{ maxWidth: "700px" }}>
              <BaasText>Details (Object TreeView)</BaasText>
              <BaasGrid p="16px 0px">
                {dataModal.body?.fileNameResponse === undefined ? (
                  <BaasText color="error" p="0px 0px 12px 0px">
                    The submitted archive is not yet processed. Please wait a
                    few minutes or contact user support.
                  </BaasText>
                ) : null}
                <BaasButton
                  backgroundColor="black"
                  variant="contained"
                  loading={isSubmitting}
                  disabled={dataModal.body?.fileNameResponse === undefined}
                  onClick={() => {
                    setIsSubmitting(true);
                    onSubmit();
                  }}
                >
                  Download
                </BaasButton>
              </BaasGrid>
              <JsonStyled theme={theme}>
                <ReactJson
                  name={null}
                  src={dataModal}
                  style={{ height: "100%", overflowY: "scroll" }}
                  displayObjectSize={false}
                  displayDataTypes={false}
                />
              </JsonStyled>
            </BoxStyled>
          </Form>
        </Formik>
      </BaasModal>
    </>
  );
}
